import {checkMobileTablet, detectBrowserName} from "@/helper/BrowserHelper";
import {getSessionStorage, setSessionStorage} from "@/helper/SessionStorageHelper";
import {MutationUser} from "@/store/modules/UserModule/mutation";
import store from '../../store'
import {hasExtension} from "@/service/extension/ExtensionExternalService";
import {BEECOST_EXTENSION_ID} from "@/constant/extension/ExtensionConstant";

const insertIframeBeeCostExtension = () => {
  if (document.querySelector('#my_checking_iframe') == null) {
    try {
      let myIf = document.createElement('div')
      myIf.innerHTML = `<iframe id="my_checking_iframe" src="https://www.beecost.com/is_install_beecost_ext" style="display: none" />`
      document.body.appendChild(myIf)
      return true
    } catch (e) {
      return false
    }
  }
}

const isSupportInstallExtension = () => {
  if (!checkMobileTablet()) {
    const browserName = detectBrowserName()
    if (browserName === 'chrome' || browserName === 'coc_coc' || browserName === 'edge') {
      return true
    }
  }
  return false
}

/**
 * Gọi hàm này để liên tục kiểm tra trạng thái cài đặt beecost extension
 * @param tryTime {number} number of time to try check Beecost Extension
 * @return {Promise<boolean|*>}
 */
const checkBeeCostExtInstall = async (tryTime = 5) => {
  if (document.location.href.includes('beecost.com') || document.location.href.includes('localhost')) {
    return await hasExtension(BEECOST_EXTENSION_ID).then(
      result => {
        store.dispatch(`UserModule/${MutationUser.setIsInstallExtension}`, !!result)
        return result
      }
    )
  }
  let isInstallExtension = isInstalledBeeCostExt()
  if (!isSupportInstallExtension() || isInstallExtension) {
    store.dispatch(`UserModule/${MutationUser.setIsInstallExtension}`, !!isInstallExtension)
    return !!isInstallExtension
  }
  let resultInsert = insertIframeBeeCostExtension()
  if (!resultInsert) {
    return false
  }
  addEventListenerMessageInstallExtension(tryTime + 5)
  const elementIframeBeeCostExtension = document.getElementById('my_checking_iframe')
  let checkBeeCostExtIntervalId = setInterval(function () {
    if (isInstalledBeeCostExt()) {
      clearInterval(checkBeeCostExtIntervalId)
      elementIframeBeeCostExtension.remove()
    } else {
      if (elementIframeBeeCostExtension !== null) {
        if (elementIframeBeeCostExtension != null && window.postMessage) {
          elementIframeBeeCostExtension.contentWindow.postMessage({type: 'CHECK_BEECOST_EXTENSION'}, 'https://www.beecost.com')
        }
      }
    }
  }, 1000)
  setTimeout(() => {
    clearInterval(checkBeeCostExtIntervalId)
  }, 1000 * tryTime)
}

const isInstalledBeeCostExt = () => {
  return store.state.UserModule.isInstallExtension
}

/**
 * add event listener message from beecost iframe
 * @param tryTime {number} number of time to try check Beecost Extension
 */
const addEventListenerMessageInstallExtension = (tryTime= 10) => {
  let is_install_beecost_ext = null
  const receiveBeeIdentified = function (evt) {
    if (evt.origin === 'https://www.beecost.com') {
      if (evt?.data?.is_install_beecost_ext !== null) {
        is_install_beecost_ext = evt.data.is_install_beecost_ext
        if (typeof is_install_beecost_ext === 'boolean') {
          setSessionStorage('is_install_beecost_ext', is_install_beecost_ext)
          store.dispatch(`UserModule/${MutationUser.setIsInstallExtension}`, is_install_beecost_ext)
          window.removeEventListener('message', receiveBeeIdentified)
        }
      }
    }
  }
  window.addEventListener('message', receiveBeeIdentified)
  setTimeout(() => {
    window.removeEventListener('message', receiveBeeIdentified)
    if (isInstalledBeeCostExt() === null) {
      store.dispatch(`UserModule/${MutationUser.setIsInstallExtension}`, false)
    }
  }, 1000 * tryTime)
}

export {
  addEventListenerMessageInstallExtension,
  checkBeeCostExtInstall,
  isInstalledBeeCostExt,
  insertIframeBeeCostExtension,
  isSupportInstallExtension
}
