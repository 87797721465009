import { isJson } from '@/helper/JsonHelper'

const localStorageGet = (key, defaultValue = null) => {
  let value = localStorage.getItem(key)
  if (value != null) {
    if (isJson(value)) {
      return JSON.parse(value)
    } else {
      return value
    }
  } else {
    return defaultValue
  }
}

const localStorageSet = async (key, value) => {
  if (typeof value === 'object') {
    await localStorage.setItem(key, JSON.stringify(value))
  } else {
    await localStorage.setItem(key, value)
  }

}

export {
  localStorageGet,
  localStorageSet,
}
