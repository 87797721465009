import {http} from "@/plugin/http";
import {
  urlApiApplyVoucher,
  urlApiCheckDownloadCSV, urlApiDownloadCSV, urlApiDownloadGroupSearch, urlApiDownloadListShop,
  urlApiGetReferrerVoucher, urlApiGroupSearch, urlApiInsightGeneralSearch
} from "@/api/market/MarketEndpoint";

const apiGroupSearch = async (optionsInput, type) => {
  if (!optionsInput || !type) {
    return
  }
  let options = {
    group_search_type: type,
    ...optionsInput
  }
  const urlApi = urlApiGroupSearch()
  try {
    const response = await http.post(urlApi, options)
    if (response?.data != null) {
      return response.data
    }
  } catch (e) {
    console.error(e)
  }
  return null
}

const apiDownloadGroupSearch = async (optionsInput, type) => {
  if (!optionsInput) {
    return null
  }
  let options = {
    group_search_type: type,
    ...optionsInput
  }
  const config = {
    responseType: 'blob'
  }
  const urlApi = urlApiDownloadGroupSearch()
  try {
    const response = await http.post(urlApi, options, config)
    if (response?.data != null) {
      // console.log(response)
      return response.data
    }
  } catch (e) {
    console.error(e)
  }
  return null
}

const apiInsightGeneralSearch = async (options) => {
  if (!options) {
    return
  }
  const urlApi = urlApiInsightGeneralSearch()
  try {
    const response = await http.post(urlApi, options)
    if (response?.data != null) {
      return response.data
    }
  } catch (e) {
    console.error(e)
  }
  return null
}

const apiCheckDownloadCSV = async (hash) => {
  if (!hash) {
    return
  }
  const urlApi = urlApiCheckDownloadCSV(hash)
  try {
    const response = await http.get(urlApi)
    if (response?.data != null) {
      return response.data
    }
  } catch (e) {
    console.error(e)
  }
  return null
}

const apiDownloadCSV = async (options) => {
  if (!options) {
    return null
  }
  const config = {
    responseType: 'blob'
  }
  const urlApi = urlApiDownloadCSV()
  try {
    const response = await http.post(urlApi, options, config)
    if (response?.data != null) {
      // console.log(response)
      return response.data
    }
  } catch (e) {
    console.error(e)
  }
  return null
}

const apiDownloadListShop = async (options) => {
  if (!options) {
    return null
  }
  const config = {
    responseType: 'blob'
  }
  const urlApi = urlApiDownloadListShop()
  try {
    const response = await http.post(urlApi, options, config)
    if (response?.data != null) {
      // console.log(response)
      return response.data
    }
  } catch (e) {
    console.error(e)
  }
  return null
}

const apiApplyVoucher = async (voucherCode) => {
  if (!voucherCode) {
    return null
  }
  const formData = new FormData();
  formData.append('voucher_code', voucherCode);
  formData.append('cookie',  document.cookie);
  const urlApi = urlApiApplyVoucher()
  try {
    const response = await http.post(urlApi, formData)
    if (response?.data != null) {
      return response.data
    }
  } catch (e) {
    return e.response.data
  }
  return null
}

const apiGetReferralVoucher = async () => {
  const urlApi = urlApiGetReferrerVoucher()
  try {
    const response = await http.get(urlApi)
    if (response?.data != null) {
      return response.data
    }
  } catch (e) {
    console.error(e)
  }
  return null
}

export {
  apiCheckDownloadCSV,
  apiDownloadCSV,
  apiDownloadListShop,
  apiApplyVoucher,
  apiGetReferralVoucher,
  apiInsightGeneralSearch,
  apiGroupSearch,
  apiDownloadGroupSearch
}
