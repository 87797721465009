import {FILTER_OPTION_DEFAULT} from "@/constant/general/PlatformConstant";
import {ALL_PLATFORM_BASE_OBJECT} from "@/constant/general/GeneralConstant";
import {PERMISSION} from "@/constant/general/PermissionConstant";

/**
 * Return platform from ALL_PLATFORM_BASE_OBJECT
 * @param platformId{Number | String}
 * @return {*}
 */
const getPlatformById = (platformId) => {
  if (!platformId) {
    return ALL_PLATFORM_BASE_OBJECT[FILTER_OPTION_DEFAULT.uniqueName]
  }
  for (const platformKey in ALL_PLATFORM_BASE_OBJECT) {
    const platform = ALL_PLATFORM_BASE_OBJECT[platformKey]
    if (platform.platform_id == platformId) {
      return platform
    }
  }
  return ALL_PLATFORM_BASE_OBJECT[FILTER_OPTION_DEFAULT.uniqueName]
}

const getPlatformByUrl = (url) => {
  if (!url) {
    return null
  }
  try {
    url = new URL(url)
  } catch (e) {
    return null
  }
  for (const platformKey in ALL_PLATFORM_BASE_OBJECT) {
    const platform = ALL_PLATFORM_BASE_OBJECT[platformKey]
    if (url.hostname.indexOf(platform.name.toLowerCase()) > -1) {
      return platform
    }
  }
}

const getBunchOfAvailablePlatformObjectByPermissionName = (permissionName) => {
  if (!permissionName || !PERMISSION[permissionName]) {
    return null
  }
  let permission = PERMISSION[permissionName]
  let result = {}
  for (const availablePlatformUniqueName of permission.availablePlatform) {
    result[availablePlatformUniqueName] = ALL_PLATFORM_BASE_OBJECT[availablePlatformUniqueName]
  }
  if (Object.keys(result).length === 0) {
    return null
  }
  return result
}

export {
  getPlatformById,
  getPlatformByUrl,
  getBunchOfAvailablePlatformObjectByPermissionName,
}
