import {
  ALL_FIELD_FILTER,
  ALL_FIXED_DURATION_DAY,
  ALL_SHOP_TYPE
} from "@/constant/search/SearchConstant";
import {
  ALL_CHART_TAB,
  ALL_PLATFORM_BASE_OBJECT,
} from "@/constant/general/GeneralConstant";
import {
  ALL_CATEGORIES_BY_PLATFORM,
  ALL_CATEGORIES_LV2_BY_PLATFORM
} from "@/constant/product/ProductCategoriesConstant";
import {locationsWarehouse} from "@/constant/product/LocationWarehouseConstant";
import {
  FIXED_VALUE_COMPARE_BAD_PRODUCT_CONDITION_LIST,
  FIXED_VALUE_COMPARE_TRENDING,
} from "@/constant/search/FixedOptionFilterConstant";

const getAllValueComparisonFieldFilter = (platformUniqueName) => {
  let result = {}
  for (const allFieldFilterKey in ALL_FIELD_FILTER) {
    let elem = ALL_FIELD_FILTER[allFieldFilterKey]
    if (elem.availableComparisonPlatformUniqueNameList.includes(platformUniqueName)) {
      result[allFieldFilterKey] = elem
    }
  }
  return result
}

const getAllValueRangeFieldFilter = (platformUniqueName) => {
  let result = {}
  for (const allFieldFilterKey in ALL_FIELD_FILTER) {
    let elem = ALL_FIELD_FILTER[allFieldFilterKey]
    if (elem.availableSetRangePlatformUniqueNameList.includes(platformUniqueName)) {
      result[allFieldFilterKey] = elem
    }
  }
  return result
}

const ALL_FILTER_OPTION_BY_PLATFORM_OBJECT = {
  all_vietnam: {
    platform_id: ALL_PLATFORM_BASE_OBJECT.all_vietnam.platform_id,
    uniqueName: ALL_PLATFORM_BASE_OBJECT.all_vietnam.uniqueName,
    allDurationDay: [...ALL_FIXED_DURATION_DAY],
    hasDurationRange: true,
    hasSmartSearch: true,
    allCategories: ALL_CATEGORIES_BY_PLATFORM[ALL_PLATFORM_BASE_OBJECT.all_vietnam.uniqueName],
    allCategoriesDetail: ALL_CATEGORIES_LV2_BY_PLATFORM[ALL_PLATFORM_BASE_OBJECT.all_vietnam.uniqueName],
    allShopType: null,
    allLocation: null,
    allValueCompareFieldFilter: getAllValueComparisonFieldFilter(ALL_PLATFORM_BASE_OBJECT.all_vietnam.uniqueName),
    allValueRangeFieldFilter: getAllValueRangeFieldFilter(ALL_PLATFORM_BASE_OBJECT.all_vietnam.uniqueName),
    allFixedOptionFilter: {
      trending: FIXED_VALUE_COMPARE_TRENDING,
      badProduct: FIXED_VALUE_COMPARE_BAD_PRODUCT_CONDITION_LIST,
      // goodSeller: FIXED_VALUE_RANGE_GOOD_SELLER_PRODUCT
    }
  },
  shopee: {
    platform_id: ALL_PLATFORM_BASE_OBJECT.shopee.platform_id,
    uniqueName: ALL_PLATFORM_BASE_OBJECT.shopee.uniqueName,
    allDurationDay: [...ALL_FIXED_DURATION_DAY],
    hasDurationRange: true,
    hasSmartSearch: true,
    allCategories: ALL_CATEGORIES_BY_PLATFORM[ALL_PLATFORM_BASE_OBJECT.shopee.uniqueName],
    allCategoriesDetail: ALL_CATEGORIES_LV2_BY_PLATFORM[ALL_PLATFORM_BASE_OBJECT.shopee.uniqueName],
    allShopType: {...ALL_SHOP_TYPE},
    allLocation: {...locationsWarehouse},
    allValueCompareFieldFilter: getAllValueComparisonFieldFilter(ALL_PLATFORM_BASE_OBJECT.shopee.uniqueName),
    allValueRangeFieldFilter: getAllValueRangeFieldFilter(ALL_PLATFORM_BASE_OBJECT.shopee.uniqueName),
    allFixedOptionFilter: {
      trending: FIXED_VALUE_COMPARE_TRENDING,
      badProduct: FIXED_VALUE_COMPARE_BAD_PRODUCT_CONDITION_LIST,
      // goodSeller: FIXED_VALUE_RANGE_GOOD_SELLER_PRODUCT
    }
  },
  sendo: {
    platform_id: ALL_PLATFORM_BASE_OBJECT.sendo.platform_id,
    uniqueName: ALL_PLATFORM_BASE_OBJECT.sendo.uniqueName,
    allDurationDay: [...ALL_FIXED_DURATION_DAY],
    hasDurationRange: true,
    hasSmartSearch: true,
    allCategories: ALL_CATEGORIES_BY_PLATFORM[ALL_PLATFORM_BASE_OBJECT.sendo.uniqueName],
    allCategoriesDetail: ALL_CATEGORIES_LV2_BY_PLATFORM[ALL_PLATFORM_BASE_OBJECT.sendo.uniqueName],
    allShopType: {...ALL_SHOP_TYPE},
    allLocation: {...locationsWarehouse},
    allValueCompareFieldFilter: getAllValueComparisonFieldFilter(ALL_PLATFORM_BASE_OBJECT.sendo.uniqueName),
    allValueRangeFieldFilter: getAllValueRangeFieldFilter(ALL_PLATFORM_BASE_OBJECT.sendo.uniqueName),
    allFixedOptionFilter: {
      trending: FIXED_VALUE_COMPARE_TRENDING,
      badProduct: FIXED_VALUE_COMPARE_BAD_PRODUCT_CONDITION_LIST,
      // goodSeller: FIXED_VALUE_RANGE_GOOD_SELLER_PRODUCT
    }
  },
  tiki: {
    platform_id: ALL_PLATFORM_BASE_OBJECT.tiki.platform_id,
    uniqueName: ALL_PLATFORM_BASE_OBJECT.tiki.uniqueName,
    allDurationDay: [...ALL_FIXED_DURATION_DAY],
    hasDurationRange: true,
    hasSmartSearch: true,
    allCategories: ALL_CATEGORIES_BY_PLATFORM[ALL_PLATFORM_BASE_OBJECT.tiki.uniqueName],
    allCategoriesDetail: ALL_CATEGORIES_LV2_BY_PLATFORM[ALL_PLATFORM_BASE_OBJECT.tiki.uniqueName],
    allShopType: null,
    allLocation: null,
    allValueCompareFieldFilter: getAllValueComparisonFieldFilter(ALL_PLATFORM_BASE_OBJECT.tiki.uniqueName),
    allValueRangeFieldFilter: getAllValueRangeFieldFilter(ALL_PLATFORM_BASE_OBJECT.tiki.uniqueName),
    allFixedOptionFilter: {
      trending: FIXED_VALUE_COMPARE_TRENDING,
      badProduct: FIXED_VALUE_COMPARE_BAD_PRODUCT_CONDITION_LIST,
      // goodSeller: FIXED_VALUE_RANGE_GOOD_SELLER_PRODUCT
    }
  },
  lazada: {
    platform_id: ALL_PLATFORM_BASE_OBJECT.lazada.platform_id,
    uniqueName: ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName,
    allDurationDay: ALL_FIXED_DURATION_DAY.filter(item => item <= 180),
    hasDurationRange: true,
    hasSmartSearch: true,
    allCategories: ALL_CATEGORIES_BY_PLATFORM[ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName],
    allShopType: {...ALL_SHOP_TYPE},
    allLocation: null,
    allValueCompareFieldFilter: getAllValueComparisonFieldFilter(ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName),
    allValueRangeFieldFilter: getAllValueRangeFieldFilter(ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName),
    allFixedOptionFilter: {
      trending: FIXED_VALUE_COMPARE_TRENDING,
      badProduct: FIXED_VALUE_COMPARE_BAD_PRODUCT_CONDITION_LIST,
      // goodSeller: FIXED_VALUE_RANGE_GOOD_SELLER_PRODUCT
    }
  },
  _1688: {
    platform_id: ALL_PLATFORM_BASE_OBJECT._1688.platform_id,
    uniqueName: ALL_PLATFORM_BASE_OBJECT._1688.uniqueName,
    allDurationDay: [ALL_FIXED_DURATION_DAY[1]],
    hasDurationRange: false,
    hasSmartSearch: true,
    allCategories: ALL_CATEGORIES_BY_PLATFORM[ALL_PLATFORM_BASE_OBJECT._1688.uniqueName],
    allShopType: null,
    allLocation: null,
    allValueCompareFieldFilter: getAllValueComparisonFieldFilter(ALL_PLATFORM_BASE_OBJECT._1688.uniqueName),
    allValueRangeFieldFilter: getAllValueRangeFieldFilter(ALL_PLATFORM_BASE_OBJECT._1688.uniqueName),
    allFixedOptionFilter: null
  },
  taobao: {
    platform_id: ALL_PLATFORM_BASE_OBJECT.taobao.platform_id,
    uniqueName: ALL_PLATFORM_BASE_OBJECT.taobao.uniqueName,
    allDurationDay: [ALL_FIXED_DURATION_DAY[1]],
    hasDurationRange: false,
    hasSmartSearch: true,
    allCategories: ALL_CATEGORIES_BY_PLATFORM[ALL_PLATFORM_BASE_OBJECT.taobao.uniqueName],
    allShopType: {...ALL_SHOP_TYPE},
    allLocation: null,
    allValueCompareFieldFilter: getAllValueComparisonFieldFilter(ALL_PLATFORM_BASE_OBJECT.taobao.uniqueName),
    allValueRangeFieldFilter: getAllValueRangeFieldFilter(ALL_PLATFORM_BASE_OBJECT.taobao.uniqueName),
    allFixedOptionFilter: null
  },
  tmall: {
    platform_id: ALL_PLATFORM_BASE_OBJECT.tmall.platform_id,
    uniqueName: ALL_PLATFORM_BASE_OBJECT.tmall.uniqueName,
    allDurationDay: [ALL_FIXED_DURATION_DAY[1]],
    hasDurationRange: false,
    hasSmartSearch: true,
    allCategories: ALL_CATEGORIES_BY_PLATFORM[ALL_PLATFORM_BASE_OBJECT.tmall.uniqueName],
    allShopType: null,
    allLocation: null,
    allValueCompareFieldFilter: getAllValueComparisonFieldFilter(ALL_PLATFORM_BASE_OBJECT.tmall.uniqueName),
    allValueRangeFieldFilter: getAllValueRangeFieldFilter(ALL_PLATFORM_BASE_OBJECT.tmall.uniqueName),
    allFixedOptionFilter: null
  }
}
const PLATFORM_CHART_TAB = {
  all_vietnam: [
    ALL_CHART_TAB.revenue.name,
    ALL_CHART_TAB.orderCount.name,
  ],
  shopee: [
    ALL_CHART_TAB.revenue.name,
    ALL_CHART_TAB.orderCount.name,
  ],
  lazada: [
    ALL_CHART_TAB.revenue.name,
    ALL_CHART_TAB.orderCount.name,
    // ALL_CHART_TAB.reviewCount.name,
  ],
  tiki: [
    ALL_CHART_TAB.revenue.name,
    ALL_CHART_TAB.orderCount.name,
  ],
  sendo: [
    ALL_CHART_TAB.revenue.name,
    ALL_CHART_TAB.orderCount.name,
  ],
  _1688: [
    ALL_CHART_TAB.revenue.name,
    ALL_CHART_TAB.orderCount.name,
  ],
  taobao: [
    ALL_CHART_TAB.revenue.name,
    ALL_CHART_TAB.orderCount.name,
  ],
  tmall: [
    ALL_CHART_TAB.revenue.name,
    ALL_CHART_TAB.orderCount.name,
  ],
}
const FILTER_OPTION_DEFAULT = ALL_FILTER_OPTION_BY_PLATFORM_OBJECT.shopee
const FILTER_OPTION_GLOBAL_DEFAULT = ALL_FILTER_OPTION_BY_PLATFORM_OBJECT._1688

export {
  ALL_FILTER_OPTION_BY_PLATFORM_OBJECT,
  FILTER_OPTION_DEFAULT,
  FILTER_OPTION_GLOBAL_DEFAULT,
  PLATFORM_CHART_TAB
}
