const MutationSidebar = {
  MODULE_PATH: 'SidebarModule',
  isCollapsed: 'isCollapsed',
  menuSelected: 'menuSelected',
  RESET: 'RESET',
}

export {
  MutationSidebar
}
