import Vue from 'vue'
import App from '@/App.vue'

// import plugins
import '@/plugin/ant'
import '@/plugin/meta'
import '@/plugin/analytics'
import '@/plugin/scrollmagic'
import '@/assets/lib/bulma_sass_market/bulma.sass'

import '@/assets/app/app.scss'
import '@/assets/styles/animation/slide.scss'
import '@/assets/styles/animation/scale.scss'


import router from './router'
import store from './store'

import {getDeviceInformation, composeDeviceInformation } from '@/helper/FingerPrintHelper'
import {apiGoogleAuthCallback} from "@/api/auth/ApiAuth";
import {MutationGeneral} from "@/store/modules/GeneralModule/mutation";


window.handleCredentialResponse = (response) => {
  getDeviceInformation().then(result => {
    const visitorData = composeDeviceInformation(result)
    const visitorId = visitorData['visitor_id']
    const payload = {
      google_token: response.credential,
      ...visitorData,
    }
    apiGoogleAuthCallback(payload).then(res => {
      if (res === 423) {
        store.commit(`GeneralModule/${MutationGeneral.showPopupLimitDeviceManual}`, true)
      } else {
        localStorage.setItem("__visitor", JSON.stringify({
          ...visitorData,
          'visitor_id': `${res.user.id}__${visitorId}`
        }))
        localStorage.setItem("__visitor_raw", JSON.stringify(result))
        localStorage.setItem('access_token', res['access_token'])
        localStorage.setItem('refresh_token', res['refresh_token'])
        localStorage.setItem('user_profile', JSON.stringify(res.user))
        localStorage.setItem('full_name', res.user.name)
        window.location.href = '/analytics'
      }
    })
  })
}

let vm = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')

export default {
  vm
}
