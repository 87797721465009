import { MutationGeneral } from '@/store/modules/GeneralModule/mutation'

const initialState = () => {
  return {
    actionName: '',
    showPopupLogin: false,
    showPopupContactSale: false,
    showPopupSupport: false,
    showPopupSearchDetail: false,
    showPopupUpgradeAdvance: false,
    showPopupPricing: false,
    showPopupInstallExtension: false,
    showPopupShareLink: false,
    showPopupPermissionNotification: false,
    showPopupMarketNotification: false,
    showPopupNotification: false,
    showPopupSignUpLeadForm: false,
    showPopupMobileNotification: false,
    showPopupPromotedNotification: false,
    showPopupSystemError: false,
    showPopupSearchFilter: false,
    showPopupChatToDownload: false,
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters: {
    showPopupChatToDownload: state => state[MutationGeneral.showPopupChatToDownload],
    [MutationGeneral.setShowPopupSignUpLeadForm]: state => state.showPopupSignUpLeadForm,
  },
  mutations: {
    [MutationGeneral.setActionName](state, value) {
      state.actionName = value
    },
    [MutationGeneral.setShowPopupLogin](state, value) {
      state.showPopupLogin = value
    },
    [MutationGeneral.setShowPopupContactSale](state, value) {
      state.showPopupContactSale = value
    },
    [MutationGeneral.setShowPopupSupport](state, value) {
      state.showPopupSupport = value
    },
    [MutationGeneral.setShowPopupSearchDetail](state, value) {
      state.showPopupSearchDetail = value
    },
    [MutationGeneral.setShowPopupUpgradeAdvance](state, value) {
      state.showPopupUpgradeAdvance = value
    },
    [MutationGeneral.setShowPopupPricing](state, value) {
      state.showPopupPricing = value
    },
    [MutationGeneral.setShowPopupSignUpLeadForm](state, value) {
      state.showPopupSignUpLeadForm = value
    },
    [MutationGeneral.setShowPopupInstallExtension](state, value) {
      state.showPopupInstallExtension = value
    },
    [MutationGeneral.setShowPopupShareLink](state, value) {
      state.showPopupShareLink = value
    },
    [MutationGeneral.setShowPopupPermissionNotification](state, value) {
      state.showPopupPermissionNotification = value
    },
    [MutationGeneral.setShowPopupMarketNotification](state, value) {
      state.showPopupMarketNotification = value
    },
    [MutationGeneral.setShowPopupNotification](state, value) {
      state.showPopupNotification = value
    },
    [MutationGeneral.showPopupMobileNotification](state, value) {
      state.showPopupMobileNotification = value
    },
    [MutationGeneral.setShowPopupPromotedNotification](state, value) {
      state.showPopupPromotedNotification = value
    },
    [MutationGeneral.setShowPopupSystemError](state, value) {
      state.showPopupSystemError = value
    },
    [MutationGeneral.setShowPopupSearchFilter](state, value) {
      state.showPopupSearchFilter = value
    },
    [MutationGeneral.showPopupChatToDownload](state, value) {
      state.showPopupChatToDownload = value
    },
    [MutationGeneral.RESET](state) {
      const s = initialState()
      Object.keys(s)
        .forEach(key => {
          state[key] = s[key]
        })
    }
  },
  actions: {
    [MutationGeneral.RESET](context) {
      context.commit(MutationGeneral.RESET)
    },
    [MutationGeneral.setShowPopupShareLink](context, payload) {
      context.commit(MutationGeneral.setShowPopupShareLink, payload)
    },
    [MutationGeneral.setShowPopupUpgradeAdvance](context, payload) {
      context.commit(MutationGeneral.setShowPopupUpgradeAdvance, payload)
    },
    [MutationGeneral.setShowPopupPermissionNotification](context, payload) {
      context.commit(MutationGeneral.setShowPopupPermissionNotification, payload)
    },
    [MutationGeneral.setShowPopupMarketNotification](context, payload) {
      context.commit(MutationGeneral.setShowPopupMarketNotification, payload)
    },
    [MutationGeneral.setShowPopupNotification](context, payload) {
      context.commit(MutationGeneral.setShowPopupNotification, payload)
    },
    [MutationGeneral.showPopupMobileNotification](context, payload) {
      context.commit(MutationGeneral.showPopupMobileNotification, payload)
    },
    [MutationGeneral.setShowPopupPromotedNotification](context, payload) {
      context.commit(MutationGeneral.setShowPopupPromotedNotification, payload)
    },
    [MutationGeneral.setShowPopupSystemError](context, payload) {
      context.commit(MutationGeneral.setShowPopupSystemError, payload)
    },
    [MutationGeneral.setShowPopupSearchFilter](context, payload) {
      context.commit(MutationGeneral.setShowPopupSearchFilter, payload)
    },
    [MutationGeneral.setShowPopupPricing](context, payload) {
      context.commit(MutationGeneral.setShowPopupPricing, payload)
    },
    [MutationGeneral.showPopupChatToDownload](context, payload) {
      context.commit(MutationGeneral.showPopupChatToDownload, payload)
    },
  }
}
