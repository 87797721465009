import {ALL_PLATFORM_BASE_OBJECT} from "@/constant/general/GeneralConstant";
import {MutationSearchFilter} from "@/store/modules/SearchFilterModule/mutation";

const ALL_FIELD_FILTER = {
  'price': {
    id: 'price',
    title: 'Giá',
    availableSetRangePlatformUniqueNameList: [
      ALL_PLATFORM_BASE_OBJECT.all_vietnam.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.shopee.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.tiki.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.sendo.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName,
      ALL_PLATFORM_BASE_OBJECT._1688.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.taobao.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.tmall.uniqueName,
    ],
    availableComparisonPlatformUniqueNameList: [
      ALL_PLATFORM_BASE_OBJECT.all_vietnam.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.shopee.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.tiki.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.sendo.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName,
    ],
    availableSort: true,
  },
  'rating_count': {
    id: 'rating_count',
    title: 'Tổng đánh giá',
    availableSetRangePlatformUniqueNameList: [
      ALL_PLATFORM_BASE_OBJECT.all_vietnam.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.shopee.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.tiki.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.sendo.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName,
    ],
    availableComparisonPlatformUniqueNameList: [
      ALL_PLATFORM_BASE_OBJECT.all_vietnam.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.shopee.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.tiki.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.sendo.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName,
    ],
    availableSort: true,
  },
  'order_count': {
    id: 'order_count',
    title: 'Tổng đã bán',
    availableSetRangePlatformUniqueNameList: [
      ALL_PLATFORM_BASE_OBJECT.all_vietnam.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.shopee.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.tiki.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.sendo.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName,
    ],
    availableComparisonPlatformUniqueNameList: [
      ALL_PLATFORM_BASE_OBJECT.all_vietnam.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.shopee.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.tiki.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.sendo.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName,
    ],
    availableSort: true,
  },
  'platform_created_at': {
    id: 'platform_created_at',
    title: 'Ngày tạo sản phẩm',
    type: 'date',
    availableSetRangePlatformUniqueNameList: [
      ALL_PLATFORM_BASE_OBJECT.all_vietnam.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.shopee.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.tiki.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName,
    ],
    availableComparisonPlatformUniqueNameList: [
    ]
  },
  'order_count_7d': {
    id: 'order_count_7d',
    title: 'Tổng đã bán 7 ngày',
    availableSetRangePlatformUniqueNameList: [
      ALL_PLATFORM_BASE_OBJECT.all_vietnam.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.shopee.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.tiki.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.sendo.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName,
    ],
    availableComparisonPlatformUniqueNameList: [
      ALL_PLATFORM_BASE_OBJECT.all_vietnam.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.shopee.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.tiki.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.sendo.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName,
    ],
    availableSort: true,
  },
  'order_count_30d': {
    id: 'order_count_30d',
    title: 'Tổng đã bán 30 ngày',
    availableSetRangePlatformUniqueNameList: [
      ALL_PLATFORM_BASE_OBJECT.all_vietnam.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.shopee.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.tiki.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.sendo.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName,
    ],
    availableComparisonPlatformUniqueNameList: [
      ALL_PLATFORM_BASE_OBJECT.all_vietnam.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.shopee.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.tiki.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.sendo.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName,
    ],
    availableSort: true,
  },
  'order_count_90d': {
    id: 'order_count_90d',
    title: 'Tổng đã bán 90 ngày',
    availableSetRangePlatformUniqueNameList: [
      ALL_PLATFORM_BASE_OBJECT.all_vietnam.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.shopee.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.tiki.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.sendo.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName,
    ],
    availableComparisonPlatformUniqueNameList: [
      ALL_PLATFORM_BASE_OBJECT.all_vietnam.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.shopee.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.tiki.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.sendo.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName,
    ],
    availableSort: true,
  },
  'order_count_180d': {
    id: 'order_count_180d',
    title: 'Tổng đã bán 180 ngày',
    availableSetRangePlatformUniqueNameList: [
      ALL_PLATFORM_BASE_OBJECT.all_vietnam.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.shopee.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.tiki.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.sendo.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName,
    ],
    availableComparisonPlatformUniqueNameList: [
      ALL_PLATFORM_BASE_OBJECT.all_vietnam.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.shopee.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.tiki.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.sendo.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName,
    ],
    availableSort: true,
  },
  // 'order_count_custom': {
  //   id: 'order_count_custom',
  //   title: 'Tổng đã bán tuỳ chỉnh',
  //   availableSetRangePlatformUniqueNameList: [
  //     ALL_PLATFORM_BASE_OBJECT.shopee.uniqueName,
  //     ALL_PLATFORM_BASE_OBJECT.tiki.uniqueName,
  //     ALL_PLATFORM_BASE_OBJECT.sendo.uniqueName,
  //     ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName,
  //   ],
  //   availableComparisonPlatformUniqueNameList: [
  //     ALL_PLATFORM_BASE_OBJECT.shopee.uniqueName,
  //     ALL_PLATFORM_BASE_OBJECT.tiki.uniqueName,
  //     ALL_PLATFORM_BASE_OBJECT.sendo.uniqueName,
  //     ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName,
  //   ],
  //   availableSort: true,
  // },
  'revenue': {
    id: 'revenue',
    title: 'Tổng doanh số',
    availableSetRangePlatformUniqueNameList: [
      ALL_PLATFORM_BASE_OBJECT.all_vietnam.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.shopee.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.tiki.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.sendo.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName,
    ],
    availableComparisonPlatformUniqueNameList: [
      ALL_PLATFORM_BASE_OBJECT.all_vietnam.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.shopee.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.tiki.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.sendo.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName,
    ],
    availableSort: true,
  },
  'order_revenue_7d': {
    id: 'order_revenue_7d',
    title: 'Tổng doanh số 7 ngày',
    availableSetRangePlatformUniqueNameList: [
      ALL_PLATFORM_BASE_OBJECT.all_vietnam.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.shopee.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.tiki.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.sendo.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName,
    ],
    availableComparisonPlatformUniqueNameList: [
      ALL_PLATFORM_BASE_OBJECT.all_vietnam.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.shopee.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.tiki.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.sendo.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName,
    ],
    availableSort: true,
  },
  'order_revenue_30d': {
    id: 'order_revenue_30d',
    title: 'Tổng doanh số 30 ngày',
    availableSetRangePlatformUniqueNameList: [
      ALL_PLATFORM_BASE_OBJECT.all_vietnam.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.shopee.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.tiki.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.sendo.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName,
    ],
    availableComparisonPlatformUniqueNameList: [
      ALL_PLATFORM_BASE_OBJECT.all_vietnam.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.shopee.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.tiki.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.sendo.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName,
    ],
    availableSort: true,
  },
  'order_revenue_90d': {
    id: 'order_revenue_90d',
    title: 'Tổng doanh số 90 ngày',
    availableSetRangePlatformUniqueNameList: [
      ALL_PLATFORM_BASE_OBJECT.all_vietnam.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.shopee.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.tiki.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.sendo.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName,
    ],
    availableComparisonPlatformUniqueNameList: [
      ALL_PLATFORM_BASE_OBJECT.all_vietnam.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.shopee.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.tiki.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.sendo.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName,
    ],
    availableSort: true,
  },
  'order_revenue_180d': {
    id: 'order_revenue_180d',
    title: 'Tổng doanh số 180 ngày',
    availableSetRangePlatformUniqueNameList: [
      ALL_PLATFORM_BASE_OBJECT.all_vietnam.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.shopee.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.tiki.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.sendo.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName,
    ],
    availableComparisonPlatformUniqueNameList: [
      ALL_PLATFORM_BASE_OBJECT.all_vietnam.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.shopee.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.tiki.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.sendo.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName,
    ],
    availableSort: true,
  },
  // 'order_revenue_custom': {
  //   id: 'order_revenue_custom',
  //   title: 'Tổng doanh số tuỳ chỉnh',
  //   availableSetRangePlatformUniqueNameList: [
  //     ALL_PLATFORM_BASE_OBJECT.shopee.uniqueName,
  //     ALL_PLATFORM_BASE_OBJECT.tiki.uniqueName,
  //     ALL_PLATFORM_BASE_OBJECT.sendo.uniqueName,
  //     ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName,
  //   ],
  //   availableComparisonPlatformUniqueNameList: [
  //     ALL_PLATFORM_BASE_OBJECT.shopee.uniqueName,
  //     ALL_PLATFORM_BASE_OBJECT.tiki.uniqueName,
  //     ALL_PLATFORM_BASE_OBJECT.sendo.uniqueName,
  //     ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName,
  //   ],
  //   availableSort: true,
  // },
  'view_count': {
    id: 'view_count',
    title: 'Tổng lượt xem',
    availableSetRangePlatformUniqueNameList: [
    ],
    availableComparisonPlatformUniqueNameList: [
    ],
    availableSort: true,
  },
  // 'review_count': {
  //   id: 'review_count',
  //   title: 'Tổng đánh giá',
  //   availableSetRangePlatformUniqueNameList: [
  //     ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName,
  //   ],
  //   availableComparisonPlatformUniqueNameList: [
  //     ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName
  //   ],
  //     availableSort: true,
  // },
  'review_count_7d': {
    id: 'review_count_7d',
    title: 'Đánh giá 7 ngày',
    availableSetRangePlatformUniqueNameList: [
      ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName,
    ],
    availableComparisonPlatformUniqueNameList: [
      ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName
    ],
    availableSort: true,
  },
  'review_count_30d': {
    id: 'review_count_30d',
    title: 'Đánh giá 30 ngày',
    availableSetRangePlatformUniqueNameList: [
      ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName,
    ],
    availableComparisonPlatformUniqueNameList: [
      ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName
    ],
    availableSort: true,
  },
  'review_count_90d': {
    id: 'review_count_90d',
    title: 'Đánh giá 90 ngày',
    availableSetRangePlatformUniqueNameList: [
      ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName,
    ],
    availableComparisonPlatformUniqueNameList: [
      ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName
    ],
    availableSort: true,
  },
  'review_count_180d': {
    id: 'review_count_180d',
    title: 'Đánh giá 180 ngày',
    availableSetRangePlatformUniqueNameList: [
      ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName,
    ],
    availableComparisonPlatformUniqueNameList: [
      ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName
    ],
    availableSort: true,
  },
  // 'review_count_custom': {
  //   id: 'review_count_custom',
  //   title: 'Đánh giá tuỳ chỉnh',
  //   availableSetRangePlatformUniqueNameList: [
  //     ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName,
  //   ],
  //   availableComparisonPlatformUniqueNameList: [
  //     ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName
  //   ],
  //   availableSort: true,
  // }
}

const ALL_COMPARISON_OPERATOR = [
  '>',
  '<',
  '=',
  '>=',
  '<='
]

const ALL_FIXED_DURATION_DAY = [
  7,
  30,
  90,
  180,
  365
]

const ALL_SHOP_TYPE = {
  mall: {
    name: 'mall',
    label: 'Shop Chính hãng (Mall)',
    fieldNameInStore: MutationSearchFilter.iIsShopMall,
    id: 1,
    color: '#D92530',
  },
  normal: {
    name: 'normal',
    label: 'Shop Thông thường',
    fieldNameInStore: MutationSearchFilter.iIsShopNormal,
    id: 0,
    color: '#A4B9CD',
  }
}

const VALUE_RANGE_DEFAULT = 'Tên số liệu'

const VALUE_COMPARE_DEFAULT_OBJECT = {
  firstField: VALUE_RANGE_DEFAULT,
  ratio: 1,
  comparisonOperator: ALL_COMPARISON_OPERATOR[0],
  secondField: VALUE_RANGE_DEFAULT,
}

export {
  ALL_COMPARISON_OPERATOR,
  ALL_FIXED_DURATION_DAY,
  ALL_FIELD_FILTER,
  ALL_SHOP_TYPE,
  VALUE_RANGE_DEFAULT,
  VALUE_COMPARE_DEFAULT_OBJECT
}
