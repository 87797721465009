import {PERMISSION} from "@/constant/general/PermissionConstant";

const MutationUser = {
  MODULE_PATH: 'UserModule',
  setIsLogin: 'setIsLogin',
  setUserProfile: 'setUserProfile',
  setFullName: 'setFullName',
  setIsExpired: 'setIsExpired',
  isBusinessAccount: 'isBusinessAccount',
  isCompanyAccount: 'isCompanyAccount',
  isAgencyOrHigherPriorityAccount: 'isAgencyOrHigherPriorityAccount',
  isPaidAccount: 'isPaidAccount',
  isDefaultAccountPlan: 'isDefaultAccountPlan',
  isBasicAccountPlan: 'isBasicAccountPlan',
  isStandardAccountPlan: 'isStandardAccountPlan',
  isAdvanceAccountPlan: 'isAdvanceAccountPlan',
  isBusinessAccountPlan: 'isBusinessAccountPlan',
  isCompanyAccountPlan: 'isCompanyAccountPlan',
  isAgencyAccountPlan: 'isAgencyAccountPlan',
  isDefaultAccount: 'isDefaultAccount',
  isBasicAccount: 'isBasicAccount',
  isStandardAccount: 'isStandardAccount',
  isStandardBAccount: 'isStandardBAccount',
  isAdvanceAccount: 'isAdvanceAccount',
  permissionAvailableName: 'permissionAvailableName',
  permissionPlanName: 'permissionPlanName',
  freeSearchCount: 'freeSearchCount',
  freeSearchCountPerWeek: 'freeSearchCountPerWeek',
  remainProductDownloadCountPerWeek: 'remainProductDownloadCountPerWeek',
  remainShopDownloadCountPerWeek: 'remainShopDownloadCountPerWeek',
  setExpirationDate: 'setExpirationDate',
  setIsInstallExtension: 'setIsInstallExtension',
  setInactiveVouchers: 'setInactiveVouchers',
  setSelectedVoucherId: 'setSelectedVoucherId',
  setPromoteVoucher: 'setPromoteVoucher',
  setMarketReferralLink: 'setMarketReferralLink',
  setVoucherCode: 'setVoucherCode',
  paymentQRCode: 'paymentQRCode',
  setPercentPaymentProgress: 'setPercentPaymentProgress',
  setIsSuccessfulPayment: 'setIsSuccessfulPayment',
  RESET: 'RESET',

}

export {
  MutationUser
}
