import { MutationSearchProductResult } from '@/store/modules/SearchProductResultModule/mutation'

const initialState = () => {
  return {
    products: [],
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters: {
    [MutationSearchProductResult.products]: state => state.products
  },
  mutations: {
    [MutationSearchProductResult.products](state, value) {
      state.products = value
    },
    [MutationSearchProductResult.RESET](state) {
      const s = initialState()
      Object.keys(s)
        .forEach(key => {
          state[key] = s[key]
        })
    }
  },
  actions: {
    [MutationSearchProductResult.RESET](context) {
      context.commit(MutationSearchProductResult.RESET)
    }
  }
}
