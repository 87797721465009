const insertScriptRaw = (scriptText, idAdjacentElement) => {
  let script = document.createElement('script')
  script.setAttribute('type', 'text/javascript')
  script.setAttribute('language', 'javascript')
  script.textContent = scriptText
  if (document.getElementById(idAdjacentElement)) {
    document.getElementById(idAdjacentElement)
      .insertAdjacentElement('afterend', script)
  }
}

const insertScriptSrc = (src, idAdjacentElement, position = 'afterend', idValue = null) => {
  let script = document.createElement('script')
  script.setAttribute('type', 'text/javascript')
  script.setAttribute('language', 'javascript')
  script.setAttribute('src', src)
  if (idValue != null) {
    script.setAttribute('id', idValue)
  }
  // console.log('insert getElementById: ', document.getElementById(idAdjacentElement))

  if (document.getElementById(idAdjacentElement)) {
    document.getElementById(idAdjacentElement)
      .insertAdjacentElement(position, script)
  }
}

export {
  insertScriptRaw,
  insertScriptSrc
}
