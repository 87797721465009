import {URL_BACKEND_API, URL_SHOP_BACKEND_API} from '@/config/ConfigEndpoint'

const urlApiProductSearch = () => {
  // return `${URL_DATA_API}/api/product/search`
  return `${URL_BACKEND_API}/market/search_basic`
}

const urlApiShopSearch = () => {
  return `${URL_SHOP_BACKEND_API}/market/shop/search`
}

const urlApiKeywordSearch = () => {
  return `${URL_SHOP_BACKEND_API}/market/keyword/search`
}

const urlApiSearchConfig = (listPlatformId) => {
  return `${URL_BACKEND_API}/market/search_basic?platformId=${listPlatformId.join('&platformId=')}`
}

const urlApiProductSearchDemo = (listPlatformId, endDateString = null) => {
  let url = `${URL_BACKEND_API}/market/search_init`
  if (listPlatformId?.length && endDateString) {
    return url + `?platform_id=${listPlatformId.join('&platform_id=')}&end_date=${endDateString}`
  }
  if(listPlatformId?.length) {
    return url + `?platform_id=${listPlatformId.join('&platform_id=')}`
  }
  if(endDateString) {
    return url + `?end_date=${endDateString}`
  }
}

const urlApiProductHistoryPrice = (productBaseId) => {
  return `${URL_BACKEND_API}/market/history/price?product_base_id=${productBaseId}`
}

const urlApiProductOrderPrice = (productBaseId) => {
  return `${URL_BACKEND_API}/market/history/order?product_base_id=${productBaseId}`
}

const urlCheckProductLink = () => {
  return `${URL_BACKEND_API}/market/product/detail`
}

export {
  urlApiProductSearch,
  urlApiSearchConfig,
  urlApiProductSearchDemo,
  urlApiProductHistoryPrice,
  urlApiProductOrderPrice,
  urlApiShopSearch,
  urlApiKeywordSearch,
  urlCheckProductLink
}
