import {ALL_COMPARISON_OPERATOR} from "@/constant/search/SearchConstant";

const FIXED_VALUE_COMPARE_TRENDING = {
  7: {
    firstField: 'order_count_7d',
    ratio: 1/2,
    comparisonOperator: ALL_COMPARISON_OPERATOR[0],
    secondField: 'order_count_30d',
  },
  30: {
    firstField: 'order_count_30d',
    ratio: 0.7,
    comparisonOperator: ALL_COMPARISON_OPERATOR[0],
    secondField: 'order_count_90d',
  },
  90: {
    firstField: 'order_count_90d',
    ratio: 1/1.25,
    comparisonOperator: ALL_COMPARISON_OPERATOR[0],
    secondField: 'order_count_180d',
  }
}

const FIXED_VALUE_COMPARE_BAD_PRODUCT_CONDITION_LIST = [
  {
    firstField: 'rating_count',
    ratio: 1/20,
    comparisonOperator: ALL_COMPARISON_OPERATOR[0],
    secondField: 'order_count',
    isDefault: true
  }
]

const FIXED_VALUE_RANGE_GOOD_SELLER_PRODUCT = {
  field: 'order_count',
  min: 1000
}

export {
  FIXED_VALUE_COMPARE_TRENDING,
  FIXED_VALUE_COMPARE_BAD_PRODUCT_CONDITION_LIST,
  FIXED_VALUE_RANGE_GOOD_SELLER_PRODUCT
}
