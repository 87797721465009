<template>
  <div>
    <!-- Messenger Plugin chat Code -->
    <div id="fb-root"></div>
    <!-- Your Plugin chat code -->
    <div id="fb-customer-chat" class="fb-customerchat">
    </div>
  </div>
</template>

<script>
import {insertScriptRaw} from "@/helper/InsertScript";

export default {
  name: "FacebookMessenger",
  mounted() {
    insertScriptRaw(`
      var chatbox = document.getElementById('fb-customer-chat');
      chatbox.setAttribute("page_id", "109511870724661");
      chatbox.setAttribute("attribution", "biz_inbox");

      window.fbAsyncInit = function() {
        FB.init({
          xfbml            : true,
          version          : 'v12.0'
        });
      };

      (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = 'https://connect.facebook.net/vi_VN/sdk/xfbml.customerchat.js';
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    `, 'fb-customer-chat')
  }
}
</script>

<style scoped>

</style>
