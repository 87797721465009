const SocialAppIds = {
  FACEBOOK: '445060059255480',
  GOOGLE: '426000576581-0o4c24jbbcmi9mkiqs1kh87p50ki5f7n.apps.googleusercontent.com',
  ZALO: '2508150291248862255'
}

const SocialResponseState = {
  FACEBOOK: 'facebook',
  GOOGLE: 'google',
  ZALO: 'zalo'
}

const Service = {
  NAME: 'metric.ecomviet.vn'
}

export {
  SocialAppIds,
  SocialResponseState,
  Service
}
