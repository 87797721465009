import {ALL_PLATFORM_BASE_OBJECT} from "@/constant/general/GeneralConstant";

const ALL_CATEGORIES_BY_PLATFORM = {
  [ALL_PLATFORM_BASE_OBJECT.all_vietnam.uniqueName]: [
    {"value": "c10101010", "label": "Làm Đẹp"},
    {"value": "c10101011", "label": "Sức khỏe"},
    {"value": "c10101012", "label": "Gia dụng - Đời sống"},
    {"value": "c10101013", "label": "Điện gia dụng"},
    {"value": "c10101014", "label": "Bách hóa - Đồ tiêu dùng"},
    {"value": "c10101015", "label": "Bách hóa - Thực phẩm"},
    {"value": "c10101016", "label": "Sách, Nhạc, Văn phòng phẩm, Quà tặng"},
    {"value": "c10101017", "label": "Mẹ & Bé"},
    {"value": "c10101018", "label": "Đồ chơi"},
    {"value": "c10101019", "label": "Điện thoại & Phụ kiện"},
    {"value": "c10101020", "label": "Phụ kiện Công nghệ, số"},
    {"value": "c10101021", "label": "Laptop & Máy tính"},
    {"value": "c10101022", "label": "Phụ kiện & Linh kiện IT"},
    {"value": "c10101023", "label": "Thiết bị văn phòng"},
    {"value": "c10101024", "label": "Thiết bị âm thanh"},
    {"value": "c10101025", "label": "Gaming & Console"},
    {"value": "c10101026", "label": "Camera & Flycam"},
    {"value": "c10101027", "label": "Thời trang Nam"},
    {"value": "c10101028", "label": "Thời trang Trẻ em & trẻ sơ sinh"},
    {"value": "c10101029", "label": "Phụ kiện thời trang"},
    {"value": "c10101030", "label": "Thời trang Nữ"},
    {"value": "c10101031", "label": "Túi - Ví Nam"},
    {"value": "c10101032", "label": "Túi - Ví Nữ"},
    {"value": "c10101033", "label": "Giày dép Nam"},
    {"value": "c10101034", "label": "Giày dép Nữ"},
    {"value": "c10101035", "label": "Đồng hồ"},
    {"value": "c10101036", "label": "Trang sức"},
    {"value": "c10101037", "label": "Thể Thao - Dã ngoại"},
    {"value": "c10101038", "label": "Du lịch - Hành lý & Đồ dùng"},
    {"value": "c10101039", "label": "Du lịch - Vé, Phòng & Dịch vụ"},
    {"value": "c10101040", "label": "Chăm sóc Thú cưng"},
    {"value": "c10101041", "label": "Voucher - Dịch vụ - Khuyến mại"},
    {"value": "c10101042", "label": "Ô tô"},
    {"value": "c10101043", "label": "Xe máy - Xe điện"},
    {"value": "c10101044", "label": "Phụ kiện, Phụ tùng, Chăm sóc xe"},
    {"value": "c10101045", "label": "Khác & Chưa phân loại"},
  ],
  [ALL_PLATFORM_BASE_OBJECT.shopee.uniqueName]: [
    {
      "value": "1__100630",
      "label": "Sắc Đẹp"
    },
    {
      "value": "1__100636",
      "label": "Nhà cửa & Đời sống"
    },
    {
      "value": "1__100017",
      "label": "Thời Trang Nữ"
    },
    {
      "value": "1__100632",
      "label": "Mẹ & Bé"
    },
    {
      "value": "1__100013",
      "label": "Điện Thoại & Phụ Kiện"
    },
    {
      "value": "1__100001",
      "label": "Sức Khỏe"
    },
    {
      "value": "1__100010",
      "label": "Thiết Bị Điện Gia Dụng"
    },
    {
      "value": "1__100629",
      "label": "Thực phẩm và đồ uống"
    },
    {
      "value": "1__100644",
      "label": "Máy tính & Laptop"
    },
    {
      "value": "1__100011",
      "label": "Thời Trang Nam"
    },
    {
      "value": "1__100633",
      "label": "Thời trang trẻ em & trẻ sơ sinh"
    },
    {
      "value": "1__100637",
      "label": "Thể Thao & Dã Ngoại"
    },
    {
      "value": "1__100532",
      "label": "Giày Dép Nữ"
    },
    {
      "value": "1__100535",
      "label": "Thiết Bị Âm Thanh"
    },
    {
      "value": "1__100009",
      "label": "Phụ Kiện Thời Trang"
    },
    {
      "value": "1__100638",
      "label": "Văn Phòng Phẩm"
    },
    {
      "value": "1__100016",
      "label": "Túi Ví Nữ"
    },
    {
      "value": "1__100631",
      "label": "Chăm Sóc Thú Cưng"
    },
    {
      "value": "1__100012",
      "label": "Giày Dép Nam"
    },
    {
      "value": "1__100639",
      "label": "Sở thích & Sưu tầm"
    },
    {
      "value": "1__100643",
      "label": "Sách & Tạp Chí"
    },
    {
      "value": "1__100640",
      "label": "Ô tô"
    },
    {
      "value": "1__100635",
      "label": "Cameras & Flycam"
    },
    {
      "value": "1__100641",
      "label": "Mô tô, xe máy"
    },
    {
      "value": "1__100534",
      "label": "Đồng Hồ"
    },
    {
      "value": "1__100533",
      "label": "Túi Ví Nam"
    },
    {
      "value": "1__100634",
      "label": "Gaming & Console"
    },
    {
      "value": "1__100642",
      "label": "Voucher & Dịch vụ"
    },
    {
      "value": "1__100015",
      "label": "Du lịch & Hành lý"
    },
    {
      "value": "1__100014",
      "label": "Muslim Fashion"
    },
    {
      "value": "1__100531",
      "label": "Food Delivery"
    }
  ],
  [ALL_PLATFORM_BASE_OBJECT.sendo.uniqueName]: [
    {
      "value": "4__2150",
      "label": "Ô tô - Xe máy"
    },
    {
      "value": "4__2849",
      "label": "Tân Trang Nhà Cửa"
    },
    {
      "value": "4__220",
      "label": "Sức Khỏe & Làm Đẹp"
    },
    {
      "value": "4__1663",
      "label": "Điện thoại - Máy tính bảng"
    },
    {
      "value": "4__1171",
      "label": "Bách Hóa Tổng Hợp"
    },
    {
      "value": "4__736",
      "label": "Thể thao - Dã ngoại - Giải trí"
    },
    {
      "value": "4__138",
      "label": "Mẹ và bé"
    },
    {
      "value": "4__1992",
      "label": "Thiết bị y tế"
    },
    {
      "value": "4__1019",
      "label": "Nhà Cửa"
    },
    {
      "value": "4__1108",
      "label": "Đồ điện gia dụng"
    },
    {
      "value": "4__3228",
      "label": "Thiết bị âm thanh"
    },
    {
      "value": "4__2901",
      "label": "Laptop, Máy tính & Thiết bị văn phòng"
    },
    {
      "value": "4__8",
      "label": "Thời Trang Nữ"
    },
    {
      "value": "4__528",
      "label": "Phụ kiện công nghệ"
    },
    {
      "value": "4__1954",
      "label": "Máy ảnh - Máy quay phim"
    },
    {
      "value": "4__94",
      "label": "Thời Trang Nam"
    },
    {
      "value": "4__1686",
      "label": "Giày Dép"
    },
    {
      "value": "4__2101",
      "label": "Tivi - Thiết bị giải trí"
    },
    {
      "value": "4__2075",
      "label": "Điện máy"
    },
    {
      "value": "4__1458",
      "label": "Đồ chơi"
    },
    {
      "value": "4__604",
      "label": "Đời sống"
    },
    {
      "value": "4__4216",
      "label": "Chăm sóc thú cưng"
    },
    {
      "value": "4__1722",
      "label": "Túi Xách"
    },
    {
      "value": "4__6365",
      "label": "Thời Trang Trẻ Em"
    },
    {
      "value": "4__2399",
      "label": "Thực Phẩm Tươi Sống"
    },
    {
      "value": "4__1366",
      "label": "Đồng Hồ"
    },
    {
      "value": "4__1629",
      "label": "Phụ Kiện Thời Trang"
    },
    {
      "value": "4__2259",
      "label": "Sách & Văn phòng phẩm"
    },
    {
      "value": "4__2702",
      "label": "Voucher dịch vụ"
    },
    {
      "value": "4__3674",
      "label": "Dịch Vụ Tiện Ích"
    },
    {
      "value": "4__6511",
      "label": "Vật phẩm phong thủy"
    },
    {
      "value": "4__3859",
      "label": "Trang sức"
    },
    {
      "value": "4__6450",
      "label": "Vé máy bay - Du lịch"
    }
  ],
  [ALL_PLATFORM_BASE_OBJECT.tiki.uniqueName]: [
    {
      "value": "3__8594",
      "label": "Xe Máy, Ô tô, Xe Đạp"
    },
    {
      "value": "3__1789",
      "label": "Điện Thoại - Máy Tính Bảng"
    },
    {
      "value": "3__1883",
      "label": "Nhà Cửa Đời Sống"
    },
    {
      "value": "3__1815",
      "label": "Phụ Kiện - Thiết Bị Số"
    },
    {
      "value": "3__1520",
      "label": "Làm Đẹp - Sức Khỏe"
    },
    {
      "value": "3__8322",
      "label": "Sách, VPP & Quà Tặng"
    },
    {
      "value": "3__1882",
      "label": "Điện Gia Dụng"
    },
    {
      "value": "3__2549",
      "label": "Đồ chơi, Mẹ & Bé"
    },
    {
      "value": "3__1846",
      "label": "Laptop - Thiết bị IT"
    },
    {
      "value": "3__4221",
      "label": "Điện Tử - Điện Lạnh"
    },
    {
      "value": "3__4384",
      "label": "Hàng Tiêu Dùng - Thực Phẩm"
    },
    {
      "value": "3__915",
      "label": "Thời trang nam"
    },
    {
      "value": "3__1975",
      "label": "Thể Thao - Dã Ngoại"
    },
    {
      "value": "3__931",
      "label": "Thời trang nữ"
    },
    {
      "value": "3__17166",
      "label": "Hàng quốc tế"
    },
    {
      "value": "3__1686",
      "label": "Giày dép nam"
    },
    {
      "value": "3__1801",
      "label": "Máy Ảnh - Quay Phim"
    },
    {
      "value": "3__6000",
      "label": "Balo & vali"
    },
    {
      "value": "3__1703",
      "label": "Giày dép nữ"
    },
    {
      "value": "3__27498",
      "label": "Phụ kiện"
    },
    {
      "value": "3__8371",
      "label": "Đồng hồ & trang sức"
    },
    {
      "value": "3__27616",
      "label": "Túi thời trang nam"
    },
    {
      "value": "3__976",
      "label": "Túi thời trang nữ"
    },
    {
      "value": "3__11312",
      "label": "Voucher - Dịch Vụ - Thẻ Cào"
    }
  ],
  [ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName]: [
    {
      "value": "2__4",
      "label": "Sức Khỏe & Làm Đẹp"
    },
    {
      "value": "2__7",
      "label": "Hàng Gia dụng & Đời sống"
    },
    {
      "value": "2__1",
      "label": "Thiết Bị Điện Tử"
    },
    {
      "value": "2__3",
      "label": "TV & Thiết Bị Điện Gia Dụng"
    },
    {
      "value": "2__6",
      "label": "Siêu Thị Tạp Hóa"
    },
    {
      "value": "2__5",
      "label": "Hàng Mẹ, Bé & Đồ Chơi"
    },
    {
      "value": "2__2",
      "label": "Phụ Kiện Điện Tử"
    },
    {
      "value": "2__8",
      "label": "Thời Trang Nữ"
    },
    {
      "value": "2__9",
      "label": "Thời Trang Nam"
    },
    {
      "value": "2__11",
      "label": "Thể Thao & Du Lịch"
    },
    {
      "value": "2__12",
      "label": "Ôtô, Xe Máy & Thiết Bị Định Vị"
    },
    {
      "value": "2__10",
      "label": "Phụ Kiện Thời Trang"
    }
  ],
  [ALL_PLATFORM_BASE_OBJECT._1688.uniqueName]: [
    {
      "value": "5__10166",
      "label": "Thời trang nữ"
    },
    {
      "value": "5__1038378",
      "cn_name": "百变鞋靴",
      "label": "Giày và bốt"
    },
    {
      "value": "5__311",
      "cn_name": "秋冬童装",
      "label": "Quần áo trẻ em thu đông"
    },
    {
      "value": "5__312",
      "cn_name": "臻品内衣",
      "label": "Đồ lót"
    },
    {
      "value": "5__10165",
      "cn_name": "潮流男装",
      "label": "Thời trang nam"
    },
    {
      "value": "5__15",
      "cn_name": "日用百货",
      "label": "Đồ dùng, vật dụng thiết yếu"
    },
    {
      "value": "5__18",
      "cn_name": "运动户外",
      "label": "Đồ thể thao"
    },
    {
      "value": "5__54",
      "cn_name": "流行配饰",
      "label": "Phụ kiện phổ biến"
    },
    {
      "value": "5__1813",
      "cn_name": "玩具",
      "label": "Đồ chơi"
    },
    {
      "value": "5__97",
      "cn_name": "美妆个护",
      "label": "Chăm sóc sắc đẹp & cá nhân"
    },
    {
      "value": "5__1042954",
      "cn_name": "箱包皮具",
      "label": "Hành lý và đồ da"
    },
    {
      "value": "5__96",
      "cn_name": "家纺家饰",
      "label": "Hàng dệt và trang trí nhà cửa"
    },
    {
      "value": "5__888899",
      "cn_name": "工业品",
      "label": "Những sản phẩm công nghiệp"
    },
    {
      "value": "5__68",
      "cn_name": "包装辅料",
      "label": "Phụ kiện đóng gói"
    },
    {
      "value": "5__122916001",
      "cn_name": "宠物园艺",
      "label": "Làm vườn và thú cưng"
    },
    {
      "value": "5__1501",
      "cn_name": "母婴用品",
      "label": "Sản phẩm dành cho trẻ em"
    },
    {
      "value": "5__122916002",
      "cn_name": "汽车用品",
      "label": "Tự lắp ráp"
    },
    {
      "value": "5__7",
      "cn_name": "数码电脑",
      "label": "Máy tính & laptop"
    },
    {
      "value": "5__2",
      "cn_name": "食品生鲜",
      "label": "Thực phẩm sạch"
    },
    {
      "value": "5__13",
      "cn_name": "家装建材",
      "label": "Vật liệu xây dựng nhà cửa"
    }
  ],
  [ALL_PLATFORM_BASE_OBJECT.taobao.uniqueName]: [
    {
      "value": "6__1620871144783",
      "label": "Cuộc sống gia đình"
    },
    {
      "value": "6__1621552360446",
      "label": "Thời trang nữ/Giày nữ/Sản phẩm cho phụ nữ"
    },
    {
      "value": "6__1620336244254",
      "label": "Thời trang nam/Giày nam/Sản phẩm cho nam"
    },
    {
      "value": "6__1620029426435",
      "label": "Túi/Phụ kiện"
    },
    {
      "value": "6__1620438969856",
      "label": "Đồ thể thao"
    },
    {
      "value": "6__1623305198886",
      "label": "Kỹ thuật số/Điện thoại di động/Smartphone"
    },
    {
      "value": "6__1622981156786",
      "label": "Laptop/dịch vụ mạng"
    },
    {
      "value": "6__1620586469409",
      "label": "Đồ cosplay, hoá trang"
    },
    {
      "value": "6__1621725555268",
      "label": "Đồ chơi/Sản phẩm cho trẻ em"
    },
    {
      "value": "6__1620742870632",
      "label": "Xe cộ/Voucher và dịch vụ"
    },
    {
      "value": "6__1622056636641",
      "label": "Thiết bị gia dụng/Âm thanh"
    },
    {
      "value": "6__1621619281392",
      "label": "Chăm sóc sắc đẹp"
    },
    {
      "value": "6__1619982909602",
      "label": "Đồ ăn nhẹ/Nước uống"
    },
    {
      "value": "6__1621141056524",
      "label": "Chăm sóc sức khoẻ"
    }
  ],
  [ALL_PLATFORM_BASE_OBJECT.tmall.uniqueName]: [
    {
      "value": "7__1620871144783",
      "label": "Cuộc sống gia đình"
    },
    {
      "value": "7__1621552360446",
      "label": "Thời trang nữ/Giày nữ/Sản phẩm cho phụ nữ"
    },
    {
      "value": "7__1620336244254",
      "label": "Thời trang nam/Giày nam/Sản phẩm cho nam"
    },
    {
      "value": "7__1620029426435",
      "label": "Túi/Phụ kiện"
    },
    {
      "value": "7__1620438969856",
      "label": "Đồ thể thao"
    },
    {
      "value": "7__1623305198886",
      "label": "Kỹ thuật số/Điện thoại di động/Smartphone"
    },
    {
      "value": "7__1622981156786",
      "label": "Laptop/dịch vụ mạng"
    },
    {
      "value": "7__1620586469409",
      "label": "Đồ cosplay, hoá trang"
    },
    {
      "value": "7__1621725555268",
      "label": "Đồ chơi/Sản phẩm cho trẻ em"
    },
    {
      "value": "7__1620742870632",
      "label": "Xe cộ/Voucher và dịch vụ"
    },
    {
      "value": "7__1622056636641",
      "label": "Thiết bị gia dụng/Âm thanh"
    },
    {
      "value": "7__1621619281392",
      "label": "Chăm sóc sắc đẹp"
    },
    {
      "value": "7__1619982909602",
      "label": "Đồ ăn nhẹ/Nước uống"
    },
    {
      "value": "7__1621141056524",
      "label": "Chăm sóc sức khoẻ"
    }
  ]
}

const ALL_CATEGORIES_LV2_BY_PLATFORM = {
  [ALL_PLATFORM_BASE_OBJECT.shopee.uniqueName]: [
    {
      "value": "1__100645",
      "label": "Đồ chế biến sẵn",
      "parent": "1__100629"
    },
    {
      "value": "1__100646",
      "label": "Đồ ăn vặt",
      "parent": "1__100629"
    },
    {
      "value": "1__100647",
      "label": "Nhu yếu phẩm",
      "parent": "1__100629"
    },
    {
      "value": "1__100648",
      "label": "Nguyên liệu nấu ăn",
      "parent": "1__100629"
    },
    {
      "value": "1__100649",
      "label": "Đồ làm bánh",
      "parent": "1__100629"
    },
    {
      "value": "1__100650",
      "label": "Ngũ cốc & mứt",
      "parent": "1__100629"
    },
    {
      "value": "1__100651",
      "label": "Đồ uống",
      "parent": "1__100629"
    },
    {
      "value": "1__100652",
      "label": "Sữa - trứng",
      "parent": "1__100629"
    },
    {
      "value": "1__100653",
      "label": "Thực phẩm tươi sống & đông lạnh",
      "parent": "1__100629"
    },
    {
      "value": "1__100654",
      "label": "Các loại bánh",
      "parent": "1__100629"
    },
    {
      "value": "1__100655",
      "label": "Đồ uống có cồn",
      "parent": "1__100629"
    },
    {
      "value": "1__100656",
      "label": "Bộ quà tặng",
      "parent": "1__100629"
    },
    {
      "value": "1__100657",
      "label": "Khác",
      "parent": "1__100629"
    },
    {
      "value": "1__100658",
      "label": "Chăm sóc tay, chân & móng",
      "parent": "1__100630"
    },
    {
      "value": "1__100659",
      "label": "Chăm sóc tóc",
      "parent": "1__100630"
    },
    {
      "value": "1__100660",
      "label": "Chăm sóc nam giới",
      "parent": "1__100630"
    },
    {
      "value": "1__100661",
      "label": "Nước hoa",
      "parent": "1__100630"
    },
    {
      "value": "1__100662",
      "label": "Trang điểm",
      "parent": "1__100630"
    },
    {
      "value": "1__100663",
      "label": "Dụng cụ làm đẹp",
      "parent": "1__100630"
    },
    {
      "value": "1__100664",
      "label": "Chăm sóc da mặt",
      "parent": "1__100630"
    },
    {
      "value": "1__100665",
      "label": "Bộ sản phẩm làm đẹp",
      "parent": "1__100630"
    },
    {
      "value": "1__100666",
      "label": "Khác",
      "parent": "1__100630"
    },
    {
      "value": "1__102002",
      "label": "Tắm & chăm sóc cơ thể",
      "parent": "1__100630"
    },
    {
      "value": "1__100667",
      "label": "Thức ăn cho thú cưng",
      "parent": "1__100631"
    },
    {
      "value": "1__100668",
      "label": "Phụ kiện cho thú cưng",
      "parent": "1__100631"
    },
    {
      "value": "1__100669",
      "label": "Vệ sinh cho thú cưng",
      "parent": "1__100631"
    },
    {
      "value": "1__100670",
      "label": "Làm đẹp cho thú cưng",
      "parent": "1__100631"
    },
    {
      "value": "1__100671",
      "label": "Quần áo & phụ kiện",
      "parent": "1__100631"
    },
    {
      "value": "1__100672",
      "label": "Chăm sóc sức khỏe",
      "parent": "1__100631"
    },
    {
      "value": "1__100673",
      "label": "Khác",
      "parent": "1__100631"
    },
    {
      "value": "1__100674",
      "label": "Đồ dùng du lịch cho bé",
      "parent": "1__100632"
    },
    {
      "value": "1__100675",
      "label": "Đồ dùng ăn dặm cho bé",
      "parent": "1__100632"
    },
    {
      "value": "1__100676",
      "label": "Phụ kiện cho mẹ",
      "parent": "1__100632"
    },
    {
      "value": "1__100677",
      "label": "Chăm sóc sức khỏe mẹ",
      "parent": "1__100632"
    },
    {
      "value": "1__100678",
      "label": "Đồ dùng phòng tắm & Chăm sóc cơ thể bé",
      "parent": "1__100632"
    },
    {
      "value": "1__100679",
      "label": "Đồ dùng phòng ngủ cho bé",
      "parent": "1__100632"
    },
    {
      "value": "1__100680",
      "label": "An toàn cho bé",
      "parent": "1__100632"
    },
    {
      "value": "1__100681",
      "label": "Sữa công thức & Thực phẩm cho bé",
      "parent": "1__100632"
    },
    {
      "value": "1__100682",
      "label": "Chăm sóc sức khỏe bé",
      "parent": "1__100632"
    },
    {
      "value": "1__100683",
      "label": "Tã & bô em bé",
      "parent": "1__100632"
    },
    {
      "value": "1__100684",
      "label": "Đồ chơi",
      "parent": "1__100632"
    },
    {
      "value": "1__100685",
      "label": "Bộ & Gói quà tặng",
      "parent": "1__100632"
    },
    {
      "value": "1__100686",
      "label": "Khác",
      "parent": "1__100632"
    },
    {
      "value": "1__100687",
      "label": "Quần áo trẻ em",
      "parent": "1__100633"
    },
    {
      "value": "1__100688",
      "label": "Bao tay trẻ em & Tất",
      "parent": "1__100633"
    },
    {
      "value": "1__100689",
      "label": "Phụ kiện trẻ em & trẻ sơ sinh",
      "parent": "1__100633"
    },
    {
      "value": "1__100690",
      "label": "Quần áo bé trai",
      "parent": "1__100633"
    },
    {
      "value": "1__100691",
      "label": "Quần áo bé gái",
      "parent": "1__100633"
    },
    {
      "value": "1__100692",
      "label": "Giày bé trai",
      "parent": "1__100633"
    },
    {
      "value": "1__100693",
      "label": "Giày bé gái",
      "parent": "1__100633"
    },
    {
      "value": "1__100694",
      "label": "Khác",
      "parent": "1__100633"
    },
    {
      "value": "1__100695",
      "label": "Máy chơi game",
      "parent": "1__100634"
    },
    {
      "value": "1__100696",
      "label": "Phụ kiện console",
      "parent": "1__100634"
    },
    {
      "value": "1__100697",
      "label": "Video Games",
      "parent": "1__100634"
    },
    {
      "value": "1__100698",
      "label": "Khác",
      "parent": "1__100634"
    },
    {
      "value": "1__100699",
      "label": "Máy ảnh",
      "parent": "1__100635"
    },
    {
      "value": "1__100700",
      "label": "Camera giám sát",
      "parent": "1__100635"
    },
    {
      "value": "1__100701",
      "label": "Ống kính",
      "parent": "1__100635"
    },
    {
      "value": "1__100702",
      "label": "Phụ kiện ống kính",
      "parent": "1__100635"
    },
    {
      "value": "1__100703",
      "label": "Phụ kiện máy ảnh",
      "parent": "1__100635"
    },
    {
      "value": "1__100704",
      "label": "Phụ kiện chăm sóc máy ảnh",
      "parent": "1__100635"
    },
    {
      "value": "1__100705",
      "label": "Flycam",
      "parent": "1__100635"
    },
    {
      "value": "1__100706",
      "label": "Phụ kiện Flycam",
      "parent": "1__100635"
    },
    {
      "value": "1__100707",
      "label": "Khác",
      "parent": "1__100635"
    },
    {
      "value": "1__100708",
      "label": "Chất khử mùi, làm thơm nhà",
      "parent": "1__100636"
    },
    {
      "value": "1__100709",
      "label": "Đồ dùng phòng tắm",
      "parent": "1__100636"
    },
    {
      "value": "1__100710",
      "label": "Chăn ga gối nệm",
      "parent": "1__100636"
    },
    {
      "value": "1__100711",
      "label": "Trang trí nhà cửa",
      "parent": "1__100636"
    },
    {
      "value": "1__100712",
      "label": "Túi làm ấm",
      "parent": "1__100636"
    },
    {
      "value": "1__100713",
      "label": "Nội thất",
      "parent": "1__100636"
    },
    {
      "value": "1__100714",
      "label": "Làm vườn",
      "parent": "1__100636"
    },
    {
      "value": "1__100715",
      "label": "Dụng cụ & Thiết bị tiện ích",
      "parent": "1__100636"
    },
    {
      "value": "1__100716",
      "label": "Dụng cụ chăm sóc nhà cửa",
      "parent": "1__100636"
    },
    {
      "value": "1__100717",
      "label": "Dụng cụ nhà bếp",
      "parent": "1__100636"
    },
    {
      "value": "1__100718",
      "label": "Bộ đồ bàn ăn",
      "parent": "1__100636"
    },
    {
      "value": "1__100719",
      "label": "Đèn",
      "parent": "1__100636"
    },
    {
      "value": "1__100720",
      "label": "Bảo hộ gia đình",
      "parent": "1__100636"
    },
    {
      "value": "1__100721",
      "label": "Sắp xếp nhà cửa",
      "parent": "1__100636"
    },
    {
      "value": "1__100722",
      "label": "Trang trí tiệc tùng",
      "parent": "1__100636"
    },
    {
      "value": "1__100723",
      "label": "Đồ thờ cúng, đồ phong thủy",
      "parent": "1__100636"
    },
    {
      "value": "1__100724",
      "label": "Khác",
      "parent": "1__100636"
    },
    {
      "value": "1__100725",
      "label": "Dụng Cụ Thể Thao & Dã Ngoại",
      "parent": "1__100637"
    },
    {
      "value": "1__100726",
      "label": "Giày Thể Thao",
      "parent": "1__100637"
    },
    {
      "value": "1__100727",
      "label": "Thời Trang Thể Thao & Dã Ngoại",
      "parent": "1__100637"
    },
    {
      "value": "1__100728",
      "label": "Phụ Kiện Thể Thao & Dã Ngoại",
      "parent": "1__100637"
    },
    {
      "value": "1__100729",
      "label": "Khác",
      "parent": "1__100637"
    },
    {
      "value": "1__100730",
      "label": "Quà Tặng - Giấy Gói",
      "parent": "1__100638"
    },
    {
      "value": "1__100731",
      "label": "Bút Các Loại",
      "parent": "1__100638"
    },
    {
      "value": "1__100732",
      "label": "Thiết Bị Trường Học",
      "parent": "1__100638"
    },
    {
      "value": "1__100733",
      "label": "Hoạ Cụ",
      "parent": "1__100638"
    },
    {
      "value": "1__100734",
      "label": "Sổ & Giấy Các Loại",
      "parent": "1__100638"
    },
    {
      "value": "1__100735",
      "label": "Thư Tín",
      "parent": "1__100638"
    },
    {
      "value": "1__100736",
      "label": "Khác",
      "parent": "1__100638"
    },
    {
      "value": "1__100737",
      "label": "Đồ Sưu Tầm",
      "parent": "1__100639"
    },
    {
      "value": "1__100738",
      "label": "Quà Lưu Niệm",
      "parent": "1__100639"
    },
    {
      "value": "1__100739",
      "label": "Đồ chơi - Giải trí",
      "parent": "1__100639"
    },
    {
      "value": "1__100740",
      "label": "Băng - Đĩa",
      "parent": "1__100639"
    },
    {
      "value": "1__100741",
      "label": "Nhạc Cụ & Phụ Kiện",
      "parent": "1__100639"
    },
    {
      "value": "1__100742",
      "label": "Đĩa Than",
      "parent": "1__100639"
    },
    {
      "value": "1__100743",
      "label": "Album Ảnh",
      "parent": "1__100639"
    },
    {
      "value": "1__100744",
      "label": "Dụng Cụ May Vá",
      "parent": "1__100639"
    },
    {
      "value": "1__100745",
      "label": "Khác",
      "parent": "1__100639"
    },
    {
      "value": "1__100746",
      "label": "Ô tô",
      "parent": "1__100640"
    },
    {
      "value": "1__100747",
      "label": "Phụ kiện nội thất ô tô",
      "parent": "1__100640"
    },
    {
      "value": "1__100748",
      "label": "Phụ kiện ngoại thất ô tô",
      "parent": "1__100640"
    },
    {
      "value": "1__100749",
      "label": "Phụ tùng ô tô",
      "parent": "1__100640"
    },
    {
      "value": "1__100750",
      "label": "Dụng cụ sửa chữa ô tô",
      "parent": "1__100640"
    },
    {
      "value": "1__100751",
      "label": "Chăm sóc ô tô",
      "parent": "1__100640"
    },
    {
      "value": "1__100752",
      "label": "Dầu nhớt và phụ gia ô tô",
      "parent": "1__100640"
    },
    {
      "value": "1__100753",
      "label": "Móc chìa khóa và Bọc chìa ô tô",
      "parent": "1__100640"
    },
    {
      "value": "1__100754",
      "label": "Khác",
      "parent": "1__100640"
    },
    {
      "value": "1__100002",
      "label": "Thực phẩm chức năng",
      "parent": "1__100001"
    },
    {
      "value": "1__100008",
      "label": "Khác",
      "parent": "1__100001"
    },
    {
      "value": "1__100018",
      "label": "Vật tư y tế",
      "parent": "1__100001"
    },
    {
      "value": "1__100019",
      "label": "Chăm sóc cá nhân",
      "parent": "1__100001"
    },
    {
      "value": "1__100020",
      "label": "Hỗ trợ tình dục",
      "parent": "1__100001"
    },
    {
      "value": "1__100760",
      "label": "Sự kiện & Giải trí",
      "parent": "1__100642"
    },
    {
      "value": "1__100761",
      "label": "Nhà hàng & Ăn uống",
      "parent": "1__100642"
    },
    {
      "value": "1__100762",
      "label": "Mua sắm",
      "parent": "1__100642"
    },
    {
      "value": "1__100763",
      "label": "Thanh toán hóa đơn",
      "parent": "1__100642"
    },
    {
      "value": "1__100764",
      "label": "Dịch vụ khác",
      "parent": "1__100642"
    },
    {
      "value": "1__100765",
      "label": "Sức khỏe & Làm đẹp",
      "parent": "1__100642"
    },
    {
      "value": "1__100766",
      "label": "Gọi xe",
      "parent": "1__100642"
    },
    {
      "value": "1__100767",
      "label": "Khóa học",
      "parent": "1__100642"
    },
    {
      "value": "1__100768",
      "label": "Nạp tiền tài khoản",
      "parent": "1__100642"
    },
    {
      "value": "1__100769",
      "label": "Du lịch & Khách sạn",
      "parent": "1__100642"
    },
    {
      "value": "1__100770",
      "label": "Tiền điện tử",
      "parent": "1__100642"
    },
    {
      "value": "1__100771",
      "label": "Bất động sản",
      "parent": "1__100642"
    },
    {
      "value": "1__100772",
      "label": "Thẻ game",
      "parent": "1__100642"
    },
    {
      "value": "1__100773",
      "label": "Streaming",
      "parent": "1__100642"
    },
    {
      "value": "1__100774",
      "label": "Mã quà tặng Shopee",
      "parent": "1__100642"
    },
    {
      "value": "1__100775",
      "label": "Khác",
      "parent": "1__100642"
    },
    {
      "value": "1__100776",
      "label": "Tạp Chí & Báo Giấy",
      "parent": "1__100643"
    },
    {
      "value": "1__100777",
      "label": "Sách",
      "parent": "1__100643"
    },
    {
      "value": "1__100778",
      "label": "E-Books",
      "parent": "1__100643"
    },
    {
      "value": "1__100779",
      "label": "Khác",
      "parent": "1__100643"
    },
    {
      "value": "1__101932",
      "label": "Máy Tính Bàn",
      "parent": "1__100644"
    },
    {
      "value": "1__101933",
      "label": "Màn Hình",
      "parent": "1__100644"
    },
    {
      "value": "1__101934",
      "label": "Linh Kiện Máy Tính",
      "parent": "1__100644"
    },
    {
      "value": "1__101935",
      "label": "Thiết Bị Lưu Trữ",
      "parent": "1__100644"
    },
    {
      "value": "1__101936",
      "label": "Thiết Bị Mạng",
      "parent": "1__100644"
    },
    {
      "value": "1__101937",
      "label": "Phần Mềm",
      "parent": "1__100644"
    },
    {
      "value": "1__101938",
      "label": "Thiết Bị Văn Phòng",
      "parent": "1__100644"
    },
    {
      "value": "1__101939",
      "label": "Máy In & Máy Scan",
      "parent": "1__100644"
    },
    {
      "value": "1__101940",
      "label": "Phụ Kiện Máy Tính",
      "parent": "1__100644"
    },
    {
      "value": "1__101941",
      "label": "Chuột & Bàn Phím",
      "parent": "1__100644"
    },
    {
      "value": "1__101942",
      "label": "Laptop",
      "parent": "1__100644"
    },
    {
      "value": "1__101943",
      "label": "Phụ Kiện Máy Tính Khác",
      "parent": "1__100644"
    },
    {
      "value": "1__100021",
      "label": "Nhẫn",
      "parent": "1__100009"
    },
    {
      "value": "1__100022",
      "label": "Bông tai",
      "parent": "1__100009"
    },
    {
      "value": "1__100023",
      "label": "Khăn choàng",
      "parent": "1__100009"
    },
    {
      "value": "1__100024",
      "label": "Găng tay",
      "parent": "1__100009"
    },
    {
      "value": "1__100025",
      "label": "Phụ kiện tóc",
      "parent": "1__100009"
    },
    {
      "value": "1__100026",
      "label": "Vòng tay & Lắc tay",
      "parent": "1__100009"
    },
    {
      "value": "1__100027",
      "label": "Lắc chân",
      "parent": "1__100009"
    },
    {
      "value": "1__100028",
      "label": "Mũ",
      "parent": "1__100009"
    },
    {
      "value": "1__100029",
      "label": "Dây chuyền",
      "parent": "1__100009"
    },
    {
      "value": "1__100030",
      "label": "Kính mắt",
      "parent": "1__100009"
    },
    {
      "value": "1__100031",
      "label": "Kim loại quý",
      "parent": "1__100009"
    },
    {
      "value": "1__100032",
      "label": "Thắt lưng",
      "parent": "1__100009"
    },
    {
      "value": "1__100033",
      "label": "Cà vạt & Nơ cổ",
      "parent": "1__100009"
    },
    {
      "value": "1__100034",
      "label": "Phụ kiện thêm",
      "parent": "1__100009"
    },
    {
      "value": "1__100035",
      "label": "Bộ phụ kiện",
      "parent": "1__100009"
    },
    {
      "value": "1__100036",
      "label": "Khác",
      "parent": "1__100009"
    },
    {
      "value": "1__100037",
      "label": "Máy chiếu & Phụ kiện",
      "parent": "1__100010"
    },
    {
      "value": "1__100038",
      "label": "Thiết bị điện gia dụng nhỏ",
      "parent": "1__100010"
    },
    {
      "value": "1__100039",
      "label": "Thiết bị điện gia dụng lớn",
      "parent": "1__100010"
    },
    {
      "value": "1__100040",
      "label": "Tivi & Phụ kiện",
      "parent": "1__100010"
    },
    {
      "value": "1__100041",
      "label": "Đồ gia dụng nhà bếp",
      "parent": "1__100010"
    },
    {
      "value": "1__100042",
      "label": "Mạch điện & Phụ tùng",
      "parent": "1__100010"
    },
    {
      "value": "1__100043",
      "label": "Pin",
      "parent": "1__100010"
    },
    {
      "value": "1__100044",
      "label": "Thuốc lá điện tử",
      "parent": "1__100010"
    },
    {
      "value": "1__100045",
      "label": "Thiết bị điều khiển từ xa",
      "parent": "1__100010"
    },
    {
      "value": "1__100046",
      "label": "Khác",
      "parent": "1__100010"
    },
    {
      "value": "1__100047",
      "label": "Quần jean",
      "parent": "1__100011"
    },
    {
      "value": "1__100048",
      "label": "Hoodie & Áo nỉ",
      "parent": "1__100011"
    },
    {
      "value": "1__100049",
      "label": "Áo len",
      "parent": "1__100011"
    },
    {
      "value": "1__100050",
      "label": "Áo khoác",
      "parent": "1__100011"
    },
    {
      "value": "1__100051",
      "label": "Com lê",
      "parent": "1__100011"
    },
    {
      "value": "1__100052",
      "label": "Quần dài",
      "parent": "1__100011"
    },
    {
      "value": "1__100053",
      "label": "Quần đùi",
      "parent": "1__100011"
    },
    {
      "value": "1__100054",
      "label": "Áo",
      "parent": "1__100011"
    },
    {
      "value": "1__100055",
      "label": "Đồ lót",
      "parent": "1__100011"
    },
    {
      "value": "1__100056",
      "label": "Đồ ngủ",
      "parent": "1__100011"
    },
    {
      "value": "1__100057",
      "label": "Bộ",
      "parent": "1__100011"
    },
    {
      "value": "1__100058",
      "label": "Trang phục truyền thống",
      "parent": "1__100011"
    },
    {
      "value": "1__100059",
      "label": "Đồ hóa trang",
      "parent": "1__100011"
    },
    {
      "value": "1__100060",
      "label": "Trang phục ngành nghề",
      "parent": "1__100011"
    },
    {
      "value": "1__100061",
      "label": "Khác",
      "parent": "1__100011"
    },
    {
      "value": "1__100062",
      "label": "Vớ/ Tất",
      "parent": "1__100011"
    },
    {
      "value": "1__100063",
      "label": "Bốt",
      "parent": "1__100012"
    },
    {
      "value": "1__100064",
      "label": "Giày thể thao/ Sneakers",
      "parent": "1__100012"
    },
    {
      "value": "1__100065",
      "label": "Giày sục",
      "parent": "1__100012"
    },
    {
      "value": "1__100066",
      "label": "Giày tây lười",
      "parent": "1__100012"
    },
    {
      "value": "1__100067",
      "label": "Giày Oxfords & Giày buộc dây",
      "parent": "1__100012"
    },
    {
      "value": "1__100068",
      "label": "Xăng-đan & Dép",
      "parent": "1__100012"
    },
    {
      "value": "1__100069",
      "label": "Phụ kiện giày dép",
      "parent": "1__100012"
    },
    {
      "value": "1__100070",
      "label": "Khác",
      "parent": "1__100012"
    },
    {
      "value": "1__100071",
      "label": "Thẻ sim",
      "parent": "1__100013"
    },
    {
      "value": "1__100072",
      "label": "Máy tính bảng",
      "parent": "1__100013"
    },
    {
      "value": "1__100073",
      "label": "Điện thoại",
      "parent": "1__100013"
    },
    {
      "value": "1__100074",
      "label": "Thiết bị đeo thông minh",
      "parent": "1__100013"
    },
    {
      "value": "1__100075",
      "label": "Phụ kiện",
      "parent": "1__100013"
    },
    {
      "value": "1__100076",
      "label": "Bộ đàm",
      "parent": "1__100013"
    },
    {
      "value": "1__100077",
      "label": "Khác",
      "parent": "1__100013"
    },
    {
      "value": "1__100085",
      "label": "Vali",
      "parent": "1__100015"
    },
    {
      "value": "1__100086",
      "label": "Túi du lịch",
      "parent": "1__100015"
    },
    {
      "value": "1__100087",
      "label": "Phụ kiện du lịch",
      "parent": "1__100015"
    },
    {
      "value": "1__100088",
      "label": "Khác",
      "parent": "1__100015"
    },
    {
      "value": "1__100089",
      "label": "Ba lô",
      "parent": "1__100016"
    },
    {
      "value": "1__100090",
      "label": "Cặp laptop",
      "parent": "1__100016"
    },
    {
      "value": "1__100091",
      "label": "Ví dự tiệc & Ví cầm tay",
      "parent": "1__100016"
    },
    {
      "value": "1__100092",
      "label": "Túi đeo hông & Túi đeo ngực",
      "parent": "1__100016"
    },
    {
      "value": "1__100093",
      "label": "Túi tote",
      "parent": "1__100016"
    },
    {
      "value": "1__100094",
      "label": "Túi quai xách",
      "parent": "1__100016"
    },
    {
      "value": "1__100095",
      "label": "Túi đeo chéo & Túi đeo vai",
      "parent": "1__100016"
    },
    {
      "value": "1__100096",
      "label": "Ví",
      "parent": "1__100016"
    },
    {
      "value": "1__100097",
      "label": "Phụ kiện túi",
      "parent": "1__100016"
    },
    {
      "value": "1__100098",
      "label": "Khác",
      "parent": "1__100016"
    },
    {
      "value": "1__100099",
      "label": "Áo",
      "parent": "1__100017"
    },
    {
      "value": "1__100100",
      "label": "Quần",
      "parent": "1__100017"
    },
    {
      "value": "1__100101",
      "label": "Quần đùi",
      "parent": "1__100017"
    },
    {
      "value": "1__100102",
      "label": "Váy",
      "parent": "1__100017"
    },
    {
      "value": "1__100103",
      "label": "Quần jeans",
      "parent": "1__100017"
    },
    {
      "value": "1__100104",
      "label": "Đầm",
      "parent": "1__100017"
    },
    {
      "value": "1__100105",
      "label": "Váy cưới",
      "parent": "1__100017"
    },
    {
      "value": "1__100106",
      "label": "Đồ liền thân",
      "parent": "1__100017"
    },
    {
      "value": "1__100107",
      "label": "Áo khoác",
      "parent": "1__100017"
    },
    {
      "value": "1__100108",
      "label": "Áo len",
      "parent": "1__100017"
    },
    {
      "value": "1__100109",
      "label": "Hoodie và Áo nỉ",
      "parent": "1__100017"
    },
    {
      "value": "1__100110",
      "label": "Bộ",
      "parent": "1__100017"
    },
    {
      "value": "1__100111",
      "label": "Đồ lót",
      "parent": "1__100017"
    },
    {
      "value": "1__100112",
      "label": "Đồ ngủ",
      "parent": "1__100017"
    },
    {
      "value": "1__100113",
      "label": "Đồ Bầu",
      "parent": "1__100017"
    },
    {
      "value": "1__100114",
      "label": "Đồ truyền thống",
      "parent": "1__100017"
    },
    {
      "value": "1__100115",
      "label": "Đồ hóa trang",
      "parent": "1__100017"
    },
    {
      "value": "1__100116",
      "label": "Khác",
      "parent": "1__100017"
    },
    {
      "value": "1__100117",
      "label": "Vải",
      "parent": "1__100017"
    },
    {
      "value": "1__100118",
      "label": "Vớ/ Tất",
      "parent": "1__100017"
    },
    {
      "value": "1__100556",
      "label": "Bốt",
      "parent": "1__100532"
    },
    {
      "value": "1__100557",
      "label": "Giày thể thao/ sneaker",
      "parent": "1__100532"
    },
    {
      "value": "1__100558",
      "label": "Giày đế bằng",
      "parent": "1__100532"
    },
    {
      "value": "1__100559",
      "label": "Giày cao gót",
      "parent": "1__100532"
    },
    {
      "value": "1__100560",
      "label": "Giày đế xuồng",
      "parent": "1__100532"
    },
    {
      "value": "1__100561",
      "label": "Xăng-đan và dép",
      "parent": "1__100532"
    },
    {
      "value": "1__100562",
      "label": "Phụ kiện & chăm sóc giày",
      "parent": "1__100532"
    },
    {
      "value": "1__100563",
      "label": "Khác",
      "parent": "1__100532"
    },
    {
      "value": "1__100564",
      "label": "Ba lô",
      "parent": "1__100533"
    },
    {
      "value": "1__100565",
      "label": "Cặp laptop",
      "parent": "1__100533"
    },
    {
      "value": "1__100566",
      "label": "Túi tote",
      "parent": "1__100533"
    },
    {
      "value": "1__100567",
      "label": "Cặp xách công sở",
      "parent": "1__100533"
    },
    {
      "value": "1__100568",
      "label": "Ví cầm tay",
      "parent": "1__100533"
    },
    {
      "value": "1__100569",
      "label": "Túi đeo hông & Túi đeo ngực",
      "parent": "1__100533"
    },
    {
      "value": "1__100570",
      "label": "Túi đeo chéo",
      "parent": "1__100533"
    },
    {
      "value": "1__100571",
      "label": "Bóp/ Ví",
      "parent": "1__100533"
    },
    {
      "value": "1__100572",
      "label": "Khác",
      "parent": "1__100533"
    },
    {
      "value": "1__100573",
      "label": "Đồng hồ nữ",
      "parent": "1__100534"
    },
    {
      "value": "1__100574",
      "label": "Đồng hồ nam",
      "parent": "1__100534"
    },
    {
      "value": "1__100575",
      "label": "Bộ đồng hồ & Đồng hồ cặp",
      "parent": "1__100534"
    },
    {
      "value": "1__100576",
      "label": "Phụ kiện đồng hồ",
      "parent": "1__100534"
    },
    {
      "value": "1__100577",
      "label": "Khác",
      "parent": "1__100534"
    },
    {
      "value": "1__100578",
      "label": "Tai nghe nhét tai & chụp tai",
      "parent": "1__100535"
    },
    {
      "value": "1__100579",
      "label": "Máy nghe nhạc",
      "parent": "1__100535"
    },
    {
      "value": "1__100580",
      "label": "Micro thu âm",
      "parent": "1__100535"
    },
    {
      "value": "1__100581",
      "label": "Amply và đầu chỉnh âm",
      "parent": "1__100535"
    },
    {
      "value": "1__100582",
      "label": "Dàn âm thanh",
      "parent": "1__100535"
    },
    {
      "value": "1__100583",
      "label": "Cáp âm thanh/ video & Đầu chuyển",
      "parent": "1__100535"
    },
    {
      "value": "1__100584",
      "label": "Khác",
      "parent": "1__100535"
    },
    {
      "value": "1__100755",
      "label": "Mô tô, xe máy",
      "parent": "1__100641"
    },
    {
      "value": "1__100756",
      "label": "Phụ kiện xe máy",
      "parent": "1__100641"
    },
    {
      "value": "1__100757",
      "label": "Phụ tùng xe máy",
      "parent": "1__100641"
    },
    {
      "value": "1__100758",
      "label": "Mũ bảo hiểm & Phụ kiện",
      "parent": "1__100641"
    },
    {
      "value": "1__100759",
      "label": "Khác",
      "parent": "1__100641"
    }
  ],
  [ALL_PLATFORM_BASE_OBJECT.sendo.uniqueName]: [
    {
      "value": "4__2339",
      "label": "Điện thoại cũ",
      "parent": "4__1663"
    },
    {
      "value": "4__2338",
      "label": "Máy tính bảng mới",
      "parent": "4__1663"
    },
    {
      "value": "4__2922",
      "label": "Máy tính bảng cũ",
      "parent": "4__1663"
    },
    {
      "value": "4__1664",
      "label": "Điện thoại mới",
      "parent": "4__1663"
    },
    {
      "value": "4__6387",
      "label": "Phụ kiện thời trang trẻ em",
      "parent": "4__6365"
    },
    {
      "value": "4__6375",
      "label": "Bé trai 5-14 tuổi",
      "parent": "4__6365"
    },
    {
      "value": "4__6371",
      "label": "Bé gái 5-14 tuổi",
      "parent": "4__6365"
    },
    {
      "value": "4__6383",
      "label": "Giày dép bé trai",
      "parent": "4__6365"
    },
    {
      "value": "4__6369",
      "label": "Bé gái 1-4 tuổi",
      "parent": "4__6365"
    },
    {
      "value": "4__6381",
      "label": "Giày dép bé gái",
      "parent": "4__6365"
    },
    {
      "value": "4__6373",
      "label": "Bé trai 1-4 tuổi",
      "parent": "4__6365"
    },
    {
      "value": "4__6366",
      "label": "Quần áo sơ sinh",
      "parent": "4__6365"
    },
    {
      "value": "4__2102",
      "label": "Tivi",
      "parent": "4__2101"
    },
    {
      "value": "4__2106",
      "label": "Thiết bị chơi game",
      "parent": "4__2101"
    },
    {
      "value": "4__2103",
      "label": "Phụ kiện tivi",
      "parent": "4__2101"
    },
    {
      "value": "4__1996",
      "label": "Dụng cụ chuyên khoa",
      "parent": "4__1992"
    },
    {
      "value": "4__1993",
      "label": "Chăm sóc sức khỏe",
      "parent": "4__1992"
    },
    {
      "value": "4__1997",
      "label": "Máy móc y tế",
      "parent": "4__1992"
    },
    {
      "value": "4__2821",
      "label": "Dụng cụ vật lý trị liệu",
      "parent": "4__1992"
    },
    {
      "value": "4__1995",
      "label": "Vật tư y tế",
      "parent": "4__1992"
    },
    {
      "value": "4__1994",
      "label": "Thiết bị massage",
      "parent": "4__1992"
    },
    {
      "value": "4__3636",
      "label": "Thiết bị y tế khác",
      "parent": "4__1992"
    },
    {
      "value": "4__4218",
      "label": "Chăm sóc cá & bể thủy sinh",
      "parent": "4__4216"
    },
    {
      "value": "4__4220",
      "label": "Chăm sóc thú cưng khác",
      "parent": "4__4216"
    },
    {
      "value": "4__4217",
      "label": "Chăm sóc chó, mèo",
      "parent": "4__4216"
    },
    {
      "value": "4__4219",
      "label": "Chăm sóc chim",
      "parent": "4__4216"
    },
    {
      "value": "4__746",
      "label": "Câu cá",
      "parent": "4__736"
    },
    {
      "value": "4__3720",
      "label": "Nhạc cụ",
      "parent": "4__736"
    },
    {
      "value": "4__3688",
      "label": "Dã ngoại, cắm trại",
      "parent": "4__736"
    },
    {
      "value": "4__747",
      "label": "Bơi lội, lặn",
      "parent": "4__736"
    },
    {
      "value": "4__3729",
      "label": "Các môn thể thao khác",
      "parent": "4__736"
    },
    {
      "value": "4__3702",
      "label": "Leo núi",
      "parent": "4__736"
    },
    {
      "value": "4__748",
      "label": "Golf",
      "parent": "4__736"
    },
    {
      "value": "4__3735",
      "label": "Yoga, võ thuật, đấm bốc",
      "parent": "4__736"
    },
    {
      "value": "4__3743",
      "label": "Chạy bộ",
      "parent": "4__736"
    },
    {
      "value": "4__757",
      "label": "Cầu lông",
      "parent": "4__736"
    },
    {
      "value": "4__741",
      "label": "Xe đạp",
      "parent": "4__736"
    },
    {
      "value": "4__743",
      "label": "Hỗ trợ an toàn",
      "parent": "4__736"
    },
    {
      "value": "4__754",
      "label": "Bóng đá",
      "parent": "4__736"
    },
    {
      "value": "4__761",
      "label": "Tennis",
      "parent": "4__736"
    },
    {
      "value": "4__753",
      "label": "Bóng rổ",
      "parent": "4__736"
    },
    {
      "value": "4__744",
      "label": "Dụng cụ, thiết bị tập thể dục",
      "parent": "4__736"
    },
    {
      "value": "4__785",
      "label": "Phụ kiện xe đạp",
      "parent": "4__736"
    },
    {
      "value": "4__749",
      "label": "Bóng bàn",
      "parent": "4__736"
    },
    {
      "value": "4__784",
      "label": "Phụ tùng xe đạp",
      "parent": "4__736"
    },
    {
      "value": "4__752",
      "label": "Bóng chuyền",
      "parent": "4__736"
    },
    {
      "value": "4__1974",
      "label": "Phụ kiện máy ảnh, máy quay",
      "parent": "4__1954"
    },
    {
      "value": "4__3642",
      "label": "Máy ảnh - Máy quay phim khác",
      "parent": "4__1954"
    },
    {
      "value": "4__1964",
      "label": "Ống kính",
      "parent": "4__1954"
    },
    {
      "value": "4__3263",
      "label": "Máy ảnh",
      "parent": "4__1954"
    },
    {
      "value": "4__1968",
      "label": "Máy quay, thiết bị ghi hình",
      "parent": "4__1954"
    },
    {
      "value": "4__1190",
      "label": "Các loại thực phẩm khô",
      "parent": "4__1171"
    },
    {
      "value": "4__1236",
      "label": "Gia vị",
      "parent": "4__1171"
    },
    {
      "value": "4__2607",
      "label": "Giặt giũ & chăm sóc nhà cửa",
      "parent": "4__1171"
    },
    {
      "value": "4__1183",
      "label": "Kẹo",
      "parent": "4__1171"
    },
    {
      "value": "4__1172",
      "label": "Bánh, mứt",
      "parent": "4__1171"
    },
    {
      "value": "4__2595",
      "label": "Vệ sinh cá nhân",
      "parent": "4__1171"
    },
    {
      "value": "4__6626",
      "label": "Quà tặng bách hóa theo mùa",
      "parent": "4__1171"
    },
    {
      "value": "4__4153",
      "label": "Thực phẩm chay",
      "parent": "4__1171"
    },
    {
      "value": "4__1233",
      "label": "Nguyên liệu",
      "parent": "4__1171"
    },
    {
      "value": "4__1195",
      "label": "Sữa và thực phẩm từ sữa",
      "parent": "4__1171"
    },
    {
      "value": "4__1214",
      "label": "Thực phẩm bổ dưỡng",
      "parent": "4__1171"
    },
    {
      "value": "4__3641",
      "label": "Bách hóa tổng hợp khác",
      "parent": "4__1171"
    },
    {
      "value": "4__1204",
      "label": "Thực phẩm đóng hộp",
      "parent": "4__1171"
    },
    {
      "value": "4__1227",
      "label": "Đồ uống",
      "parent": "4__1171"
    },
    {
      "value": "4__3174",
      "label": "Bếp điện, ấm đun",
      "parent": "4__1108"
    },
    {
      "value": "4__3185",
      "label": "Máy hút bụi",
      "parent": "4__1108"
    },
    {
      "value": "4__4007",
      "label": "Máy nước nóng",
      "parent": "4__1108"
    },
    {
      "value": "4__4109",
      "label": "Thiết bị điện cho nhà hàng, quán cafe",
      "parent": "4__1108"
    },
    {
      "value": "4__3147",
      "label": "Lò nướng, bếp nướng",
      "parent": "4__1108"
    },
    {
      "value": "4__3164",
      "label": "Quạt",
      "parent": "4__1108"
    },
    {
      "value": "4__3141",
      "label": "Nồi điện, nồi cơm điện",
      "parent": "4__1108"
    },
    {
      "value": "4__4000",
      "label": "Thiết bị chăm sóc quần áo",
      "parent": "4__1108"
    },
    {
      "value": "4__1114",
      "label": "Máy lọc, điều hòa không khí",
      "parent": "4__1108"
    },
    {
      "value": "4__3156",
      "label": "Máy xay, máy ép",
      "parent": "4__1108"
    },
    {
      "value": "4__1111",
      "label": "Thiết bị điện nhà bếp",
      "parent": "4__1108"
    },
    {
      "value": "4__2264",
      "label": "Sách Văn học - Tiểu thuyết",
      "parent": "4__2259"
    },
    {
      "value": "4__2261",
      "label": "Sách Ngoại ngữ - Từ điển",
      "parent": "4__2259"
    },
    {
      "value": "4__2263",
      "label": "Sách chuyên ngành",
      "parent": "4__2259"
    },
    {
      "value": "4__2265",
      "label": "Sách Văn hóa - Du lịch",
      "parent": "4__2259"
    },
    {
      "value": "4__2267",
      "label": "Sách cũ",
      "parent": "4__2259"
    },
    {
      "value": "4__2260",
      "label": "Sách Giáo khoa - Tham khảo",
      "parent": "4__2259"
    },
    {
      "value": "4__2692",
      "label": "Sách thiếu nhi",
      "parent": "4__2259"
    },
    {
      "value": "4__2262",
      "label": "Sách Kinh tế",
      "parent": "4__2259"
    },
    {
      "value": "4__2697",
      "label": "Dụng cụ mỹ thuật",
      "parent": "4__2259"
    },
    {
      "value": "4__2266",
      "label": "Sách thường thức - đời sống",
      "parent": "4__2259"
    },
    {
      "value": "4__2268",
      "label": "Văn Phòng Phẩm",
      "parent": "4__2259"
    },
    {
      "value": "4__3212",
      "label": "Sạc điện thoại, máy tính bảng",
      "parent": "4__528"
    },
    {
      "value": "4__529",
      "label": "Phụ kiện điện thoại, máy tính bảng",
      "parent": "4__528"
    },
    {
      "value": "4__3202",
      "label": "Ốp lưng, bao da điện thoại, máy tính bảng",
      "parent": "4__528"
    },
    {
      "value": "4__531",
      "label": "Thiết bị mạng",
      "parent": "4__528"
    },
    {
      "value": "4__1428",
      "label": "Thiết bị đeo công nghệ",
      "parent": "4__528"
    },
    {
      "value": "4__3220",
      "label": "Thiết bị lưu trữ",
      "parent": "4__528"
    },
    {
      "value": "4__3803",
      "label": "Cáp sạc, cáp kết nối",
      "parent": "4__528"
    },
    {
      "value": "4__3795",
      "label": "Linh kiện điện thoại, máy tính bảng",
      "parent": "4__528"
    },
    {
      "value": "4__3775",
      "label": "SIM card, dụng cụ chuyển đổi SIM",
      "parent": "4__528"
    },
    {
      "value": "4__3646",
      "label": "Phụ kiện công nghệ khác",
      "parent": "4__528"
    },
    {
      "value": "4__1690",
      "label": "Dép nam",
      "parent": "4__1686"
    },
    {
      "value": "4__2558",
      "label": "Giày boot nữ",
      "parent": "4__1686"
    },
    {
      "value": "4__1788",
      "label": "Giày thể thao chuyên dụng",
      "parent": "4__1686"
    },
    {
      "value": "4__4162",
      "label": "Giày sneaker/thể thao nữ",
      "parent": "4__1686"
    },
    {
      "value": "4__4169",
      "label": "Giày lười, giày mọi nam",
      "parent": "4__1686"
    },
    {
      "value": "4__4199",
      "label": "Giày, dép nữ big size",
      "parent": "4__1686"
    },
    {
      "value": "4__4131",
      "label": "Giày, dép unisex",
      "parent": "4__1686"
    },
    {
      "value": "4__4185",
      "label": "Giày tăng chiều cao nam",
      "parent": "4__1686"
    },
    {
      "value": "4__4165",
      "label": "Giày lười, giày mọi, slip-on nữ",
      "parent": "4__1686"
    },
    {
      "value": "4__4170",
      "label": "Giày sandal nữ",
      "parent": "4__1686"
    },
    {
      "value": "4__1687",
      "label": "Giày nữ khác",
      "parent": "4__1686"
    },
    {
      "value": "4__1691",
      "label": "Phụ kiện giày dép",
      "parent": "4__1686"
    },
    {
      "value": "4__4190",
      "label": "Giày, dép nhựa đi mưa",
      "parent": "4__1686"
    },
    {
      "value": "4__4207",
      "label": "Giày, dép nam big size",
      "parent": "4__1686"
    },
    {
      "value": "4__1688",
      "label": "Dép nữ",
      "parent": "4__1686"
    },
    {
      "value": "4__1689",
      "label": "Giày nam khác",
      "parent": "4__1686"
    },
    {
      "value": "4__4166",
      "label": "Giày sneaker/thể thao nam",
      "parent": "4__1686"
    },
    {
      "value": "4__4179",
      "label": "Giày tây nam",
      "parent": "4__1686"
    },
    {
      "value": "4__2545",
      "label": "Giày cao gót nữ",
      "parent": "4__1686"
    },
    {
      "value": "4__9",
      "label": "Áo nữ",
      "parent": "4__8"
    },
    {
      "value": "4__2031",
      "label": "Thời trang trung niên",
      "parent": "4__8"
    },
    {
      "value": "4__664",
      "label": "Áo khoác nữ",
      "parent": "4__8"
    },
    {
      "value": "4__2497",
      "label": "Thời trang big size",
      "parent": "4__8"
    },
    {
      "value": "4__2629",
      "label": "Thời trang thiết kế",
      "parent": "4__8"
    },
    {
      "value": "4__62",
      "label": "Đồ bơi, đồ đi biển",
      "parent": "4__8"
    },
    {
      "value": "4__34",
      "label": "Chân váy",
      "parent": "4__8"
    },
    {
      "value": "4__52",
      "label": "Đồ lót nữ",
      "parent": "4__8"
    },
    {
      "value": "4__1898",
      "label": "Quần áo thể thao nữ",
      "parent": "4__8"
    },
    {
      "value": "4__26",
      "label": "Đầm, váy",
      "parent": "4__8"
    },
    {
      "value": "4__3828",
      "label": "Vải, nguyên phụ liệu may mặc",
      "parent": "4__8"
    },
    {
      "value": "4__3831",
      "label": "Đồ đôi nam nữ",
      "parent": "4__8"
    },
    {
      "value": "4__1938",
      "label": "Đồ mặc nhà, đồ ngủ",
      "parent": "4__8"
    },
    {
      "value": "4__18",
      "label": "Quần nữ",
      "parent": "4__8"
    },
    {
      "value": "4__401",
      "label": "Trang phục cưới",
      "parent": "4__8"
    },
    {
      "value": "4__695",
      "label": "Set bộ, jumpsuit",
      "parent": "4__8"
    },
    {
      "value": "4__4014",
      "label": "Thiết bị nước",
      "parent": "4__2849"
    },
    {
      "value": "4__4024",
      "label": "Linh kiện điện tử",
      "parent": "4__2849"
    },
    {
      "value": "4__3344",
      "label": "Bóng đèn và phụ kiện đèn",
      "parent": "4__2849"
    },
    {
      "value": "4__3130",
      "label": "Dụng cụ làm vườn ",
      "parent": "4__2849"
    },
    {
      "value": "4__3070",
      "label": "Máy cơ khí và chế tạo",
      "parent": "4__2849"
    },
    {
      "value": "4__3640",
      "label": "Tân trang nhà cửa khác",
      "parent": "4__2849"
    },
    {
      "value": "4__3055",
      "label": "Dụng cụ cầm tay",
      "parent": "4__2849"
    },
    {
      "value": "4__2852",
      "label": "Thiết bị an ninh",
      "parent": "4__2849"
    },
    {
      "value": "4__6614",
      "label": "Thiết bị và dụng cụ PCCC",
      "parent": "4__2849"
    },
    {
      "value": "4__6594",
      "label": "Thiết bị nâng đỡ",
      "parent": "4__2849"
    },
    {
      "value": "4__3343",
      "label": "Các loại đèn",
      "parent": "4__2849"
    },
    {
      "value": "4__2853",
      "label": "Đồ kim khí",
      "parent": "4__2849"
    },
    {
      "value": "4__3000",
      "label": "Bảo hộ lao động",
      "parent": "4__2849"
    },
    {
      "value": "4__3120",
      "label": "Thiết bị điện và phụ kiện",
      "parent": "4__2849"
    },
    {
      "value": "4__3107",
      "label": "Dụng cụ xây dựng cho gia đình",
      "parent": "4__2849"
    },
    {
      "value": "4__4033",
      "label": "Phụ kiện máy cơ khí và chế tạo",
      "parent": "4__2849"
    },
    {
      "value": "4__3044",
      "label": "Thiết bị đo lường",
      "parent": "4__2849"
    },
    {
      "value": "4__2223",
      "label": "Motor, xe máy, xe đạp điện",
      "parent": "4__2150"
    },
    {
      "value": "4__3432",
      "label": "Phụ tùng xe máy",
      "parent": "4__2150"
    },
    {
      "value": "4__3481",
      "label": "Hệ thống cơ điện, nhiên liệu, bộ lọc",
      "parent": "4__2150"
    },
    {
      "value": "4__3431",
      "label": "Chăm sóc và bảo dưỡng ô tô, xe máy",
      "parent": "4__2150"
    },
    {
      "value": "4__2157",
      "label": "Phụ kiện bên trong ô tô",
      "parent": "4__2150"
    },
    {
      "value": "4__3497",
      "label": "Đèn xe máy",
      "parent": "4__2150"
    },
    {
      "value": "4__3449",
      "label": "Bánh xe, lốp xe máy",
      "parent": "4__2150"
    },
    {
      "value": "4__2151",
      "label": "Thiết bị số cho ô tô",
      "parent": "4__2150"
    },
    {
      "value": "4__3711",
      "label": "Xe ô tô",
      "parent": "4__2150"
    },
    {
      "value": "4__3430",
      "label": "Đồ bảo hộ mô tô, xe máy",
      "parent": "4__2150"
    },
    {
      "value": "4__2180",
      "label": "Thiết bị định vị, cảm biến",
      "parent": "4__2150"
    },
    {
      "value": "4__3457",
      "label": "Phụ kiện, đồ chơi xe máy",
      "parent": "4__2150"
    },
    {
      "value": "4__2173",
      "label": "Phụ kiện bên ngoài ô tô, phụ tùng",
      "parent": "4__2150"
    },
    {
      "value": "4__321",
      "label": "Thực phẩm chức năng",
      "parent": "4__220"
    },
    {
      "value": "4__2989",
      "label": "Trị liệu chuyên sâu da mặt",
      "parent": "4__220"
    },
    {
      "value": "4__1435",
      "label": "Dụng cụ trang điểm",
      "parent": "4__220"
    },
    {
      "value": "4__4102",
      "label": "Chăm sóc phụ nữ",
      "parent": "4__220"
    },
    {
      "value": "4__2995",
      "label": "Mặt nạ dưỡng da",
      "parent": "4__220"
    },
    {
      "value": "4__221",
      "label": "Mỹ phẩm nam",
      "parent": "4__220"
    },
    {
      "value": "4__6624",
      "label": "Dinh dưỡng thể thao, protein",
      "parent": "4__220"
    },
    {
      "value": "4__275",
      "label": "Chăm sóc tóc",
      "parent": "4__220"
    },
    {
      "value": "4__2816",
      "label": "Tắm & chăm sóc cơ thể",
      "parent": "4__220"
    },
    {
      "value": "4__2972",
      "label": "Làm sạch da mặt",
      "parent": "4__220"
    },
    {
      "value": "4__1333",
      "label": "Sản phẩm hỗ trợ tình dục",
      "parent": "4__220"
    },
    {
      "value": "4__257",
      "label": "Trang điểm mắt",
      "parent": "4__220"
    },
    {
      "value": "4__2037",
      "label": "Mỹ phẩm Handmade & Organic",
      "parent": "4__220"
    },
    {
      "value": "4__247",
      "label": "Trang điểm mặt",
      "parent": "4__220"
    },
    {
      "value": "4__723",
      "label": "Thiết bị & dụng cụ chăm sóc cá nhân",
      "parent": "4__220"
    },
    {
      "value": "4__272",
      "label": "Nước hoa",
      "parent": "4__220"
    },
    {
      "value": "4__263",
      "label": "Trang điểm môi",
      "parent": "4__220"
    },
    {
      "value": "4__269",
      "label": "Chăm sóc móng",
      "parent": "4__220"
    },
    {
      "value": "4__2978",
      "label": "Dưỡng da mặt",
      "parent": "4__220"
    },
    {
      "value": "4__4095",
      "label": "Chăm sóc răng miệng",
      "parent": "4__220"
    },
    {
      "value": "4__1590",
      "label": "Đồ dùng phòng tắm cho bé",
      "parent": "4__138"
    },
    {
      "value": "4__1314",
      "label": "Sữa và thức ăn dặm",
      "parent": "4__138"
    },
    {
      "value": "4__177",
      "label": "Đồ cho bé ra ngoài",
      "parent": "4__138"
    },
    {
      "value": "4__139",
      "label": "Thời trang mẹ bầu",
      "parent": "4__138"
    },
    {
      "value": "4__1295",
      "label": "Thời trang gia đình",
      "parent": "4__138"
    },
    {
      "value": "4__1881",
      "label": "Đồ dùng cho mẹ bầu",
      "parent": "4__138"
    },
    {
      "value": "4__198",
      "label": "Dụng cụ ăn uống",
      "parent": "4__138"
    },
    {
      "value": "4__1580",
      "label": "Bỉm, Tã, Vệ sinh",
      "parent": "4__138"
    },
    {
      "value": "4__1554",
      "label": "Chăm sóc sức khỏe và an toàn",
      "parent": "4__138"
    },
    {
      "value": "4__184",
      "label": "Đồ dùng phòng ngủ cho bé",
      "parent": "4__138"
    },
    {
      "value": "4__6533",
      "label": "Tranh phong thủy",
      "parent": "4__6511"
    },
    {
      "value": "4__6512",
      "label": "Tượng Phật",
      "parent": "4__6511"
    },
    {
      "value": "4__6526",
      "label": "Đá phong thủy",
      "parent": "4__6511"
    },
    {
      "value": "4__6556",
      "label": "Đồ thờ cúng",
      "parent": "4__6511"
    },
    {
      "value": "4__6518",
      "label": "Linh vật phong thủy",
      "parent": "4__6511"
    },
    {
      "value": "4__6546",
      "label": "Phong thủy trang trí",
      "parent": "4__6511"
    },
    {
      "value": "4__6565",
      "label": "Trang sức phong thủy",
      "parent": "4__6511"
    },
    {
      "value": "4__6668",
      "label": "Trang sức vàng nam",
      "parent": "4__3859"
    },
    {
      "value": "4__6703",
      "label": "Trang sức vàng khác",
      "parent": "4__3859"
    },
    {
      "value": "4__3866",
      "label": "Trang sức cặp đôi",
      "parent": "4__3859"
    },
    {
      "value": "4__6646",
      "label": "Trang sức vàng nữ",
      "parent": "4__3859"
    },
    {
      "value": "4__3861",
      "label": "Vòng, lắc tay",
      "parent": "4__3859"
    },
    {
      "value": "4__3865",
      "label": "Trang sức cho bé",
      "parent": "4__3859"
    },
    {
      "value": "4__3874",
      "label": "Set bộ trang sức",
      "parent": "4__3859"
    },
    {
      "value": "4__3863",
      "label": "Trang sức cơ thể",
      "parent": "4__3859"
    },
    {
      "value": "4__3868",
      "label": "Bông tai, khuyên tai",
      "parent": "4__3859"
    },
    {
      "value": "4__3860",
      "label": "Dây chuyền, vòng cổ",
      "parent": "4__3859"
    },
    {
      "value": "4__3901",
      "label": "Nhẫn",
      "parent": "4__3859"
    },
    {
      "value": "4__3862",
      "label": "Charm",
      "parent": "4__3859"
    },
    {
      "value": "4__3867",
      "label": "Nguyên liệu & dụng cụ làm trang sức",
      "parent": "4__3859"
    },
    {
      "value": "4__3864",
      "label": "Kệ treo, hộp đựng trang sức",
      "parent": "4__3859"
    },
    {
      "value": "4__1831",
      "label": "Thắt Lưng",
      "parent": "4__1629"
    },
    {
      "value": "4__1630",
      "label": "Phụ kiện thời trang nam",
      "parent": "4__1629"
    },
    {
      "value": "4__1825",
      "label": "Nón",
      "parent": "4__1629"
    },
    {
      "value": "4__1820",
      "label": "Mắt kính nam nữ",
      "parent": "4__1629"
    },
    {
      "value": "4__1631",
      "label": "Phụ kiện thời trang nữ",
      "parent": "4__1629"
    },
    {
      "value": "4__1834",
      "label": "Phụ kiện tóc nữ",
      "parent": "4__1629"
    },
    {
      "value": "4__95",
      "label": "Áo thun, áo polo nam",
      "parent": "4__94"
    },
    {
      "value": "4__673",
      "label": "Áo Khoác Nam",
      "parent": "4__94"
    },
    {
      "value": "4__1670",
      "label": "Quần jeans nam",
      "parent": "4__94"
    },
    {
      "value": "4__674",
      "label": "Áo vest, blazer",
      "parent": "4__94"
    },
    {
      "value": "4__3839",
      "label": "Quần Dài Nam",
      "parent": "4__94"
    },
    {
      "value": "4__1676",
      "label": "Quần shorts nam",
      "parent": "4__94"
    },
    {
      "value": "4__106",
      "label": "Đồ lót và đồ ngủ nam",
      "parent": "4__94"
    },
    {
      "value": "4__112",
      "label": "Quần áo thể thao nam",
      "parent": "4__94"
    },
    {
      "value": "4__1666",
      "label": "Áo sơ mi nam",
      "parent": "4__94"
    },
    {
      "value": "4__3850",
      "label": "Thời trang nam big size",
      "parent": "4__94"
    },
    {
      "value": "4__3713",
      "label": "Áo dài nam",
      "parent": "4__94"
    },
    {
      "value": "4__1729",
      "label": "Ví, bóp nam",
      "parent": "4__1722"
    },
    {
      "value": "4__1725",
      "label": "Ví, bóp nữ",
      "parent": "4__1722"
    },
    {
      "value": "4__1814",
      "label": "Túi laptop, ipad, điện thoại",
      "parent": "4__1722"
    },
    {
      "value": "4__1808",
      "label": "Vali - Túi xách du lịch",
      "parent": "4__1722"
    },
    {
      "value": "4__1804",
      "label": "Balo",
      "parent": "4__1722"
    },
    {
      "value": "4__1723",
      "label": "Túi xách nữ",
      "parent": "4__1722"
    },
    {
      "value": "4__1726",
      "label": "Túi xách nam",
      "parent": "4__1722"
    },
    {
      "value": "4__2577",
      "label": "Đồng hồ đôi",
      "parent": "4__1366"
    },
    {
      "value": "4__1382",
      "label": "Đồng hồ trẻ em",
      "parent": "4__1366"
    },
    {
      "value": "4__1367",
      "label": "Đồng hồ nam",
      "parent": "4__1366"
    },
    {
      "value": "4__1376",
      "label": "Đồng hồ khác",
      "parent": "4__1366"
    },
    {
      "value": "4__1372",
      "label": "Đồng hồ nữ",
      "parent": "4__1366"
    },
    {
      "value": "4__1387",
      "label": "Phụ kiện đồng hồ",
      "parent": "4__1366"
    },
    {
      "value": "4__1444",
      "label": "Dụng cụ làm bánh, kem",
      "parent": "4__1019"
    },
    {
      "value": "4__1020",
      "label": "Đồ dùng nhà bếp",
      "parent": "4__1019"
    },
    {
      "value": "4__1074",
      "label": "Vệ sinh, chăm sóc nhà cửa",
      "parent": "4__1019"
    },
    {
      "value": "4__2049",
      "label": "Nội thất phòng khách",
      "parent": "4__1019"
    },
    {
      "value": "4__2071",
      "label": "Nội thất phòng trẻ em",
      "parent": "4__1019"
    },
    {
      "value": "4__1067",
      "label": "Thiết bị & đồ dùng phòng tắm",
      "parent": "4__1019"
    },
    {
      "value": "4__1028",
      "label": "Đồ dùng phòng ăn, uống",
      "parent": "4__1019"
    },
    {
      "value": "4__2067",
      "label": "Nội thất phòng làm việc",
      "parent": "4__1019"
    },
    {
      "value": "4__2061",
      "label": "Nội thất phòng ngủ",
      "parent": "4__1019"
    },
    {
      "value": "4__2370",
      "label": "Đồ Ngoại Thất",
      "parent": "4__1019"
    },
    {
      "value": "4__1088",
      "label": "Đồ dùng sinh hoạt",
      "parent": "4__1019"
    },
    {
      "value": "4__1059",
      "label": "Đồ dùng phòng ngủ",
      "parent": "4__1019"
    },
    {
      "value": "4__3638",
      "label": "Đồ dùng trong nhà khác",
      "parent": "4__1019"
    },
    {
      "value": "4__4289",
      "label": "Bộ đồ ăn dùng một lần",
      "parent": "4__1019"
    },
    {
      "value": "4__2055",
      "label": "Nội thất bếp, phòng ăn",
      "parent": "4__1019"
    },
    {
      "value": "4__6889",
      "label": "E-Voucher - Quà Tặng",
      "parent": "4__2702"
    },
    {
      "value": "4__6894",
      "label": "Dịch vụ giúp việc - Sửa chữa",
      "parent": "4__2702"
    },
    {
      "value": "4__6706",
      "label": "Mã code phần mềm",
      "parent": "4__2702"
    },
    {
      "value": "4__6730",
      "label": "Làm đẹp - Spa - Gym",
      "parent": "4__2702"
    },
    {
      "value": "4__6712",
      "label": "Dịch vụ bảo hiểm",
      "parent": "4__2702"
    },
    {
      "value": "4__2705",
      "label": "Khóa học",
      "parent": "4__2702"
    },
    {
      "value": "4__2703",
      "label": "Nhà hàng - Ẩm thực",
      "parent": "4__2702"
    },
    {
      "value": "4__6633",
      "label": "Dịch vụ quà tặng",
      "parent": "4__2702"
    },
    {
      "value": "4__2825",
      "label": "Giải trí",
      "parent": "4__2702"
    },
    {
      "value": "4__6864",
      "label": "Vé máy bay Sendo",
      "parent": "4__6450"
    },
    {
      "value": "4__6901",
      "label": "Vé xe Sendo",
      "parent": "4__6450"
    },
    {
      "value": "4__6873",
      "label": "Vé tàu Sendo",
      "parent": "4__6450"
    },
    {
      "value": "4__6740",
      "label": "Khách sạn",
      "parent": "4__6450"
    },
    {
      "value": "4__6886",
      "label": "Combo du lịch trong nước",
      "parent": "4__6450"
    },
    {
      "value": "4__6478",
      "label": "Tour du lịch trong nước",
      "parent": "4__6450"
    },
    {
      "value": "4__6880",
      "label": "Phương tiện di chuyển - Thuê xe",
      "parent": "4__6450"
    },
    {
      "value": "4__6508",
      "label": "Tour trọn gói",
      "parent": "4__6450"
    },
    {
      "value": "4__6489",
      "label": "Dịch vụ du lịch trong nước",
      "parent": "4__6450"
    },
    {
      "value": "4__2502",
      "label": "Máy lạnh, máy điều hòa",
      "parent": "4__2075"
    },
    {
      "value": "4__2506",
      "label": "Máy giặt, máy sấy",
      "parent": "4__2075"
    },
    {
      "value": "4__2077",
      "label": "Điện gia dụng lớn",
      "parent": "4__2075"
    },
    {
      "value": "4__2076",
      "label": "Tủ lạnh, tủ đông, tủ mát",
      "parent": "4__2075"
    },
    {
      "value": "4__6755",
      "label": "Tiện Ích Nổi Bật",
      "parent": "4__3674"
    },
    {
      "value": "4__6898",
      "label": "Tài chính",
      "parent": "4__3674"
    },
    {
      "value": "4__6756",
      "label": "Tiện Ích Điện Thoại",
      "parent": "4__3674"
    },
    {
      "value": "4__6758",
      "label": "Tiện Ích khác",
      "parent": "4__3674"
    },
    {
      "value": "4__3677",
      "label": "Thanh Toán Hóa đơn",
      "parent": "4__3674"
    },
    {
      "value": "4__6757",
      "label": "Du Lịch - Giải Trí",
      "parent": "4__3674"
    },
    {
      "value": "4__2403",
      "label": "Thực phẩm đông lạnh",
      "parent": "4__2399"
    },
    {
      "value": "4__3673",
      "label": "Rau - củ - quả",
      "parent": "4__2399"
    },
    {
      "value": "4__2401",
      "label": "Thủy hải sản",
      "parent": "4__2399"
    },
    {
      "value": "4__2400",
      "label": "Thịt - Trứng",
      "parent": "4__2399"
    },
    {
      "value": "4__2442",
      "label": "Các Loại Mắm",
      "parent": "4__2399"
    },
    {
      "value": "4__2434",
      "label": "Hoa, Cây cảnh",
      "parent": "4__604"
    },
    {
      "value": "4__607",
      "label": "Quà tặng, hàng thủ công",
      "parent": "4__604"
    },
    {
      "value": "4__4307",
      "label": "Nến và sản phẩm làm thơm phòng",
      "parent": "4__604"
    },
    {
      "value": "4__605",
      "label": "Trang trí nhà cửa",
      "parent": "4__604"
    },
    {
      "value": "4__4309",
      "label": "Trang trí cửa sổ, lối đi",
      "parent": "4__604"
    },
    {
      "value": "4__606",
      "label": "Trang trí bàn ",
      "parent": "4__604"
    },
    {
      "value": "4__3963",
      "label": "Nguyên vật liệu làm đồ handmade",
      "parent": "4__604"
    },
    {
      "value": "4__3964",
      "label": "Dụng cụ làm đồ handmade",
      "parent": "4__604"
    },
    {
      "value": "4__4308",
      "label": "Trang trí tiệc và sự kiện",
      "parent": "4__604"
    },
    {
      "value": "4__3229",
      "label": "Loa",
      "parent": "4__3228"
    },
    {
      "value": "4__3648",
      "label": "Thiết bị âm thanh khác",
      "parent": "4__3228"
    },
    {
      "value": "4__3248",
      "label": "Linh kiện và phụ kiện âm thanh",
      "parent": "4__3228"
    },
    {
      "value": "4__3235",
      "label": "Tai nghe",
      "parent": "4__3228"
    },
    {
      "value": "4__3239",
      "label": "Hệ thống giải trí, karaoke",
      "parent": "4__3228"
    },
    {
      "value": "4__1508",
      "label": "Hóa trang, cosplay",
      "parent": "4__1458"
    },
    {
      "value": "4__2760",
      "label": "Đồ chơi đồ hàng",
      "parent": "4__1458"
    },
    {
      "value": "4__2756",
      "label": "Đồ chơi cho bé dưới 18 tháng",
      "parent": "4__1458"
    },
    {
      "value": "4__1459",
      "label": "Đồ chơi vận động cho bé",
      "parent": "4__1458"
    },
    {
      "value": "4__1492",
      "label": "Đồ chơi điều khiển từ xa",
      "parent": "4__1458"
    },
    {
      "value": "4__1502",
      "label": "Trò chơi",
      "parent": "4__1458"
    },
    {
      "value": "4__1485",
      "label": "Mô hình đồ chơi",
      "parent": "4__1458"
    },
    {
      "value": "4__1468",
      "label": "Đồ chơi phát triển trí tuệ",
      "parent": "4__1458"
    },
    {
      "value": "4__1478",
      "label": "Búp bê, thú bông",
      "parent": "4__1458"
    },
    {
      "value": "4__3639",
      "label": "Đồ chơi khác",
      "parent": "4__1458"
    },
    {
      "value": "4__2905",
      "label": "Máy in & Phụ kiện máy in",
      "parent": "4__2901"
    },
    {
      "value": "4__2904",
      "label": "Máy tính để bàn",
      "parent": "4__2901"
    },
    {
      "value": "4__2926",
      "label": "Phần mềm máy tính",
      "parent": "4__2901"
    },
    {
      "value": "4__4123",
      "label": "Thiết bị văn phòng khác",
      "parent": "4__2901"
    },
    {
      "value": "4__2902",
      "label": "Laptop mới",
      "parent": "4__2901"
    },
    {
      "value": "4__2903",
      "label": "Laptop cũ",
      "parent": "4__2901"
    },
    {
      "value": "4__3277",
      "label": "Linh kiện máy tính, laptop",
      "parent": "4__2901"
    },
    {
      "value": "4__3288",
      "label": "Phụ kiện máy tính, laptop",
      "parent": "4__2901"
    },
    {
      "value": "4__3294",
      "label": "Máy chiếu và phụ kiện",
      "parent": "4__2901"
    },
    {
      "value": "4__3270",
      "label": "Chuột, bàn phím",
      "parent": "4__2901"
    }
  ],
  [ALL_PLATFORM_BASE_OBJECT.tiki.uniqueName]: [
    {
      "value": "3__27550",
      "label": "Phụ kiện thời trang nam",
      "parent": "3__27498"
    },
    {
      "value": "3__8370",
      "label": "Mắt kính",
      "parent": "3__27498"
    },
    {
      "value": "3__975",
      "label": "Phụ kiện thời trang nữ",
      "parent": "3__27498"
    },
    {
      "value": "3__49650",
      "label": "Túi bao tử, túi đeo bụng",
      "parent": "3__27616"
    },
    {
      "value": "3__959",
      "label": "Ví nam",
      "parent": "3__27616"
    },
    {
      "value": "3__5337",
      "label": "Túi xách công sở nam",
      "parent": "3__27616"
    },
    {
      "value": "3__958",
      "label": "Túi đeo chéo nam",
      "parent": "3__27616"
    },
    {
      "value": "3__24294",
      "label": "Dụng cụ câu cá",
      "parent": "3__1975"
    },
    {
      "value": "3__6826",
      "label": "Giày thể thao nam",
      "parent": "3__1975"
    },
    {
      "value": "3__8411",
      "label": "Các môn thể thao chơi vợt",
      "parent": "3__1975"
    },
    {
      "value": "3__24306",
      "label": "Phụ kiện thể thao",
      "parent": "3__1975"
    },
    {
      "value": "3__6140",
      "label": "Trang phục thể thao nam",
      "parent": "3__1975"
    },
    {
      "value": "3__24002",
      "label": "Các môn thể thao khác",
      "parent": "3__1975"
    },
    {
      "value": "3__6141",
      "label": "Trang phục thể thao nữ",
      "parent": "3__1975"
    },
    {
      "value": "3__8409",
      "label": "Dụng cụ tập thể hình",
      "parent": "3__1975"
    },
    {
      "value": "3__24128",
      "label": "Dụng cụ - thiết bị tập thể thao",
      "parent": "3__1975"
    },
    {
      "value": "3__4227",
      "label": "Các môn thể thao đồng đội",
      "parent": "3__1975"
    },
    {
      "value": "3__24258",
      "label": "Dụng cụ leo núi",
      "parent": "3__1975"
    },
    {
      "value": "3__6827",
      "label": "Giày thể thao nữ",
      "parent": "3__1975"
    },
    {
      "value": "3__10803",
      "label": "Thực phẩm bổ sung năng lượng",
      "parent": "3__1975"
    },
    {
      "value": "3__8413",
      "label": "Thể thao dưới nước",
      "parent": "3__1975"
    },
    {
      "value": "3__23120",
      "label": "Các môn thể thao đối kháng ",
      "parent": "3__1975"
    },
    {
      "value": "3__8428",
      "label": "Đồ dùng dã ngoại",
      "parent": "3__1975"
    },
    {
      "value": "3__3364",
      "label": "Collections",
      "parent": "3__1975"
    },
    {
      "value": "3__27468",
      "label": "Artist Collection",
      "parent": "3__17166"
    },
    {
      "value": "3__25036",
      "label": "Sản phẩm - thiết bị công nghiệp",
      "parent": "3__17166"
    },
    {
      "value": "3__21356",
      "label": "Thể thao - Dã ngoại",
      "parent": "3__17166"
    },
    {
      "value": "3__20766",
      "label": "Bách hóa online",
      "parent": "3__17166"
    },
    {
      "value": "3__21496",
      "label": "Tivi & Thiết Bị Nghe Nhìn",
      "parent": "3__17166"
    },
    {
      "value": "3__21382",
      "label": "Thiết Bị Số - Phụ Kiện Số",
      "parent": "3__17166"
    },
    {
      "value": "3__21298",
      "label": "Sách, Văn phòng phẩm & Quà lưu niệm",
      "parent": "3__17166"
    },
    {
      "value": "3__21346",
      "label": "Ô tô, xe máy, xe đạp",
      "parent": "3__17166"
    },
    {
      "value": "3__20908",
      "label": "Làm Đẹp - Sức Khỏe",
      "parent": "3__17166"
    },
    {
      "value": "3__21442",
      "label": "Thời Trang",
      "parent": "3__17166"
    },
    {
      "value": "3__20824",
      "label": "Điện gia dụng",
      "parent": "3__17166"
    },
    {
      "value": "3__21268",
      "label": "Nhà Cửa - Đời Sống",
      "parent": "3__17166"
    },
    {
      "value": "3__21134",
      "label": "Máy Ảnh - Máy Quay Phim",
      "parent": "3__17166"
    },
    {
      "value": "3__21074",
      "label": "Laptop & Máy Vi Tính",
      "parent": "3__17166"
    },
    {
      "value": "3__21166",
      "label": "Mẹ & Bé",
      "parent": "3__17166"
    },
    {
      "value": "3__21054",
      "label": "Điện Thoại - Máy Tính Bảng",
      "parent": "3__17166"
    },
    {
      "value": "3__8387",
      "label": "Túi du lịch và phụ kiện",
      "parent": "3__6000"
    },
    {
      "value": "3__6526",
      "label": "Vali, phụ kiện vali ",
      "parent": "3__6000"
    },
    {
      "value": "3__27608",
      "label": "Balo",
      "parent": "3__6000"
    },
    {
      "value": "3__27612",
      "label": "Balo, cặp, túi chống sốc laptop",
      "parent": "3__6000"
    },
    {
      "value": "3__1795",
      "label": "Điện thoại Smartphone",
      "parent": "3__1789"
    },
    {
      "value": "3__28856",
      "label": "Máy đọc sách",
      "parent": "3__1789"
    },
    {
      "value": "3__1796",
      "label": "Điện thoại phổ thông",
      "parent": "3__1789"
    },
    {
      "value": "3__8061",
      "label": "Điện thoại bàn",
      "parent": "3__1789"
    },
    {
      "value": "3__1794",
      "label": "Máy tính bảng",
      "parent": "3__1789"
    },
    {
      "value": "3__18328",
      "label": "Quà lưu niệm",
      "parent": "3__8322"
    },
    {
      "value": "3__5290",
      "label": "Ebook",
      "parent": "3__8322"
    },
    {
      "value": "3__316",
      "label": "Sách tiếng Việt",
      "parent": "3__8322"
    },
    {
      "value": "3__320",
      "label": "English Books",
      "parent": "3__8322"
    },
    {
      "value": "3__7741",
      "label": "Văn phòng phẩm",
      "parent": "3__8322"
    },
    {
      "value": "3__1840",
      "label": "Balo - Túi Đựng - Bao Da",
      "parent": "3__1801"
    },
    {
      "value": "3__4077",
      "label": "Camera Giám Sát",
      "parent": "3__1801"
    },
    {
      "value": "3__28822",
      "label": "Thiết Bị Quay Phim",
      "parent": "3__1801"
    },
    {
      "value": "3__8047",
      "label": "Máy Bay Camera và Phụ Kiện",
      "parent": "3__1801"
    },
    {
      "value": "3__28834",
      "label": "Camera Hành Trình - Action Camera và Phụ Kiện",
      "parent": "3__1801"
    },
    {
      "value": "3__28806",
      "label": "Máy Ảnh",
      "parent": "3__1801"
    },
    {
      "value": "3__28814",
      "label": "Thiết Bị Ánh Sáng",
      "parent": "3__1801"
    },
    {
      "value": "3__28794",
      "label": "Ống Kính - Ống Ngắm",
      "parent": "3__1801"
    },
    {
      "value": "3__2757",
      "label": "Ống Kính (Lens)",
      "parent": "3__1801"
    },
    {
      "value": "3__1818",
      "label": "Phụ Kiện Máy Ảnh, Máy Quay",
      "parent": "3__1801"
    },
    {
      "value": "3__6629",
      "label": "Sim số - Thẻ cào - Thẻ game",
      "parent": "3__11312"
    },
    {
      "value": "3__12064",
      "label": "Vé nội địa 1 chiều",
      "parent": "3__11312"
    },
    {
      "value": "3__12066",
      "label": "Vé nội địa 2 chiều",
      "parent": "3__11312"
    },
    {
      "value": "3__12068",
      "label": "Vé quốc tế 1 chiều",
      "parent": "3__11312"
    },
    {
      "value": "3__14600",
      "label": "Bảo hiểm",
      "parent": "3__11312"
    },
    {
      "value": "3__13744",
      "label": "Phiếu quà tặng",
      "parent": "3__11312"
    },
    {
      "value": "3__38730",
      "label": "Quảng cáo Tiki Ads",
      "parent": "3__11312"
    },
    {
      "value": "3__11326",
      "label": "Nha khoa - Sức khỏe",
      "parent": "3__11312"
    },
    {
      "value": "3__33466",
      "label": "TikiNOW",
      "parent": "3__11312"
    },
    {
      "value": "3__11319",
      "label": "Du lịch - Khách sạn",
      "parent": "3__11312"
    },
    {
      "value": "3__11333",
      "label": "Sự kiện - Thể thao",
      "parent": "3__11312"
    },
    {
      "value": "3__11332",
      "label": "Khóa học",
      "parent": "3__11312"
    },
    {
      "value": "3__11313",
      "label": "Nhà hàng - Ăn uống",
      "parent": "3__11312"
    },
    {
      "value": "3__11327",
      "label": "Sự kiện - Giải trí",
      "parent": "3__11312"
    },
    {
      "value": "3__11322",
      "label": "Spa & Làm đẹp",
      "parent": "3__11312"
    },
    {
      "value": "3__11334",
      "label": "Dịch vụ khác",
      "parent": "3__11312"
    },
    {
      "value": "3__26568",
      "label": "Âm thanh & Phụ kiện Tivi",
      "parent": "3__4221"
    },
    {
      "value": "3__3862",
      "label": "Máy giặt",
      "parent": "3__4221"
    },
    {
      "value": "3__2328",
      "label": "Tủ lạnh",
      "parent": "3__4221"
    },
    {
      "value": "3__3865",
      "label": "Máy lạnh - Máy điều hòa",
      "parent": "3__4221"
    },
    {
      "value": "3__8074",
      "label": "Phụ kiện điện lạnh",
      "parent": "3__4221"
    },
    {
      "value": "3__5015",
      "label": "Tivi",
      "parent": "3__4221"
    },
    {
      "value": "3__3868",
      "label": "Tủ đông - Tủ mát",
      "parent": "3__4221"
    },
    {
      "value": "3__3863",
      "label": "Máy sấy quần áo",
      "parent": "3__4221"
    },
    {
      "value": "3__3866",
      "label": "Máy nước nóng",
      "parent": "3__4221"
    },
    {
      "value": "3__3869",
      "label": "Tủ ướp rượu",
      "parent": "3__4221"
    },
    {
      "value": "3__3864",
      "label": "Máy rửa chén",
      "parent": "3__4221"
    },
    {
      "value": "3__10387",
      "label": "Đồ lam nữ",
      "parent": "3__931"
    },
    {
      "value": "3__1508",
      "label": "Đồ ngủ - Đồ mặc nhà nữ",
      "parent": "3__931"
    },
    {
      "value": "3__941",
      "label": "Đầm nữ",
      "parent": "3__931"
    },
    {
      "value": "3__1698",
      "label": "Áo nữ",
      "parent": "3__931"
    },
    {
      "value": "3__936",
      "label": "Áo khoác nữ",
      "parent": "3__931"
    },
    {
      "value": "3__27600",
      "label": "Quần nữ",
      "parent": "3__931"
    },
    {
      "value": "3__4553",
      "label": "Đồ đôi - Đồ gia đình",
      "parent": "3__931"
    },
    {
      "value": "3__1702",
      "label": "Áo liền quần - Bộ trang phục",
      "parent": "3__931"
    },
    {
      "value": "3__5404",
      "label": "Chân váy",
      "parent": "3__931"
    },
    {
      "value": "3__4554",
      "label": "Đồ lót nữ",
      "parent": "3__931"
    },
    {
      "value": "3__6179",
      "label": "Trang phục bơi nữ",
      "parent": "3__931"
    },
    {
      "value": "3__49384",
      "label": "Thời trang trung niên",
      "parent": "3__931"
    },
    {
      "value": "3__49372",
      "label": "Thời trang bầu và sau sinh",
      "parent": "3__931"
    },
    {
      "value": "3__11251",
      "label": "Thực phẩm bảo vệ sức khỏe",
      "parent": "3__4384"
    },
    {
      "value": "3__15074",
      "label": "Thực phẩm",
      "parent": "3__4384"
    },
    {
      "value": "3__5451",
      "label": "Chăm sóc thú cưng",
      "parent": "3__4384"
    },
    {
      "value": "3__15078",
      "label": "Chăm sóc nhà cửa",
      "parent": "3__4384"
    },
    {
      "value": "3__22998",
      "label": "Đồ uống - Pha chế",
      "parent": "3__4384"
    },
    {
      "value": "3__4421",
      "label": "Bánh kẹo",
      "parent": "3__4384"
    },
    {
      "value": "3__15080",
      "label": "Chăm sóc cá nhân",
      "parent": "3__4384"
    },
    {
      "value": "3__16974",
      "label": "Thực phẩm tươi",
      "parent": "3__4384"
    },
    {
      "value": "3__11346",
      "label": "Thực phẩm cao cấp",
      "parent": "3__4384"
    },
    {
      "value": "3__4422",
      "label": "Gia vị",
      "parent": "3__4384"
    },
    {
      "value": "3__24024",
      "label": "Đồ uống - Giải khát",
      "parent": "3__4384"
    },
    {
      "value": "3__11347",
      "label": "Bộ quà tặng",
      "parent": "3__4384"
    },
    {
      "value": "3__11660",
      "label": "Thực phẩm hữu cơ",
      "parent": "3__4384"
    },
    {
      "value": "3__5479",
      "label": "Thực phẩm chay",
      "parent": "3__4384"
    },
    {
      "value": "3__8240",
      "label": "Thực phẩm bổ dưỡng",
      "parent": "3__4384"
    },
    {
      "value": "3__49516",
      "label": "Đồ đôi - Đồ gia đình nam",
      "parent": "3__915"
    },
    {
      "value": "3__27562",
      "label": "Quần nam",
      "parent": "3__915"
    },
    {
      "value": "3__16004",
      "label": "Đồ bơi - Đồ đi biển nam",
      "parent": "3__915"
    },
    {
      "value": "3__10382",
      "label": "Áo hoodie nam",
      "parent": "3__915"
    },
    {
      "value": "3__918",
      "label": "Áo sơ mi nam",
      "parent": "3__915"
    },
    {
      "value": "3__925",
      "label": "Áo vest - Áo khoác nam",
      "parent": "3__915"
    },
    {
      "value": "3__27548",
      "label": "Đồ lót nam",
      "parent": "3__915"
    },
    {
      "value": "3__4546",
      "label": "Áo nỉ - Áo len nam",
      "parent": "3__915"
    },
    {
      "value": "3__49524",
      "label": "Quần áo nam kích cỡ lớn",
      "parent": "3__915"
    },
    {
      "value": "3__49532",
      "label": "Quần áo nam trung niên",
      "parent": "3__915"
    },
    {
      "value": "3__27570",
      "label": "Đồ ngủ, đồ mặc nhà nam",
      "parent": "3__915"
    },
    {
      "value": "3__917",
      "label": "Áo thun nam",
      "parent": "3__915"
    },
    {
      "value": "3__2640",
      "label": "Chăm sóc mẹ mang thai, sau sinh",
      "parent": "3__2549"
    },
    {
      "value": "3__11603",
      "label": "Thời Trang Cho Mẹ Và Bé",
      "parent": "3__2549"
    },
    {
      "value": "3__11601",
      "label": "Đồ dùng cho bé",
      "parent": "3__2549"
    },
    {
      "value": "3__2551",
      "label": "Tã, Bỉm",
      "parent": "3__2549"
    },
    {
      "value": "3__5250",
      "label": "Đồ chơi",
      "parent": "3__2549"
    },
    {
      "value": "3__8339",
      "label": "Dinh dưỡng cho bé",
      "parent": "3__2549"
    },
    {
      "value": "3__10570",
      "label": "Chuẩn bị mang thai",
      "parent": "3__2549"
    },
    {
      "value": "3__10416",
      "label": "Dinh dưỡng cho mẹ",
      "parent": "3__2549"
    },
    {
      "value": "3__6568",
      "label": "Thực phẩm ăn dặm",
      "parent": "3__2549"
    },
    {
      "value": "3__10418",
      "label": "Dinh dưỡng cho người lớn",
      "parent": "3__2549"
    },
    {
      "value": "3__5164",
      "label": "Đồ dùng cho người già",
      "parent": "3__2549"
    },
    {
      "value": "3__8374",
      "label": "Trang sức",
      "parent": "3__8371"
    },
    {
      "value": "3__11375",
      "label": "Đồng hồ trẻ em",
      "parent": "3__8371"
    },
    {
      "value": "3__27542",
      "label": "Phụ kiện đồng hồ",
      "parent": "3__8371"
    },
    {
      "value": "3__977",
      "label": "Đồng hồ nữ",
      "parent": "3__8371"
    },
    {
      "value": "3__1778",
      "label": "Đồng hồ nam",
      "parent": "3__8371"
    },
    {
      "value": "3__11376",
      "label": "Đồng hồ thông minh",
      "parent": "3__8371"
    },
    {
      "value": "3__8355",
      "label": "Giày cao gót",
      "parent": "3__1703"
    },
    {
      "value": "3__27604",
      "label": "Giày thể thao nữ",
      "parent": "3__1703"
    },
    {
      "value": "3__4550",
      "label": "Giày sandals nữ",
      "parent": "3__1703"
    },
    {
      "value": "3__1192",
      "label": "Giày búp bê",
      "parent": "3__1703"
    },
    {
      "value": "3__981",
      "label": "Giày boots nữ",
      "parent": "3__1703"
    },
    {
      "value": "3__8357",
      "label": "Phụ kiện giày",
      "parent": "3__1703"
    },
    {
      "value": "3__984",
      "label": "Dép - Guốc nữ",
      "parent": "3__1703"
    },
    {
      "value": "3__1008",
      "label": "Giày Đế xuồng nữ ",
      "parent": "3__1703"
    },
    {
      "value": "3__4551",
      "label": "Giày lười nữ",
      "parent": "3__1703"
    },
    {
      "value": "3__5340",
      "label": "Giày lười nam",
      "parent": "3__1686"
    },
    {
      "value": "3__1581",
      "label": "Giày tây nam",
      "parent": "3__1686"
    },
    {
      "value": "3__8337",
      "label": "Giày boots nam",
      "parent": "3__1686"
    },
    {
      "value": "3__5342",
      "label": "Dép nam",
      "parent": "3__1686"
    },
    {
      "value": "3__27572",
      "label": "Giày thể thao nam",
      "parent": "3__1686"
    },
    {
      "value": "3__8338",
      "label": "Phụ kiện giày nam",
      "parent": "3__1686"
    },
    {
      "value": "3__4673",
      "label": "Giày sneakers nam",
      "parent": "3__1686"
    },
    {
      "value": "3__5341",
      "label": "Giày sandals nam",
      "parent": "3__1686"
    },
    {
      "value": "3__4561",
      "label": "Ví nữ",
      "parent": "3__976"
    },
    {
      "value": "3__4559",
      "label": "Túi tote nữ",
      "parent": "3__976"
    },
    {
      "value": "3__4560",
      "label": "Túi xách tay nữ",
      "parent": "3__976"
    },
    {
      "value": "3__4558",
      "label": "Túi đeo chéo, túi đeo vai nữ",
      "parent": "3__976"
    },
    {
      "value": "3__8350",
      "label": "Ví đi tiệc",
      "parent": "3__976"
    },
    {
      "value": "3__8352",
      "label": "Phụ kiện túi",
      "parent": "3__976"
    },
    {
      "value": "3__24832",
      "label": "Phụ kiện - Chăm sóc xe",
      "parent": "3__8594"
    },
    {
      "value": "3__17208",
      "label": "Dịch vụ, lắp đặt",
      "parent": "3__8594"
    },
    {
      "value": "3__8435",
      "label": "Xe Scooter",
      "parent": "3__8594"
    },
    {
      "value": "3__8597",
      "label": "Xe máy",
      "parent": "3__8594"
    },
    {
      "value": "3__8431",
      "label": "Xe đạp",
      "parent": "3__8594"
    },
    {
      "value": "3__6070",
      "label": "Xe điện",
      "parent": "3__8594"
    },
    {
      "value": "3__2663",
      "label": "Thiết Bị Mạng",
      "parent": "3__1846"
    },
    {
      "value": "3__12884",
      "label": "Thiết Bị Văn Phòng - Thiết Bị Ngoại Vi",
      "parent": "3__1846"
    },
    {
      "value": "3__8060",
      "label": "Thiết Bị Lưu Trữ",
      "parent": "3__1846"
    },
    {
      "value": "3__8093",
      "label": "PC - Máy Tính Bộ",
      "parent": "3__1846"
    },
    {
      "value": "3__8095",
      "label": "Laptop",
      "parent": "3__1846"
    },
    {
      "value": "3__8129",
      "label": "Linh Kiện Máy Tính - Phụ Kiện Máy Tính",
      "parent": "3__1846"
    },
    {
      "value": "3__1884",
      "label": "Đồ dùng nhà bếp",
      "parent": "3__1882"
    },
    {
      "value": "3__1946",
      "label": "Thiết bị gia đình",
      "parent": "3__1882"
    },
    {
      "value": "3__2322",
      "label": "Thực phẩm chức năng",
      "parent": "3__1520"
    },
    {
      "value": "3__1592",
      "label": "Chăm sóc cơ thể",
      "parent": "3__1520"
    },
    {
      "value": "3__1594",
      "label": "Chăm sóc cá nhân",
      "parent": "3__1520"
    },
    {
      "value": "3__2307",
      "label": "Máy Massage & Thiết bị chăm sóc sức khỏe",
      "parent": "3__1520"
    },
    {
      "value": "3__2306",
      "label": "Dụng cụ làm đẹp",
      "parent": "3__1520"
    },
    {
      "value": "3__1584",
      "label": "Trang điểm ",
      "parent": "3__1520"
    },
    {
      "value": "3__1591",
      "label": "Chăm sóc tóc và da đầu",
      "parent": "3__1520"
    },
    {
      "value": "3__5874",
      "label": "Dược mỹ phẩm",
      "parent": "3__1520"
    },
    {
      "value": "3__8142",
      "label": "Hỗ trợ tình dục",
      "parent": "3__1520"
    },
    {
      "value": "3__11348",
      "label": "Tinh dầu spa",
      "parent": "3__1520"
    },
    {
      "value": "3__1595",
      "label": "Nước hoa",
      "parent": "3__1520"
    },
    {
      "value": "3__11506",
      "label": "Tinh dầu spa",
      "parent": "3__1520"
    },
    {
      "value": "3__5873",
      "label": "Sản phẩm thiên nhiên & Khác",
      "parent": "3__1520"
    },
    {
      "value": "3__8161",
      "label": "Bộ sản phẩm làm đẹp",
      "parent": "3__1520"
    },
    {
      "value": "3__1582",
      "label": "Chăm sóc da mặt",
      "parent": "3__1520"
    },
    {
      "value": "3__11854",
      "label": "Ổn áp, biến áp",
      "parent": "3__1883"
    },
    {
      "value": "3__18852",
      "label": "Hoa tươi và cây cảnh",
      "parent": "3__1883"
    },
    {
      "value": "3__10068",
      "label": "Nhạc cụ",
      "parent": "3__1883"
    },
    {
      "value": "3__23044",
      "label": "Phụ kiện giặt ủi",
      "parent": "3__1883"
    },
    {
      "value": "3__2150",
      "label": "Nội thất",
      "parent": "3__1883"
    },
    {
      "value": "3__1954",
      "label": "Đồ dùng phòng ăn",
      "parent": "3__1883"
    },
    {
      "value": "3__1966",
      "label": "Đồ dùng và thiết bị nhà tắm",
      "parent": "3__1883"
    },
    {
      "value": "3__2223",
      "label": "Ngoài trời & sân vườn",
      "parent": "3__1883"
    },
    {
      "value": "3__8313",
      "label": "Đồ dùng phòng ngủ",
      "parent": "3__1883"
    },
    {
      "value": "3__1974",
      "label": "Sửa chữa nhà cửa",
      "parent": "3__1883"
    },
    {
      "value": "3__23046",
      "label": "Bảo vệ nhà cửa",
      "parent": "3__1883"
    },
    {
      "value": "3__2015",
      "label": "Đèn & thiết bị chiếu sáng",
      "parent": "3__1883"
    },
    {
      "value": "3__5848",
      "label": "Vật phẩm phong thủy",
      "parent": "3__1883"
    },
    {
      "value": "3__23054",
      "label": "Đồ thờ cúng",
      "parent": "3__1883"
    },
    {
      "value": "3__23018",
      "label": "Dụng cụ vệ sinh",
      "parent": "3__1883"
    },
    {
      "value": "3__2021",
      "label": "Ổ cắm điện",
      "parent": "3__1883"
    },
    {
      "value": "3__23012",
      "label": "Pin và dụng cụ sạc pin",
      "parent": "3__1883"
    },
    {
      "value": "3__23004",
      "label": "Dụng cụ diệt chuột, côn trùng",
      "parent": "3__1883"
    },
    {
      "value": "3__1951",
      "label": "Dụng cụ nhà bếp",
      "parent": "3__1883"
    },
    {
      "value": "3__1973",
      "label": "Trang trí nhà cửa",
      "parent": "3__1883"
    },
    {
      "value": "3__20492",
      "label": "Thiết bị điều khiển thông minh",
      "parent": "3__1883"
    },
    {
      "value": "3__28432",
      "label": "Thiết Bị Thông Minh và Linh Kiện Điện Tử",
      "parent": "3__1815"
    },
    {
      "value": "3__8214",
      "label": "Phụ Kiện Điện Thoại và Máy Tính Bảng",
      "parent": "3__1815"
    },
    {
      "value": "3__8215",
      "label": "Thiết Bị Âm Thanh và Phụ Kiện",
      "parent": "3__1815"
    },
    {
      "value": "3__28670",
      "label": "Phụ kiện máy tính và Laptop",
      "parent": "3__1815"
    },
    {
      "value": "3__2667",
      "label": "Thiết Bị Chơi Game và Phụ Kiện",
      "parent": "3__1815"
    },
    {
      "value": "3__8039",
      "label": "Thiết Bị Đeo Thông Minh và Phụ Kiện",
      "parent": "3__1815"
    }
  ]
}

export {
  ALL_CATEGORIES_BY_PLATFORM,
  ALL_CATEGORIES_LV2_BY_PLATFORM,
}
