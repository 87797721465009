import { SocialAppIds, SocialResponseState } from '@/config/ConfigAuth'
import { URL_BACKEND_API, URL_BEECOST_BACKEND_API, URL_WEB } from '@/config/ConfigEndpoint'

const REDIRECT_URI = `${URL_WEB}/oauthlogin`
// const REDIRECT_URI = `https://apiv2.beecost.com/oauthlogin`

const SocialLoginURIs = {
  FACEBOOK: `https://www.facebook.com/dialog/oauth?client_id=${SocialAppIds.FACEBOOK}&redirect_uri=${REDIRECT_URI}&response_type=code&state=${SocialResponseState.FACEBOOK}&scope=email`,
  GOOGLE: `https://accounts.google.com/o/oauth2/v2/auth?client_id=${SocialAppIds.GOOGLE}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=email+profile&state=${SocialResponseState.GOOGLE}`,
  ZALO: `https://oauth.zaloapp.com/v3/auth?app_id=${SocialAppIds.ZALO}&redirect_uri=${REDIRECT_URI}&response_type=code&state=${SocialResponseState.ZALO}`
}

const urlResponseLogin = (socialType) => {
  //important change /auth/market/${socialType}
  return `${URL_BEECOST_BACKEND_API}/auth/ecomviet_dot_metric/${socialType}`
  // return `${URL_BEECOST_BACKEND_API}/auth/marketbee/${socialType}`
  // return `${URL_BEECOST_BACKEND_API}/auth/market/test/${socialType}`
}

const getLoginUrl = (socialResponseStateType) => {
  for (let k in SocialResponseState) {
    let currentSocialStateType = SocialResponseState[k]
    if (socialResponseStateType?.includes(currentSocialStateType)) {
      return urlResponseLogin(currentSocialStateType)
    }
  }
  return null
}

const urlGetProfileMarket = () => {
  return `${URL_BACKEND_API}/market/me`
}

const urlCustomQuery = (hash) => {
  if (hash) {
    return `${URL_BACKEND_API}/market/custom_query?hash=${hash}`
  }
  return `${URL_BACKEND_API}/market/custom_query`
}

export {
  REDIRECT_URI,
  SocialLoginURIs,
  urlResponseLogin,
  getLoginUrl,
  urlGetProfileMarket,
  urlCustomQuery
}
