import {
  apiApplyDiscountVoucher,
  apiCreateVoucher,
  apiGetListTransaction, apiGetPricingPlans,
  apiSetPaidTransaction,
  apiVerifyPayment
} from "@/api/payment/ApiPayment";
import {
  ALL_PRICING_PLAN_V2,
  LIST_VOUCHER, PRICING_PLAN_DURATION, PRICING_PLAN_DURATION_V2,
  PRICING_PLANS,
  TRANSACTION_STATE, UPDATE_PRICING_STATE
} from "@/constant/pricing/PricingPlansConstant";
import {formatSortTextCurrency} from "@/helper/FormatHelper";
import {toNumber} from "@/helper/StringHelper";

const createVoucher = async (voucherType) => {
  if (!voucherType) {
    return null
  }
  let voucher = await apiCreateVoucher(voucherType)
  return voucher
}

const verifyPayment = async (voucherCode) => {
  if (!voucherCode) {
    return null
  }
  let data = await apiVerifyPayment(voucherCode)
  return data?.status
}

const verifyPaymentInterval = (voucherCode, successFunction, failureFunction, secondsPerRequest = 10, secondsTimeout = 10) => {
  if (typeof secondsPerRequest !== "number" || secondsPerRequest * 1000 < 10) {
    secondsPerRequest = 50 / 1000
  }
  if (!voucherCode) {
    return false
  }
  const verifyLoop = setInterval(async () => {
    let transactionState = await verifyPayment(voucherCode)
    switch (transactionState) {
      case TRANSACTION_STATE.COMPLETE: {
        await successFunction(transactionState)
        clearInterval(verifyLoop)
        break
      }
      case TRANSACTION_STATE.ERROR: {
        await failureFunction(transactionState)
        clearInterval(verifyLoop)
        break
      }
      case TRANSACTION_STATE.WAITING: {
        break
      }
    }
  }, 1000 * secondsPerRequest)
  setTimeout(async () => {
    let transactionState = await verifyPayment(voucherCode)
    switch (transactionState) {
      case TRANSACTION_STATE.COMPLETE: {
        await successFunction(transactionState)
        break
      }
      case TRANSACTION_STATE.ERROR: {
        await failureFunction(transactionState)
        break
      }
      case TRANSACTION_STATE.WAITING: {
        break
      }
    }
    clearInterval(verifyLoop)
  }, 1000 * secondsTimeout)
}

const getListTransaction = async () => {
  let data = await apiGetListTransaction()
  let result = data?.map(payment => {
    let voucher = findPlanInfo(payment.voucher_type)
    return {
      ...payment,
      voucher_id: voucher?.type,
      content: voucher?.content,
      name: voucher?.name || PRICING_PLANS.market_advance.name
    }
  })
  return result
}

const getTransactionData = async (voucherCode) => {
  if (!voucherCode) {
    return null
  }
  let data = await apiVerifyPayment(voucherCode)
  return data
}

const setPaidTransaction = async (voucherCode) => {
  if (!voucherCode) {
    return null
  }
  let data = await apiSetPaidTransaction(voucherCode)
  return data
}

const getTransactionState = async (voucherCode) => {
  if (!voucherCode) {
    return null
  }
  let data = await apiVerifyPayment(voucherCode)
  return data.status
}

const applyDiscountVoucher = async (discountVoucherCode, voucherType) => {
  if (!discountVoucherCode || !voucherType) {
    return null
  }
  let voucher = await apiApplyDiscountVoucher(discountVoucherCode, voucherType)
  return voucher
}

const extractPlanType = (planType) => {
  if (!planType) {
    return null
  }
  let planId = planType.split('__')[0]
  let durationTime = planType.split('__')[1]
  for (const pricingPlanDurationKey in PRICING_PLAN_DURATION) {
    let duration = PRICING_PLAN_DURATION[pricingPlanDurationKey]
    if (duration.apiKey === durationTime) {
      durationTime = duration.id
      break
    }
  }
  return {
    planId: planId,
    durationTime: durationTime
  }
}

const extractPlanTypeV2 = (planType) => {
  if (!planType) {
    return null
  }
  let planId = planType.split('__')[0]
  let durationTime = planType.split('__')[1]
  for (const pricingPlanDurationKey in PRICING_PLAN_DURATION_V2) {
    let duration = PRICING_PLAN_DURATION_V2[pricingPlanDurationKey]
    if (duration.apiKey === durationTime) {
      durationTime = duration.id
      break
    }
  }
  return {
    planId: planId,
    durationTime: durationTime
  }
}

const findPlanInfo = (planType) => {
  let objectExtracted = extractPlanType(planType)
  if (!objectExtracted) {
    return null
  }
  let planId = objectExtracted.planId
  if (!PRICING_PLANS[planId]) {
    objectExtracted = extractPlanTypeV2(planType)
  }
  let durationTime = objectExtracted.durationTime
  let all_pricing_plan = {
    ...PRICING_PLANS,
    ...ALL_PRICING_PLAN_V2
  }
  let all_pricing_plan_duration = {
    ...PRICING_PLAN_DURATION,
    ...PRICING_PLAN_DURATION_V2
  }
  if (all_pricing_plan[planId]) {
    return {
      id: all_pricing_plan[planId].id,
      name: all_pricing_plan[planId].name,
      type: planType,
      duration: durationTime,
      content: all_pricing_plan_duration[durationTime]?.label,
      sub_content: '',
      price: all_pricing_plan[planId].price?.[durationTime],
      promotedText: all_pricing_plan[planId].promotedText?.[durationTime],
      text_price: formatSortTextCurrency(all_pricing_plan[planId].price?.[durationTime]),
      btn_title: 'Nâng cấp',
      title: `${all_pricing_plan[planId].name} ${all_pricing_plan_duration[durationTime]?.label}`,
    }
  }
  let result = LIST_VOUCHER.find(item => planType === item.id)
  if (result) {
    result.name = 'Gói VIP'
  }
  return result
}

const updatePricingPlans = async () => {
  let pricingPlansPrice = await apiGetPricingPlans()
  setPricingPlan(pricingPlansPrice)
  setPricingPlanV2(pricingPlansPrice)
}

const setPricingPlan = (pricingPlansPrice) => {
  if (!pricingPlansPrice) {
    return
  }
  for (const pricingPlansPriceElement of pricingPlansPrice) {
    const extractObject = extractPlanType(pricingPlansPriceElement.id)
    if (!PRICING_PLANS[extractObject.planId]) {
      continue
    }
    if (PRICING_PLANS[extractObject.planId].price[extractObject.durationTime] !== UPDATE_PRICING_STATE) {
      continue
    }
    PRICING_PLANS[extractObject.planId].price[extractObject.durationTime] = pricingPlansPriceElement.base_price
    PRICING_PLANS[extractObject.planId].promotedText[extractObject.durationTime] = pricingPlansPriceElement.promoted_price
  }
}
const setPricingPlanV2 = (pricingPlansPrice) => {
  if (!pricingPlansPrice) {
    return
  }
  for (const pricingPlansPriceElement of pricingPlansPrice) {
    const extractObjectV2 = extractPlanTypeV2(pricingPlansPriceElement.id)
    if (!ALL_PRICING_PLAN_V2[extractObjectV2.planId]) {
      continue
    }
    if (ALL_PRICING_PLAN_V2[extractObjectV2.planId].price[extractObjectV2.durationTime] !== UPDATE_PRICING_STATE) {
      continue
    }
    ALL_PRICING_PLAN_V2[extractObjectV2.planId].price[extractObjectV2.durationTime] = pricingPlansPriceElement.base_price
    ALL_PRICING_PLAN_V2[extractObjectV2.planId].promotedText[extractObjectV2.durationTime] = pricingPlansPriceElement.promoted_price
  }
}

const getPricingPlanDuration = (planId) => {
  let result = {}
  let all_pricing_plan = {
    ...PRICING_PLANS,
    ...ALL_PRICING_PLAN_V2
  }
  let all_pricing_plan_duration = {
    ...PRICING_PLAN_DURATION,
    ...PRICING_PLAN_DURATION_V2
  }
  if (!all_pricing_plan[planId]) {
    return null
  }
  for (const priceDurationKey in all_pricing_plan[planId].price) {
    if (toNumber(all_pricing_plan[planId].price[priceDurationKey]) > 0) {
      result = {
        ...result,
        [priceDurationKey]: all_pricing_plan_duration[priceDurationKey]
      }
    }
  }
  return result
}

export {
  createVoucher,
  verifyPayment,
  verifyPaymentInterval,
  getListTransaction,
  getTransactionData,
  setPaidTransaction,
  getTransactionState,
  applyDiscountVoucher,
  findPlanInfo,
  updatePricingPlans,
  getPricingPlanDuration,
  extractPlanType,
  extractPlanTypeV2
}
