const MutationGlobal = {
  PATH_MODULE: 'GlobalModule',
  deviceType: 'deviceType',
  isMobile: 'isMobile',
  isDesktop: 'isDesktop',
  RESET: 'RESET'
}

export {
  MutationGlobal
}
