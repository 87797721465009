import Vue from 'vue'
import VueMultianalytics from 'vue-multianalytics'
import router from '@/router'

let gaConfig = {
  appName: 'beecost.vn/market',
  appVersion: '0.1',
  // trackingId: 'UA-158678954-11',
  trackingId: 'UA-bee-staging',
  debug: false,
}

let fbPixelConfig = {
  token: '1368330710199012',
  debug: false
}

Vue.use(VueMultianalytics, {
  modules: {
    ga: gaConfig,
    // facebook: fbPixelConfig,
    // mixpanel: mixpanelConfig,
    // segment: segmentConfig
  },
  routing: {
    vueRouter: router, //  Pass the router instance to automatically sync with router (optional)
    preferredProperty: 'path', // By default 'path' and related with vueRouter (optional)
  }
})
