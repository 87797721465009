import {
  URL_BACKEND_API,
  URL_BEECOST_BACKEND_API,
  URL_MARKET_BACKEND_API
} from '@/config/ConfigEndpoint'

const urlApiCheckDownloadCSV = (hash) => {
  return `${URL_BEECOST_BACKEND_API}/market/custom_query?hash=${hash}&is_exported=1`
}

const urlApiDownloadCSV = () => {
  return `${URL_BACKEND_API}/market/custom_query`
}

const urlApiDownloadListShop = () => {
  return `${URL_BACKEND_API}/market/shop/export`
}

const urlApiApplyVoucher = () => {
  return `${URL_MARKET_BACKEND_API}/market/voucher/active`
}

const urlApiCheckMe = () => {
  return `${URL_MARKET_BACKEND_API}/market/user/paid/me`
}

const urlApiRegisterMe = () => {
  return `${URL_MARKET_BACKEND_API}/market/user/paid/register`
}

const urlApiGetReferrerVoucher = () => {
  return `${URL_MARKET_BACKEND_API}/market/user/paid/get_referrer_voucher`
}

const urlApiGetPromotedVoucher = () => {
  return `${URL_BEECOST_BACKEND_API}/market/user/get_free_voucher_1d`
}

const urlApiListShopSubscribe = () => {
  return `${URL_BEECOST_BACKEND_API}/market/user/subscribe_shop/list`
}

const urlApiCreateShopSubscribe = () => {
  return `${URL_BEECOST_BACKEND_API}/market/user/subscribe_shop/create`
}

const urlApiDeleteShopSubscribe = () => {
  return `${URL_BEECOST_BACKEND_API}/market/user/subscribe_shop/delete`
}

const urlApiInsightGeneralSearch = () => {
  return `${URL_BEECOST_BACKEND_API}/market/insight_search`
}

const urlApiGetMarketShopMonitor = ({ shopUrl, isIncludeProduct = true }) => {
  const timestamp = new Date().getTime()
  // return `${URL_BEECOST_BACKEND_API}/monitor/market_shop_monitor?shop_url=${shopUrl}&is_include_product=${isIncludeProduct}`;
  return `https://apiv2.beecost.vn/monitor/market_shop_monitor?shop_url=${shopUrl}&is_include_product=${isIncludeProduct}&v=${timestamp}`;
};

const urlApiGroupSearch = () => {
  return `${URL_BEECOST_BACKEND_API}/market/groupsearch/search`
}

const urlApiDownloadGroupSearch = () => {
  return `${URL_BEECOST_BACKEND_API}/market/groupsearch/export`
}

export {
  urlApiCheckDownloadCSV,
  urlApiDownloadCSV,
  urlApiDownloadListShop,
  urlApiApplyVoucher,
  urlApiCheckMe,
  urlApiGetReferrerVoucher,
  urlApiRegisterMe,
  urlApiGetPromotedVoucher,
  urlApiListShopSubscribe,
  urlApiCreateShopSubscribe,
  urlApiDeleteShopSubscribe,
  urlApiInsightGeneralSearch,
  urlApiGetMarketShopMonitor,
  urlApiGroupSearch,
  urlApiDownloadGroupSearch
}
