const getSessionStorage = (name, defaultValue = null) => {
  if (process.client) {
    let value = sessionStorage.getItem(name)
    if (value == null) {
      value = defaultValue
    }
    return value
  }
}

const setSessionStorage = (name, value) => {
  if (process.client) {
    sessionStorage.setItem(name, value)
  }
}

export {
  getSessionStorage,
  setSessionStorage
}
