const MutationSearchFilter = {
  MODULE_PATH: 'SearchFilterModule',
  RESET: 'RESET',
  RESET_SEARCH: 'RESET_SEARCH',
  SET_RESPONSE_SEARCH_PRODUCT: 'SET_RESPONSE_SEARCH_PRODUCT',
  SET_RESPONSE_SEARCH_KEYWORD_PRODUCT: 'SET_RESPONSE_SEARCH_KEYWORD_PRODUCT',
  SET_RESPONSE_SEARCH_PRODUCT_COMPARISON: 'SET_RESPONSE_SEARCH_PRODUCT_COMPARISON',
  SEARCH_PRODUCT: 'SEARCH_PRODUCT',
  SEARCH_SHOPS: 'SEARCH_SHOPS',
  SEARCH_BRANDS: 'SEARCH_BRANDS',
  SEARCH_INSIGHT_SHOPS: 'SEARCH_INSIGHT_SHOPS',
  SEARCH_KEYWORDS: 'SEARCH_KEYWORDS',
  SEARCH_DETAIL_STATISTIC: 'SEARCH_DETAIL_STATISTIC',
  BUILD_OPTIONS: 'BUILD_OPTIONS',
  APPLY_OPTIONS: 'APPLY_OPTIONS',
  BUILD_PRODUCT_OPTIONS: 'BUILD_PRODUCT_OPTIONS',
  BUILD_REVENUE_STATISTIC: 'BUILD_REVENUE_STATISTIC',
  BUILD_ORDER_COUNT_STATISTIC: 'BUILD_ORDER_COUNT_STATISTIC',
  BUILD_REVIEW_COUNT_STATISTIC: 'BUILD_REVIEW_COUNT_STATISTIC',
  BUILD_GENERAL_STATISTIC: 'BUILD_GENERAL_STATISTIC',
  options: 'options',
  isSavedReport: 'isSavedReport',
  searchConfig: 'searchConfig',
  columnsTable: 'columnsTable',
  isLoading: 'isLoading',
  isClickedSearched: 'isClickedSearched',
  isDemo: 'isDemo',
  isOldFilter: 'isOldFilter',
  iSortBy: 'iSortBy',
  iCategoriesSelected: 'iCategoriesSelected',
  iCategoriesLevel: 'iCategoriesLevel',
  iIsRemoveBadProduct: 'iIsRemoveBadProduct',
  iTrendingTime: 'iTrendingTime',
  iLocationWarehouses: 'iLocationWarehouses',
  iKeywords: 'iKeywords',
  iBrands: 'iBrands',
  iIsShopMall: 'iIsShopMall',
  iIsShopNormal: 'iIsShopNormal',
  iPlatforms: 'iPlatforms',
  iDurationDay: 'iDurationDay',
  iDurationRange: 'iDurationRange',
  iDurationRangeComparison: 'iDurationRangeComparison',
  iQueries: 'iQueries',
  iQueriesExclude: 'iQueriesExclude',
  iOriginalQueries: 'iOriginalQueries',
  isSmartQueries: 'isSmartQueries',
  iLimit: 'iLimit',
  iPage: 'iPage',
  iCompare: 'iCompare',
  iValueRange: 'iValueRange',
  timestamp: 'timestamp',
}

export {
  MutationSearchFilter
}
