import {MutationGlobal} from "@/store/modules/GlobalModule/mutation";

const deviceTypeValue = {
  mobile: 'mobile',
  desktop: 'desktop'
}

const initialState = () => {
  return {
    [MutationGlobal.deviceType]: deviceTypeValue.desktop
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters: {
    [MutationGlobal.isMobile]: state => state[MutationGlobal.deviceType] === deviceTypeValue.mobile,
    [MutationGlobal.isDesktop]: state => state[MutationGlobal.deviceType] === deviceTypeValue.desktop,
  },
  mutations: {
    [MutationGlobal.deviceType] (state, value) {
      state[MutationGlobal.deviceType] = value
    },
    [MutationGlobal.RESET](state) {
      const s = initialState()
      Object.keys(s)
        .forEach(key => {
          state[key] = s[key]
        })
    }
  },
  actions: {
    [MutationGlobal.isMobile] (context) {
      context.commit(MutationGlobal.deviceType, deviceTypeValue.mobile)
    },
    [MutationGlobal.isDesktop] (context) {
      context.commit(MutationGlobal.deviceType, deviceTypeValue.desktop)
    },
    [MutationGlobal.RESET](context) {
      context.commit(MutationGlobal.RESET)
    }
  }
}
