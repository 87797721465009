import Vue from 'vue'


// import { default as ALayout } from 'ant-design-vue/lib/layout'
// import '@/assets/lib/ant-design-vue/lib/layout/style'
// import { default as ARow } from 'ant-design-vue/lib/row'
// import '@/assets/lib/ant-design-vue/lib/row/style'
//
// import { default as ACol } from 'ant-design-vue/lib/col'
// import '@/assets/lib/ant-design-vue/lib/col/style'
//
// import { default as AMenu } from 'ant-design-vue/lib/menu'
// import '@/assets/lib/ant-design-vue/lib/menu/style'
//
// import { default as AIcon } from 'ant-design-vue/lib/icon'
// import '@/assets/lib/ant-design-vue/lib/icon/style'
//
// import { default as AButton } from 'ant-design-vue/lib/button'
// import '@/assets/lib/ant-design-vue/lib/button/style'
//
// import { default as AForm } from 'ant-design-vue/lib/form'
// import '@/assets/lib/ant-design-vue/lib/form/style'
//
// import { default as ASelect } from 'ant-design-vue/lib/select'
// import '@/assets/lib/ant-design-vue/lib/select/style'
//
// import { default as AInput } from 'ant-design-vue/lib/input'
// import '@/assets/lib/ant-design-vue/lib/input/style'
//
// import { default as AInputNumber } from 'ant-design-vue/lib/input-number'
// import '@/assets/lib/ant-design-vue/lib/input-number/style'
//
// import { default as ACheckbox } from 'ant-design-vue/lib/checkbox'
// import '@/assets/lib/ant-design-vue/lib/checkbox/style'
//
// import { default as ARadio } from 'ant-design-vue/lib/radio'
// import '@/assets/lib/ant-design-vue/lib/radio/style'
//
// import { default as ATable } from 'ant-design-vue/lib/table'
// import '@/assets/lib/ant-design-vue/lib/table/style'
//
// import { default as APopover } from 'ant-design-vue/lib/popover'
// import '@/assets/lib/ant-design-vue/lib/popover/style'
//
// import { default as ATooltip } from 'ant-design-vue/lib/tooltip'
// import '@/assets/lib/ant-design-vue/lib/tooltip/style'
//
// import { default as ARate } from 'ant-design-vue/lib/rate'
// import '@/assets/lib/ant-design-vue/lib/rate/style'
//
// import { default as ASpin } from 'ant-design-vue/lib/spin'
// import '@/assets/lib/ant-design-vue/lib/spin/style'
//
// import { default as ADivider } from 'ant-design-vue/lib/divider'
// import '@/assets/lib/ant-design-vue/lib/divider/style'


/*
// import Antd from 'ant-design-vue'
// import '@/assets/lib/ant-design-vue/dist/antd.less'
// Vue.use(Antd)
*/


import { Layout as ALayout } from 'ant-design-vue'
import { Row as ARow } from 'ant-design-vue'
import { Col as ACol } from 'ant-design-vue'
import { Button as AButton } from 'ant-design-vue'
import { Menu as AMenu } from 'ant-design-vue'
import { Pagination as APagination } from 'ant-design-vue'
import { Icon as AIcon } from 'ant-design-vue'
// import { Form as AForm } from 'ant-design-vue'
import { Select as ASelect } from 'ant-design-vue-1.3.5'
import { Switch as ASwitch } from 'ant-design-vue'
import { Tag as ATag } from 'ant-design-vue'
import { Input as AInput } from 'ant-design-vue'
import { InputNumber as AInputNumber } from 'ant-design-vue'
import { Checkbox as ACheckbox } from 'ant-design-vue'
import { DatePicker as ADatePicker } from 'ant-design-vue'
import { Radio as ARadio } from 'ant-design-vue'
import { Table as ATable } from 'ant-design-vue'
import { Popover as APopover } from 'ant-design-vue'
import { Tooltip as ATooltip } from 'ant-design-vue'
import { Rate as ARate } from 'ant-design-vue'
import { Divider as ADivider } from 'ant-design-vue'
import { Spin as ASpin } from 'ant-design-vue'
import { Progress as Progress } from 'ant-design-vue'
// import { Steps as Steps } from 'ant-design-vue'

import { Tabs as ATabs } from 'ant-design-vue'
import { Drawer as ADrawer } from 'ant-design-vue'
import { Modal as AModal } from 'ant-design-vue'
import { Dropdown as ADropdown } from 'ant-design-vue'
import { Badge as ABadge } from 'ant-design-vue'
import { message } from 'ant-design-vue'
import { notification } from 'ant-design-vue'

Vue.use(ALayout)
Vue.use(ARow)
Vue.use(ACol)
Vue.use(AButton)
Vue.use(AMenu)
Vue.use(APagination)
Vue.use(AIcon)
// Vue.use(AForm)
Vue.use(ASelect)
Vue.use(ASwitch)
Vue.use(ATag)
Vue.use(AInput)
Vue.use(AInputNumber)
Vue.use(ACheckbox)
Vue.use(ADatePicker)
Vue.use(ARadio)
Vue.use(ATable)
Vue.use(APopover)
Vue.use(ATooltip)
Vue.use(ARate)
Vue.use(ADivider)
Vue.use(ASpin)
Vue.use(Progress)
// Vue.use(Steps)
Vue.use(ATabs)
Vue.use(ADrawer)
Vue.use(AModal)
Vue.use(ADropdown)
Vue.use(ABadge)

Vue.prototype.$message = message
Vue.prototype.$notification = notification
Vue.prototype.$confirm = AModal.confirm
