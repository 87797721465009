import { MutationSearchStatisticResult } from '@/store/modules/SearchStatisticResultModule/mutation'
import {ALL_CHART_TAB, ALL_GENERAL_STATISTIC, ALL_METRIC} from "@/constant/general/GeneralConstant";

const initialState = () => {
  let aggState = {}
  for (const chartTabKey in ALL_CHART_TAB) {
    let chartTabStoreName = ALL_CHART_TAB[chartTabKey].storeName
    for (const metricKey in ALL_METRIC) {
      let metricStoreName = ALL_METRIC[metricKey].storeName
      aggState[MutationSearchStatisticResult[chartTabStoreName][metricStoreName]] = null
    }
  }
  for (const generalStatisticKey in ALL_GENERAL_STATISTIC) {
    let generalStatisticStoreName =  ALL_GENERAL_STATISTIC[generalStatisticKey].storeName
    aggState[generalStatisticStoreName] = null
    if (ALL_GENERAL_STATISTIC[generalStatisticKey].storeNameDetailStatistic) {
      aggState[ALL_GENERAL_STATISTIC[generalStatisticKey].storeNameDetailStatistic] = null
    }
  }
  return {
    status: false,
    // orderTotal: null,
    // revenueTotal: null,
    // productTotal: null,
    // shopTotal: null,
    // commissionTotal: null,
    // reviewTotal: null,
    shopMall: null,
    otherShop: null,
    revenueByShopMall: null,
    revenueByOtherShop: null,
    orderByShopMall: null,
    orderByOtherShop: null,
    aggRevenueByCategories: null,
    aggRevenueByPriceRange: null,
    aggRevenueByBrands: null,
    aggRevenueByLocations: null,
    aggOrderCountByCategories: null,
    aggOrderCountByPriceRange: null,
    aggOrderCountByBrands: null,
    aggOrderCountByLocations: null,
    aggCommissionByCategories: null,
    aggCommissionByPriceRange: null,
    aggCommissionByBrands: null,
    aggCommissionByLocations: null,
    ...aggState
  }
}

let gettersAgg = {}
let mutationsAgg = {}
let actionsAgg = {}
for (const chartTabKey in ALL_CHART_TAB) {
  let chartTabStoreName = ALL_CHART_TAB[chartTabKey].storeName
  for (const metricKey in ALL_METRIC) {
    let metricStoreName = ALL_METRIC[metricKey].storeName
    gettersAgg[MutationSearchStatisticResult[chartTabStoreName][metricStoreName]] = state => state[MutationSearchStatisticResult[chartTabStoreName][metricStoreName]]
    mutationsAgg[MutationSearchStatisticResult[chartTabStoreName][metricStoreName]] = (state, value) => {state[MutationSearchStatisticResult[chartTabStoreName][metricStoreName]] = value}
    actionsAgg[MutationSearchStatisticResult[chartTabStoreName][metricStoreName]] = (context, data) => {context.commit(MutationSearchStatisticResult[chartTabStoreName][metricStoreName], data)}
  }
}

for (const generalStatisticKey in ALL_GENERAL_STATISTIC) {
  let generalStatisticStoreName =  ALL_GENERAL_STATISTIC[generalStatisticKey].storeName
  gettersAgg[generalStatisticStoreName] = state => state[MutationSearchStatisticResult[generalStatisticStoreName]]
  mutationsAgg[MutationSearchStatisticResult[generalStatisticStoreName]] = (state, value) => {state[MutationSearchStatisticResult[generalStatisticStoreName]] = value}
  actionsAgg[MutationSearchStatisticResult[generalStatisticStoreName]] = (context, data) => {context.commit(MutationSearchStatisticResult[generalStatisticStoreName], data)}
  if (ALL_GENERAL_STATISTIC[generalStatisticKey].storeNameDetailStatistic) {
    gettersAgg[MutationSearchStatisticResult[ALL_GENERAL_STATISTIC[generalStatisticKey].storeNameDetailStatistic]] = state => state[MutationSearchStatisticResult[MutationSearchStatisticResult[ALL_GENERAL_STATISTIC[generalStatisticKey].storeNameDetailStatistic]]]
    mutationsAgg[MutationSearchStatisticResult[ALL_GENERAL_STATISTIC[generalStatisticKey].storeNameDetailStatistic]] = (state, value) => {state[MutationSearchStatisticResult[ALL_GENERAL_STATISTIC[generalStatisticKey].storeNameDetailStatistic]] = value}
    actionsAgg[MutationSearchStatisticResult[ALL_GENERAL_STATISTIC[generalStatisticKey].storeNameDetailStatistic]] = (context, data) => {context.commit(MutationSearchStatisticResult[ALL_GENERAL_STATISTIC[generalStatisticKey].storeNameDetailStatistic], data)}
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters: {
    status: state => state.status,
    // orderTotal: state => state.orderTotal,
    // revenueTotal: state => state.revenueTotal,
    // productTotal: state => state.productTotal,
    // shopTotal: state => state.shopTotal,
    // commissionTotal: state => state.commissionTotal,
    [MutationSearchStatisticResult.shopMall]: state => state.shopMall,
    [MutationSearchStatisticResult.otherShop]: state => state.otherShop,
    [MutationSearchStatisticResult.revenueByShopMall]: state => state.revenueByShopMall,
    [MutationSearchStatisticResult.revenueByOtherShop]: state => state.revenueByOtherShop,
    [MutationSearchStatisticResult.orderByShopMall]: state => state.orderByShopMall,
    [MutationSearchStatisticResult.orderByOtherShop]: state => state.orderByOtherShop,
    aggRevenueByCategories: state => state.aggRevenueByCategories,
    aggRevenueByPriceRange: state => state.aggRevenueByPriceRange,
    aggRevenueByBrands: state => state.aggRevenueByBrands,
    aggRevenueByLocations: state => state.aggRevenueByLocations,
    aggOrderCountByCategories: state => state.aggOrderCountByCategories,
    aggOrderCountByPriceRange: state => state.aggOrderCountByPriceRange,
    aggOrderCountByBrands: state => state.aggOrderCountByBrands,
    aggOrderCountByLocations: state => state.aggOrderCountByLocations,
    aggCommissionByCategories: state => state.aggCommissionByCategories,
    aggCommissionByPriceRange: state => state.aggCommissionByPriceRange,
    aggCommissionByBrands: state => state.aggCommissionByBrands,
    aggCommissionByLocations: state => state.aggCommissionByLocations,
    ...gettersAgg
  },
  mutations: {
    [MutationSearchStatisticResult.status](state, value) {
      state.status = value
    },
    [MutationSearchStatisticResult.setOrderTotal](state, value) {
      state.orderTotal = value
    },
    [MutationSearchStatisticResult.setRevenueTotal](state, value) {
      state.revenueTotal = value
    },
    [MutationSearchStatisticResult.setProductTotal](state, value) {
      state.productTotal = value
    },
    [MutationSearchStatisticResult.setShopTotal](state, value) {
      state.shopTotal = value
    },
    [MutationSearchStatisticResult.shopMall](state, value) {
      state.shopMall = value
    },
    [MutationSearchStatisticResult.otherShop](state, value) {
      state.otherShop = value
    },
    [MutationSearchStatisticResult.revenueByShopMall](state, value) {
      state.revenueByShopMall = value
    },
    [MutationSearchStatisticResult.revenueByOtherShop](state, value) {
      state.revenueByOtherShop = value
    },
    [MutationSearchStatisticResult.orderByShopMall](state, value) {
      state.orderByShopMall = value
    },
    [MutationSearchStatisticResult.orderByOtherShop](state, value) {
      state.orderByOtherShop = value
    },
    [MutationSearchStatisticResult.setCommissionTotal](state, value) {
      state.commissionTotal = value
    },

    [MutationSearchStatisticResult.setAggRevenueByCategories](state, value) {
      state.aggRevenueByCategories = value
    },
    [MutationSearchStatisticResult.setAggRevenueByPriceRange](state, value) {
      state.aggRevenueByPriceRange = value
    },
    [MutationSearchStatisticResult.setAggRevenueByBrands](state, value) {
      state.aggRevenueByBrands = value
    },
    [MutationSearchStatisticResult.setAggRevenueByLocations](state, value) {
      state.aggRevenueByLocations = value
    },

    [MutationSearchStatisticResult.setAggOrderCountByCategories](state, value) {
      state.aggOrderCountByCategories = value
    },
    [MutationSearchStatisticResult.setAggOrderCountByPriceRange](state, value) {
      state.aggOrderCountByPriceRange = value
    },
    [MutationSearchStatisticResult.setAggOrderCountByBrands](state, value) {
      state.aggOrderCountByBrands = value
    },
    [MutationSearchStatisticResult.setAggOrderCountByLocations](state, value) {
      state.aggOrderCountByLocations = value
    },

    [MutationSearchStatisticResult.setAggCommissionByCategories](state, value) {
      state.aggCommissionByCategories = value
    },
    [MutationSearchStatisticResult.setAggCommissionByPriceRange](state, value) {
      state.aggCommissionByPriceRange = value
    },
    [MutationSearchStatisticResult.setAggCommissionByBrands](state, value) {
      state.aggCommissionByBrands = value
    },
    [MutationSearchStatisticResult.setAggCommissionByLocations](state, value) {
      state.aggCommissionByLocations = value
    },
    [MutationSearchStatisticResult.aggOrderCount](state, value) {
      state.aggOrderCount = value
    },
    [MutationSearchStatisticResult.aggRevenue](state, value) {
      state.aggRevenue = value
    },
    [MutationSearchStatisticResult.aggReviewCount](state, value) {
      state.aggReviewCount = value
    },
    [MutationSearchStatisticResult.aggCommission](state, value) {
      state.aggCommission = value
    },
    ...mutationsAgg,
    [MutationSearchStatisticResult.RESET](state) {
      const s = initialState()
      Object.keys(s)
        .forEach(key => {
          state[key] = s[key]
        })
    }
  },
  actions: {
    [MutationSearchStatisticResult.status](context, data) {
      context.commit(MutationSearchStatisticResult.status, data)
    },
    [MutationSearchStatisticResult.shopMall](context, data) {
      context.commit(MutationSearchStatisticResult.shopMall, data)
    },
    [MutationSearchStatisticResult.otherShop](context, data) {
      context.commit(MutationSearchStatisticResult.otherShop, data)
    },
    [MutationSearchStatisticResult.revenueByShopMall](context, data) {
      context.commit(MutationSearchStatisticResult.revenueByShopMall, data)
    },
    [MutationSearchStatisticResult.revenueByOtherShop](context, data) {
      context.commit(MutationSearchStatisticResult.revenueByOtherShop, data)
    },
    [MutationSearchStatisticResult.orderByShopMall](context, data) {
      context.commit(MutationSearchStatisticResult.orderByShopMall, data)
    },
    [MutationSearchStatisticResult.orderByOtherShop](context, data) {
      context.commit(MutationSearchStatisticResult.orderByOtherShop, data)
    },
    [MutationSearchStatisticResult.aggOrderCount](context, data) {
      context.commit(MutationSearchStatisticResult.aggOrderCount, data)
    },
    [MutationSearchStatisticResult.aggRevenue](context, data) {
      context.commit(MutationSearchStatisticResult.aggRevenue, data)
    },
    [MutationSearchStatisticResult.aggReviewCount](context, data) {
      context.commit(MutationSearchStatisticResult.aggReviewCount, data)
    },
    [MutationSearchStatisticResult.aggCommission](context, data) {
      context.commit(MutationSearchStatisticResult.aggCommission, data)
    },
    ...actionsAgg,
    [MutationSearchStatisticResult.RESET](context) {
      context.commit(MutationSearchStatisticResult.RESET)
    }
  }
}
