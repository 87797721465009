import {
  apiApplyVoucher,
  apiCheckDownloadCSV,
  apiDownloadCSV, apiDownloadListShop,
  apiGetReferralVoucher
} from "@/api/market/ApiMarket";

const canDownloadCSV = async (hash) => {
  if (!hash) {
    return
  }
  let status = await apiCheckDownloadCSV(hash)
  return !!status?.is_exported
}

const getFileProductData = async (options) => {
  const data = await apiDownloadCSV(options)
  return data
}

const getListShopData = async (options) => {
  const data = await apiDownloadListShop(options)
  return data
}

const downloadFile = (data, filename, fileType = 'xlsx') => {
  if (!data) {
    return false
  }
  let downloadLink;

  // Download link
  downloadLink = document.createElement("a");

  // File name
  downloadLink.download = `${filename}.${fileType}`;

  // Create a link to the file
  downloadLink.href = window.URL.createObjectURL(data);

  // Hide download link
  downloadLink.style.display = "none";

  // Add the link to DOM
  document.body.appendChild(downloadLink);

  // Click download link
  downloadLink.click();

  // Remove the link to DOM
  document.body.removeChild(downloadLink);
  return true
}

const applyVoucher = async (voucherCode) => {
  let result =  await apiApplyVoucher(voucherCode)
  if (result?.status) {
    return result
  }
  return null
}

const getReferralVoucher = async () => {
  let result =  await apiGetReferralVoucher()
  if (result?.status) {
    return result?.data
  }
  return null
}

const generateColumnsTable = (durationDay) => {
  const columns = [
    {
      title: 'Tên sản phẩm',
      dataIndex: 'product_name',
      key: 'product_name',
      scopedSlots: { customRender: 'product_name' },
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
      width: '350px',
    },
    {
      title: 'Giá bán',
      dataIndex: 'price',
      key: 'price',
      scopedSlots: { customRender: 'price' },
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
      // defaultSortOrder: 'ascend'
      width: '150px',
    },
    {
      // title: 'Giá trong phân loại hàng',
      dataIndex: '',
      key: 'min_max_price',
      slots: { title: 'min_max_price_title' },
      scopedSlots: { customRender: 'min_max_price' },
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
      // defaultSortOrder: 'ascend'
      width: '150px',
    },
    {
      title: durationDay ? `Sản phẩm đã bán ${durationDay} ngày` : 'Sản phẩm đã bán',
      // slots: { title: `order_count_${durationDay}d` },
      scopedSlots: { customRender: durationDay ? `order_count_${durationDay}d` : `order_count_custom` },
      dataIndex: durationDay ? `order_count_${durationDay}d` : 'order_count_custom',
      key: durationDay ? `order_count_${durationDay}d` : 'order_count_custom',
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
      // defaultSortOrder: 'descend'
      width: '140px',
    },
    {
      title: durationDay ? `Doanh số ${durationDay} ngày` : 'Doanh số',
      // slots: { title: `order_revenue_${durationDay}d` },
      scopedSlots: { customRender: durationDay ? `order_revenue_${durationDay}d` : `order_revenue_custom` },
      dataIndex: durationDay ? `order_revenue_${durationDay}d` : `order_revenue_custom`,
      key: durationDay ? `order_revenue_${durationDay}d` : `order_revenue_custom`,
      defaultSortOrder: 'descend',
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
      sorter: true,
      width: '170px',
    },
    {
      title: durationDay ? `Đánh giá ${durationDay} ngày` : 'Đánh giá',
      // slots: { title: `review_count_${durationDay}d` },
      scopedSlots: { customRender: durationDay ? `review_count_${durationDay}d` : `review_count_custom` },
      dataIndex: durationDay ? `review_count_${durationDay}d` : `review_count_custom`,
      key: durationDay ? `review_count_${durationDay}d` : `review_count_custom`,
      // defaultSortReview: 'ascend',
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
      sorter: true,
      width: '170px',
    },
    {
      title: 'Tổng doanh số',
      dataIndex: 'revenue',
      key: 'revenue',
      scopedSlots: { customRender: 'revenue' },
      sorter: true,
      width: '170px',
    },
    {
      title: 'Ngành hàng',
      dataIndex: 'category_name',
      key: 'category_name',
      scopedSlots: { customRender: 'category_name' },
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
      sorter: true,
      width: '170px',
    },
    {
      title: 'Thương hiệu',
      dataIndex: 'brand',
      key: 'brand',
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
      sorter: true,
      width: '170px',
    },
    {
      title: 'Thao tác',
      dataIndex: 'action',
      key: 'action',
      fixed: 'right',
      scopedSlots: { customRender: 'action' },
      width: '110px',
    },
  ]
  return columns
}

const generateColumnsProductGlobalTable = (durationDay) => {
  const columns = [
    {
      title: 'Tên sản phẩm',
      dataIndex: 'product_name',
      key: 'product_name',
      scopedSlots: { customRender: 'product_name' },
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
      width: '350px',
    },
    {
      title: 'Giá bán (CNY)',
      dataIndex: 'price_raw',
      key: 'price_raw',
      scopedSlots: { customRender: 'price_raw' },
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
      defaultSortOrder: 'ascend'
    },
    {
      title: 'Giá bán (VND)',
      dataIndex: 'price_vnd',
      key: 'price_vnd',
      scopedSlots: { customRender: 'price_vnd' },
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
      defaultSortOrder: 'ascend'
    },
    {
      title: durationDay ? `Sản phẩm đã bán ${durationDay} ngày` : 'Sản phẩm đã bán',
      // slots: { title: `order_count_${durationDay}d` },
      scopedSlots: { customRender: durationDay ? `order_count_${durationDay}d` : `order_count_custom` },
      dataIndex: durationDay ? `order_count_${durationDay}d` : 'order_count_custom',
      key: durationDay ? `order_count_${durationDay}d` : 'order_count_custom',
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
      // defaultSortOrder: 'descend',
      width: '150px'
    },
    {
      title: durationDay ? `Doanh số ${durationDay} ngày` : 'Doanh số',
      // slots: { title: `order_revenue_${durationDay}d` },
      scopedSlots: { customRender: durationDay ? `order_revenue_${durationDay}d` : `order_revenue_custom` },
      dataIndex: durationDay ? `order_revenue_${durationDay}d` : `order_revenue_custom`,
      key: durationDay ? `order_revenue_${durationDay}d` : `order_revenue_custom`,
      defaultSortOrder: 'descend',
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
      sorter: true,
      width: '200px'
    },
    {
      title: 'Tổng doanh số',
      dataIndex: 'revenue',
      key: 'revenue',
      scopedSlots: { customRender: 'order_revenue' },
      sorter: true,
    },
    {
      title: 'Ngành hàng',
      dataIndex: 'category_name',
      key: 'category_name',
      scopedSlots: { customRender: 'category_name' },
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
      sorter: true
    },
  ]
  return columns
}

const generateColumnsShopTable = (durationDay) => {
  let columns = [
    {
      title: 'Tên shop',
      dataIndex: 'shop_name',
      scopedSlots: { customRender: 'shop_name' },
      sorter: false,
      width: '20%',
    },
    {
      title: 'Nơi bán',
      dataIndex: 'shop_url',
      scopedSlots: { customRender: 'shop_url' },
      sorter: false,
    },
    {
      title: 'Nơi bán',
      dataIndex: 'platform',
      scopedSlots: { customRender: 'platform' },
    },
    {
      title: 'Số sản phẩm',
      dataIndex: 'product_total',
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
  ]
  columns.push(
    {
      title: `Sản phẩm đã bán ${durationDay ? `${durationDay} ngày` : 'trong khoảng đã chọn'}`,
      scopedSlots: { customRender: 'order_count_selected' },
      dataIndex: `${durationDay ? `order_count_${durationDay}d` : 'order_count_custom'}`,
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
    {
      title: `Doanh số ${durationDay ? `${durationDay} ngày` : 'trong khoảng đã chọn'}`,
      scopedSlots: { customRender: 'order_revenue_selected' },
      defaultSortOrder: 'descend',
      dataIndex: `${durationDay ? `order_revenue_${durationDay}d` : 'order_revenue_custom'}`,
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
  )
  columns.push(
    {
      title: `Sản phẩm đã bán trong khoảng đã chọn`,
      scopedSlots: { customRender: 'order_count_custom' },
      dataIndex: 'order_count_custom',
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
    {
      title: `Doanh số trong khoảng đã chọn`,
      scopedSlots: { customRender: 'order_revenue_custom' },
      dataIndex: 'order_revenue_custom',
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
    {
      title: 'Sản phẩm đã bán 7 ngày',
      scopedSlots: { customRender: 'order_count_7d' },
      dataIndex: 'order_count_7d',
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
    {
      title: 'Doanh số 7 ngày',
      dataIndex: 'order_revenue_7d',
      scopedSlots: { customRender: 'order_revenue_7d' },
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
    {
      title: 'Sản phẩm đã bán 30 ngày',
      dataIndex: 'order_count_30d',
      scopedSlots: { customRender: 'order_count_30d' },
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
    {
      title: 'Doanh số 30 ngày',
      dataIndex: 'order_revenue_30d',
      scopedSlots: { customRender: 'order_revenue_30d' },
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
    {
      title: 'Sản phẩm đã bán 90 ngày',
      dataIndex: 'order_count_90d',
      scopedSlots: { customRender: 'order_count_90d' },
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
    {
      title: 'Doanh số 90 ngày',
      dataIndex: 'order_revenue_90d',
      scopedSlots: { customRender: 'order_revenue_90d' },
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
    {
      title: 'Sản phẩm đã bán 180 ngày',
      dataIndex: 'order_count_180d',
      scopedSlots: { customRender: 'order_count_180d' },
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
    {
      title: 'Doanh số 180 ngày',
      dataIndex: 'revenue_count_180d',
      scopedSlots: { customRender: 'revenue_count_180d' },
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
    {
      title: 'Đánh giá 7 ngày',
      dataIndex: 'review_count_7d',
      scopedSlots: { customRender: 'review_count_7d' },
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
    {
      title: 'Đánh giá 30 ngày',
      dataIndex: 'review_count_30d',
      scopedSlots: { customRender: 'review_count_30d' },
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
    {
      title: 'Đánh giá 90 ngày',
      dataIndex: 'review_count_90d',
      scopedSlots: { customRender: 'review_count_90d' },
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
    {
      title: 'Đánh giá 180 ngày',
      dataIndex: 'review_count_180d',
      scopedSlots: { customRender: 'review_count_180d' },
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
    {
      title: 'Tổng đánh giá',
      dataIndex: 'rating_count',
      scopedSlots: { customRender: 'rating_count' },
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
    {
      title: 'Tổng lượt xem',
      dataIndex: 'view_count',
      scopedSlots: { customRender: 'view_count' },
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
    {
      title: 'Tổng doanh số',
      dataIndex: 'order_revenue',
      scopedSlots: { customRender: 'order_revenue' },
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
    {
      title: 'Tổng lượt bán',
      dataIndex: 'order_count',
      scopedSlots: { customRender: 'order_count' },
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
    {
      title: 'Thao tác',
      dataIndex: 'action',
      scopedSlots: { customRender: 'action' },
    },
  )
  return columns
}

const generateColumnsBrandTable = (durationDay) => {
  let columns = [
    {
      title: 'Tên Thương hiệu',
      dataIndex: 'brand',
      scopedSlots: { customRender: 'brand' },
      sorter: false,
    },
    {
      title: 'Số sản phẩm',
      dataIndex: 'product_total',
      scopedSlots: { customRender: 'product_total' },
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
  ]
  columns.push(
    {
      title: `Sản phẩm đã bán ${durationDay ? `${durationDay} ngày` : 'trong khoảng đã chọn'}`,
      scopedSlots: { customRender: 'order_count_selected' },
      dataIndex: `${durationDay ? `order_count_${durationDay}d` : 'order_count_custom'}`,
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
    {
      title: `Doanh số ${durationDay ? `${durationDay} ngày` : 'trong khoảng đã chọn'}`,
      scopedSlots: { customRender: 'order_revenue_selected' },
      defaultSortOrder: 'descend',
      dataIndex: `${durationDay ? `order_revenue_${durationDay}d` : 'order_revenue_custom'}`,
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
  )
  columns.push(
    {
      title: `Sản phẩm đã bán trong khoảng đã chọn`,
      scopedSlots: { customRender: 'order_count_custom' },
      dataIndex: 'order_count_custom',
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
    {
      title: `Doanh số trong khoảng đã chọn`,
      scopedSlots: { customRender: 'order_revenue_custom' },
      dataIndex: 'order_revenue_custom',
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
    {
      title: 'Sản phẩm đã bán 7 ngày',
      scopedSlots: { customRender: 'order_count_7d' },
      dataIndex: 'order_count_7d',
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
    {
      title: 'Doanh số 7 ngày',
      dataIndex: 'order_revenue_7d',
      scopedSlots: { customRender: 'order_revenue_7d' },
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
    {
      title: 'Sản phẩm đã bán 30 ngày',
      dataIndex: 'order_count_30d',
      scopedSlots: { customRender: 'order_count_30d' },
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
    {
      title: 'Doanh số 30 ngày',
      dataIndex: 'order_revenue_30d',
      scopedSlots: { customRender: 'order_revenue_30d' },
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
    {
      title: 'Sản phẩm đã bán 90 ngày',
      dataIndex: 'order_count_90d',
      scopedSlots: { customRender: 'order_count_90d' },
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
    {
      title: 'Doanh số 90 ngày',
      dataIndex: 'order_revenue_90d',
      scopedSlots: { customRender: 'order_revenue_90d' },
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
    {
      title: 'Sản phẩm đã bán 180 ngày',
      dataIndex: 'order_count_180d',
      scopedSlots: { customRender: 'order_count_180d' },
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
    {
      title: 'Doanh số 180 ngày',
      dataIndex: 'revenue_count_180d',
      scopedSlots: { customRender: 'revenue_count_180d' },
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
    {
      title: 'Đánh giá 7 ngày',
      dataIndex: 'review_count_7d',
      scopedSlots: { customRender: 'review_count_7d' },
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
    {
      title: 'Đánh giá 30 ngày',
      dataIndex: 'review_count_30d',
      scopedSlots: { customRender: 'review_count_30d' },
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
    {
      title: 'Đánh giá 90 ngày',
      dataIndex: 'review_count_90d',
      scopedSlots: { customRender: 'review_count_90d' },
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
    {
      title: 'Đánh giá 180 ngày',
      dataIndex: 'review_count_180d',
      scopedSlots: { customRender: 'review_count_180d' },
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
    {
      title: 'Tổng đánh giá',
      dataIndex: 'rating_count',
      scopedSlots: { customRender: 'rating_count' },
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
    {
      title: 'Tổng doanh số',
      dataIndex: 'order_revenue',
      scopedSlots: { customRender: 'order_revenue' },
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
    {
      title: 'Tổng lượt bán',
      dataIndex: 'order_count',
      scopedSlots: { customRender: 'order_count' },
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
    {
      title: 'Thao tác',
      dataIndex: 'action',
      scopedSlots: { customRender: 'action' },
    },
  )
  return columns
}

const generateColumnsKeywordTable = (durationDay) => {
  let columns = [
    {
      title: 'Từ khoá',
      dataIndex: 'keyword',
      scopedSlots: { customRender: 'keyword' },
      sorter: false,
      width: '20%',
    },
  ]
  columns.push(
    {
      title: `Doanh số ${durationDay ? `${durationDay} ngày` : 'trong khoảng đã chọn'}`,
      scopedSlots: { customRender: 'order_revenue_selected' },
      defaultSortOrder: 'descend',
      dataIndex: `${durationDay ? `order_revenue_${durationDay}d` : 'order_revenue_custom'}`,
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
    {
      title: `Sản phẩm đã bán ${durationDay ? `${durationDay} ngày` : 'trong khoảng đã chọn'}`,
      scopedSlots: { customRender: 'order_count_selected' },
      dataIndex: `${durationDay ? `order_count_${durationDay}d` : 'order_count_custom'}`,
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
  )
  columns.push(
    {
      title: 'Doanh số',
      scopedSlots: { customRender: 'order_revenue' },
      dataIndex: 'order_revenue',
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
    {
      title: 'Sản phẩm đã bán',
      scopedSlots: { customRender: 'order_count' },
      dataIndex: 'order_count',
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
    {
      title: 'Số sản phẩm có từ khoá',
      scopedSlots: { customRender: 'product_total' },
      dataIndex: 'product_total',
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
    {
      title: 'Số Shop sử dụng từ khoá',
      scopedSlots: { customRender: 'shop_total' },
      dataIndex: 'shop_total',
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
    {
      title: 'Thao tác',
      dataIndex: 'action',
      scopedSlots: { customRender: 'action' },
    },
  )
  return columns
}

const generateColumnsSavedReport = (durationDay) => {
  let columns = [
    {
      title: 'Tên Báo cáo',
      dataIndex: 'report_name',
      scopedSlots: { customRender: 'report_name' },
      sorter: true,
      width: '20%',
    },
    {
      title: 'Kênh bán',
      dataIndex: 'report_search_platform_id',
      scopedSlots: { customRender: 'report_search_platform_id' },
      sorter: false,
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      scopedSlots: { customRender: 'created_at' },
      sorter: true,
    },
    {
      title: 'Từ khóa',
      dataIndex: 'report_search_keyword',
      scopedSlots: { customRender: 'report_search_keyword' },
      sorter: false,
      width: '20%',
    },
    {
      title: `Doanh số ${durationDay ? `${durationDay} ngày gần đây` : 'trong khoảng đã chọn'}`,
      dataIndex: `${durationDay ? `order_revenue_${durationDay}d` : 'order_revenue_custom'}`,
      scopedSlots: { customRender: 'order_revenue_selected' },
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
    {
      title: `Sản phẩm đã bán ${durationDay ? `${durationDay} ngày gần đây` : 'trong khoảng đã chọn'}`,
      dataIndex: `${durationDay ? `order_count_${durationDay}d` : 'order_count_custom'}`,
      scopedSlots: { customRender: 'order_count_selected' },
      sorter: true,
      sortDirections: ['descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend','descend', 'ascend'],
    },
    {
      title: 'Số Shop bán',
      dataIndex: 'shop_count',
      scopedSlots: { customRender: 'shop_count' },
      sorter: true
    },
    {
      title: 'Số sản phẩm',
      dataIndex: 'product_count',
      scopedSlots: { customRender: 'product_count' },
      sorter: true,
    },
    {
      title: '',
      dataIndex: 'action',
      scopedSlots: { customRender: 'action' },
    },
  ]
  return columns
}

const removeColumnsTable = (columnsTable, removeColumnName) => {
  if (!removeColumnName) {
    return columnsTable
  }
  let result = []
  for (const columnsTableElement of columnsTable) {
    if (columnsTableElement.dataIndex.indexOf(removeColumnName) === 0) {
      continue
    }
    result.push(columnsTableElement)
  }
  return result
}

export {
  canDownloadCSV,
  getFileProductData,
  getListShopData,
  downloadFile,
  applyVoucher,
  getReferralVoucher,
  generateColumnsTable,
  generateColumnsProductGlobalTable,
  generateColumnsShopTable,
  generateColumnsBrandTable,
  generateColumnsKeywordTable,
  generateColumnsSavedReport,
  removeColumnsTable,
}
