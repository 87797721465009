import {http} from "@/plugin/http"
import {urlGetReferralUrlLink, urlSaveReferralTracking} from "@/api/referral/ReferralEndpoint"

const apiSaveReferralTracking = async (marketTid) => {
  let saveReferralTrackingUrl = urlSaveReferralTracking()
  try {
    const formData = new FormData();
    formData.append('tid', marketTid);
    let response = await http.post(saveReferralTrackingUrl, formData)

    if (response?.data) {

      if (response.data?.referral_id) {
        return response.data
      }
    }
  } catch (e) {
    console.error(e)
  }

  return null
}

const apiGetReferralLink = async () => {
  let getReferralLinkUrl = urlGetReferralUrlLink()
  try {
    let response = await http.get(getReferralLinkUrl)
    if (response?.data) {
      if (response.data?.referral_link) {
        return response.data.referral_link
      }

    }
  } catch (e) {
    console.error(e)
  }

  return null
}

const apiGetReferralId = async () => {
  let urlApi = urlGetReferralUrlLink()
  try {
    let response = await http.get(urlApi)
    if (response?.data) {
      if (response.data?.referral_id) {
        return response.data.referral_id
      }

    }
  } catch (e) {
    console.error(e)
  }

  return null
}

export {
  apiSaveReferralTracking,
  apiGetReferralLink,
  apiGetReferralId
}
