import {
  ALL_COMPARISON_OPERATOR, ALL_FIELD_FILTER,
} from "@/constant/search/SearchConstant";
import moment from "moment";
import {apiSearchConfig} from "@/api/product/ApiProductSearch";
import {
  ALL_PLATFORM_BASE_OBJECT, CNY_RATE,
  CNY_RATIO, PLATFORM_APPLIED_CNY_RATIO_ARRAY,
} from "@/constant/general/GeneralConstant";
import {PERMISSION} from "@/constant/general/PermissionConstant";
import {
  extractProductBaseId,
  getUrlProductBeeCostDetail,
  getUrlProductBeeCostDetailHistoryPrice,
  getUrlProductDirect
} from "@/service/product/UrlProductService";
import {
  ALL_CATEGORIES_BY_PLATFORM,
  ALL_CATEGORIES_LV2_BY_PLATFORM
} from "@/constant/product/ProductCategoriesConstant";
import {getPlatformById} from "@/service/platform/PlatformCommonService";

/**
 * generate composite_compare_lst in option search from store
 * @param listCompareRow
 * @return {[]}
 */
const generateListCompareFromStore = (listCompareRow) => {
  let result = []
  listCompareRow.forEach(compareRow => {
    if (
      !!ALL_COMPARISON_OPERATOR.includes(compareRow.comparisonOperator)
        && !!ALL_FIELD_FILTER[compareRow.firstField]
        && !!ALL_FIELD_FILTER[compareRow.secondField]
    ) {
      result.push(
        [
          {
            "first_field": compareRow.firstField,
            "second_field": compareRow.secondField,
            "first_rate": 1.0,
            "second_rate": compareRow.ratio,
            "compare_condition": compareRow.comparisonOperator,
            "is_default": compareRow.isDefault
          }
        ]
      )
    }
  })
  result = result.length ? result : null
  return result
}

/**
 * convert list compare from option search to save in store
 * @param listCompare
 */
const convertListCompareToStore = (listCompare) => {
  if (!listCompare?.length) {
    return []
  }
  let listCompareRow = listCompare
    .filter(compareOption => !compareOption[0].is_default)
    .map(compareOption => {
    return {
      firstField: compareOption[0].first_field,
      secondField: compareOption[0].second_field,
      ratio: compareOption[0].first_rate / compareOption[0].second_rate,
      comparisonOperator: compareOption[0].compare_condition
    }
  })
  return listCompareRow
}

/**
 * generate object {from, to}
 * @param minValue
 * @param maxValue
 * @param minDefault
 * @param maxDefault
 * @return {{from: {any}, to: {any}}}
 */
const generateValueRangeObject = (minValue, maxValue, minDefault = null, maxDefault= null) => {
  let result = {}
  if (minValue != null) {
    result = {
      ...result,
      'from': minValue
    }
  } else if (minDefault) {
    result = {
      ...result,
      'from': minDefault,
      is_default_from: true
    }
  }
  if (maxValue != null) {
    result = {
      ...result,
      'to': maxValue
    }
  } else if (maxDefault) {
    result = {
      ...result,
      'to': maxDefault,
      is_default_to: true
    }
  }
  return result
}

const convertValueRangeStore = (objectValueRange) => {
  if (
    (!objectValueRange.from || objectValueRange.is_default_from)
    && (!objectValueRange.to || objectValueRange.is_default_to)
  ) {
    return null
  }
  let result = {}
  if (objectValueRange.from && !objectValueRange.is_default_from) {
    result.min = objectValueRange.from
  }
  if (objectValueRange.to && !objectValueRange.is_default_to) {
    result.max = objectValueRange.to
  }
  return result
}

/**
 * get duration object in the same time in past
 * if duration range is full n month then return previous month
 * @param  durationRange {[moment.Moment, moment.Moment]}: current duration - iDurationRange
 * @return {{label: string, value: null[]} | null}
 */
const getDurationPrevious = (durationRange= undefined) => {
  if (!durationRange?.length) {
    return null
  }
  let result = {
    label: '',
    value: [null, null]
  }
  let startMomment = moment(durationRange[0])
  let endMommentPlus = moment(durationRange[1]).add(1, 'days')
  let durationDay = moment(startMomment).isSame(moment(endMommentPlus)) ? 1 : moment(endMommentPlus).diff(moment(startMomment), 'day')
  let isFullMonth = startMomment.date() + endMommentPlus.date() === 2
  let diffMonth = endMommentPlus.diff(startMomment, 'month')
  let durationSubtract = durationDay
  let unitSubtract = 'days'
  if (isFullMonth) {
    result.label = `${diffMonth} Tháng trước đó`
    durationSubtract = diffMonth
    unitSubtract = 'month'
  } else {
    switch (durationDay) {
      case 7:
        result.label = 'Tuần trước đó'
        break
      default:
        result.label = `${durationDay} ngày trước đó`
        break
    }
  }
  result.value[0] = startMomment.subtract(durationSubtract, unitSubtract)
  result.value[1] = endMommentPlus.subtract(durationSubtract, unitSubtract).subtract(1, 'day')
  return result
}

/**
 * get duration object in the same time in previous quarter
 * if duration range is full n month then return full month in previous quarter
 * @param  durationRange {[moment.Moment, moment.Moment]}: current duration - iDurationRange
 * @return {{label: string, value: null[]} | null}
 */
const getDurationPreviousQuarter = (durationRange= undefined) => {
  if (!durationRange) {
    return null
  }
  let startMomment = moment(durationRange[0])
  let endMommentPlus = moment(durationRange[1]).add(1, 'days')
  let diffMonth = endMommentPlus.diff(startMomment, 'month')
  if (diffMonth > 3) {
    return null
  }
  let isFullMonth = startMomment.date() + endMommentPlus.date() === 2
  let durationDay = moment(startMomment).isSame(moment(endMommentPlus)) ? 1 : moment(endMommentPlus).diff(moment(startMomment), 'day')
  let result = {
    label: '',
    value: [null, null]
  }
  if (durationDay < 30) {
    result.label = 'Tháng trước'
    result.value[0] = startMomment.subtract(1, "month")
    result.value[1] = isFullMonth
                        ? endMommentPlus.subtract(1, "month").subtract(1, 'day')
                        : endMommentPlus.subtract(1, "day").subtract(1, 'month')
  } else {
    result.label = `Quý trước`
    result.value[0] = startMomment.subtract(3, "months")
    result.value[1] = isFullMonth
      ? endMommentPlus.subtract(3, "month").subtract(1, 'day')
      : endMommentPlus.subtract(1, "day").subtract(3, 'month')
  }
  return result
}

/**
 * get duration range [startDate, endDate]: day between 2 date include startDate and endStart is durationDay
 * @param durationDay {number}
 * @param endDate {string | date | moment.Moment}
 * @return {[moment.Moment, moment.Moment]}
 */
const getDurationRange = (durationDay, endDate= null) => {
  if (!endDate) {
    // const todayString = moment().format("YYYY MM DD")
    // endDate = moment(todayString, "YYYY MM DD").subtract(1, 'days')
    return [null, null]
  } else if (!moment.isMoment(endDate)) {
    const endDateString = moment(endDate).format("YYYY MM DD")
    endDate = moment(endDateString, "YYYY MM DD")
  }
  let startDate = moment(endDate).subtract(durationDay - 1, 'days')
  return [startDate, endDate]
}

/**
 * convert date form option format
 * @param date
 * @return {Date}
 */
const getDateFromOption = (date) => {
  if (!date) {
    return null
  }
  date = date.toString()
  let year = +date.substring(0,4)
  let month = +date.substring(4,6) - 1
  let day = +date.substring(6,8)
  return new Date(year, month, day)
}

/**
 * request update config by platformId. Currently, config contain only date range
 * @param listPlatformId{Array<String|Number>|Number|String}
 * @return {Promise<{current_date: string, start_date: string, status: string, platforms: []}>}
 */
const getSearchConfig = async (listPlatformId) => {
  if (typeof listPlatformId === "string" || typeof listPlatformId === "number") {
    listPlatformId = [listPlatformId]
  }
  if (!Array.isArray(listPlatformId)) {
    return null
  }
  let config = await apiSearchConfig(listPlatformId)
  return config?.data
}

/**
 * extract option from Route of Vue
 * @param route
 * @return {{iQueries: [*], iPlatform: number} | null}
 */
const extractOptionFromRoute = (route) => {
  let result = {}
  let urlShop = route.query['shop_url']
  if (urlShop) {
    result.iQueries = [urlShop]
    let platformName = new URL(urlShop).hostname.replaceAll(/www.|.com|.vn/g, '')
    let platformId = ALL_PLATFORM_BASE_OBJECT[platformName]?.platform_id
    if (platformId) {
      result.iPlatform = platformId
    }
  }
  if (Object.keys(result).length === 0) {
    return null
  }
  return result
}

const getFilterOptionByPermission = (filterOption, permissionName) => {
  let permission = PERMISSION[permissionName] ? PERMISSION[permissionName] : PERMISSION.market_default
  let result = {}
  for (const filterOptionKey in filterOption) {
    if (typeof permission.availableFilterOption[filterOptionKey] === "function") {
      result[filterOptionKey] = permission.availableFilterOption[filterOptionKey](filterOption[filterOptionKey])
      continue
    }
    result[filterOptionKey] = filterOption[filterOptionKey]
  }
  return result
}

/**
 * Check durationRange in in or equal fixedRange
 * @param durationRange
 * @param fixedRange
 * @return {boolean | null}
 */
const isOverRange = (durationRange, fixedRange) => {
  if (!durationRange[0] || !durationRange[1] || !fixedRange[0] || !fixedRange[1]) {
    return null
  }
  let smallerDateIndex = durationRange[0].valueOf() < durationRange[1].valueOf() ? 0 : 1
  let smallerFixedDateIndex = fixedRange[0].valueOf() < fixedRange[1].valueOf() ? 0 : 1
  return durationRange[smallerDateIndex].valueOf() < fixedRange[smallerFixedDateIndex].valueOf()
    || durationRange[1 - smallerDateIndex].valueOf() > fixedRange[1 - smallerFixedDateIndex].valueOf()
}

const hasLinkShop = (queries) => {
  if (!queries) {
    return false
  }
  for (const query of queries) {
    let isLinkShopPlatform = isLinkShop(query)
    if (isLinkShopPlatform) {
      return true
    }
  }
  return false
}

const isLinkShop = (text) => {
  if (!text) {
    return false
  }
  try {
    let link = new URL(text)
    return !!link
  } catch (e) {
    return false
  }
}


const getLeafFieldValueFromObject = (object, childFieldName, childFieldValue, leafFieldName) => {
  if (!leafFieldName || !childFieldName || !childFieldValue) {
    return null
  }
  for (const objectKey in object) {
    if (object[objectKey] && object[objectKey][childFieldName] === childFieldValue) {
      return object[objectKey][leafFieldName]
    }
  }
  return null
}

const transformProductData = (productRaw) => {
  if (productRaw == null || productRaw.length === 0) {
    return productRaw
  }
  return productRaw.filter(row => !!row).map(row => {
    let category_name = ''
    let urlDirect = getUrlProductDirect(row?.['product_base_id'], row?.['product_name'])
    let urlProductDetailBeeCost = getUrlProductBeeCostDetail(row?.['product_base_id'], row?.['product_name'])
    let urlProductDetailBeeCostLSG = getUrlProductBeeCostDetailHistoryPrice(row?.['product_base_id'], row?.['product_name'])
    if (row?.['categories'] != null && row?.['categories'].length > 0) {
      category_name = row?.['categories'][0]?.['name']
      if (!category_name && row?.['categories'][0]?.id) {
        category_name = getCategoryNameById(row?.['categories'][0]?.id)
      }
    }
    if (row?.['bee_categories'] != null && row?.['bee_categories'].length > 0) {
      category_name = row?.['bee_categories'][0]?.['name']
      if (!category_name && row?.['bee_categories'][0]?.id) {
        category_name = getCategoryNameById(row?.['bee_categories'][0]?.id)
      }
    }
    let { platform_id, product_id, product_seller_id } = extractProductBaseId(row['product_base_id'])
    if (PLATFORM_APPLIED_CNY_RATIO_ARRAY.includes(+platform_id)) {
      for (const rowKey in row) {
        if (rowKey !== 'price_updated_at' && rowKey.includes('price') || rowKey.includes('revenue')) {
          row[rowKey] = row[rowKey] * CNY_RATIO * CNY_RATE
        }
      }
    }
    return {
      ...row,
      key: row.product_base_id || productRaw.indexOf(row),
      'category_name': category_name,
      'url_direct': urlDirect,
      'url_product_detail_beecost': urlProductDetailBeeCost,
      'url_product_detail_beecost_lsg': urlProductDetailBeeCostLSG
    }
  })
}
const transformBrandsFromMultiplePlatform = (bunchOfBrandByPlatform) => {
  let result = []
  if (!bunchOfBrandByPlatform?.length) {
    return result
  }
  for (const brandByPlatform of bunchOfBrandByPlatform) {
    if (!brandByPlatform?.platforms) {
      continue
    }
    brandByPlatform.platforms.forEach(brand => {
      brand.group_key = brandByPlatform.group_key
      brand.platform_logo_url = getPlatformById(brand.platform_id).urlLogo
    })
    result.push(...brandByPlatform.platforms)
  }
  return result
}
const transformBrands = (bunchOfBrandByPlatform) => {
  if (!bunchOfBrandByPlatform?.length) {
    return bunchOfBrandByPlatform
  }
  let result = []
  for (const brandByPlatform of bunchOfBrandByPlatform) {
    if (!brandByPlatform?.platforms) {
      brandByPlatform.brand = brandByPlatform.group_key
      result.push(brandByPlatform)
      continue
    }
    brandByPlatform.platforms.forEach(brand => {
      brand.platform_logo_url = getPlatformById(brand.platform_id).urlLogo
      for (const brandKey in brand) {
        if (typeof brand[brandKey] === "number" && brandKey !== 'platform_logo_url') {
          brandByPlatform[brandKey] = brandByPlatform[brandKey] ? brandByPlatform[brandKey] : 0
          brandByPlatform[brandKey] += brand[brandKey]
        } else {
          brandByPlatform[brandKey] = brandByPlatform[brandKey]
            ? brandByPlatform[brandKey]
            : []
          brandByPlatform[brandKey] = Array.isArray(brandByPlatform[brandKey])
            ? brandByPlatform[brandKey]
            : [brandByPlatform[brandKey]]
          brandByPlatform[brandKey].push(brand[brandKey])
        }
      }
      brand.brand = brandByPlatform.group_key
    })
    brandByPlatform.brand = brandByPlatform.group_key
    result.push(brandByPlatform)
  }
  return result
}
const getCategoryNameById = (categoryId) => {
  let categoryName = null
  for (const platformName in ALL_CATEGORIES_BY_PLATFORM) {
    categoryName = ALL_CATEGORIES_BY_PLATFORM[platformName].find(item => item.value === categoryId)?.label
    if (categoryName) {
      return categoryName
    }
  }
  for (const platformName in ALL_CATEGORIES_LV2_BY_PLATFORM) {
    categoryName = ALL_CATEGORIES_LV2_BY_PLATFORM[platformName].find(item => item.value === categoryId)?.label
    if (categoryName) {
      return categoryName
    }
  }
  return null
}

export {
  generateListCompareFromStore,
  convertListCompareToStore,
  generateValueRangeObject,
  convertValueRangeStore,
  getDurationPrevious,
  getDurationPreviousQuarter,
  getDurationRange,
  getDateFromOption,
  getSearchConfig,
  extractOptionFromRoute,
  getFilterOptionByPermission,
  isOverRange,
  hasLinkShop,
  getLeafFieldValueFromObject,
  transformProductData,
  transformBrandsFromMultiplePlatform,
  transformBrands,
  getCategoryNameById,
}
