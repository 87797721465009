// const URL_DATA_API = 'https://api-market.beecost.vn'
// staging
// const URL_BEECOST_BACKEND_API = 'https://apiv2market-fullecom.beecost.vn'
// const URL_MARKET_BACKEND_API = URL_BEECOST_BACKEND_API

// production

const URL_BEECOST_BACKEND_API = 'https://apiv3.metric.vn'
const URL_MARKET_BACKEND_API = 'https://apiv3.metric.vn'
const URL_AUTH_SERVICE_API = 'https://auth.metric.vn'
const URL_CRM_BACKEND_API = 'https://api-crm.beecost.net'

const URL_SHOP_BACKEND_API = URL_BEECOST_BACKEND_API
const URL_BACKEND_API = URL_BEECOST_BACKEND_API
// const URL_WEB = process.env.BASE_URL || 'https://market.beecost.vn'
// const URL_WEB = 'https://beetest.sliverwoft.tk'
// const URL_WEB = 'https://8ad4559c.ngrok.io'
// const URL_WEB = 'https://web-test.beecost.vn'
// const URL_WEB = 'https://banhang.beecost.com'
// const URL_WEB = 'https://marketmove.net'
// const URL_WEB = 'https://beecost.vn/market'
const URL_WEB = 'https://ecomviet.metric.vn'
// const URL_WEB = 'https://marketbee.vn/market'
const URL_LOG_API = 'https://op.vaonet.vn'
const URL_DOWNLOAD_VAONET = 'https://static.vaonet.vn/download'
const URL_CONTACT = 'https://docs.google.com/forms/d/1F8f9eBmizgqryFeEk1LbFCWomm4gnZyfizBNv_k61Ag'

export {
  URL_BACKEND_API,
  URL_BEECOST_BACKEND_API,
  // URL_DATA_API,
  URL_WEB,
  URL_LOG_API,
  URL_DOWNLOAD_VAONET,
  URL_CRM_BACKEND_API,
  URL_MARKET_BACKEND_API,
  URL_CONTACT,
  URL_SHOP_BACKEND_API,
  URL_AUTH_SERVICE_API
}
