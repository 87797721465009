import { getUserProfile } from '@/service/user/UserProfileService'
import store from "../../store"
import {apiCheckMe, apiRegisterMe} from "@/api/user/ApiUserProfile";
import {PERMISSION} from "@/constant/general/PermissionConstant";
import {generateListCompareFromStore, hasLinkShop} from "@/service/search/SearchService";
import {MutationUser} from "@/store/modules/UserModule/mutation";
import {
  FIXED_VALUE_COMPARE_BAD_PRODUCT_CONDITION_LIST, FIXED_VALUE_COMPARE_TRENDING,
  FIXED_VALUE_RANGE_GOOD_SELLER_PRODUCT
} from "@/constant/search/FixedOptionFilterConstant";

/**
 * Only user installed extension has permission to search with advance filter
 * @param options
 * @return {Promise<{isPermission: boolean, type: string, title: string, message: string}|{isPermission: boolean, type: string, message: string, title: string}>}
 */
const canFilterProduct = async (options) => {
  let result = {
    'isPermission': true,
    'type': 'default',
    'message': '',
    'title': 'Thông báo',
  }

  if (store.state.UserModule.isInstallExtension) {
    result = {
      'isPermission': true,
      'type': 'success',
      'title': '',
      'message': '',
    }
    return result
  }

  if (options != null) {
    if (options.page * options.limit > 10) {
      result = {
        'isPermission': false,
        'type': 'over_quota',
        'title': 'Hãy nâng cấp tài khoản',
        'message': 'Tài khoản của bạn không được sử dụng tính Năng này'
      }
    }

    if (options.categories != null && options.categories.length > 1) {
      result = {
        'isPermission': false,
        'type': 'over_filter_category',
        'title': 'Hãy nâng cấp tài khoản',
        'message': 'Tài khoản của bạn chỉ được lọc tối đa 1 Danh mục'
      }
    }
    if (options.price_range != null
      || options.order_count_7d_range != null || options.order_count_30d_range != null || options.order_count_90d_range != null || options.order_count_180d_range != null
      || options.order_revenue_180d_range != null || options.order_revenue_180d_range != null || options.order_revenue_180d_range != null || options.order_revenue_180d_range != null
    ) {
      result = {
        'isPermission': false,
        'type': 'over_filter',
        'title': 'Hãy nâng cấp tài khoản',
        'message': 'Tài khoản của bạn không được Lọc nâng cao'
      }
    }
    if (options.queries != null && options.queries.length > 1) {
      result = {
        'isPermission': false,
        'type': 'over_filter_category',
        'title': 'Hãy nâng cấp tài khoản',
        'message': 'Tài khoản của bạn chỉ được lọc tối đa 1 Từ khóa'
      }
    }

    if (options.brands != null && options.brands.length > 1) {
      result = {
        'isPermission': false,
        'type': 'over_filter_category',
        'title': 'Hãy nâng cấp tài khoản',
        'message': 'Tài khoản của bạn chỉ được lọc tối đa 1 Thương hiệu'
      }
    }

    if (options.locations != null && options.locations.length > 1) {
      result = {
        'isPermission': false,
        'type': 'over_filter_category',
        'title': 'Hãy nâng cấp tài khoản',
        'message': 'Tài khoản của bạn chỉ được lọc tối đa 1 Kho'
      }
    }
  }

  return result

}

/**
 * Check Option is Advance or not
 * @param options
 * @return {Boolean}
 */
const isAdvanceOption = (options) => {
  let result = false
  if (!options) {
    return false
  }
  //
  // if (options.platforms != null && options.platforms.length > 1) {
  //   return true
  // }

  if (options.exclude_query != null) {
    return true
  }

  if (options.include_query?.queries != null && options.include_query?.queries.length > 0) {
    return true
  }
  if (options.include_query?.original_queries != null && options.include_query?.original_queries.length > 0) {
    return true
  }
  if (options.include_query?.is_smart_queries === true) {
    return true
  }
  if (options.include_query?.categories != null && options.include_query?.categories.length > 1) {
    return true
  }
  // if (options.keywords != null && options.keywords.length > 1) {
  //   return true
  // }
  if (options.include_query?.brands != null && options.include_query?.brands.length > 0) {
    return true
  }
  if (options.include_query?.official_types != null && options.include_query?.official_types.length != 2) {
    return true
  }
  if (options.include_query?.locations != null && options.include_query?.locations.length > 0) {
    return true
  }
  if (options.include_query?.composite_compare_lst != null && options.include_query?.composite_compare_lst.length > 0) {
    let composite_compare_lst = options.include_query?.composite_compare_lst.flat(2)
    let listFixedCondition = [
        ...FIXED_VALUE_COMPARE_BAD_PRODUCT_CONDITION_LIST,
        ...Object.values(FIXED_VALUE_COMPARE_TRENDING)
    ]
    listFixedCondition = generateListCompareFromStore(listFixedCondition).flat(2)
    for (const compositeCompareLstElement of composite_compare_lst) {
      if (JSON.stringify(listFixedCondition).indexOf(JSON.stringify(compositeCompareLstElement)) === -1) {
        return true
      }
    }
  }
  if (options.page > 0) {
    return true
  }
  if (options.limit > 10) {
    return true
  }
  if (
    options.include_query.price_range != null
    || options.include_query.view_count_range != null
    || options.include_query.revenue_range != null
    || (options.include_query.order_count_7d_range != null && (options.include_query.order_count_7d_range.from !== 1 || options.include_query.order_count_7d_range.to != null))
    || (options.include_query.order_count_30d_range != null && (options.include_query.order_count_30d_range.from !== 1 || options.include_query.order_count_30d_range.to != null))
    || options.include_query.order_count_90d_range != null
    || options.include_query.order_count_180d_range != null
    || options.include_query.order_count_custom_range != null
    || (options.include_query.order_count_range != null && (options.include_query.order_count_range.from !== FIXED_VALUE_RANGE_GOOD_SELLER_PRODUCT.min || options.include_query.order_count_range.to != null))
    || (options.include_query.order_revenue_7d_range != null && (options.include_query.order_revenue_7d_range.from !== 1 || options.include_query.order_revenue_7d_range.to != null))
    || (options.include_query.order_revenue_30d_range != null && (options.include_query.order_revenue_30d_range.from !== 1 || options.include_query.order_revenue_30d_range.to != null))
    || options.include_query.order_revenue_90d_range != null
    || options.include_query.order_revenue_180d_range != null
    || options.include_query.order_revenue_custom_range != null
    || options.include_query.revenue_range != null
    || (options.include_query.review_count_7d_range != null && (options.include_query.review_count_7d_range.from !== 1 || options.include_query.review_count_7d_range.to != null))
    || (options.include_query.review_count_30d_range != null && (options.include_query.review_count_30d_range.from !== 1 || options.include_query.review_count_30d_range.to != null))
    || options.include_query.review_count_90d_range != null
    || options.include_query.review_count_180d_range != null
    || options.include_query.review_count_range != null
    || options.include_query.review_count_custom_range != null
    || options.include_query.rating_count_range != null
  ) {
    return true
  }
  return result
}

const isAvailableOption = (options, permissionName) => {
  let isAdvance = isAdvanceOption(options)
  if (!isAdvance) {
    return  true
  }
  if (permissionName === PERMISSION.market_default.name) {
    return false
  }
  if (
    permissionName === PERMISSION.market_basic.name
    || permissionName === PERMISSION.market_standard.name
  ) {
    return !hasLinkShop(options.include_query?.queries)
  }
  return true
}

/**
 * basic được xem
 *
 * @param options
 * @returns {Promise<{isPermission: boolean, type: string, title: string, message: string}|{isPermission: boolean, type: string, message: string, title: string}>}
 */
const canShowProductLink = async (options) => {
  let result = {
    'isPermission': true,
    'type': 'default',
    'message': '',
    'title': '',
  }
  // let isExpired = store.state.UserModule.isExpired
  // let freeSearchCount = store.state.UserModule.freeSearchCount
  let isDefaultAccount = store.state.UserModule[MutationUser.permissionAvailableName] === PERMISSION.market_default.name
  if (isDefaultAccount) {
    result = {
      'isPermission': false,
      'type': 'default',
      'message': '',
      'title': 'Nâng cấp tài khoản để đi tới Link sản phẩm'
    }
  }
  return result
}

const canShowHistoryPrice = async (options) => {
  let result = await canShowProductLink(options)
  result.title = 'Nâng cấp tài khoản để xem lịch sử giá'
  return result
}

const canShowHistoryOrder = async (options) => {
  let result = await canShowProductLink(options)
  result.title = 'Nâng cấp tài khoản để xem lịch sử bán'
  return result
}

const canDownloadProductList = async (options) => {

  let result = {
    'isPermission': true,
    'type': 'default',
    'message': '',
    'title': 'Thông báo',
  }

  let profile = await getUserProfile()
  if (profile == null) {
    result = {
      'isPermission': false,
      'type': 'not_login',
      'title': 'Hãy đăng nhập tài khoản',
      'message': '',
    }
    return result
  }

  let roles = profile['roles']

  if (!roles.includes('market_advance')) {
    result = {
      'isPermission': false,
      'type': 'not_permission_show_download_product_list',
      'title': 'Hãy nâng cấp tài khoản',
      'message': 'Tài khoản của bạn không được tải Danh sách Sản phẩm',
    }
    return result
  }

  return result
}

/**
 * Get end time to search advance
 */
const getPaidUserInformation = async () => {
  let result = await apiCheckMe()
  if (!result) {
    result = await apiRegisterMe()
    if (!result) {
      return null
    }
    return result
  }
  return result
}

/**
 * get permission from PERMISSION constant by role of user
 * @param roles {[string]}: array of string presented for role of user
 * @return {Object | null}
 */
const getUserPermissionFromRoles = (roles= null) => {
  if (!roles?.length) {
    return PERMISSION.market_default
  }
  let result = null
  for (let role of roles) {
    role = role.toLowerCase()
    result =  !PERMISSION[role] || result?.priority > PERMISSION[role].priority ? result : PERMISSION[role]
  }
  if (!result) {
    return PERMISSION.market_default
  }
  return result
}

export {
  canFilterProduct,
  canShowProductLink,
  canDownloadProductList,
  canShowHistoryPrice,
  canShowHistoryOrder,
  getPaidUserInformation,
  isAdvanceOption,
  isAvailableOption,
  getUserPermissionFromRoles
}
