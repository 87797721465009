import {MutationSearchShopsResult} from "@/store/modules/SearchShopsResultModule/mutation";

const initialState = () => {
  return {
    shops: [],
    shopsInsight: []
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters: {
    [MutationSearchShopsResult.shops]: state => state.shops,
    [MutationSearchShopsResult.shopsInsight]: state => state.shopsInsight
  },
  mutations: {
    [MutationSearchShopsResult.shops](state, value) {
      state[MutationSearchShopsResult.shops] = value
    },
    [MutationSearchShopsResult.shopsInsight](state, value) {
      state[MutationSearchShopsResult.shopsInsight] = value
    },
    [MutationSearchShopsResult.RESET](state) {
      const s = initialState()
      Object.keys(s)
        .forEach(key => {
          state[key] = s[key]
        })
    }
  },
  actions: {
    [MutationSearchShopsResult.shops](context, data) {
      context.commit(MutationSearchShopsResult.shops, data)
    },
    [MutationSearchShopsResult.shopsInsight](context, data) {
      context.commit(MutationSearchShopsResult.shopsInsight, data)
    },
    [MutationSearchShopsResult.pushInsightToShopsInsight](context, data) {
      let shopsInsight = context.getters[MutationSearchShopsResult.shopsInsight]
      shopsInsight.push(data)
      context.commit(MutationSearchShopsResult.shopsInsight, shopsInsight)
    },
    [MutationSearchShopsResult.RESET](context) {
      context.commit(MutationSearchShopsResult.RESET)
    }
  }
}
