import {URL_BEECOST_BACKEND_API} from "@/config/ConfigEndpoint"

const urlSaveReferralTracking = () => {
  //return `http://127.0.0.1:8000/market/user/ref`
  return `${URL_BEECOST_BACKEND_API}/market/user/ref`
}
const urlGetReferralUrlLink = () => {
  return `${URL_BEECOST_BACKEND_API}/market/user/ref`
}
export {
  urlSaveReferralTracking,
  urlGetReferralUrlLink
}
