const MutationGeneral = {
  MODULE_PATH: 'GeneralModule',
  setActionName: 'setActionName',
  setShowPopupLogin: 'setShowPopupLogin',
  setShowPopupSupport: 'setShowPopupSupport',
  setShowPopupContactSale: 'setShowPopupContactSale',
  setShowPopupSearchDetail: 'setShowPopupSearchDetail',
  setShowPopupUpgradeAdvance: 'setShowPopupUpgradeAdvance',
  setShowPopupPricing: 'setShowPopupPricing',
  setShowPopupSignUpLeadForm: 'setShowPopupSignUpLeadForm',
  setShowPopupInstallExtension: 'setShowPopupInstallExtension',
  setShowPopupShareLink: 'setShowPopupShareLink',
  setShowPopupPermissionNotification: 'setShowPopupPermissionNotification',
  setShowPopupMarketNotification: 'setShowPopupMarketNotification',
  setShowPopupNotification: 'setShowPopupNotification',
  showPopupMobileNotification: 'showPopupMobileNotification',
  setShowPopupPromotedNotification: 'setShowPopupPromotedNotification',
  setShowPopupSystemError: 'setShowPopupSystemError',
  setShowPopupSearchFilter: 'setShowPopupSearchFilter',
  showPopupChatToDownload: 'showPopupChatToDownload',
  showPopupLimitDeviceManual: 'showPopupLimitDeviceManual',
  RESET: 'RESET',
}

export {
  MutationGeneral
}
