import {URL_BEECOST_BACKEND_API} from "@/config/ConfigEndpoint";

const urlApiCreateVoucher = (voucherType) => {
  return `${URL_BEECOST_BACKEND_API}/market/hook/buy/voucher?voucher_type=${voucherType}`
}

const urlApiVerifyPayment = (voucherCode) => {
  return `${URL_BEECOST_BACKEND_API}/market/hook/buy/voucher?voucher_code=${voucherCode}`
}

const urlApiTransaction = () => {
  return `${URL_BEECOST_BACKEND_API}/market/user/paid/transaction`
}

const urlApiDiscountVoucher = () => {
  return `${URL_BEECOST_BACKEND_API}/market/voucher/discount`
}

const urlApiPricingPlans = () => {
  return `${URL_BEECOST_BACKEND_API}/market/pricing`
}

export {
  urlApiCreateVoucher,
  urlApiVerifyPayment,
  urlApiTransaction,
  urlApiDiscountVoucher,
  urlApiPricingPlans
}
