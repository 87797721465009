import * as ProductEndpoint from '@/api/product/ProductEndpoint'
import { http } from '@/plugin/http'
import {ALL_PLATFORM_BASE_OBJECT} from "@/constant/general/GeneralConstant";
import {gaTrackEvent} from "@/service/tracking/GaTrackingService";
import {
  urlApiKeywordSearch,
  urlApiShopSearch,
  urlCheckProductLink
} from "@/api/product/ProductEndpoint";
import {urlApiInsightGeneralSearch} from "@/api/market/MarketEndpoint";
import {getUserProfile} from "@/service/user/UserProfileService";

const apiSearchProduct = async (options, isDemo = false, listPlatformId) => {
  let urlApi = ProductEndpoint.urlApiProductSearch()
  if (isDemo) {
    // if whithoute platform id, default platform id would be shopee, if not, use platform id from his availablePlatform
    urlApi = ProductEndpoint.urlApiProductSearchDemo(listPlatformId)
  }
  try {
    const response = await http.post(urlApi, options)
    let profile = await getUserProfile()
    let email = profile?.email
    gaTrackEvent(
      'api_search_status',
      `${response.status}_${response?.data?.status}_${response?.data?.data}`,
      `${email}_${JSON.stringify(options)}`
    )
    if (response?.data != null) {
      return response.data
    }

  } catch (e) {
    console.error(e)
  }
  return null
}

/**
 * api get config by platformId. Currently, config contain only date range
 * @param listPlatformId {Array<String|Number>}
 * @return {Promise<null|{current_date: string, start_date: string, status: string}>}
 */
const apiSearchConfig = async (listPlatformId) => {
  let urlApi = ProductEndpoint.urlApiSearchConfig(listPlatformId)
  try {
    const response = await http.get(urlApi)
    if (response?.data != null) {
      return response.data
    }

  } catch (e) {
    console.error(e)
  }
  return null
}

const apiSearchDemo = async (listPlatformId = [], endDateString = null) => {
  if (!Array.isArray(listPlatformId)) {
    listPlatformId = [listPlatformId]
  }
  // if whithoute platform id, default platform id would be shopee, if not, use platform id from his availablePlatform
  let urlApi = ProductEndpoint.urlApiProductSearchDemo(listPlatformId, endDateString)
  try {
    const response = await http.get(urlApi)
    if (response?.data != null) {
      return response.data
    }

  } catch (e) {
    console.error(e)
  }
  return null
}

const apiSearchShop = async (options) => {
  let urlApi = urlApiShopSearch()

  try {
    const response = await http.post(urlApi, options)
    if (response?.data != null) {
      return response.data
    }

  } catch (e) {
    console.error(e)
  }
  return null
}

const apiSearchInsightShop = async (options) => {
  let urlApi = urlApiInsightGeneralSearch()

  try {
    const response = await http.post(urlApi, options)
    if (response?.data != null) {
      return response.data
    }

  } catch (e) {
    console.error(e)
  }
  return null
}

const apiSearchKeyword = async (options) => {
  let urlApi = urlApiKeywordSearch()

  try {
    const response = await http.post(urlApi, options)
    if (response?.data != null) {
      return response.data
    }

  } catch (e) {
    console.error(e)
  }
  return null
}

const apiCheckProductLinkDetail = async (data) => {
  if (!data) {
    return null
  }
  let urlApi = urlCheckProductLink()
  try {
    let response = await http.post(urlApi, data)

    if (response?.data) {
      return response.data
    }
  } catch (e) {
    return {status: e.response.status, data: e.response.data}
  }
}


export {
  apiSearchProduct,
  apiSearchDemo,
  apiSearchConfig,
  apiSearchShop,
  apiSearchKeyword,
  apiSearchInsightShop,
  apiCheckProductLinkDetail
}
