const readCookie = (name, defaultValue = null) => {
  let nameEQ = name + '='
  let ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
  }
  return defaultValue
}

const setCookie = (name, value, days = 30, path = '/') => {
  const expires = new Date(Date.now() + days * 864e5).toUTCString()
  // console.log(`prepare setCookie `, name, ': ', value, '; day = ', days)
  document.cookie = name + '=' + encodeURIComponent(value) + '; expires=' + expires + '; path=' + path
}

/**
 * set Cookie with full option
 * @param name
 * @param value
 * @param domain
 * @param isCrossOrigin
 * @param path
 * @param days
 */
const setCookieExpanded = (name, value, domain = null, isCrossOrigin= false, path = '/', days = 30) => {
  const expires = new Date(Date.now() + 30 * 864e5).toUTCString()
  let cookies = name + '=' + encodeURIComponent(value) + '; expires=' + expires + '; path=' + path
  if (domain) {
    cookies += ';domain=' + domain
  }
  if (isCrossOrigin) {
    cookies += ';SameSite=None; Secure'
  }
  document.cookie = cookies
}

export { readCookie, setCookie, setCookieExpanded }
