const getValueByKey = (arr, keyName, keyValue) => {
  if (arr == null || arr.length === 0) {
    return null
  }

  for (let element of arr) {
    if (element?.[keyName] === keyValue) {
      return element
    }
  }
  return null
}

const meanBy = (arr, key) => {
  if (arr == null || arr.length === 0) {
    return null
  }
  let sum = null
  for (let element of arr) {
    sum += element[key]
  }
  let mean = sum / arr.length
  return mean
}

const uniqBy = (myArr, prop) => {
  if (myArr == null) {
    return null
  }
  if (myArr.length === 0) {
    return []
  }
  return myArr.filter((element, pos, arr) => {
    return arr.map(mapObj => mapObj[prop])
      .indexOf(element[prop]) === pos
  })
}

const groupBy = (array, key) => {
  // Return the end result
  return array.reduce((result, currentValue) => {
    // If an array already present for key, push it to the array. Else create an array and push the object
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    )
    // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
    return result
  }, {}) // empty object is the initial value for result object
}

export {
  meanBy, uniqBy, groupBy, getValueByKey
}
