import Vue from 'vue'
import VueRouter from 'vue-router'
import { websitePrefixRoute } from '@/config/ConfigProject'
import {setAccessToken} from "@/service/user/UserProfileService";
import {localStorageSet} from "@/helper/LocalStorageHelper";
import {LocalStorageKeys} from "@/constant/localstorage/LocalStorageConstant";

Vue.use(VueRouter)

const routes = [
  {
    path: `${websitePrefixRoute}`,
    name: 'Home',
    component: () => import('@/views/homePage/index'),
  },
  {
    path: `${websitePrefixRoute}analytics`,
    name: 'Analytics',
    component: () => import('@/views/market/MarketView'),
  },
  {
    path: `${websitePrefixRoute}global`,
    name: 'market_global',
    component: () => import('@/views/market/MarketGlobalView'),
  },
  {
    path: `${websitePrefixRoute}product`,
    name: 'table_product_view',
    component: () => import('@/views/search/TableProductView'),
  },
  {
    path: `${websitePrefixRoute}product-global`,
    name: 'table_product_global_view',
    component: () => import('@/views/search/TableProductGlobalView'),
  },
  {
    path: `${websitePrefixRoute}keyword`,
    name: 'table_keyword_view',
    component: () => import('@/views/search/TableKeywordView'),
  },
  {
    path: `${websitePrefixRoute}shop`,
    name: 'table_shop_view',
    component: () => import('@/views/search/TableShopView'),
  },
  {
    path: `${websitePrefixRoute}saved-report`,
    name: 'saved_report_view',
    component: () => import('@/views/report/SavedReportView'),
  },
  {
    path: `${websitePrefixRoute}privacy`,
    name: 'privacy',
    component: () => import('@/views/PrivacyView'),
  },
  {
    path: `${websitePrefixRoute}chinh-sach-bao-ve-thong-tin-ca-nhan-khach-hang`,
    name: 'user_privacy',
    component: () => import('@/views/UserPrivacyView'),
  },
  {
    path: `${websitePrefixRoute}quy-dinh-dung-thu-dich-vu`,
    name: 'term_of_trial',
    component: () => import('@/views/TermsOfTrialView'),
  },
  {
    path: `${websitePrefixRoute}hinh-thuc-thanh-toan`,
    name: 'payment_method',
    component: () => import('@/views/PaymentMethodView'),
  },
  {
    path: `${websitePrefixRoute}chinh-sach-bao-hanh-va-bao-tri`,
    name: 'warranty_policy_view',
    component: () => import('@/views/WarrantyPolicyView'),
  },
  {
    path: `${websitePrefixRoute}chinh-sach-doi-tra-san-pham`,
    name: 'product_returns',
    component: () => import('@/views/ProductReturnsView'),
  },
  {
    path: `${websitePrefixRoute}chinh-sach-dat-hang`,
    name: 'order_policy_view',
    component: () => import('@/views/OrderPolicyView'),
  },
  {
    path: `${websitePrefixRoute}dieu-kien-giao-dich-chung`,
    name: 'order_policy_view',
    component: () => import('@/views/TransactionCondition'),
  },
  {
      path: `${websitePrefixRoute}terms`,
      name: 'terms_of_use',
      component: () => import('@/views/TermsView'),
    },

  {
    path: `${websitePrefixRoute}subscribe`,
    name: 'subcribe_index_view',
    component: () => import('@/views/subscribe/SubcribeIndexView'),
  },

  {
    path: `${websitePrefixRoute}ref/:tid`,
    name: 'referral_tracking_view',
    component: () => import('@/views/referral/ReferralTracking'),
  },

  {
    path: `${websitePrefixRoute}referral-program`,
    name: 'referral_program',
    component: () => import('@/views/referral/ReferralProgram'),
  },

  {
    path: `${websitePrefixRoute}pricing`,
    name: 'pricing',
    component: () => import('@/views/pricing/PricingViewV2'),
    // component: () => import('@/views/market/MarketView'),
  },
  {
    path: `${websitePrefixRoute}pricing-new`,
    name: 'pricing_v2',
    redirect: `${websitePrefixRoute}pricing`,
  },
  {
    path: `${websitePrefixRoute}new-pricing`,
    redirect: `${websitePrefixRoute}pricing`,
  },
  {
    path: `${websitePrefixRoute}pricing/business`,
    name: 'business_pricing',
    redirect: `${websitePrefixRoute}pricing`,
  },

  {
    path: `${websitePrefixRoute}login/:token`,
    name: 'login_by_token',
    beforeEnter: async (to, from, next) => {
      await setAccessToken(to.params.token)
      next()
    },
    component: () => import('@/views/market/MarketView'),
  },

  {
    path: `${websitePrefixRoute}login`,
    name: 'login',
    component: () => import('@/views/auth/LoginView'),
  },
  {
    path: `${websitePrefixRoute}logout`,
    name: 'logout',
    beforeEnter: async (to, from, next) => {
      await localStorageSet(LocalStorageKeys.ACCESS_TOKEN, null)
      await localStorageSet(LocalStorageKeys.USER_PROFILE, null)
      await localStorageSet(LocalStorageKeys.FULL_NAME, null)
      next()
    },
    component: () => import('@/views/market/MarketView'),
  },

  {
    path: `${websitePrefixRoute}payment`,
    name: 'payment',
    component: () => import('@/views/payment/PaymentView'),
  },

  {
    path: `${websitePrefixRoute}payment/:voucherId/:voucherCode`,
    name: 'transaction_detail',
    component: () => import('@/views/payment/TransactionDetailView'),
  },

  {
    path: `${websitePrefixRoute}payment-guide`,
    name: 'payment_guide',
    component: () => import('@/views/payment/PaymentGuideView'),
  },

  {
    path: `${websitePrefixRoute}order-report`,
    name: 'order_summary_report',
    redirect: `${websitePrefixRoute}`,
    // component: () => import('@/views/report/OrderSummaryReport'),
  },
  {
    path: `${websitePrefixRoute}revenue-report`,
    name: 'revenue_summary_report',
    redirect: `${websitePrefixRoute}`,
    // component: () => import('@/views/report/RevenueSummaryReport'),
  },
  {
    path: `${websitePrefixRoute}insight`,
    redirect: `${websitePrefixRoute}`
  },
  {
    path: `${websitePrefixRoute}insight/:hash`,
    name: 'custom_market_view',
    component: () => import('@/views/market/CustomMarketView'),
  },
  {
    path: `${websitePrefixRoute}user`,
    name: 'user_view',
    component: () => import('@/views/user/ProfileView'),
  },
  {
    path: `${websitePrefixRoute}oauthlogin`,
    name: 'oauthlogin_view',
    component: () => import('@/views/auth/AuthView'),
  },

  {
    path: `${websitePrefixRoute}mgg`,
    name: 'mgg_promoted_view',
    props: { voucherCode: 'BEECOSTT9' },
    component: () => import('@/views/promoted/PromotedView'),
  },
  {
    path: `${websitePrefixRoute}khuyenmai`,
    name: 'khuyenmai_promoted_view',
    props: { voucherCode: 'HAPPYT9' },
    component: () => import('@/views/promoted/PromotedView'),
  },
  {
    path: `${websitePrefixRoute}price-alert`,
    name: 'price_alert_view',
    component: () => import('@/views/priceAlert/PriceAlertView'),
  },
  {
    path: `${websitePrefixRoute}iframe/market_widget_chart`,
    name: 'market_widget_chart_view',
    component: () => import('@/views/iframe/MarketWidgetChartIframeView/index'),
  },
  {
    path: `${websitePrefixRoute}iframe/market_view`,
    name: 'market_iframe_view',
    component: () => import('@/views/iframe/MarketIframeView/index'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
