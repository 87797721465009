import { MutationUser } from '@/store/modules/UserModule/mutation'
import {PERMISSION} from "@/constant/general/PermissionConstant";

const initialState = () => {
  return {
    isLogin: false,
    userProfile: null,
    fullName: null,
    isExpired: true,
    isBusinessAccount: false,
    [MutationUser.permissionAvailableName]: null,
    [MutationUser.permissionPlanName]: null,
    expirationDate: null,
    freeSearchCount: 0,
    [MutationUser.freeSearchCountPerWeek]: 0,
    [MutationUser.remainProductDownloadCountPerWeek]: 0,
    [MutationUser.remainShopDownloadCountPerWeek]: 0,
    isInstallExtension: true,
    inactiveVouchers: null,
    promoteVoucher: null,
    selectedVoucherId: null,
    voucherCode: null,
    [MutationUser.paymentQRCode]: null,
    percentPaymentProgress: 0,
    isSuccessfulPayment: null,
    marketReferralLink: null
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters: {
    isLogin: state => state.isLogin,
    userProfile: state => state.userProfile,
    email: state => state.userProfile?.email,
    fullName: state => state.fullName,
    isExpired: state => state.isExpired,
    [MutationUser.permissionAvailableName]: state => state[MutationUser.permissionAvailableName],
    [MutationUser.permissionPlanName]: state => state[MutationUser.permissionPlanName],
    expirationDate: state => state.expirationDate,
    freeSearchCount: state => state.freeSearchCount,
    [MutationUser.freeSearchCountPerWeek]: state => state[MutationUser.freeSearchCountPerWeek],
    [MutationUser.remainProductDownloadCountPerWeek]: state => state[MutationUser.remainProductDownloadCountPerWeek],
    [MutationUser.remainShopDownloadCountPerWeek]: state => state[MutationUser.remainShopDownloadCountPerWeek],
    isInstallExtension: state => state.isInstallExtension,
    inactiveVouchers: state => state.inactiveVouchers,
    promoteVoucher: state => state.promoteVoucher,
    selectedVoucherId: state => state.selectedVoucherId,
    voucherCode: state => state.voucherCode,
    [MutationUser.paymentQRCode]: state => state[MutationUser.paymentQRCode],
    percentPaymentProgress: state => state.percentPaymentProgress,
    isSuccessfulPayment: state => state.isSuccessfulPayment,
    marketReferralLink: state => state.marketReferralLink,
    [MutationUser.isDefaultAccountPlan]: (state) => {
      return state[MutationUser.permissionPlanName] === PERMISSION.market_default.name
    },
    [MutationUser.isBasicAccountPlan]: (state) => {
      return state[MutationUser.permissionPlanName] === PERMISSION.market_basic.name
    },
    [MutationUser.isStandardAccountPlan]: (state) => {
      return state[MutationUser.permissionPlanName] === PERMISSION.market_standard.name
    },
    [MutationUser.isAdvanceAccountPlan]: (state) => {
      return state[MutationUser.permissionPlanName] === PERMISSION.market_advance.name
    },
    [MutationUser.isBusinessAccountPlan]: (state) => {
      return PERMISSION[state[MutationUser.permissionPlanName]]?.priority >= PERMISSION.market_business_small.priority
    },
    [MutationUser.isCompanyAccountPlan]: (state) => {
      return PERMISSION[state[MutationUser.permissionPlanName]]?.priority > PERMISSION.market_business_small.priority
    },
    [MutationUser.isAgencyAccountPlan]: (state) => {
      return PERMISSION[state[MutationUser.permissionPlanName]]?.priority > PERMISSION.market_business.priority
    },
    [MutationUser.isDefaultAccount]: (state) => {
      return state[MutationUser.permissionAvailableName] === PERMISSION.market_default.name
    },
    [MutationUser.isBasicAccount]: (state) => {
      return state[MutationUser.permissionAvailableName] === PERMISSION.market_basic.name
    },
    [MutationUser.isStandardAccount]: (state) => {
      return state[MutationUser.permissionAvailableName] === PERMISSION.market_standard.name
    },
    [MutationUser.isStandardBAccount]: (state) => {
      return state[MutationUser.permissionAvailableName] === PERMISSION.marketv2_standard_b.name
    },
    [MutationUser.isAdvanceAccount]: (state) => {
      return state[MutationUser.permissionAvailableName] === PERMISSION.market_advance.name
    },
    [MutationUser.isBusinessAccount]: (state) => {
      return PERMISSION[state[MutationUser.permissionAvailableName]]?.priority >= PERMISSION.market_business_small.priority
    },
    [MutationUser.isCompanyAccount]: (state) => {
      return PERMISSION[state[MutationUser.permissionAvailableName]]?.priority > PERMISSION.market_business_small.priority
    },
    [MutationUser.isAgencyOrHigherPriorityAccount]: (state) => {
      return PERMISSION[state[MutationUser.permissionAvailableName]]?.priority > PERMISSION.market_business.priority
    },
    [MutationUser.isPaidAccount]: (state) => {
      return PERMISSION[state[MutationUser.permissionAvailableName]]?.priority > PERMISSION.market_default.priority
    },
  },
  mutations: {
    [MutationUser.setIsLogin](state, value) {
      state.isLogin = value
    },
    [MutationUser.setUserProfile](state, value) {
      state.userProfile = value
    },
    [MutationUser.setFullName](state, value) {
      state.fullName = value
    },
    [MutationUser.setIsExpired](state, value) {
      state.isExpired = value
    },
    [MutationUser.permissionAvailableName](state, value) {
      state[MutationUser.permissionAvailableName] = value
    },
    [MutationUser.permissionPlanName](state, value) {
      state[MutationUser.permissionPlanName] = value
    },
    [MutationUser.setExpirationDate](state, value) {
      state.expirationDate = value
    },
    [MutationUser.freeSearchCount](state, value) {
      state.freeSearchCount = value
    },
    [MutationUser.freeSearchCountPerWeek](state, value) {
      state[MutationUser.freeSearchCountPerWeek] = value
    },
    [MutationUser.remainProductDownloadCountPerWeek](state, value) {
      state[MutationUser.remainProductDownloadCountPerWeek] = value
    },
    [MutationUser.remainShopDownloadCountPerWeek](state, value) {
      state[MutationUser.remainShopDownloadCountPerWeek] = value
    },
    [MutationUser.setIsInstallExtension](state, value) {
      state.isInstallExtension = value
    },
    [MutationUser.setInactiveVouchers](state, value) {
      state.inactiveVouchers = value
    },
    [MutationUser.setPromoteVoucher](state, value) {
      state.promoteVoucher = value
    },
      [MutationUser.setMarketReferralLink](state, value) {
          state.marketReferralLink = value
      },
    [MutationUser.setSelectedVoucherId](state, value) {
      state.selectedVoucherId = value
    },
    [MutationUser.setVoucherCode](state, value) {
      state.voucherCode = value
    },
    [MutationUser.paymentQRCode](state, value) {
      state[MutationUser.paymentQRCode] = value
    },
    [MutationUser.setPercentPaymentProgress](state, value) {
      state.percentPaymentProgress = value
    },
    [MutationUser.setIsSuccessfulPayment](state, value) {
      state.isSuccessfulPayment = value
    },
    [MutationUser.RESET](state) {
      const s = initialState()
      Object.keys(s)
        .forEach(key => {
          state[key] = s[key]
        })
    }
  },
  actions: {
    [MutationUser.setIsExpired](context, data) {
      context.commit(MutationUser.setIsExpired, data)
    },
    [MutationUser.isBusinessAccount](context, data) {
      context.commit(MutationUser.isBusinessAccount, data)
    },
    [MutationUser.permissionAvailableName](context, data) {
      context.commit(MutationUser.permissionAvailableName, data)
    },
    [MutationUser.permissionPlanName](context, data) {
      context.commit(MutationUser.permissionPlanName, data)
    },
    [MutationUser.setExpirationDate](context, data) {
      context.commit(MutationUser.setExpirationDate, data)
    },
    [MutationUser.freeSearchCount](context, data) {
      context.commit(MutationUser.freeSearchCount, data)
    },
    [MutationUser.freeSearchCountPerWeek](context, data) {
      context.commit(MutationUser.freeSearchCountPerWeek, data)
    },
    [MutationUser.remainProductDownloadCountPerWeek](context, data) {
      context.commit(MutationUser.remainProductDownloadCountPerWeek, data)
    },
    [MutationUser.remainShopDownloadCountPerWeek](context, data) {
      context.commit(MutationUser.remainShopDownloadCountPerWeek, data)
    },
    [MutationUser.setIsInstallExtension](context, data) {
      context.commit(MutationUser.setIsInstallExtension, data)
    },
    [MutationUser.setInactiveVouchers](context, data) {
      context.commit(MutationUser.setInactiveVouchers, data)
    },
    [MutationUser.setMarketReferralLink](context, data){
      context.commit(MutationUser.setMarketReferralLink,data)
    },
    [MutationUser.setPromoteVoucher](context, data) {
      context.commit(MutationUser.setPromoteVoucher, data)
    },
    [MutationUser.setSelectedVoucherId](context, data) {
      context.commit(MutationUser.setSelectedVoucherId, data)
    },
    [MutationUser.setVoucherCode](context, data) {
      context.commit(MutationUser.setVoucherCode, data)
    },
    [MutationUser.paymentQRCode](context, data) {
      context.commit(MutationUser.paymentQRCode, data)
    },
    [MutationUser.setPercentPaymentProgress](context, data) {
      context.commit(MutationUser.setPercentPaymentProgress, data)
    },
    [MutationUser.setIsSuccessfulPayment](context, data) {
      context.commit(MutationUser.setIsSuccessfulPayment, data)
    },
    [MutationUser.RESET](context) {
      context.commit(MutationUser.RESET)
    }
  }
}
