import {
  ALL_CHART_TAB,
  ALL_GENERAL_STATISTIC,
  ALL_PLATFORM_BASE_OBJECT
} from "@/constant/general/GeneralConstant";
// todo: update availableDataIndexShopTable, availableDataIndexProductTable

const DOWNLOAD_TYPE = {
  excel: 'excel',
  csv: 'csv'
}

const PERMISSION = {
  "market_default": {
    id: 'market_default',
    priority: 10,
    name: 'market_default',
    label: 'Tài khoản Miễn phí',
    availablePlatform: [
      ALL_PLATFORM_BASE_OBJECT.shopee.uniqueName,
      // ALL_PLATFORM_BASE_OBJECT.sendo.uniqueName
    ],
    availableFilterOption: {
      'allDurationDay': (allDurationDay) => {
        return allDurationDay.filter(item => item < 90)
      },
      'hasDurationRange': (hasDurationRange) => {
        return false
      },
      'hasSmartSearch': (hasSmartSearch) => {
        return hasSmartSearch
      },
      'allCategories': (allCategories) => {
        return allCategories
      },
      'allCategoriesDetail': (allCategoriesDetail) => {
        return null
      },
      'allShopType': (allShopType) => {
        return allShopType
      },
      'allLocation': (allLocation) => {
        return allLocation
      },
      'allValueCompareFieldFilter': (allValueCompareFieldFilter) => {
        return allValueCompareFieldFilter
      },
      'allValueRangeFieldFilter': (allValueRangeFieldFilter) => {
        return allValueRangeFieldFilter
      },
      'allFixedOptionFilter': (allFixedOptionFilter) => {
        return allFixedOptionFilter
      }
    },
    availableDataIndexProductTable: [
      'product_name',
      'price',
      'category_name',
      'brand',
    ],
    availableDataIndexShopTable: [
    ],
    availableDataIndexBrandsTable: [
    ],
    limitShopSubscribe: 0,
    allLimitLineProductDownload: [],
    allTypeProductDownload: [DOWNLOAD_TYPE.excel, DOWNLOAD_TYPE.csv],
    allLimitLineShopDownload: [],
    allTypeShopDownload: [DOWNLOAD_TYPE.excel, DOWNLOAD_TYPE.csv],
    allLimitLineBrandDownload: [],
    allTypeBrandDownload: [DOWNLOAD_TYPE.excel, DOWNLOAD_TYPE.csv],
  },
  "market_basic": {
    id: 'market_basic',
    priority: 20,
    name: 'market_basic',
    label: 'Tài khoản Tiêu chuẩn',
    availablePlatform: [
      ALL_PLATFORM_BASE_OBJECT.shopee.uniqueName,
      // ALL_PLATFORM_BASE_OBJECT.sendo.uniqueName
    ],
    availableFilterOption: {
      'allDurationDay': (allDurationDay) => {
        return allDurationDay
      },
      'hasDurationRange': (hasDurationRange) => {
        return false
      },
      'hasSmartSearch': (hasSmartSearch) => {
        return hasSmartSearch
      },
      'allCategories': (allCategories) => {
        return allCategories
      },
      'allCategoriesDetail': (allCategoriesDetail) => {
        return null
      },
      'allShopType': (allShopType) => {
        return allShopType
      },
      'allLocation': (allLocation) => {
        return allLocation
      },
      'allValueCompareFieldFilter': (allValueCompareFieldFilter) => {
        return allValueCompareFieldFilter
      },
      'allValueRangeFieldFilter': (allValueRangeFieldFilter) => {
        return allValueRangeFieldFilter
      },
      'allFixedOptionFilter': (allFixedOptionFilter) => {
        return allFixedOptionFilter
      }
    },
    availableDataIndexProductTable: [
      'product_name',
      'price',
      'order_count',
      'review_count',
      'order_revenue',
      'category_name',
      'brand',
      'productLink',
      'productHistory',
    ],
    availableDataIndexShopTable: [
      "shop_platform_id",
      "shop_name",
      "shop_url",
      "product_total",
      "order_revenue_selected",
      "order_count_selected",
    ],
    availableDataIndexBrandsTable: [
    ],
    limitShopSubscribe: 0,
    allLimitLineProductDownload: [50, 100, 200],
    allLimitLineShopDownload: [],
    allTypeProductDownload: [DOWNLOAD_TYPE.excel, DOWNLOAD_TYPE.csv],
    allTypeShopDownload: [DOWNLOAD_TYPE.excel, DOWNLOAD_TYPE.csv],
    allLimitLineBrandDownload: [],
    allTypeBrandDownload: [DOWNLOAD_TYPE.excel, DOWNLOAD_TYPE.csv],
  },
  "market_standard": {
    id: 'market_standard',
    priority: 25,
    name: 'market_standard',
    label: 'Tài khoản VIP',
    availablePlatform: [
      ALL_PLATFORM_BASE_OBJECT.shopee.uniqueName,
      // ALL_PLATFORM_BASE_OBJECT.sendo.uniqueName
    ],
    availableFilterOption: {
      'allDurationDay': (allDurationDay) => {
        return allDurationDay
      },
      'hasDurationRange': (hasDurationRange) => {
        return false
      },
      'hasSmartSearch': (hasSmartSearch) => {
        return hasSmartSearch
      },
      'allCategories': (allCategories) => {
        return allCategories
      },
      'allCategoriesDetail': (allCategoriesDetail) => {
        return null
      },
      'allShopType': (allShopType) => {
        return allShopType
      },
      'allLocation': (allLocation) => {
        return allLocation
      },
      'allValueCompareFieldFilter': (allValueCompareFieldFilter) => {
        return allValueCompareFieldFilter
      },
      'allValueRangeFieldFilter': (allValueRangeFieldFilter) => {
        return allValueRangeFieldFilter
      },
      'allFixedOptionFilter': (allFixedOptionFilter) => {
        return allFixedOptionFilter
      }
    },
    availableDataIndexProductTable: [
      'product_name',
      'price',
      'order_count',
      'review_count',
      'order_revenue',
      'category_name',
      'brand',
      'productLink',
      'productHistory',
    ],
    availableDataIndexShopTable: [
      "shop_platform_id",
      "shop_name",
      "shop_url",
      "product_total",
      "order_revenue_selected",
      "order_count_selected",
    ],
    availableDataIndexBrandsTable: [
    ],
    limitShopSubscribe: 0,
    allLimitLineProductDownload: [50, 100, 200],
    allLimitLineShopDownload: [],
    allTypeProductDownload: [DOWNLOAD_TYPE.excel, DOWNLOAD_TYPE.csv],
    allTypeShopDownload: [DOWNLOAD_TYPE.excel, DOWNLOAD_TYPE.csv],
    allLimitLineBrandDownload: [],
    allTypeBrandDownload: [DOWNLOAD_TYPE.excel, DOWNLOAD_TYPE.csv],
  },
  "market_advance": {
    id: 'market_advance',
    priority: 30,
    name: 'market_advance',
    label: 'Tài khoản Nâng cao',
    availablePlatform: [
      ALL_PLATFORM_BASE_OBJECT.shopee.uniqueName,
      ALL_PLATFORM_BASE_OBJECT._1688.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.taobao.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.tmall.uniqueName,
    ],
    availableFilterOption: {
      'allDurationDay': (allDurationDay) => {
        return allDurationDay
      },
      'hasDurationRange': (hasDurationRange) => {
        return true
      },
      'hasSmartSearch': (hasSmartSearch) => {
        return hasSmartSearch
      },
      'allCategories': (allCategories) => {
        return allCategories
      },
      'allCategoriesDetail': (allCategoriesDetail) => {
        return allCategoriesDetail
      },
      'allShopType': (allShopType) => {
        return allShopType
      },
      'allLocation': (allLocation) => {
        return allLocation
      },
      'allValueCompareFieldFilter': (allValueCompareFieldFilter) => {
        return allValueCompareFieldFilter
      },
      'allValueRangeFieldFilter': (allValueRangeFieldFilter) => {
        return allValueRangeFieldFilter
      },
      'allFixedOptionFilter': (allFixedOptionFilter) => {
        return allFixedOptionFilter
      }
    },
    availableDataIndexProductTable: [
      'product_name',
      'price',
      'order_count_custom',
      'review_count_custom',
      'order_revenue_custom',
      'category_name',
      'brand',
      'productLink',
      'productHistory',
    ],
    availableDataIndexShopTable: [
      "shop_platform_id",
      "shop_name",
      "shop_url",
      "product_total",
      "order_revenue_selected",
      "order_count_selected",
      // "order_count_7d",
      // "order_revenue_7d",
      // "order_count_30d",
      // "order_revenue_30d",
      // "order_count_90d",
      // "order_revenue_90d",
      // "order_count_180d",
      // "order_revenue_180d",
      // 'order_count_custom',
      // 'order_revenue_custom',
      // "view_count",
      "order_revenue",
      "order_count",
      'review_count_7d',
      'review_count_30d',
      'review_count_90d',
      'review_count_180d',
      'rating_count'
    ],
    availableDataIndexBrandsTable: [
    ],
    limitShopSubscribe: 10,
    allLimitLineProductDownload: [100, 200, 500, 1000],
    allLimitLineShopDownload: [10, 20, 50, 100],
    allTypeProductDownload: [DOWNLOAD_TYPE.excel, DOWNLOAD_TYPE.csv],
    allTypeShopDownload: [DOWNLOAD_TYPE.excel, DOWNLOAD_TYPE.csv],
    allLimitLineBrandDownload: [],
    allTypeBrandDownload: [DOWNLOAD_TYPE.excel, DOWNLOAD_TYPE.csv],
  },
  "market_business_small": {
    id: 'market_business_small',
    priority: 35,
    name: 'market_business_small',
    label: 'Tài khoản Doanh nghiệp (Small SME)',
    availablePlatform: [
      ALL_PLATFORM_BASE_OBJECT.shopee.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.sendo.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.tiki.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName,
      ALL_PLATFORM_BASE_OBJECT._1688.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.taobao.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.tmall.uniqueName,
    ],
    availableFilterOption: {
      'allDurationDay': (allDurationDay) => {
        return allDurationDay
      },
      'hasDurationRange': (hasDurationRange) => {
        return true
      },
      'hasSmartSearch': (hasSmartSearch) => {
        return hasSmartSearch
      },
      'allCategories': (allCategories) => {
        return allCategories
      },
      'allCategoriesDetail': (allCategoriesDetail) => {
        return allCategoriesDetail
      },
      'allShopType': (allShopType) => {
        return allShopType
      },
      'allLocation': (allLocation) => {
        return allLocation
      },
      'allValueCompareFieldFilter': (allValueCompareFieldFilter) => {
        return allValueCompareFieldFilter
      },
      'allValueRangeFieldFilter': (allValueRangeFieldFilter) => {
        return allValueRangeFieldFilter
      },
      'allFixedOptionFilter': (allFixedOptionFilter) => {
        return allFixedOptionFilter
      }
    },
    availableGeneralStatisticDetail: [
      ALL_GENERAL_STATISTIC[ALL_CHART_TAB.revenue.generalStatisticName].apiKeyDetailStatistic,
      ALL_GENERAL_STATISTIC[ALL_CHART_TAB.orderCount.generalStatisticName].apiKeyDetailStatistic
    ],
    availableDataIndexProductTable: [
      'product_name',
      'price',
      'order_count_custom',
      'review_count_custom',
      'order_revenue_custom',
      'category_name',
      'brand',
      'productLink',
      'productHistory',
    ],
    availableDataIndexProductGlobalTable: [
      'product_name',
      'price',
      'order_count_custom',
      'review_count_custom',
      'order_revenue_custom',
      'category_name',
      'brand',
      'action',
      'productLink',
      'productHistory',
    ],
    availableDataIndexShopTable: [
      "shop_platform_id",
      "shop_name",
      "shop_url",
      "product_total",
      "order_revenue_selected",
      "order_count_selected",
      // "order_count_7d",
      // "order_revenue_7d",
      // "order_count_30d",
      // "order_revenue_30d",
      // "order_count_90d",
      // "order_revenue_90d",
      // "order_count_180d",
      // "order_revenue_180d",
      // 'order_count_custom',
      // 'order_revenue_custom',
      // "view_count",
      "order_revenue",
      "order_count",
      'review_count_7d',
      'review_count_30d',
      'review_count_90d',
      'review_count_180d',
      'rating_count'
    ],
    availableDataIndexBrandsTable: [
      "brand",
      "product_total",
      "order_revenue_selected",
      "order_count_selected",
      // "order_count_7d",
      // "order_revenue_7d",
      // "order_count_30d",
      // "order_revenue_30d",
      // "order_count_90d",
      // "order_revenue_90d",
      // "order_count_180d",
      // "order_revenue_180d",
      // 'order_count_custom',
      // 'order_revenue_custom',
      "view_count",
      "order_revenue",
      "order_count",
      'review_count_7d',
      'review_count_30d',
      'review_count_90d',
      'review_count_180d',
      'rating_count'
    ],
    limitShopSubscribe: 15,
    allLimitLineProductDownload: [100, 200, 500, 1000],
    allLimitLineShopDownload: [20, 50, 100],
    allTypeProductDownload: [DOWNLOAD_TYPE.excel, DOWNLOAD_TYPE.csv],
    allTypeShopDownload: [DOWNLOAD_TYPE.excel, DOWNLOAD_TYPE.csv],
    allLimitLineBrandDownload: [],
    allTypeBrandDownload: [DOWNLOAD_TYPE.excel, DOWNLOAD_TYPE.csv],
  },
  "market_business": {
    id: 'market_business',
    priority: 40,
    name: 'market_business',
    label: 'Tài khoản Doanh nghiệp (SME)',
    availablePlatform: [
      ALL_PLATFORM_BASE_OBJECT.shopee.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.sendo.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.tiki.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName,
      ALL_PLATFORM_BASE_OBJECT._1688.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.taobao.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.tmall.uniqueName,
    ],
    availableFilterOption: {
      'allDurationDay': (allDurationDay) => {
        return allDurationDay
      },
      'hasDurationRange': (hasDurationRange) => {
        return true
      },
      'hasSmartSearch': (hasSmartSearch) => {
        return hasSmartSearch
      },
      'allCategories': (allCategories) => {
        return allCategories
      },
      'allCategoriesDetail': (allCategoriesDetail) => {
        return allCategoriesDetail
      },
      'allShopType': (allShopType) => {
        return allShopType
      },
      'allLocation': (allLocation) => {
        return allLocation
      },
      'allValueCompareFieldFilter': (allValueCompareFieldFilter) => {
        return allValueCompareFieldFilter
      },
      'allValueRangeFieldFilter': (allValueRangeFieldFilter) => {
        return allValueRangeFieldFilter
      },
      'allFixedOptionFilter': (allFixedOptionFilter) => {
        return allFixedOptionFilter
      }
    },
    availableGeneralStatisticDetail: [
      ALL_GENERAL_STATISTIC[ALL_CHART_TAB.revenue.generalStatisticName].apiKeyDetailStatistic,
      ALL_GENERAL_STATISTIC[ALL_CHART_TAB.orderCount.generalStatisticName].apiKeyDetailStatistic
    ],
    availableDataIndexProductTable: [
      'product_name',
      'price',
      'order_count_custom',
      'review_count_custom',
      'order_revenue_custom',
      'category_name',
      'brand',
      'action',
      'productLink',
      'productHistory',
    ],
    availableDataIndexProductGlobalTable: [
      'product_name',
      'price',
      'order_count_custom',
      'review_count_custom',
      'order_revenue_custom',
      'category_name',
      'brand',
      'action',
      'productLink',
      'productHistory',
    ],
    availableDataIndexShopTable: [
      "shop_platform_id",
      "shop_name",
      "shop_url",
      "product_total",
      "order_revenue_selected",
      "order_count_selected",
      // "order_count_7d",
      // "order_revenue_7d",
      // "order_count_30d",
      // "order_revenue_30d",
      // "order_count_90d",
      // "order_revenue_90d",
      // "order_count_180d",
      // "order_revenue_180d",
      // 'order_count_custom',
      // 'order_revenue_custom',
      'review_count_7d',
      'review_count_30d',
      'review_count_90d',
      'review_count_180d',
      'rating_count',
      // "view_count",
      "order_revenue",
      "order_count",
    ],
    availableDataIndexBrandsTable: [
      "brand",
      "product_total",
      "order_revenue_selected",
      "order_count_selected",
      // "order_count_7d",
      // "order_revenue_7d",
      // "order_count_30d",
      // "order_revenue_30d",
      // "order_count_90d",
      // "order_revenue_90d",
      // "order_count_180d",
      // "order_revenue_180d",
      // 'order_count_custom',
      // 'order_revenue_custom',
      "view_count",
      "order_revenue",
      "order_count",
      'review_count_7d',
      'review_count_30d',
      'review_count_90d',
      'review_count_180d',
      'rating_count'
    ],
    limitShopSubscribe: 20,
    limitSavedReport: 10,
    allLimitLineProductDownload: [500, 1000, 2000, 5000],
    allLimitLineShopDownload: [50, 100, 200, 500],
    allTypeProductDownload: [DOWNLOAD_TYPE.excel, DOWNLOAD_TYPE.csv],
    allTypeShopDownload: [DOWNLOAD_TYPE.excel, DOWNLOAD_TYPE.csv],
    allLimitLineBrandDownload: [200],
    allTypeBrandDownload: [DOWNLOAD_TYPE.excel, DOWNLOAD_TYPE.csv],
  },
  "market_business_agency": {
    id: 'market_business_agency',
    priority: 50,
    name: 'market_business_agency',
    label: 'Tài khoản Doanh nghiệp (Agency)',
    availablePlatform: [
      ALL_PLATFORM_BASE_OBJECT.all_vietnam.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.shopee.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.sendo.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.tiki.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName,
      ALL_PLATFORM_BASE_OBJECT._1688.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.taobao.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.tmall.uniqueName,
    ],
    availableFilterOption: {
      'allDurationDay': (allDurationDay) => {
        return allDurationDay
      },
      'hasDurationRange': (hasDurationRange) => {
        return true
      },
      'hasSmartSearch': (hasSmartSearch) => {
        return hasSmartSearch
      },
      'allCategories': (allCategories) => {
        return allCategories
      },
      'allCategoriesDetail': (allCategoriesDetail) => {
        return allCategoriesDetail
      },
      'allShopType': (allShopType) => {
        return allShopType
      },
      'allLocation': (allLocation) => {
        return allLocation
      },
      'allValueCompareFieldFilter': (allValueCompareFieldFilter) => {
        return allValueCompareFieldFilter
      },
      'allValueRangeFieldFilter': (allValueRangeFieldFilter) => {
        return allValueRangeFieldFilter
      },
      'allFixedOptionFilter': (allFixedOptionFilter) => {
        return allFixedOptionFilter
      }
    },
    availableGeneralStatisticDetail: [
      ALL_GENERAL_STATISTIC[ALL_CHART_TAB.revenue.generalStatisticName].apiKeyDetailStatistic,
      ALL_GENERAL_STATISTIC[ALL_CHART_TAB.orderCount.generalStatisticName].apiKeyDetailStatistic
    ],
    availableDataIndexProductTable: [
      'product_name',
      'price',
      'order_count_custom',
      'review_count_custom',
      'order_revenue_custom',
      'category_name',
      'brand',
      'action',
      'productLink',
      'productHistory',
    ],
    availableDataIndexProductGlobalTable: [
      'product_name',
      'price',
      'order_count_custom',
      'review_count_custom',
      'order_revenue_custom',
      'category_name',
      'brand',
      'action',
      'productLink',
      'productHistory',
    ],
    availableDataIndexShopTable: [
      "shop_platform_id",
      "shop_name",
      "shop_url",
      "product_total",
      "order_revenue_selected",
      "order_count_selected",
      // "order_count_7d",
      // "order_revenue_7d",
      // "order_count_30d",
      // "order_revenue_30d",
      // "order_count_90d",
      // "order_revenue_90d",
      // "order_count_180d",
      // "order_revenue_180d",
      // 'order_count_custom',
      // 'order_revenue_custom',
      'review_count_7d',
      'review_count_30d',
      'review_count_90d',
      'review_count_180d',
      'rating_count',
      // "view_count",
      "order_revenue",
      "order_count",
    ],
    availableDataIndexBrandsTable: [
      "brand",
      "product_total",
      "order_revenue_selected",
      "order_count_selected",
      // "order_count_7d",
      // "order_revenue_7d",
      // "order_count_30d",
      // "order_revenue_30d",
      // "order_count_90d",
      // "order_revenue_90d",
      // "order_count_180d",
      // "order_revenue_180d",
      // 'order_count_custom',
      // 'order_revenue_custom',
      "view_count",
      "order_revenue",
      "order_count",
      'review_count_7d',
      'review_count_30d',
      'review_count_90d',
      'review_count_180d',
      'rating_count'
    ],
    limitShopSubscribe: 50,
    limitSavedReport: 10,
    allLimitLineProductDownload: [500, 1000, 2000, 5000, 10000],
    allLimitLineShopDownload: [100, 200, 500, 1000],
    allTypeProductDownload: [DOWNLOAD_TYPE.excel, DOWNLOAD_TYPE.csv],
    allTypeShopDownload: [DOWNLOAD_TYPE.excel, DOWNLOAD_TYPE.csv],
    allLimitLineBrandDownload: [200],
    allTypeBrandDownload: [DOWNLOAD_TYPE.excel, DOWNLOAD_TYPE.csv],
  },
  "market_business_big_agency": {
    id: 'market_business_big_agency',
    priority: 60,
    name: 'market_business_big_agency',
    label: 'Tài khoản Doanh nghiệp (Big Agency)',
    availablePlatform: [
      ALL_PLATFORM_BASE_OBJECT.all_vietnam.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.shopee.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.sendo.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.tiki.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName,
      ALL_PLATFORM_BASE_OBJECT._1688.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.taobao.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.tmall.uniqueName,
    ],
    availableFilterOption: {
      'allDurationDay': (allDurationDay) => {
        return allDurationDay
      },
      'hasDurationRange': (hasDurationRange) => {
        return true
      },
      'hasSmartSearch': (hasSmartSearch) => {
        return hasSmartSearch
      },
      'allCategories': (allCategories) => {
        return allCategories
      },
      'allCategoriesDetail': (allCategoriesDetail) => {
        return allCategoriesDetail
      },
      'allShopType': (allShopType) => {
        return allShopType
      },
      'allLocation': (allLocation) => {
        return allLocation
      },
      'allValueCompareFieldFilter': (allValueCompareFieldFilter) => {
        return allValueCompareFieldFilter
      },
      'allValueRangeFieldFilter': (allValueRangeFieldFilter) => {
        return allValueRangeFieldFilter
      },
      'allFixedOptionFilter': (allFixedOptionFilter) => {
        return allFixedOptionFilter
      }
    },
    availableGeneralStatisticDetail: [
      ALL_GENERAL_STATISTIC[ALL_CHART_TAB.revenue.generalStatisticName].apiKeyDetailStatistic,
      ALL_GENERAL_STATISTIC[ALL_CHART_TAB.orderCount.generalStatisticName].apiKeyDetailStatistic
    ],
    availableDataIndexProductTable: [
      'product_name',
      'price',
      'order_count_custom',
      'review_count_custom',
      'order_revenue_custom',
      'category_name',
      'brand',
      'action',
      'productLink',
      'productHistory',
    ],
    availableDataIndexProductGlobalTable: [
      'product_name',
      'price',
      'order_count_custom',
      'review_count_custom',
      'order_revenue_custom',
      'category_name',
      'brand',
      'action',
      'productLink',
      'productHistory',
    ],
    availableDataIndexShopTable: [
      "shop_platform_id",
      "shop_name",
      "shop_url",
      "product_total",
      "order_revenue_selected",
      "order_count_selected",
      // "order_count_7d",
      // "order_revenue_7d",
      // "order_count_30d",
      // "order_revenue_30d",
      // "order_count_90d",
      // "order_revenue_90d",
      // "order_count_180d",
      // "order_revenue_180d",
      // 'order_count_custom',
      // 'order_revenue_custom',
      'review_count_7d',
      'review_count_30d',
      'review_count_90d',
      'review_count_180d',
      'rating_count',
      // "view_count",
      "order_revenue",
      "order_count",
    ],
    availableDataIndexBrandsTable: [
      "brand",
      "product_total",
      "order_revenue_selected",
      "order_count_selected",
      // "order_count_7d",
      // "order_revenue_7d",
      // "order_count_30d",
      // "order_revenue_30d",
      // "order_count_90d",
      // "order_revenue_90d",
      // "order_count_180d",
      // "order_revenue_180d",
      // 'order_count_custom',
      // 'order_revenue_custom',
      "view_count",
      "order_revenue",
      "order_count",
      'review_count_7d',
      'review_count_30d',
      'review_count_90d',
      'review_count_180d',
      'rating_count'
    ],
    limitShopSubscribe: 100,
    limitSavedReport: 10,
    allLimitLineProductDownload: [500, 1000, 2000, 5000, 10000, 20000],
    allLimitLineShopDownload: [100, 200, 500, 1000, 2000],
    allTypeProductDownload: [DOWNLOAD_TYPE.excel, DOWNLOAD_TYPE.csv],
    allTypeShopDownload: [DOWNLOAD_TYPE.excel, DOWNLOAD_TYPE.csv],
    allLimitLineBrandDownload: [200],
    allTypeBrandDownload: [DOWNLOAD_TYPE.excel, DOWNLOAD_TYPE.csv],
  },
  "market_staff": {
    id: 'market_staff',
    priority: 61,
    name: 'market_staff',
    label: 'Tài khoản nhân viên Market',
    availablePlatform: [
      ALL_PLATFORM_BASE_OBJECT.all_vietnam.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.shopee.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.sendo.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.tiki.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName,
      ALL_PLATFORM_BASE_OBJECT._1688.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.taobao.uniqueName,
      ALL_PLATFORM_BASE_OBJECT.tmall.uniqueName,
    ],
    availableFilterOption: {
      'allDurationDay': (allDurationDay) => {
        return allDurationDay
      },
      'hasDurationRange': (hasDurationRange) => {
        return true
      },
      'hasSmartSearch': (hasSmartSearch) => {
        return hasSmartSearch
      },
      'allCategories': (allCategories) => {
        return allCategories
      },
      'allCategoriesDetail': (allCategoriesDetail) => {
        return allCategoriesDetail
      },
      'allShopType': (allShopType) => {
        return allShopType
      },
      'allLocation': (allLocation) => {
        return allLocation
      },
      'allValueCompareFieldFilter': (allValueCompareFieldFilter) => {
        return allValueCompareFieldFilter
      },
      'allValueRangeFieldFilter': (allValueRangeFieldFilter) => {
        return allValueRangeFieldFilter
      },
      'allFixedOptionFilter': (allFixedOptionFilter) => {
        return allFixedOptionFilter
      }
    },
    availableGeneralStatisticDetail: [
      ALL_GENERAL_STATISTIC[ALL_CHART_TAB.revenue.generalStatisticName].apiKeyDetailStatistic,
      ALL_GENERAL_STATISTIC[ALL_CHART_TAB.orderCount.generalStatisticName].apiKeyDetailStatistic
    ],
    availableDataIndexProductTable: [
      'product_name',
      'price',
      'order_count_custom',
      'review_count_custom',
      'order_revenue_custom',
      'category_name',
      'brand',
      'action',
      'productLink',
      'productHistory',
    ],
    availableDataIndexProductGlobalTable: [
      'product_name',
      'price',
      'order_count_custom',
      'review_count_custom',
      'order_revenue_custom',
      'category_name',
      'brand',
      'action',
      'productLink',
      'productHistory',
    ],
    availableDataIndexShopTable: [
      "shop_platform_id",
      "shop_name",
      "shop_url",
      "product_total",
      "order_revenue_selected",
      "order_count_selected",
      // "order_count_7d",
      // "order_revenue_7d",
      // "order_count_30d",
      // "order_revenue_30d",
      // "order_count_90d",
      // "order_revenue_90d",
      // "order_count_180d",
      // "order_revenue_180d",
      // 'order_count_custom',
      // 'order_revenue_custom',
      'review_count_7d',
      'review_count_30d',
      'review_count_90d',
      'review_count_180d',
      'rating_count',
      // "view_count",
      "order_revenue",
      "order_count",
    ],
    availableDataIndexBrandsTable: [
      "brand",
      "product_total",
      "order_revenue_selected",
      "order_count_selected",
      // "order_count_7d",
      // "order_revenue_7d",
      // "order_count_30d",
      // "order_revenue_30d",
      // "order_count_90d",
      // "order_revenue_90d",
      // "order_count_180d",
      // "order_revenue_180d",
      // 'order_count_custom',
      // 'order_revenue_custom',
      "view_count",
      "order_revenue",
      "order_count",
      'review_count_7d',
      'review_count_30d',
      'review_count_90d',
      'review_count_180d',
      'rating_count'
    ],
    limitShopSubscribe: 100000000,
    limitSavedReport: 100,
    allLimitLineProductDownload: [500, 1000, 2000, 5000, 10000, 20000],
    allLimitLineShopDownload: [100, 200, 500, 1000, 2000],
    allTypeProductDownload: [DOWNLOAD_TYPE.excel, DOWNLOAD_TYPE.csv],
    allTypeShopDownload: [DOWNLOAD_TYPE.excel, DOWNLOAD_TYPE.csv],
    allLimitLineBrandDownload: [200],
    allTypeBrandDownload: [DOWNLOAD_TYPE.excel, DOWNLOAD_TYPE.csv],
  },
}

PERMISSION['marketv2_small'] = JSON.parse(JSON.stringify(PERMISSION.market_advance))
PERMISSION.marketv2_small.availablePlatform = [
  ALL_PLATFORM_BASE_OBJECT.shopee.uniqueName,
  ALL_PLATFORM_BASE_OBJECT._1688.uniqueName,
]
PERMISSION.marketv2_small.id =  'marketv2_small'
PERMISSION.marketv2_small.priority =  PERMISSION.market_advance.priority + 1
PERMISSION.marketv2_small.name =  'marketv2_small'
PERMISSION.marketv2_small.label =  'Gói Small'
PERMISSION.marketv2_small.limitSavedReport = null
PERMISSION.marketv2_small.limitShopSubscribe = 15
PERMISSION.marketv2_small.allLimitLineProductDownload = [100, 200, 500]
PERMISSION.marketv2_small.allLimitLineShopDownload = [20, 50, 100]
PERMISSION.marketv2_small.allLimitLineBrandDownload = [10, 20, 50]
PERMISSION.marketv2_small.availableDataIndexBrandsTable = [
  "brand",
  "product_total",
  "order_revenue_selected",
  "order_count_selected",
  // "order_count_7d",
  // "order_revenue_7d",
  // "order_count_30d",
  // "order_revenue_30d",
  // "order_count_90d",
  // "order_revenue_90d",
  // "order_count_180d",
  // "order_revenue_180d",
  // 'order_count_custom',
  // 'order_revenue_custom',
  "view_count",
  "order_revenue",
  "order_count",
  'review_count_7d',
  'review_count_30d',
  'review_count_90d',
  'review_count_180d',
  'rating_count'
]

PERMISSION['marketv2_small_a'] = JSON.parse(JSON.stringify(PERMISSION.marketv2_small))
PERMISSION.marketv2_small_a.id =  'marketv2_small_a'
PERMISSION.marketv2_small_a.name =  'marketv2_small_a'
PERMISSION.marketv2_small_a.label =  'Gói Small (Mã marketv2_small_a)'
PERMISSION['marketv2_small_c'] = JSON.parse(JSON.stringify(PERMISSION.marketv2_small))
PERMISSION.marketv2_small_c.id =  'marketv2_small_c'
PERMISSION.marketv2_small_c.name =  'marketv2_small_c'
PERMISSION.marketv2_small_c.label =  'Gói Small (Mã marketv2_small_c)'
PERMISSION['marketv2_small_g'] = JSON.parse(JSON.stringify(PERMISSION.marketv2_small))
PERMISSION.marketv2_small_g.id =  'marketv2_small_g'
PERMISSION.marketv2_small_g.name =  'marketv2_small_g'
PERMISSION.marketv2_small_g.label =  'Gói Small (Mã marketv2_small_g)'
PERMISSION['marketv2_small_l'] = JSON.parse(JSON.stringify(PERMISSION.marketv2_small))
PERMISSION.marketv2_small_l.id =  'marketv2_small_l'
PERMISSION.marketv2_small_l.name =  'marketv2_small_l'
PERMISSION.marketv2_small_l.label =  'Gói Small (Mã marketv2_small_l)'
PERMISSION.marketv2_small_l.availablePlatform = [
  ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName,
  ALL_PLATFORM_BASE_OBJECT._1688.uniqueName,
]
PERMISSION.marketv2_small_l.defaultPlatform =  ALL_PLATFORM_BASE_OBJECT.lazada
PERMISSION.marketv2_small_l.defaultPlatformGlobal =  ALL_PLATFORM_BASE_OBJECT._1688
PERMISSION['marketv2_small_t'] = JSON.parse(JSON.stringify(PERMISSION.marketv2_small))
PERMISSION.marketv2_small_t.id =  'marketv2_small_t'
PERMISSION.marketv2_small_t.name =  'marketv2_small_t'
PERMISSION.marketv2_small_t.label =  'Gói Small (Mã marketv2_small_t)'
PERMISSION.marketv2_small_t.availablePlatform = [
  ALL_PLATFORM_BASE_OBJECT.tiki.uniqueName,
  ALL_PLATFORM_BASE_OBJECT._1688.uniqueName,
]
PERMISSION.marketv2_small_t.defaultPlatform =  ALL_PLATFORM_BASE_OBJECT.tiki
PERMISSION.marketv2_small_t.defaultPlatformGlobal =  ALL_PLATFORM_BASE_OBJECT._1688

PERMISSION['marketv2_standard'] = JSON.parse(JSON.stringify(PERMISSION.market_business_small))
PERMISSION.marketv2_standard.id =  'marketv2_standard'
PERMISSION.marketv2_standard.priority =  PERMISSION.market_business_small.priority + 1
PERMISSION.marketv2_standard.name =  'marketv2_standard'
PERMISSION.marketv2_standard.label =  'Gói Standard'
PERMISSION.marketv2_standard.limitShopSubscribe = 15
PERMISSION.marketv2_standard.limitSavedReport = null
PERMISSION.marketv2_standard.allLimitLineProductDownload = [100, 200, 500, 1000]
PERMISSION.marketv2_standard.allLimitLineShopDownload = [20, 50, 100]
PERMISSION.marketv2_standard.allLimitLineBrandDownload = [10, 20, 50]

PERMISSION['marketv2_standard_a'] = JSON.parse(JSON.stringify(PERMISSION.marketv2_standard))
PERMISSION.marketv2_standard_a.id =  'marketv2_standard_a'
PERMISSION.marketv2_standard_a.name =  'marketv2_standard_a'
PERMISSION.marketv2_standard_a.label =  'Gói Standard (Mã marketv2_standard_a)'
PERMISSION.marketv2_standard_b = JSON.parse(JSON.stringify(PERMISSION.marketv2_standard))
PERMISSION.marketv2_standard_b.id =  'marketv2_standard_b'
PERMISSION.marketv2_standard_b.name =  'marketv2_standard_b'
PERMISSION.marketv2_standard_b.label =  'Gói Standard (Mã marketv2_standard_b)'
PERMISSION.marketv2_standard_b.limitSavedReport = 10
PERMISSION['marketv2_standard_c'] = JSON.parse(JSON.stringify(PERMISSION.marketv2_standard))
PERMISSION.marketv2_standard_c.id =  'marketv2_standard_c'
PERMISSION.marketv2_standard_c.name =  'marketv2_standard_c'
PERMISSION.marketv2_standard_c.label =  'Gói Standard (Mã marketv2_standard_c)'
PERMISSION['marketv2_standard_g'] = JSON.parse(JSON.stringify(PERMISSION.marketv2_standard))
PERMISSION.marketv2_standard_g.id =  'marketv2_standard_g'
PERMISSION.marketv2_standard_g.name =  'marketv2_standard_g'
PERMISSION.marketv2_standard_g.label =  'Gói Standard (Mã marketv2_standard_g)'

PERMISSION['marketv2_advanced'] = JSON.parse(JSON.stringify(PERMISSION.market_business))
PERMISSION.marketv2_advanced.id =  'marketv2_advanced'
PERMISSION.marketv2_advanced.priority =  PERMISSION.market_business.priority + 1
PERMISSION.marketv2_advanced.name =  'marketv2_advanced'
PERMISSION.marketv2_advanced.label =  'Gói Advanced'
PERMISSION.marketv2_advanced.availablePlatform = [
  ALL_PLATFORM_BASE_OBJECT.all_vietnam.uniqueName,
  ALL_PLATFORM_BASE_OBJECT.shopee.uniqueName,
  ALL_PLATFORM_BASE_OBJECT.sendo.uniqueName,
  ALL_PLATFORM_BASE_OBJECT.tiki.uniqueName,
  ALL_PLATFORM_BASE_OBJECT.lazada.uniqueName,
  ALL_PLATFORM_BASE_OBJECT._1688.uniqueName,
  ALL_PLATFORM_BASE_OBJECT.taobao.uniqueName,
  ALL_PLATFORM_BASE_OBJECT.tmall.uniqueName,
]
PERMISSION.marketv2_advanced.limitShopSubscribe = 20
PERMISSION.marketv2_advanced.limitSavedReport = null
PERMISSION.marketv2_advanced.allLimitLineProductDownload = [500, 1000, 2000, 5000]
PERMISSION.marketv2_advanced.allLimitLineShopDownload = [20, 50, 100, 200]
PERMISSION.marketv2_advanced.allLimitLineBrandDownload = [10, 20, 50, 100]

PERMISSION['marketv2_advanced_b'] = JSON.parse(JSON.stringify(PERMISSION.marketv2_advanced))
PERMISSION.marketv2_advanced_b.id =  'marketv2_advanced_b'
PERMISSION.marketv2_advanced_b.name =  'marketv2_advanced_b'
PERMISSION.marketv2_advanced_b.label =  'Gói Advanced (Mã marketv2_advanced_b)'
PERMISSION.marketv2_advanced_b.limitSavedReport = 10
PERMISSION['marketv2_advanced_bg'] = JSON.parse(JSON.stringify(PERMISSION.marketv2_advanced))
PERMISSION.marketv2_advanced_bg.id =  'marketv2_advanced_bg'
PERMISSION.marketv2_advanced_bg.name =  'marketv2_advanced_bg'
PERMISSION.marketv2_advanced_bg.label =  'Gói Advanced (Mã marketv2_advanced_bg)'
PERMISSION.marketv2_advanced_bg.limitSavedReport = 10
PERMISSION['marketv2_advanced_d'] = JSON.parse(JSON.stringify(PERMISSION.marketv2_advanced))
PERMISSION.marketv2_advanced_d.id =  'marketv2_advanced_d'
PERMISSION.marketv2_advanced_d.name =  'marketv2_advanced_d'
PERMISSION.marketv2_advanced_d.label =  'Gói Advanced (Mã marketv2_advanced_d)'
PERMISSION['marketv2_advanced_g'] = JSON.parse(JSON.stringify(PERMISSION.marketv2_advanced))
PERMISSION.marketv2_advanced_g.id =  'marketv2_advanced_g'
PERMISSION.marketv2_advanced_g.name =  'marketv2_advanced_g'
PERMISSION.marketv2_advanced_g.label =  'Gói Advanced (Mã marketv2_advanced_g)'

PERMISSION['marketv2_premium'] = JSON.parse(JSON.stringify(PERMISSION.market_business_agency))
PERMISSION.marketv2_premium.id =  'marketv2_premium'
PERMISSION.marketv2_premium.priority =  PERMISSION.market_business_agency.priority + 1
PERMISSION.marketv2_premium.name =  'marketv2_premium'
PERMISSION.marketv2_premium.label =  'Gói Premium'
PERMISSION.marketv2_premium.limitShopSubscribe = 30
PERMISSION.marketv2_premium.allLimitLineProductDownload = [500, 1000, 2000, 5000, 10000]
PERMISSION.marketv2_premium.allLimitLineShopDownload = [50, 100, 200, 500]
PERMISSION.marketv2_premium.allLimitLineBrandDownload = [20, 50, 100, 200]

PERMISSION['marketv2_premium_e'] = JSON.parse(JSON.stringify(PERMISSION.marketv2_premium))
PERMISSION.marketv2_premium_e.id =  'marketv2_premium_e'
PERMISSION.marketv2_premium_e.name =  'marketv2_premium_e'
PERMISSION.marketv2_premium_e.label =  'Gói Premium (Mã marketv2_premium_e)'
PERMISSION['marketv2_premium_f'] = JSON.parse(JSON.stringify(PERMISSION.marketv2_premium))
PERMISSION.marketv2_premium_f.id =  'marketv2_premium_f'
PERMISSION.marketv2_premium_f.name =  'marketv2_premium_f'
PERMISSION.marketv2_premium_f.label =  'Gói Premium (Mã marketv2_premium_f)'

PERMISSION['marketv2_enterprise'] = JSON.parse(JSON.stringify(PERMISSION.market_business_big_agency))
PERMISSION.marketv2_enterprise.id =  'marketv2_enterprise'
PERMISSION.marketv2_enterprise.priority =  PERMISSION.market_business_big_agency.priority + 1
PERMISSION.marketv2_enterprise.name =  'marketv2_enterprise'
PERMISSION.marketv2_enterprise.label =  'Gói Enterprise'
PERMISSION.marketv2_enterprise.limitShopSubscribe = 30
PERMISSION.marketv2_enterprise.allLimitLineProductDownload = [500, 1000, 2000, 5000, 10000]
PERMISSION.marketv2_enterprise.allLimitLineShopDownload = [50, 100, 200, 500]
PERMISSION.marketv2_enterprise.allLimitLineBrandDownload = [20, 50, 100, 200]

export {
  PERMISSION,
  DOWNLOAD_TYPE
}
