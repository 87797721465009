const isMobile = () => {
  if (navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true
  } else {
    return false
  }
}

const detectBrowserOnDesktop = () => {

  let isFirefox = typeof InstallTrigger !== 'undefined'

  let isIE = /*@cc_on!@*/false || !!document.documentMode

  let isEdge = !isIE && !!window.StyleMedia

  let isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)

  return { isIE, isFirefox, isEdge, isChrome }
}

const detectDeviceOnMobile = () => {

  let isAndroid = /Android/i.test(navigator.userAgent) || false
  let isiOS = /iPhone|iPad|iPod/i.test(navigator.userAgent) || false
  return { isAndroid, isiOS }
}

const addMaximumScaleToMetaViewport = () => {
  const el = document.querySelector('meta[name=viewport]');

  if (el !== null) {
    let content = el.getAttribute('content');
    let re = /maximum\-scale=[0-9\.]+/g;

    if (re.test(content)) {
      content = content.replace(re, 'maximum-scale=1.0');
    } else {
      content = [content, 'maximum-scale=1.0'].join(', ')
    }

    el.setAttribute('content', content);
  }
};

const disableIOSTextFieldZoom = () => {
  if (detectDeviceOnMobile().isiOS) {
    addMaximumScaleToMetaViewport();
  }
};

export {
  isMobile,
  detectBrowserOnDesktop,
  detectDeviceOnMobile,
  disableIOSTextFieldZoom
}
