const websiteUrl = 'https://www.beecost.com'
const websiteName = 'BeeCost'
const websiteDomain = 'beecost.com'
const websitePrefix = '/'
// const websitePrefixRoute = '/market/'
const websitePrefixRoute = '/'

export {
  websitePrefixRoute,
  websitePrefix,
  websiteName,
  websiteDomain,
  websiteUrl
}
