import {ALL_CHART_TAB, ALL_GENERAL_STATISTIC, ALL_METRIC} from "@/constant/general/GeneralConstant";
import {MutationSearchStatisticComparisonResult} from "@/store/modules/SearchStatisticComparisonResultModule/mutation";

const initialState = () => {
  let aggState = {}
  for (const chartTabKey in ALL_CHART_TAB) {
    let chartTabStoreName = ALL_CHART_TAB[chartTabKey].storeName
    for (const metricKey in ALL_METRIC) {
      let metricStoreName = ALL_METRIC[metricKey].storeName
      aggState[MutationSearchStatisticComparisonResult[chartTabStoreName][metricStoreName]] = null
    }
  }
  // for (const generalStatisticKey in ALL_GENERAL_STATISTIC) {
  //   let generalStatisticStoreName =  ALL_GENERAL_STATISTIC[generalStatisticKey].storeName
  //   aggState[generalStatisticStoreName] = null
  // }
  return {
    status: false,
    textComparison: '',
    orderTotal: null,
    reviewTotal: null,
    revenueTotal: null,
    productTotal: null,
    shopTotal: null,
    commissionTotal: null,
    aggRevenueByCategories: null,
    aggRevenueByPriceRange: null,
    aggRevenueByBrands: null,
    aggRevenueByLocations: null,
    aggOrderCountByCategories: null,
    aggOrderCountByPriceRange: null,
    aggOrderCountByBrands: null,
    aggOrderCountByLocations: null,
    aggCommissionByCategories: null,
    aggCommissionByPriceRange: null,
    aggCommissionByBrands: null,
    aggCommissionByLocations: null,
    ...aggState
  }
}

let gettersAgg = {}
let mutationsAgg = {}
let actionsAgg = {}
for (const chartTabKey in ALL_CHART_TAB) {
  let chartTabStoreName = ALL_CHART_TAB[chartTabKey].storeName
  for (const metricKey in ALL_METRIC) {
    let metricStoreName = ALL_METRIC[metricKey].storeName
    gettersAgg[MutationSearchStatisticComparisonResult[chartTabStoreName][metricStoreName]] = state => state[MutationSearchStatisticComparisonResult[chartTabStoreName][metricStoreName]]
    mutationsAgg[MutationSearchStatisticComparisonResult[chartTabStoreName][metricStoreName]] = (state, value) => {state[MutationSearchStatisticComparisonResult[chartTabStoreName][metricStoreName]] = value}
    actionsAgg[MutationSearchStatisticComparisonResult[chartTabStoreName][metricStoreName]] = (context, data) => {context.commit(MutationSearchStatisticComparisonResult[chartTabStoreName][metricStoreName], data)}
  }
}

for (const generalStatisticKey in ALL_GENERAL_STATISTIC) {
  let generalStatisticStoreName =  ALL_GENERAL_STATISTIC[generalStatisticKey].storeName
  gettersAgg[generalStatisticStoreName] = state => state[MutationSearchStatisticComparisonResult[generalStatisticStoreName]]
  mutationsAgg[MutationSearchStatisticComparisonResult[generalStatisticStoreName]] = (state, value) => {state[MutationSearchStatisticComparisonResult[generalStatisticStoreName]] = value}
  actionsAgg[MutationSearchStatisticComparisonResult[generalStatisticStoreName]] = (context, data) => {context.commit(MutationSearchStatisticComparisonResult[generalStatisticStoreName], data)}
}

export default {
  namespaced: true,
  state: initialState,
  getters: {
    status: state => state.status,
    textComparison: state => state.textComparison,
    // orderTotal: state => state.orderTotal,
    // revenueTotal: state => state.revenueTotal,
    // productTotal: state => state.productTotal,
    // shopTotal: state => state.shopTotal,
    // commissionTotal: state => state.commissionTotal,
    aggRevenueByCategories: state => state.aggRevenueByCategories,
    aggRevenueByPriceRange: state => state.aggRevenueByPriceRange,
    aggRevenueByBrands: state => state.aggRevenueByBrands,
    aggRevenueByLocations: state => state.aggRevenueByLocations,
    aggOrderCountByCategories: state => state.aggOrderCountByCategories,
    aggOrderCountByPriceRange: state => state.aggOrderCountByPriceRange,
    aggOrderCountByBrands: state => state.aggOrderCountByBrands,
    aggOrderCountByLocations: state => state.aggOrderCountByLocations,
    aggCommissionByCategories: state => state.aggCommissionByCategories,
    aggCommissionByPriceRange: state => state.aggCommissionByPriceRange,
    aggCommissionByBrands: state => state.aggCommissionByBrands,
    aggCommissionByLocations: state => state.aggCommissionByLocations,
    ...gettersAgg
  },
  mutations: {
    [MutationSearchStatisticComparisonResult.status](state, value) {
      state.status = value
    },
    [MutationSearchStatisticComparisonResult.setTextComparison](state, value) {
      state.textComparison = value
    },
    [MutationSearchStatisticComparisonResult.setOrderTotal](state, value) {
      state.orderTotal = value
    },
    [MutationSearchStatisticComparisonResult.setRevenueTotal](state, value) {
      state.revenueTotal = value
    },
    [MutationSearchStatisticComparisonResult.setProductTotal](state, value) {
      state.productTotal = value
    },
    [MutationSearchStatisticComparisonResult.setShopTotal](state, value) {
      state.shopTotal = value
    },
    [MutationSearchStatisticComparisonResult.setCommissionTotal](state, value) {
      state.commissionTotal = value
    },

    [MutationSearchStatisticComparisonResult.setAggRevenueByCategories](state, value) {
      state.aggRevenueByCategories = value
    },
    [MutationSearchStatisticComparisonResult.setAggRevenueByPriceRange](state, value) {
      state.aggRevenueByPriceRange = value
    },
    [MutationSearchStatisticComparisonResult.setAggRevenueByBrands](state, value) {
      state.aggRevenueByBrands = value
    },
    [MutationSearchStatisticComparisonResult.setAggRevenueByLocations](state, value) {
      state.aggRevenueByLocations = value
    },

    [MutationSearchStatisticComparisonResult.setAggOrderCountByCategories](state, value) {
      state.aggOrderCountByCategories = value
    },
    [MutationSearchStatisticComparisonResult.setAggOrderCountByPriceRange](state, value) {
      state.aggOrderCountByPriceRange = value
    },
    [MutationSearchStatisticComparisonResult.setAggOrderCountByBrands](state, value) {
      state.aggOrderCountByBrands = value
    },
    [MutationSearchStatisticComparisonResult.setAggOrderCountByLocations](state, value) {
      state.aggOrderCountByLocations = value
    },

    [MutationSearchStatisticComparisonResult.setAggCommissionByCategories](state, value) {
      state.aggCommissionByCategories = value
    },
    [MutationSearchStatisticComparisonResult.setAggCommissionByPriceRange](state, value) {
      state.aggCommissionByPriceRange = value
    },
    [MutationSearchStatisticComparisonResult.setAggCommissionByBrands](state, value) {
      state.aggCommissionByBrands = value
    },
    [MutationSearchStatisticComparisonResult.setAggCommissionByLocations](state, value) {
      state.aggCommissionByLocations = value
    },
    [MutationSearchStatisticComparisonResult.aggOrderCount](state, value) {
      state.aggOrderCount = value
    },
    [MutationSearchStatisticComparisonResult.aggRevenue](state, value) {
      state.aggRevenue = value
    },
    [MutationSearchStatisticComparisonResult.aggReviewCount](state, value) {
      state.aggReviewCount = value
    },
    [MutationSearchStatisticComparisonResult.aggCommission](state, value) {
      state.aggCommission = value
    },
    ...mutationsAgg,
    [MutationSearchStatisticComparisonResult.RESET](state) {
      const s = initialState()
      Object.keys(s)
        .forEach(key => {
          state[key] = s[key]
        })
    }
  },
  actions: {
    [MutationSearchStatisticComparisonResult.status](context, data) {
      context.commit(MutationSearchStatisticComparisonResult.status, data)
    },
    [MutationSearchStatisticComparisonResult.setTextComparison](context, data) {
      context.commit(MutationSearchStatisticComparisonResult.setTextComparison, data)
    },
    [MutationSearchStatisticComparisonResult.aggOrderCount](context, data) {
      context.commit(MutationSearchStatisticComparisonResult.aggOrderCount, data)
    },
    [MutationSearchStatisticComparisonResult.aggRevenue](context, data) {
      context.commit(MutationSearchStatisticComparisonResult.aggRevenue, data)
    },
    [MutationSearchStatisticComparisonResult.aggReviewCount](context, data) {
      context.commit(MutationSearchStatisticComparisonResult.aggReviewCount, data)
    },
    [MutationSearchStatisticComparisonResult.aggCommission](context, data) {
      context.commit(MutationSearchStatisticComparisonResult.aggCommission, data)
    },
    [MutationSearchStatisticComparisonResult.RESET](context) {
      context.commit(MutationSearchStatisticComparisonResult.RESET)
    }
  }
}
