const LocalStorageKeys = {
  ACCESS_TOKEN: 'access_token',
  USER_PROFILE: 'user_profile',
  FULL_NAME: 'full_name',
  EXPIRATION_DATE: 'expiration_date',
  VOUCHER_INFO: 'voucher_info',
  LIST_VOUCHER_WAITING: 'list_voucher_waiting',
}

export {
  LocalStorageKeys
}
