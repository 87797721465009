const getUrlCurrent = () => {
  let urlCurrent = normalizeUrl(window.location.href)
  return urlCurrent
}

const extractDomain = (url) => {
  if (url == null) {
    return null
  }
  let hostname

  // find & remove protocol (http, ftp, etc.) and get hostname

  if (url.indexOf('//') > -1) {
    hostname = url.split('/')[2]
  } else {
    hostname = url.split('/')[0]
  }

  // find & remove port number
  hostname = hostname.split(':')[0]
  // find & remove "?"
  hostname = hostname.split('?')[0]
  if (hostname.startsWith('www.')) {
    hostname = hostname.replace('www.', '')
  }
  return hostname
}

const normalizeUrl = (url) => {
  // loại bỏ ?, #
  let urlNormalization = url
  if (url != null) {
    // urlNormalization = url.split('?')[0].split('#')[0]

    urlNormalization = urlNormalization.replace('/#/', '')
      .replace('#/', '')
    if (urlNormalization[urlNormalization.length - 1] === '/') {
      urlNormalization = urlNormalization.substring(0, urlNormalization.length - 1)
    }
    // if (urlNormalization.substring(urlNormalization.length - 2, urlNormalization.length) === '#/') {
    //   urlNormalization = urlNormalization.substring(0, urlNormalization.length - 2)
    // }
  }
  return urlNormalization
}

const getParamsFromUrl = (url) => {
  let params = {}
  let parser = document.createElement('a')
  parser.href = url
  let query = parser.search.substring(1)
  let vars = query.split('&')
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split('=')
    params[pair[0]] = decodeURIComponent(pair[1])
  }
  return params
}

const addParamFromUrl = (url, key, value) => {
  let urlOutput = url
  if (url != null && url.length > 0) {
    if (getParamsFromUrl(url)[key] == null || getParamsFromUrl(url)[key].length === 0) {
      if (url.includes('?')) {
        urlOutput += `&${key}=${value}`
      } else {
        urlOutput += `?${key}=${value}`
      }
    }
    // nếu khác null nhưng giá trị khác ??? -> override

  }
  return urlOutput
}

const main = () => {
  console.log(extractDomain('https://google.com'))
  console.log(extractDomain('https://www.google.com'))
  console.log(extractDomain('https://www.youtube.com/watch?v=ClkQA2Lb_iE'))
}

export {
  extractDomain,
  addParamFromUrl,
  normalizeUrl,
  getParamsFromUrl,
  getUrlCurrent
}
