import {
  urlApiDiscountVoucher,
  urlApiCreateVoucher,
  urlApiTransaction,
  urlApiVerifyPayment, urlApiPricingPlans
} from "@/api/payment/PaymentEndpoint";
import {http} from "@/plugin/http";

const apiCreateVoucher = async (voucherType) => {
  const urlApi = urlApiCreateVoucher(voucherType)
  let data = {
    cookie: document.cookie
  }
  try {
    const response = await http.put(urlApi, data)
    if (response?.data != null) {
      return response.data
    }
  } catch (e) {
    console.error(e)
  }
  return null
}

const apiVerifyPayment = async (voucherCode) => {
  const urlApi = urlApiVerifyPayment(voucherCode)
  try {
    const response = await http.get(urlApi)
    if (response?.data != null) {
      return response.data
    }
  } catch (e) {
    console.error(e)
  }
  return null
}

const apiGetListTransaction = async () => {
  const urlApi = urlApiTransaction()
  try {
    const response = await http.get(urlApi)
    if (response?.data?.data != null) {
      return response.data.data
    }
  } catch (e) {
    // console.error(e)
  }
  return null
}

const apiSetPaidTransaction = async (voucherCode) => {
  if (!voucherCode) {
    return
  }
  const urlApi = urlApiTransaction()
  let config = {
    withCredentials: true
  }
  let data = {
    "voucher_code": voucherCode,
    cookie: document.cookie
  }
  try {
    const response = await http.post(urlApi, data, config)
    if (response?.data?.data != null) {
      return response.data.data
    }
  } catch (e) {
    // console.error(e)
  }
  return null
}

const apiApplyDiscountVoucher = async (discountVoucherCode, voucherType) => {
  const urlApi = urlApiDiscountVoucher()
  const formData = new FormData();
  formData.append('voucher_code', discountVoucherCode);
  formData.append('voucher_type',  voucherType);
  try {
    const response = await http.post(urlApi, formData)
    if (response?.data != null) {
      return response.data
    }
  } catch (e) {
    console.error(e)
  }
  return null
}

const apiGetPricingPlans = async () => {
  const urlApi = urlApiPricingPlans()
  try {
    const response = await http.get(urlApi)
    if (response?.data != null) {
      return response.data
    }
  } catch (e) {
    // console.error(e)
  }
  return null
}

export {
  apiCreateVoucher,
  apiVerifyPayment,
  apiGetListTransaction,
  apiSetPaidTransaction,
  apiApplyDiscountVoucher,
  apiGetPricingPlans
}
