import Vue from 'vue'
import Vuex from 'vuex'
import GeneralModule from '@/store/modules/GeneralModule'
import SidebarModule from '@/store/modules/SidebarModule'
import SearchFilterModule from '@/store/modules/SearchFilterModule'
import SearchProductResultModule from '@/store/modules/SearchProductResultModule'
import SearchShopsResultModule from '@/store/modules/SearchShopsResultModule'
import SearchKeywordsResultModule from '@/store/modules/SearchKeywordsResultModule'
import SearchStatisticResultModule from '@/store/modules/SearchStatisticResultModule'
import SearchStatisticComparisonResultModule from '@/store/modules/SearchStatisticComparisonResultModule'
import UserModule from '@/store/modules/UserModule'
import GlobalModule from '@/store/modules/GlobalModule'
import SearchBrandsResultModule from "@/store/modules/SearchBrandsResultModule";
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    GlobalModule,
    UserModule,
    GeneralModule,
    SidebarModule,
    SearchShopsResultModule,
    SearchFilterModule,
    SearchProductResultModule,
    SearchStatisticResultModule,
    SearchStatisticComparisonResultModule,
    SearchKeywordsResultModule,
    SearchBrandsResultModule,
  },
})
