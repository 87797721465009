import {MutationSearchKeywordsResult} from "@/store/modules/SearchKeywordsResultModule/mutation";

const initialState = () => {
  return {
    keywords: [],
    products: null
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters: {
    [MutationSearchKeywordsResult.keywords]: state => state[MutationSearchKeywordsResult.keywords],
    [MutationSearchKeywordsResult.products]: state => state[MutationSearchKeywordsResult.products]
  },
  mutations: {
    [MutationSearchKeywordsResult.keywords](state, value) {
      state[MutationSearchKeywordsResult.keywords] = value
    },
    [MutationSearchKeywordsResult.products](state, value) {
      state[MutationSearchKeywordsResult.products] = value
    },
    [MutationSearchKeywordsResult.RESET](state) {
      const s = initialState()
      Object.keys(s)
        .forEach(key => {
          state[key] = s[key]
        })
    }
  },
  actions: {
    [MutationSearchKeywordsResult.keywords](context, data) {
      context.commit(MutationSearchKeywordsResult.keywords, data)
    },
    [MutationSearchKeywordsResult.products](context, data) {
      context.commit(MutationSearchKeywordsResult.products, data)
    },
    [MutationSearchKeywordsResult.RESET](context) {
      context.commit(MutationSearchKeywordsResult.RESET)
    }
  }
}
