const gaTrackEvent = (category, action, label = '', value = 1, ma) => {
  if (ma) {
    window.vue.$ma.trackEvent({
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
      eventValue: value
    })
  } else if (ga) {
    ga('send', 'event', {
      eventCategory: category,
      eventAction: action,
      eventLabel: label
    });
  }
}

export {
  gaTrackEvent,
}
