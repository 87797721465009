import {URL_WEB} from "@/config/ConfigEndpoint";
import {apiGetReferralId} from "@/api/referral/ApiReferralTracking";

const getMarketReferralLink = async () => {
  let referralId = await apiGetReferralId()
  if (!referralId) {
    return null
  }
  return `${URL_WEB}/ref/${referralId}`
}

export {
  getMarketReferralLink
}
