import {PERMISSION} from "@/constant/general/PermissionConstant";
const UPDATE_PRICING_STATE = 'Đang cập nhật'
const CONTACT_US_STATE = 'Liên hệ'
const NOT_AVAILABLE_STATE = 'Không hỗ trợ'


const UNLIMITED = 'Không giới hạn'
const UNLIMITED_SUPPORT = 'Hỗ trợ không giới hạn'

const PRICING_PLAN_DURATION = {
  weekly: {
    id: 'weekly',
    apiKey: '1w',
    label: '1 tuần',
    promotedText: ''
  },
  monthly: {
    id: 'monthly',
    isMonthType: true,
    label: '1 tháng',
    apiKey: '1m',
    promotedText: ''
  },
  three_monthly: {
    id: 'three_monthly',
    isMonthType: true,
    label: '3 tháng',
    apiKey: '3m',
    promotedText: 'Tiết kiệm tới 22%'
  },
  six_monthly: {
    id: 'six_monthly',
    isMonthType: true,
    label: '6 tháng',
    apiKey: '6m',
    promotedText: 'Tiết kiệm tới 33%'
  },
  yearly: {
    id: 'yearly',
    apiKey: '12m',
    label: '1 năm',
    promotedText: 'Tiết kiệm tới 45%'
  },
}

const PRICING_PLAN_DURATION_V2 = {
  trial: {
    id: 'trial',
    label: 'Dùng thử 5 ngày',
    detailLabel: 'Dùng thử 5 ngày',
    apiKey: '5d',
    promotedText: ''
  },
  threeMonths: {
    id: 'threeMonths',
    label: '3 tháng',
    detailLabel: '3 tháng (92 ngày)',
    apiKey: '3m',
    promotedText: ''
  },
  sixMonths: {
    id: 'sixMonths',
    label: '6 tháng',
    detailLabel: '6 tháng (184 ngày)',
    apiKey: '6m',
    promotedText: ''
  },
  twelveMonths: {
    id: 'twelveMonths',
    label: '1 năm',
    detailLabel: '1 năm (366 ngày)',
    apiKey: '12m',
    promotedText: 'Tiết kiệm 25%'
  },
}

const PRICING_PLANS = {
  market_default: {
    id: 'market_default',
    name: 'Gói Miễn Phí',
    permission: PERMISSION.market_default.name,
    types: {
      [PRICING_PLAN_DURATION.weekly.id]: 'market_default__1w',
      [PRICING_PLAN_DURATION.monthly.id]: 'market_default__1m',
      [PRICING_PLAN_DURATION.three_monthly.id]: 'market_default__3m',
      [PRICING_PLAN_DURATION.six_monthly.id]: 'market_default__6m',
      [PRICING_PLAN_DURATION.yearly.id]: 'market_default__12m',
    },
    href: '#',
    price: {
      [PRICING_PLAN_DURATION.weekly.id]: 'Miễn phí',
      [PRICING_PLAN_DURATION.monthly.id]: 'Miễn phí',
      [PRICING_PLAN_DURATION.three_monthly.id]: 'Miễn phí',
      [PRICING_PLAN_DURATION.six_monthly.id]: 'Miễn phí',
      [PRICING_PLAN_DURATION.yearly.id]: 'Miễn phí',
    },
    promotedText: {
      [PRICING_PLAN_DURATION.weekly.id]: '',
      [PRICING_PLAN_DURATION.monthly.id]: '',
      [PRICING_PLAN_DURATION.three_monthly.id]: '',
      [PRICING_PLAN_DURATION.six_monthly.id]: '',
      [PRICING_PLAN_DURATION.yearly.id]: '',
    },
    description: 'Khảo sát thị trường miễn phí các sàn thương mại điện tử',
    listValue: [
      'Khảo sát thị trường miễn phí'
    ]
  },
  market_basic: {
    id: 'market_basic',
    name: 'Gói Tiêu Chuẩn',
    permission: PERMISSION.market_basic.name,
    types: {
      [PRICING_PLAN_DURATION.weekly.id]: 'market_basic__1w',
      [PRICING_PLAN_DURATION.monthly.id]: 'market_basic__1m',
      [PRICING_PLAN_DURATION.three_monthly.id]: 'market_basic__3m',
      [PRICING_PLAN_DURATION.six_monthly.id]: 'market_basic__6m',
      [PRICING_PLAN_DURATION.yearly.id]: 'market_basic__12m',
    },
    href: '#',
    price: {
      [PRICING_PLAN_DURATION.weekly.id]: UPDATE_PRICING_STATE,
      [PRICING_PLAN_DURATION.monthly.id]: UPDATE_PRICING_STATE,
      [PRICING_PLAN_DURATION.three_monthly.id]: UPDATE_PRICING_STATE,
      [PRICING_PLAN_DURATION.six_monthly.id]: UPDATE_PRICING_STATE,
      [PRICING_PLAN_DURATION.yearly.id]: UPDATE_PRICING_STATE,
    },
    promotedText: {
      [PRICING_PLAN_DURATION.weekly.id]: '',
      [PRICING_PLAN_DURATION.monthly.id]: '',
      [PRICING_PLAN_DURATION.three_monthly.id]: '',
      [PRICING_PLAN_DURATION.six_monthly.id]: '',
      [PRICING_PLAN_DURATION.yearly.id]: '',
    },
    description: 'Tìm bán mặt hàng tiêu thụ cao, trending',
    listValue: [
      'Tìm hàng bán chạy/trending để kinh doanh',
      'Phân tích theo từ khóa, lọc thông minh (bỏ đơn ảo, bất thường)',
      'Cộng đồng hỗ trợ kinh nghiệm bán hàng'
    ]
  },
  market_advance: {
    id: 'market_advance',
    name: 'Gói Nâng Cao',
    permission: PERMISSION.market_advance.name,
    types: {
      [PRICING_PLAN_DURATION.weekly.id]: 'market_advance__1w',
      [PRICING_PLAN_DURATION.monthly.id]: 'market_advance__1m',
      [PRICING_PLAN_DURATION.three_monthly.id]: 'market_advance__3m',
      [PRICING_PLAN_DURATION.six_monthly.id]: 'market_advance__6m',
      [PRICING_PLAN_DURATION.yearly.id]: 'market_advance__12m',
    },
    href: '#',
    price: {
      [PRICING_PLAN_DURATION.weekly.id]: NOT_AVAILABLE_STATE,
      [PRICING_PLAN_DURATION.monthly.id]: UPDATE_PRICING_STATE,
      [PRICING_PLAN_DURATION.three_monthly.id]: UPDATE_PRICING_STATE,
      [PRICING_PLAN_DURATION.six_monthly.id]: UPDATE_PRICING_STATE,
      [PRICING_PLAN_DURATION.yearly.id]: UPDATE_PRICING_STATE,
    },
    promotedText: {
      [PRICING_PLAN_DURATION.weekly.id]: '',
      [PRICING_PLAN_DURATION.monthly.id]: '',
      [PRICING_PLAN_DURATION.three_monthly.id]: '',
      [PRICING_PLAN_DURATION.six_monthly.id]: '',
      [PRICING_PLAN_DURATION.yearly.id]: '',
    },
    description: 'Theo dõi trending, đối thủ. Hướng dẫn nhân viên, người mới',
    listValue: [
      'Tìm mặt hàng bán chạy/trending số lượng lớn',
      'Phân tích nâng cao theo một/nhiều shop',
      'Đánh giá hiệu năng bán sản phẩm',
      'Theo dõi doanh số đối thủ',
      'Được hỗ trợ các kỹ năng phân tích dữ liệu nâng cao',
      'Hàng trending các nước (1688.com, Taobao, Tmall)'
    ]
  },
  market_business_wrap: {
    id: 'market_business_wrap',
    name: 'Gói Doanh Nghiệp',
    permission: null,
    types: {
      [PRICING_PLAN_DURATION.weekly.id]: 'market_business__1w',
      [PRICING_PLAN_DURATION.monthly.id]: 'market_business__1m',
      [PRICING_PLAN_DURATION.three_monthly.id]: 'market_business__3m',
      [PRICING_PLAN_DURATION.six_monthly.id]: 'market_business__6m',
      [PRICING_PLAN_DURATION.yearly.id]: 'market_business__12m',
    },
    href: '#',
    price: {
      [PRICING_PLAN_DURATION.weekly.id]: 'Tùy chọn mức giá',
      [PRICING_PLAN_DURATION.monthly.id]: 'Tùy chọn mức giá',
      [PRICING_PLAN_DURATION.three_monthly.id]: 'Tùy chọn mức giá',
      [PRICING_PLAN_DURATION.six_monthly.id]: 'Tùy chọn mức giá',
      [PRICING_PLAN_DURATION.yearly.id]: 'Tùy chọn mức giá',
    },
    promotedText: {
      [PRICING_PLAN_DURATION.weekly.id]: '',
      [PRICING_PLAN_DURATION.monthly.id]: '',
      [PRICING_PLAN_DURATION.three_monthly.id]: '',
      [PRICING_PLAN_DURATION.six_monthly.id]: '',
      [PRICING_PLAN_DURATION.yearly.id]: '',
    },
    description: 'Không giới hạn tài khoản. Hỗ trợ 4 sàn: Shopee, Lazada, Tiki, Sendo. Report + Data APIs theo yêu cầu',
    listValue: [
      'So sánh tăng trưởng thị phần các shop theo tháng',
      'Nhiều tài khoản sử dụng + không giới hạn phân tích',
      'Tặng gói đặt Banner tới 3 tháng trên beecost.vn (5 triệu views/tháng)',
      'Hỗ trợ 4 sàn: Shopee, Lazada, Tiki, Sendo',
      'Nghiên cứu thị trường nhanh hơn 50 lần. Rút ngắn vòng quay kinh doanh.',
      'Phân tích tăng trưởng ngành hàng, dòng sản phẩm, thị trường theo thời gian',
      'Tuỳ chọn khoảng thời gian phân tích số liệu',
      'Big Data API cho CSDL doanh nghiệp',
      'Business Support'
    ]
  },
  market_personal: {
    id: 'market_personal',
    name: 'Gói Cá nhân',
    permission: null,
    types: {
      [PRICING_PLAN_DURATION.weekly.id]: 'market_personal__1w',
      [PRICING_PLAN_DURATION.monthly.id]: 'market_personal__1m',
      [PRICING_PLAN_DURATION.three_monthly.id]: 'market_personal__3m',
      [PRICING_PLAN_DURATION.six_monthly.id]: 'market_personal__6m',
      [PRICING_PLAN_DURATION.yearly.id]: 'market_personal__12m',
    },
    href: '#',
    price: {
      [PRICING_PLAN_DURATION.weekly.id]: 'Tùy chọn mức giá',
      [PRICING_PLAN_DURATION.monthly.id]: 'Tùy chọn mức giá',
      [PRICING_PLAN_DURATION.three_monthly.id]: 'Tùy chọn mức giá',
      [PRICING_PLAN_DURATION.six_monthly.id]: 'Tùy chọn mức giá',
      [PRICING_PLAN_DURATION.yearly.id]: 'Tùy chọn mức giá',
    },
    promotedText: {
      [PRICING_PLAN_DURATION.weekly.id]: '',
      [PRICING_PLAN_DURATION.monthly.id]: '',
      [PRICING_PLAN_DURATION.three_monthly.id]: '',
      [PRICING_PLAN_DURATION.six_monthly.id]: '',
      [PRICING_PLAN_DURATION.yearly.id]: '',
    },
    description: 'Theo dõi trending, đối thủ. Hướng dẫn nhân viên, người mới',
    listValue: [
      'Tìm mặt hàng bán chạy/trending số lượng lớn',
      'Phân tích nâng cao theo một/nhiều shop',
      'Đánh giá hiệu năng bán sản phẩm',
      'Theo dõi doanh số đối thủ',
      'Được hỗ trợ các kỹ năng phân tích dữ liệu nâng cao'
    ]
  },
  market_business_small: {
    id: 'market_business_small',
    name: 'Gói Small SME',
    permission: PERMISSION.market_business_small.name,
    types: {
      [PRICING_PLAN_DURATION.weekly.id]: 'market_business_small__1w',
      [PRICING_PLAN_DURATION.monthly.id]: 'market_business_small__1m',
      [PRICING_PLAN_DURATION.three_monthly.id]: 'market_business_small__3m',
      [PRICING_PLAN_DURATION.six_monthly.id]: 'market_business_small__6m',
      [PRICING_PLAN_DURATION.yearly.id]: 'market_business_small__12m',
    },
    href: '#',
    price: {
      [PRICING_PLAN_DURATION.weekly.id]: NOT_AVAILABLE_STATE,
      [PRICING_PLAN_DURATION.monthly.id]: UPDATE_PRICING_STATE,
      [PRICING_PLAN_DURATION.three_monthly.id]: UPDATE_PRICING_STATE,
      [PRICING_PLAN_DURATION.six_monthly.id]: UPDATE_PRICING_STATE,
      [PRICING_PLAN_DURATION.yearly.id]: UPDATE_PRICING_STATE,
    },
    promotedText: {
      [PRICING_PLAN_DURATION.weekly.id]: '',
      [PRICING_PLAN_DURATION.monthly.id]: '',
      [PRICING_PLAN_DURATION.three_monthly.id]: '',
      [PRICING_PLAN_DURATION.six_monthly.id]: '',
      [PRICING_PLAN_DURATION.yearly.id]: '',
    },
    description: 'Nắm rõ tỷ lệ chiếm lĩnh thị trường và đối thủ. Xác định xu hướng ngành và phân khúc khách hàng. Big Data Insight API',
    listValue: [
      'Nhanh hơn 50 lần tốc độ Nghiên cứu Thị trường',
      'Biểu đồ tăng trưởng doanh số theo thời gian',
      'Biểu đồ so sánh tăng trưởng thị phần các shop',
      'Rút ngắn vòng quay kinh doanh sản phẩm (tối ưu lợi nhuận)',
      'Liên tục cập nhật tỷ lệ chiếm lĩnh thị trường và số liệu đối thủ',
      'Quan sát Phân tích số liệu theo các mốc thời gian',
      'Không giới hạn phân tích & dữ liệu',
      'Business Support'
    ]
  },
  market_business: {
    id: 'market_business',
    name: 'Gói SME',
    permission: PERMISSION.market_business.name,
    types: {
      [PRICING_PLAN_DURATION.weekly.id]: 'market_business__1w',
      [PRICING_PLAN_DURATION.monthly.id]: 'market_business__1m',
      [PRICING_PLAN_DURATION.three_monthly.id]: 'market_business__3m',
      [PRICING_PLAN_DURATION.six_monthly.id]: 'market_business__6m',
      [PRICING_PLAN_DURATION.yearly.id]: 'market_business__12m',
    },
    href: '#',
    price: {
      [PRICING_PLAN_DURATION.weekly.id]: NOT_AVAILABLE_STATE,
      [PRICING_PLAN_DURATION.monthly.id]: CONTACT_US_STATE,
      [PRICING_PLAN_DURATION.three_monthly.id]: CONTACT_US_STATE,
      [PRICING_PLAN_DURATION.six_monthly.id]: CONTACT_US_STATE,
      [PRICING_PLAN_DURATION.yearly.id]: CONTACT_US_STATE,
    },
    fixedYearPrice: '27 triệu',
    promotedText: {
      [PRICING_PLAN_DURATION.weekly.id]: '',
      [PRICING_PLAN_DURATION.monthly.id]: '',
      [PRICING_PLAN_DURATION.three_monthly.id]: '',
      [PRICING_PLAN_DURATION.six_monthly.id]: '',
      [PRICING_PLAN_DURATION.yearly.id]: 'Tiết kiệm 55%',
    },
    description: 'Nắm rõ tỷ lệ chiếm lĩnh thị trường và đối thủ. Xác định xu hướng ngành và phân khúc khách hàng. Big Data Insight API',
    listValue: [
      '2 tài khoản sử dụng. <strong>Hoặc</strong>, tặng gói đặt Banner tới 3 tháng trên beecost.vn (5 triệu views/tháng)',
      'Biểu đồ tăng trưởng doanh số theo thời gian',
      'Biểu đồ so sánh tăng trưởng thị phần các shop',
      'Rút ngắn vòng quay kinh doanh sản phẩm (tối ưu lợi nhuận)',
      'Quan sát Phân tích số liệu theo các mốc thời gian',
      'Không giới hạn phân tích & dữ liệu',
      'Business Support'
    ]
  },
  market_business_agency: {
    id: 'market_business_agency',
    name: 'Gói Agency',
    permission: PERMISSION.market_business_agency.name,
    types: {
      [PRICING_PLAN_DURATION.weekly.id]: 'market_business_agency__1w',
      [PRICING_PLAN_DURATION.monthly.id]: 'market_business_agency__1m',
      [PRICING_PLAN_DURATION.three_monthly.id]: 'market_business_agency__3m',
      [PRICING_PLAN_DURATION.six_monthly.id]: 'market_business_agency__6m',
      [PRICING_PLAN_DURATION.yearly.id]: 'market_business_agency__12m',
    },
    href: '#',
    price: {
      [PRICING_PLAN_DURATION.weekly.id]: NOT_AVAILABLE_STATE,
      [PRICING_PLAN_DURATION.monthly.id]: CONTACT_US_STATE,
      [PRICING_PLAN_DURATION.three_monthly.id]: CONTACT_US_STATE,
      [PRICING_PLAN_DURATION.six_monthly.id]: CONTACT_US_STATE,
      [PRICING_PLAN_DURATION.yearly.id]: CONTACT_US_STATE,
    },
    fixedYearPrice: '48 triệu',
    promotedText: {
      [PRICING_PLAN_DURATION.weekly.id]: '',
      [PRICING_PLAN_DURATION.monthly.id]: '',
      [PRICING_PLAN_DURATION.three_monthly.id]: '',
      [PRICING_PLAN_DURATION.six_monthly.id]: '',
      [PRICING_PLAN_DURATION.yearly.id]: 'Tiết kiệm 65%',
    },
    description: 'Nắm rõ tỷ lệ chiếm lĩnh thị trường và đối thủ. Xác định xu hướng ngành và phân khúc khách hàng',
    listValue: [
      '5 tài khoản sử dụng. <strong>Hoặc</strong>, tặng gói đặt Banner tới 3 tháng trên beecost.vn (5 triệu views/tháng)',
      'Biểu đồ tăng trưởng doanh số theo thời gian',
      'Biểu đồ so sánh tăng trưởng thị phần các shop',
      'Tạo báo cáo ngành hàng/dòng sản phẩm theo yêu cầu, hàng tuần',
      'Quan sát Phân tích số liệu theo các mốc thời gian',
      'Không giới hạn phân tích & dữ liệu',
      'Business Support'
    ]
  },
  market_business_big_agency: {
    id: 'market_business_big_agency',
    name: 'Gói Big Agency',
    permission: PERMISSION.market_business_big_agency.name,
    types: {
      [PRICING_PLAN_DURATION.weekly.id]: 'market_business_big_agency__1w',
      [PRICING_PLAN_DURATION.monthly.id]: 'market_business_big_agency__1m',
      [PRICING_PLAN_DURATION.three_monthly.id]: 'market_business_big_agency__3m',
      [PRICING_PLAN_DURATION.six_monthly.id]: 'market_business_big_agency__6m',
      [PRICING_PLAN_DURATION.yearly.id]: 'market_business_big_agency__12m',
    },
    href: '#',
    price: {
      [PRICING_PLAN_DURATION.weekly.id]: NOT_AVAILABLE_STATE,
      [PRICING_PLAN_DURATION.monthly.id]: CONTACT_US_STATE,
      [PRICING_PLAN_DURATION.three_monthly.id]: CONTACT_US_STATE,
      [PRICING_PLAN_DURATION.six_monthly.id]: CONTACT_US_STATE,
      [PRICING_PLAN_DURATION.yearly.id]: CONTACT_US_STATE,
    },
    fixedYearPrice: '96 triệu',
    promotedText: {
      [PRICING_PLAN_DURATION.weekly.id]: '',
      [PRICING_PLAN_DURATION.monthly.id]: '',
      [PRICING_PLAN_DURATION.three_monthly.id]: '',
      [PRICING_PLAN_DURATION.six_monthly.id]: '',
      [PRICING_PLAN_DURATION.yearly.id]: 'Tiết kiệm 75%',
    },
    description: 'Nắm rõ tỷ lệ chiếm lĩnh thị trường và đối thủ. Xác định xu hướng ngành và phân khúc khách hàng. Big Data Insight API',
    listValue: [
      '10 tài khoản sử dụng (Hỗ trợ không giới hạn số lượng)',
      'Tặng gói đặt Banner tới 3 tháng trên beecost.vn (5 triệu views/tháng)',
      'Biểu đồ tăng trưởng doanh số theo thời gian',
      'Biểu đồ so sánh tăng trưởng thị phần các shop',
      'Tạo báo cáo ngành hàng/dòng sản phẩm theo yêu cầu, hàng tuần',
      'Quan sát Phân tích số liệu theo các mốc thời gian',
      'Không giới hạn phân tích & dữ liệu',
      'Big Data APIs cho CSDL doanh nghiệp',
      'Business Support'
    ]
  },
}
const ALL_FEATURES = {
  numberOfAccount: {
    isRelease: true,
    name: 'numberOfAccount',
    description: 'Số người sử dụng tối đa',
    availablePlan:
      {
        [PRICING_PLANS.market_default.id]: '1',
        [PRICING_PLANS.market_basic.id]: '1',
        [PRICING_PLANS.market_advance.id]: '1',
        [PRICING_PLANS.market_business_wrap.id]: `10 (${UNLIMITED_SUPPORT})`,
        [PRICING_PLANS.market_business_small.id]: '1',
        [PRICING_PLANS.market_business.id]: '2',
        [PRICING_PLANS.market_business_agency.id]: '5',
        [PRICING_PLANS.market_business_big_agency.id]: `10 (${UNLIMITED_SUPPORT})`
      }
  },
  searchLimitPerDay: {
    isRelease: true,
    name: 'searchLimitPerDay',
    description: 'Số phân tích mỗi ngày',
    availablePlan:
      {
        [PRICING_PLANS.market_default.id]: '5',
        [PRICING_PLANS.market_basic.id]: '50',
        [PRICING_PLANS.market_advance.id]: '100',
        [PRICING_PLANS.market_business_wrap.id]: UNLIMITED,
        [PRICING_PLANS.market_business_small.id]: UNLIMITED,
        [PRICING_PLANS.market_business.id]: UNLIMITED,
        [PRICING_PLANS.market_business_agency.id]: UNLIMITED,
        [PRICING_PLANS.market_business_big_agency.id]: UNLIMITED
      }
  },
  searchLimitPerWeek: {
    isRelease: true,
    name: 'searchLimitPerWeek',
    description: 'Số phân tích mỗi tuần',
    availablePlan:
      {
        [PRICING_PLANS.market_default.id]: '15',
        [PRICING_PLANS.market_basic.id]: '150',
        [PRICING_PLANS.market_advance.id]: '300',
        [PRICING_PLANS.market_business_wrap.id]: UNLIMITED,
        [PRICING_PLANS.market_business_small.id]: '500',
        [PRICING_PLANS.market_business.id]: UNLIMITED,
        [PRICING_PLANS.market_business_agency.id]: UNLIMITED,
        [PRICING_PLANS.market_business_big_agency.id]: UNLIMITED
      }
  },
  searchByTimeRange: {
    isRelease: true,
    name: 'searchByTimeRange',
    description: 'Khoảng thời gian số liệu',
    availablePlan: {
      [PRICING_PLANS.market_default.id]: 'Hai mốc thời gian (7, 30 ngày gần nhất)',
      [PRICING_PLANS.market_basic.id]: '4 mốc thời gian (7, 30, 90, 180 ngày gần nhất)',
      [PRICING_PLANS.market_advance.id]: '4 mốc thời gian (7, 30, 90, 180 ngày gần nhất)',
      [PRICING_PLANS.market_business_wrap.id]: 'Tùy chọn mốc thời gian (2 năm gần nhất)',
      [PRICING_PLANS.market_business_small.id]: 'Tùy chọn mốc thời gian (1 năm gần nhất)',
      [PRICING_PLANS.market_business.id]: 'Tùy chọn mốc thời gian (1 năm gần nhất)',
      [PRICING_PLANS.market_business_agency.id]: 'Tùy chọn mốc thời gian (1 năm gần nhất)',
      [PRICING_PLANS.market_business_big_agency.id]: 'Tùy chọn mốc thời gian (2 năm gần nhất - updating)'
    }
  },
  searchByStatistic: {
    isRelease: true,
    name: 'searchByStatistic',
    description: 'Bộ lọc hữu ích',
    availablePlan: {
      [PRICING_PLANS.market_default.id]: true,
      [PRICING_PLANS.market_basic.id]: true,
      [PRICING_PLANS.market_advance.id]: true,
      [PRICING_PLANS.market_business_wrap.id]: true,
      [PRICING_PLANS.market_business_small.id]: true,
      [PRICING_PLANS.market_business.id]: true,
      [PRICING_PLANS.market_business_agency.id]: true,
      [PRICING_PLANS.market_business_big_agency.id]: true
    }
  },
  searchByAdvanceField: {
    isRelease: true,
    name: 'searchByAdvanceField',
    description: 'Bộ lọc nâng cao',
    availablePlan: {
      [PRICING_PLANS.market_default.id]: false,
      [PRICING_PLANS.market_basic.id]: true,
      [PRICING_PLANS.market_advance.id]: true,
      [PRICING_PLANS.market_business_wrap.id]: true,
      [PRICING_PLANS.market_business_small.id]: true,
      [PRICING_PLANS.market_business.id]: true,
      [PRICING_PLANS.market_business_agency.id]: true,
      [PRICING_PLANS.market_business_big_agency.id]: true
    }
  },
  searchByMultiShop: {
    isRelease: true,
    name: 'searchByMultiShop',
    description: 'Phân tích theo một hoặc nhiều Shop',
    availablePlan:
      {
        [PRICING_PLANS.market_default.id]: false,
        [PRICING_PLANS.market_basic.id]: true,
        [PRICING_PLANS.market_advance.id]: true,
        [PRICING_PLANS.market_business_wrap.id]: true,
        [PRICING_PLANS.market_business_small.id]: true,
        [PRICING_PLANS.market_business.id]: true,
        [PRICING_PLANS.market_business_agency.id]: true,
        [PRICING_PLANS.market_business_big_agency.id]: true
      }
  },
  advanceFilter: {
    isRelease: true,
    name: 'advanceFilter',
    description: 'Lọc loại trừ nâng cao: Từ khóa loại trừ, từ khóa bắt buộc.',
    availablePlan:
      {
        [PRICING_PLANS.market_default.id]: false,
        [PRICING_PLANS.market_basic.id]: true,
        [PRICING_PLANS.market_advance.id]: true,
        [PRICING_PLANS.market_business_wrap.id]: true,
        [PRICING_PLANS.market_business_small.id]: true,
        [PRICING_PLANS.market_business.id]: true,
        [PRICING_PLANS.market_business_agency.id]: true,
        [PRICING_PLANS.market_business_big_agency.id]: true
      }
  },
  viewProductBaseInfo: {
    isRelease: true,
    name: 'viewProductBaseInfo',
    description: 'Xem link gốc sản phẩm, lịch sử giá bán, lịch sử đã bán',
    availablePlan: {
      [PRICING_PLANS.market_default.id]: false,
      [PRICING_PLANS.market_basic.id]: true,
      [PRICING_PLANS.market_advance.id]: true,
      [PRICING_PLANS.market_business_wrap.id]: true,
      [PRICING_PLANS.market_business_small.id]: true,
      [PRICING_PLANS.market_business.id]: true,
      [PRICING_PLANS.market_business_agency.id]: true,
      [PRICING_PLANS.market_business_big_agency.id]: true
    }
  },
  downloadLindLimitPerWeek: {
    isRelease: true,
    name: 'downloadLindLimitPerWeek',
    description: 'Số dòng data được download mỗi tuần',
    availablePlan:
      {
        [PRICING_PLANS.market_default.id]: '0',
        [PRICING_PLANS.market_basic.id]: '100K',
        [PRICING_PLANS.market_advance.id]: '200K',
        [PRICING_PLANS.market_business_wrap.id]: UNLIMITED,
        [PRICING_PLANS.market_business_small.id]: '500.000 dòng',
        [PRICING_PLANS.market_business.id]: '2.000.000 dòng',
        [PRICING_PLANS.market_business_agency.id]: UNLIMITED,
        [PRICING_PLANS.market_business_big_agency.id]: UNLIMITED
      }
  },
  downloadProductNumber: {
    isRelease: true,
    name: 'downloadProductNumber',
    description: 'Số dòng tối đa mỗi file Product List tải về',
    availablePlan:
      {
        [PRICING_PLANS.market_default.id]: false,
        [PRICING_PLANS.market_basic.id]: '200',
        [PRICING_PLANS.market_advance.id]: '1000',
        [PRICING_PLANS.market_business_wrap.id]: UNLIMITED_SUPPORT,
        [PRICING_PLANS.market_business_small.id]: '1.000',
        [PRICING_PLANS.market_business.id]: '2.500 (Tối đa 10% số sản phẩm trong phân tích)',
        [PRICING_PLANS.market_business_agency.id]: '10.000 (Tối đa 15% số sản phẩm trong phân tích)',
        [PRICING_PLANS.market_business_big_agency.id]: '20000 (Hỗ trợ không giới hạn)'
      }
  },
  fieldInDownloadProductFile: {
    isRelease: true,
    name: 'fieldInDownloadProductFile',
    description: 'Trường thông tin trong danh sách sản phẩm tải về',
    availablePlan:
      {
        [PRICING_PLANS.market_default.id]: false,
        [PRICING_PLANS.market_basic.id]: 'Thông tin cơ bản, Doanh số, Đã bán, Link sản phẩm',
        [PRICING_PLANS.market_advance.id]: 'Thông tin cơ bản, Doanh số, Đã bán, link sản phẩm, Doanh số/Đã bán 7, 30, 90, 180 ngày',
        [PRICING_PLANS.market_business_wrap.id]: UNLIMITED,
        [PRICING_PLANS.market_business_small.id]: UNLIMITED,
        [PRICING_PLANS.market_business.id]: UNLIMITED,
        [PRICING_PLANS.market_business_agency.id]: UNLIMITED,
        [PRICING_PLANS.market_business_big_agency.id]: UNLIMITED
      }
  },
  downloadShopNumber: {
    isRelease: true,
    name: 'downloadShopNumber',
    description: 'Số dòng tối đa mỗi file danh sách Shop tải về',
    availablePlan:
      {
        [PRICING_PLANS.market_default.id]: false,
        [PRICING_PLANS.market_basic.id]: false,
        [PRICING_PLANS.market_advance.id]: '100',
        [PRICING_PLANS.market_business_wrap.id]: UNLIMITED_SUPPORT,
        [PRICING_PLANS.market_business_small.id]: '100',
        [PRICING_PLANS.market_business.id]: '500 (Max 10% số shop trong phân tích)',
        [PRICING_PLANS.market_business_agency.id]: '1.000 (Max 15% số shop trong phân tích)',
        [PRICING_PLANS.market_business_big_agency.id]: '2.000 (Hỗ trợ không giới hạn)'
      }
  },
  saveSearchRequestNumber: {
    isRelease: false,
    name: 'saveSearchRequestNumber',
    description: 'Số lượng phân tích có thể lưu vào danh sách quản lý',
    availablePlan: {
      [PRICING_PLANS.market_default.id]: false,
      [PRICING_PLANS.market_basic.id]: false,
      [PRICING_PLANS.market_advance.id]: '20',
      [PRICING_PLANS.market_business_wrap.id]: UNLIMITED,
      [PRICING_PLANS.market_business_small.id]: UNLIMITED,
      [PRICING_PLANS.market_business.id]: UNLIMITED,
      [PRICING_PLANS.market_business_agency.id]: UNLIMITED,
      [PRICING_PLANS.market_business_big_agency.id]: UNLIMITED
    }
  },
  shopSubscribeNumber: {
    isRelease: true,
    name: 'shopSubscribeNumber',
    description: 'Số lượng shop tối đa có thể theo dõi',
    availablePlan: {
      [PRICING_PLANS.market_default.id]: false,
      [PRICING_PLANS.market_basic.id]: false,
      [PRICING_PLANS.market_advance.id]: '10',
      [PRICING_PLANS.market_business_wrap.id]: UNLIMITED_SUPPORT,
      [PRICING_PLANS.market_business_small.id]: '15',
      [PRICING_PLANS.market_business.id]: '20',
      [PRICING_PLANS.market_business_agency.id]: '50',
      [PRICING_PLANS.market_business_big_agency.id]: '100 (Hỗ trợ không giới hạn)'
    }
  },
  fieldInDownloadShopFile: {
    isRelease: true,
    name: 'fieldInDownloadShopFile',
    description: 'Trường thông tin của Shop bán chạy trong file Download',
    availablePlan:
      {
        [PRICING_PLANS.market_default.id]: false,
        [PRICING_PLANS.market_basic.id]: false,
        [PRICING_PLANS.market_advance.id]: 'Thông tin cơ bản, Đã bán, Doanh số, Link Shop',
        [PRICING_PLANS.market_business_wrap.id]: 'Thông tin cơ bản, Đã bán, Doanh số, Link Shop, Doanh số 7, 30, 90, 180 ngày, Đã bán 7, 30, 90, 180 ngày',
        [PRICING_PLANS.market_business_small.id]: 'Thông tin cơ bản, Đã bán, Doanh số, Link Shop, Doanh số 7, 30, 90, 180 ngày, Đã bán 7, 30, 90, 180 ngày',
        [PRICING_PLANS.market_business.id]: 'Thông tin cơ bản, Đã bán, Doanh số, Link Shop, Doanh số 7, 30, 90, 180 ngày, Đã bán 7, 30, 90, 180 ngày',
        [PRICING_PLANS.market_business_agency.id]: 'Thông tin cơ bản, Đã bán, Doanh số, Link Shop, Doanh số 7, 30, 90, 180 ngày, Đã bán 7, 30, 90, 180 ngày',
        [PRICING_PLANS.market_business_big_agency.id]: 'Thông tin cơ bản, Đã bán, Doanh số, Link Shop, Doanh số 7, 30, 90, 180 ngày, Đã bán 7, 30, 90, 180 ngày'
      }
  },
  downloadBrandNumber: {
    isRelease: true,
    name: 'downloadBrandNumber',
    description: 'Số dòng tối đa mỗi file danh sách thương hiệu tải về',
    availablePlan:
      {
        [PRICING_PLANS.market_default.id]: false,
        [PRICING_PLANS.market_basic.id]: false,
        [PRICING_PLANS.market_advance.id]: false,
        [PRICING_PLANS.market_business_wrap.id]: '200',
        [PRICING_PLANS.market_business_small.id]: false,
        [PRICING_PLANS.market_business.id]: '200',
        [PRICING_PLANS.market_business_agency.id]: '200',
        [PRICING_PLANS.market_business_big_agency.id]: '200'
      }
  },
  categoryDetail: {
    isRelease: true,
    name: 'categoryDetail',
    description: 'Phân tích theo danh mục chuyên sâu',
    availablePlan:
      {
        [PRICING_PLANS.market_default.id]: false,
        [PRICING_PLANS.market_basic.id]: false,
        [PRICING_PLANS.market_advance.id]: true,
        [PRICING_PLANS.market_business_wrap.id]: true,
        [PRICING_PLANS.market_business_small.id]: true,
        [PRICING_PLANS.market_business.id]: true,
        [PRICING_PLANS.market_business_agency.id]: true,
        [PRICING_PLANS.market_business_big_agency.id]: true
      }
  },
  globalTrending: {
    isRelease: true,
    name: 'globalTrending',
    description: 'Hàng trending các nước (1688.com, Taobao, Tmall)',
    availablePlan:
      {
        [PRICING_PLANS.market_default.id]: false,
        [PRICING_PLANS.market_basic.id]: false,
        [PRICING_PLANS.market_advance.id]: true,
        [PRICING_PLANS.market_business_wrap.id]: true,
        [PRICING_PLANS.market_business_small.id]: true,
        [PRICING_PLANS.market_business.id]: true,
        [PRICING_PLANS.market_business_agency.id]: true,
        [PRICING_PLANS.market_business_big_agency.id]: true
      }
  },
  filterByChildCategory: {
    isRelease: false,
    name: 'filterByChildCategory',
    description: 'Lọc nâng cao theo ngành hàng chi tiết',
    availablePlan:
      {
        [PRICING_PLANS.market_default.id]: false,
        [PRICING_PLANS.market_basic.id]: false,
        [PRICING_PLANS.market_advance.id]: true,
        [PRICING_PLANS.market_business_wrap.id]: true,
        [PRICING_PLANS.market_business_small.id]: true,
        [PRICING_PLANS.market_business.id]: true,
        [PRICING_PLANS.market_business_agency.id]: true,
        [PRICING_PLANS.market_business_big_agency.id]: true
      }
  },
  subscribeQueryNumber: {
    isRelease: false,
    name: 'subscribeQueryNumber',
    description: 'Số lượng cặp từ khóa/sản phẩm theo dõi vị trí tìm kiếm',
    availablePlan: {
      [PRICING_PLANS.market_default.id]: false,
      [PRICING_PLANS.market_basic.id]: false,
      [PRICING_PLANS.market_advance.id]: '50',
      [PRICING_PLANS.market_business_wrap.id]: '500',
      [PRICING_PLANS.market_business_small.id]: '50',
      [PRICING_PLANS.market_business.id]: '200',
      [PRICING_PLANS.market_business_agency.id]: '500',
      [PRICING_PLANS.market_business_big_agency.id]: '500'
    }
  },
  chartByTimeRange: {
    isRelease: true,
    name: 'chartByTimeRange',
    description: 'Biểu đồ theo từng mốc thời gian của mỗi phân tích.',
    availablePlan: {
      [PRICING_PLANS.market_default.id]: false,
      [PRICING_PLANS.market_basic.id]: false,
      [PRICING_PLANS.market_advance.id]: false,
      [PRICING_PLANS.market_business_wrap.id]: true,
      [PRICING_PLANS.market_business_small.id]: true,
      [PRICING_PLANS.market_business.id]: true,
      [PRICING_PLANS.market_business_agency.id]: true,
      [PRICING_PLANS.market_business_big_agency.id]: true
    }
  },
  compareShopByChart: {
    isRelease: true,
    name: 'compareShopByChart',
    description: 'So sánh tăng trưởng thị phần các shop theo tháng',
    availablePlan: {
      [PRICING_PLANS.market_default.id]: false,
      [PRICING_PLANS.market_basic.id]: false,
      [PRICING_PLANS.market_advance.id]: false,
      [PRICING_PLANS.market_business_wrap.id]: true,
      [PRICING_PLANS.market_business_small.id]: true,
      [PRICING_PLANS.market_business.id]: true,
      [PRICING_PLANS.market_business_agency.id]: true,
      [PRICING_PLANS.market_business_big_agency.id]: true
    }
  },
  downloadProductByShop: {
    isRelease: false,
    name: 'downloadProductByShop',
    description: 'Download danh sách sản phẩm của shop bất kỳ (Không phụ thuộc vào phân tích)',
    availablePlan: {
      [PRICING_PLANS.market_default.id]: false,
      [PRICING_PLANS.market_basic.id]: false,
      [PRICING_PLANS.market_advance.id]: false,
      [PRICING_PLANS.market_business_wrap.id]: true,
      [PRICING_PLANS.market_business_small.id]: true,
      [PRICING_PLANS.market_business.id]: true,
      [PRICING_PLANS.market_business_agency.id]: true,
      [PRICING_PLANS.market_business_big_agency.id]: true
    }
  },
  requestMoreFieldInProductFile: {
    isRelease: true,
    name: 'requestMoreFieldInProductFile',
    description: 'Yêu cầu thêm trường thông tin trong file download danh sách sản phẩm',
    availablePlan: {
      [PRICING_PLANS.market_default.id]: false,
      [PRICING_PLANS.market_basic.id]: false,
      [PRICING_PLANS.market_advance.id]: false,
      [PRICING_PLANS.market_business_wrap.id]: UNLIMITED,
      [PRICING_PLANS.market_business_small.id]: false,
      [PRICING_PLANS.market_business.id]: 'Giới hạn thông tin public',
      [PRICING_PLANS.market_business_agency.id]: 'Giới hạn thông tin public',
      [PRICING_PLANS.market_business_big_agency.id]: UNLIMITED
    }
  },
  requestMoreFieldInShopFile: {
    isRelease: true,
    name: 'requestMoreFieldInShopFile',
    description: 'Yêu cầu thêm trường thông tin trong file download danh sách Shop',
    availablePlan: {
      [PRICING_PLANS.market_default.id]: false,
      [PRICING_PLANS.market_basic.id]: false,
      [PRICING_PLANS.market_advance.id]: false,
      [PRICING_PLANS.market_business_wrap.id]: UNLIMITED,
      [PRICING_PLANS.market_business_small.id]: false,
      [PRICING_PLANS.market_business.id]: 'Giới hạn thông tin public',
      [PRICING_PLANS.market_business_agency.id]: 'Giới hạn thông tin public',
      [PRICING_PLANS.market_business_big_agency.id]: UNLIMITED
    }
  },
  savedReportLimit: {
    isRelease: true,
    name: 'savedReportLimit',
    description: 'Lưu báo cáo',
    availablePlan: {
      [PRICING_PLANS.market_default.id]: false,
      [PRICING_PLANS.market_basic.id]: false,
      [PRICING_PLANS.market_advance.id]: false,
      [PRICING_PLANS.market_business_wrap.id]: true,
      [PRICING_PLANS.market_business_small.id]: false,
      [PRICING_PLANS.market_business.id]: false,
      [PRICING_PLANS.market_business_agency.id]: true,
      [PRICING_PLANS.market_business_big_agency.id]: true
    }
  },
  apiData: {
    isRelease: true,
    name: 'apiData',
    description: 'Data APIs',
    availablePlan:
      {
        [PRICING_PLANS.market_default.id]: false,
        [PRICING_PLANS.market_basic.id]: false,
        [PRICING_PLANS.market_advance.id]: false,
        [PRICING_PLANS.market_business_wrap.id]: true,
        [PRICING_PLANS.market_business_small.id]: false,
        [PRICING_PLANS.market_business.id]: false,
        [PRICING_PLANS.market_business_agency.id]: false,
        [PRICING_PLANS.market_business_big_agency.id]: true
      }
  },
  timeTracking: {
    isRelease: false,
    name: 'timeTracking',
    description: 'Thời gian ghi nhận số liệu',
    availablePlan:
      {
        [PRICING_PLANS.market_default.id]: 'Tối đa 6 tháng',
        [PRICING_PLANS.market_basic.id]: 'Tối đa 6 tháng',
        [PRICING_PLANS.market_advance.id]: 'Tối đa 6 tháng',
        [PRICING_PLANS.market_business_wrap.id]: UNLIMITED_SUPPORT,
        [PRICING_PLANS.market_business_small.id]: '1 năm',
        [PRICING_PLANS.market_business.id]: '1 năm',
        [PRICING_PLANS.market_business_agency.id]: '1 năm',
        [PRICING_PLANS.market_business_big_agency.id]: UNLIMITED_SUPPORT
      }
  },
  platformAvailableNumber: {
    isRelease: true,
    name: 'platformAvailableNumber',
    description: 'Sàn TMĐT hỗ trợ',
    availablePlan:
      {
        [PRICING_PLANS.market_default.id]: 'Shopee',
        [PRICING_PLANS.market_basic.id]: 'Shopee',
        [PRICING_PLANS.market_advance.id]: 'Shopee',
        [PRICING_PLANS.market_business_wrap.id]: 'Shopee, Sendo, Tiki, Lazada',
        [PRICING_PLANS.market_business_small.id]: 'Shopee, Sendo, Tiki, Lazada',
        [PRICING_PLANS.market_business.id]: 'Shopee, Sendo, Tiki, Lazada',
        [PRICING_PLANS.market_business_agency.id]: 'Shopee, Sendo, Tiki, Lazada',
        [PRICING_PLANS.market_business_big_agency.id]: 'Shopee, Sendo, Tiki, Lazada'
      }
  },
}

function getFeatures(permissionName) {
  let result = []
  for (let featureKey in ALL_FEATURES) {
    let feature = ALL_FEATURES[featureKey]
    if (feature.availablePlan[permissionName] && feature.isRelease) {
      result.push(feature.name)
    }
  }
  return result
}

function getFeaturesV2(permissionName) {
  let result = []
  for (let featureKey in ALL_FEATURES_V2) {
    let feature = ALL_FEATURES_V2[featureKey]
    if (feature.availablePlan[permissionName] && feature.isRelease) {
      result.push(feature.name)
    }
  }
  return result
}

const ALL_PRICING_PLAN_V2 = {
  market_default: {
    id: 'market_default',
    name: 'Miễn phí',
    permission: PERMISSION.market_default.name,
    userPersona: '',
    types: {
      [PRICING_PLAN_DURATION_V2.trial.id]: 'market_default__5d',
      [PRICING_PLAN_DURATION_V2.threeMonths.id]: 'market_default__3m',
      [PRICING_PLAN_DURATION_V2.sixMonths.id]: 'market_default__6m',
      [PRICING_PLAN_DURATION_V2.twelveMonths.id]: 'market_default__12m',
    },
    price: {
      [PRICING_PLAN_DURATION_V2.trial.id]: null,
      [PRICING_PLAN_DURATION_V2.threeMonths.id]: null,
      [PRICING_PLAN_DURATION_V2.sixMonths.id]: null,
      [PRICING_PLAN_DURATION_V2.twelveMonths.id]: null,
    },
    promotedText: {
      [PRICING_PLAN_DURATION_V2.trial.id]: '',
      [PRICING_PLAN_DURATION_V2.threeMonths.id]: '',
      [PRICING_PLAN_DURATION_V2.sixMonths.id]: '',
      [PRICING_PLAN_DURATION_V2.twelveMonths.id]: '',
    },
    description: 'Tìm bán mặt hàng tiêu thụ cao, trending',
    listValue: [
      'Khảo sát thị trường miễn phí',
      '5 phân tích / ngày',
    ]
  },
  marketv2_small: {
    id: 'marketv2_small',
    name: 'Small',
    permission: 'marketv2_small',
    userPersona: 'Dành cho khách hàng vừa và nhỏ',
    types: {
      [PRICING_PLAN_DURATION_V2.trial.id]: 'marketv2_small__5d',
      [PRICING_PLAN_DURATION_V2.threeMonths.id]: 'marketv2_small__3m',
      [PRICING_PLAN_DURATION_V2.sixMonths.id]: 'marketv2_small__6m',
      [PRICING_PLAN_DURATION_V2.twelveMonths.id]: 'marketv2_small__12m',
    },
    price: {
      [PRICING_PLAN_DURATION_V2.trial.id]: UPDATE_PRICING_STATE,
      [PRICING_PLAN_DURATION_V2.threeMonths.id]: UPDATE_PRICING_STATE,
      [PRICING_PLAN_DURATION_V2.sixMonths.id]: UPDATE_PRICING_STATE,
      [PRICING_PLAN_DURATION_V2.twelveMonths.id]: UPDATE_PRICING_STATE,
    },
    promotedText: {
      [PRICING_PLAN_DURATION_V2.trial.id]: '',
      [PRICING_PLAN_DURATION_V2.threeMonths.id]: '',
      [PRICING_PLAN_DURATION_V2.sixMonths.id]: '',
      [PRICING_PLAN_DURATION_V2.twelveMonths.id]: '',
    },
    description: 'Theo dõi trending, đối thủ. Hướng dẫn nhân viên, người mới',
    listValue: [
      'Số liệu 1 sàn Shopee',
      '100 phân tích/ngày (300/tuần)',
      'Download 500 dòng mỗi list sản phẩm, 100 dòng mỗi list shop (max 20% full list).',
      'Theo dõi 15 đối thủ / user',
      '5 mốc thời gian phân tích: 7, 30, 90, 180, 365 ngày.',
      'Cập nhật dữ liệu: hàng tuần',
    ]
  },
  marketv2_standard: {
    id: 'marketv2_standard',
    name: 'Standard',
    permission: 'marketv2_standard',
    userPersona: 'Dành cho khách hàng phân tích volume thị trường, tìm kiếm sản phẩm bán chạy và theo dõi đối thủ',
    types: {
      [PRICING_PLAN_DURATION_V2.trial.id]: 'marketv2_standard__5d',
      [PRICING_PLAN_DURATION_V2.threeMonths.id]: 'marketv2_standard__3m',
      [PRICING_PLAN_DURATION_V2.sixMonths.id]: 'marketv2_standard__6m',
      [PRICING_PLAN_DURATION_V2.twelveMonths.id]: 'marketv2_standard__12m',
    },
    price: {
      [PRICING_PLAN_DURATION_V2.trial.id]: UPDATE_PRICING_STATE,
      [PRICING_PLAN_DURATION_V2.threeMonths.id]: UPDATE_PRICING_STATE,
      [PRICING_PLAN_DURATION_V2.sixMonths.id]: UPDATE_PRICING_STATE,
      [PRICING_PLAN_DURATION_V2.twelveMonths.id]: UPDATE_PRICING_STATE,
    },
    promotedText: {
      [PRICING_PLAN_DURATION_V2.trial.id]: '',
      [PRICING_PLAN_DURATION_V2.threeMonths.id]: '',
      [PRICING_PLAN_DURATION_V2.sixMonths.id]: '',
      [PRICING_PLAN_DURATION_V2.twelveMonths.id]: '',
    },
    description: 'Nắm rõ tỷ lệ chiếm lĩnh thị trường và đối thủ. Xác định xu hướng ngành và phân khúc khách hàng. Big Data Insight API',
    listValue: [
      'Số liệu 4 sàn riêng biệt',
      '500 phân tích/tuần (không giới hạn phân tích trong ngày)',
      'Download 1000 dòng mỗi list sản phẩm, 100 dòng mỗi list shop (max 20% full list).',
      'Phân tích tăng trưởng doanh số theo thời gian',
      '+Full tính năng gói Small',
    ]
  },
  marketv2_advanced: {
    id: 'marketv2_advanced',
    name: 'Advanced',
    permission: 'marketv2_advanced',
    userPersona: 'Dành cho khách hàng phân tích volume thị trường, tìm kiếm sản phẩm bán chạy và theo dõi đối thủ',
    types: {
      [PRICING_PLAN_DURATION_V2.trial.id]: 'marketv2_advanced__5d',
      [PRICING_PLAN_DURATION_V2.threeMonths.id]: 'marketv2_advanced__3m',
      [PRICING_PLAN_DURATION_V2.sixMonths.id]: 'marketv2_advanced__6m',
      [PRICING_PLAN_DURATION_V2.twelveMonths.id]: 'marketv2_advanced__12m',
    },
    price: {
      [PRICING_PLAN_DURATION_V2.trial.id]: CONTACT_US_STATE,
      [PRICING_PLAN_DURATION_V2.threeMonths.id]: NOT_AVAILABLE_STATE,
      [PRICING_PLAN_DURATION_V2.sixMonths.id]: UPDATE_PRICING_STATE,
      [PRICING_PLAN_DURATION_V2.twelveMonths.id]: UPDATE_PRICING_STATE,
    },
    promotedText: {
      [PRICING_PLAN_DURATION_V2.trial.id]: '',
      [PRICING_PLAN_DURATION_V2.threeMonths.id]: '',
      [PRICING_PLAN_DURATION_V2.sixMonths.id]: '',
      [PRICING_PLAN_DURATION_V2.twelveMonths.id]: '',
    },
    description: 'Nắm rõ tỷ lệ chiếm lĩnh thị trường và đối thủ. Xác định xu hướng ngành và phân khúc khách hàng. Big Data Insight API',
    listValue: [
      'Không giới hạn phân tích',
      'Download 5000 dòng mỗi list sản phẩm, 200 dòng mỗi list shop (max 20% full list)',
      'Theo dõi 20 đối thủ / user',
      'Không giới hạn mốc thời gian phân tích trong 1 năm',
      'Báo cáo chuyên sâu dành riêng cho DN (Q2/2022)',
      'Phân tích số liệu 4 sàn trong 1 báo cáo',
      '+Full tính năng gói Standard',
    ]
  },
  marketv2_premium: {
    id: 'marketv2_premium',
    name: 'Premium',
    permission: 'marketv2_premium',
    userPersona: 'Dành cho khách hàng phân tích volume thị trường, tìm kiếm sản phẩm bán chạy và theo dõi đối thủ',
    types: {
      [PRICING_PLAN_DURATION_V2.trial.id]: 'marketv2_premium__5d',
      [PRICING_PLAN_DURATION_V2.threeMonths.id]: 'marketv2_premium__3m',
      [PRICING_PLAN_DURATION_V2.sixMonths.id]: 'marketv2_premium__6m',
      [PRICING_PLAN_DURATION_V2.twelveMonths.id]: 'marketv2_premium__12m',
    },
    price: {
      [PRICING_PLAN_DURATION_V2.trial.id]: CONTACT_US_STATE,
      [PRICING_PLAN_DURATION_V2.threeMonths.id]: NOT_AVAILABLE_STATE,
      [PRICING_PLAN_DURATION_V2.sixMonths.id]: UPDATE_PRICING_STATE,
      [PRICING_PLAN_DURATION_V2.twelveMonths.id]: UPDATE_PRICING_STATE,
    },
    promotedText: {
      [PRICING_PLAN_DURATION_V2.trial.id]: '',
      [PRICING_PLAN_DURATION_V2.threeMonths.id]: '',
      [PRICING_PLAN_DURATION_V2.sixMonths.id]: '',
      [PRICING_PLAN_DURATION_V2.twelveMonths.id]: '',
    },
    description: 'Nắm rõ tỷ lệ chiếm lĩnh thị trường và đối thủ. Xác định xu hướng ngành và phân khúc khách hàng',
    listValue: [
      '5 users sử dụng',
      'Download 10.000 dòng mỗi list sản phẩm, 500 dòng mỗi list shop (max 20% full list)',
      'Theo dõi 30 đối thủ / user',
      'Không giới hạn data tải về mỗi tuần.',
      'Cập nhật dữ liệu: 2 lần/tuần',
      'Theo dõi giá sản phẩm, cập nhật hàng giờ (Q1/2022)',
      'Lưu các báo cáo đã tạo',
      '+Full tính năng gói Advanced',
    ]
  },
  marketv2_enterprise: {
    id: 'marketv2_enterprise',
    name: 'Enteprise',
    permission: 'marketv2_enterprise',
    userPersona: 'Dành cho doanh nghiệp phân tích chuyên sâu và tích hợp dữ liệu',
    types: {
      [PRICING_PLAN_DURATION_V2.trial.id]: 'MARKETV2_ENTERPRISE__5d',
      [PRICING_PLAN_DURATION_V2.threeMonths.id]: 'MARKETV2_ENTERPRISE__3m',
      [PRICING_PLAN_DURATION_V2.sixMonths.id]: 'MARKETV2_ENTERPRISE__6m',
      [PRICING_PLAN_DURATION_V2.twelveMonths.id]: 'MARKETV2_ENTERPRISE__12m',
    },
    price: {
      [PRICING_PLAN_DURATION_V2.trial.id]: CONTACT_US_STATE,
      [PRICING_PLAN_DURATION_V2.threeMonths.id]: NOT_AVAILABLE_STATE,
      [PRICING_PLAN_DURATION_V2.sixMonths.id]: NOT_AVAILABLE_STATE,
      [PRICING_PLAN_DURATION_V2.twelveMonths.id]: CONTACT_US_STATE,
    },
    promotedText: {
      [PRICING_PLAN_DURATION_V2.trial.id]: '',
      [PRICING_PLAN_DURATION_V2.threeMonths.id]: '',
      [PRICING_PLAN_DURATION_V2.sixMonths.id]: '',
      [PRICING_PLAN_DURATION_V2.twelveMonths.id]: '',
    },
    description: 'Nắm rõ tỷ lệ chiếm lĩnh thị trường và đối thủ. Xác định xu hướng ngành và phân khúc khách hàng. Big Data Insight API',
    listValue: [
      'Full tính năng Phân tích + tính năng phát triển riêng',
      'Download 10K dòng mỗi list sản phẩm, 500 dòng mỗi list shop (không limit % full list)',
      'Theo dõi 30 đối thủ / user',
      'Lịch sử doanh số: 2 năm',
      'Không giới hạn Users',
      'Không giới hạn Data APIs theo yêu cầu (product, category, keyword, …)',
    ]
  },
  marketv2_business: {
    id: 'marketv2_business',
    name: 'Business',
    permission: 'marketv2_business',
    userPersona: 'Dành cho doanh nghiệp phân tích chuyên sâu và tích hợp dữ liệu',
    types: {
      [PRICING_PLAN_DURATION_V2.trial.id]: null,
      [PRICING_PLAN_DURATION_V2.threeMonths.id]: null,
      [PRICING_PLAN_DURATION_V2.sixMonths.id]: null,
      [PRICING_PLAN_DURATION_V2.twelveMonths.id]: null,
    },
    price: {
      [PRICING_PLAN_DURATION_V2.trial.id]: CONTACT_US_STATE,
      [PRICING_PLAN_DURATION_V2.threeMonths.id]: CONTACT_US_STATE,
      [PRICING_PLAN_DURATION_V2.sixMonths.id]: CONTACT_US_STATE,
      [PRICING_PLAN_DURATION_V2.twelveMonths.id]: CONTACT_US_STATE,
    },
    promotedText: {
      [PRICING_PLAN_DURATION_V2.trial.id]: '',
      [PRICING_PLAN_DURATION_V2.threeMonths.id]: '',
      [PRICING_PLAN_DURATION_V2.sixMonths.id]: '',
      [PRICING_PLAN_DURATION_V2.twelveMonths.id]: '',
    },
  },
}

const ALL_FEATURES_V2 = {
  analyticVietNamPlatform: {
    isDisplay: true,
    isRelease: true,
    name: 'availablePlatform',
    description: 'Sàn TMĐT',
    availablePlan: {
      [ALL_PRICING_PLAN_V2.market_default.id]: ['1 sàn', '(Shopee)'],
      [ALL_PRICING_PLAN_V2.marketv2_small.id]: ['1 sàn tự chọn', '(mặc định Shopee)'],
      [ALL_PRICING_PLAN_V2.marketv2_standard.id]: ['4 sàn', '(Shopee, Lazada, Tiki, Sendo)'],
      [ALL_PRICING_PLAN_V2.marketv2_advanced.id]: ['4 sàn', '(Shopee, Lazada, Tiki, Sendo)'],
      [ALL_PRICING_PLAN_V2.marketv2_premium.id]: ['4 sàn', '(Shopee, Lazada, Tiki, Sendo)'],
      [ALL_PRICING_PLAN_V2.marketv2_enterprise.id]: ['4 sàn', '(Shopee, Lazada, Tiki, Sendo)'],
      [ALL_PRICING_PLAN_V2.marketv2_business.id]: ['4 sàn', '(Shopee, Lazada, Tiki, Sendo)'],
    }
  },
  analyticGlobalTrending: {
    isDisplay: true,
    isRelease: true,
    name: 'analyticGlobalTrending',
    description: 'Trending Sàn nước ngoài',
    availablePlan: {
      [ALL_PRICING_PLAN_V2.market_default.id]: false,
      [ALL_PRICING_PLAN_V2.marketv2_small.id]: ['1 sàn', '(1688)'],
      [ALL_PRICING_PLAN_V2.marketv2_standard.id]: ['3 sàn', '(1688, taobao, tmall)'],
      [ALL_PRICING_PLAN_V2.marketv2_advanced.id]: ['3 sàn', '(1688, taobao, tmall)'],
      [ALL_PRICING_PLAN_V2.marketv2_premium.id]: ['3 sàn', '(1688, taobao, tmall)'],
      [ALL_PRICING_PLAN_V2.marketv2_enterprise.id]: ['3 sàn', '(1688, taobao, tmall)'],
      [ALL_PRICING_PLAN_V2.marketv2_business.id]: ['3 sàn', '(1688, taobao, tmall)'],
    }
  },
  analyticMultiplePlatform: {
    isDisplay: true,
    isRelease: true,
    name: 'analyticMultiplePlatform',
    description: 'Phân tích số liệu 4 sàn trong một báo cáo',
    availablePlan: {
      [ALL_PRICING_PLAN_V2.market_default.id]: false,
      [ALL_PRICING_PLAN_V2.marketv2_small.id]: false,
      [ALL_PRICING_PLAN_V2.marketv2_standard.id]: false,
      [ALL_PRICING_PLAN_V2.marketv2_advanced.id]: true,
      [ALL_PRICING_PLAN_V2.marketv2_premium.id]: true,
      [ALL_PRICING_PLAN_V2.marketv2_enterprise.id]: true,
      [ALL_PRICING_PLAN_V2.marketv2_business.id]: true,
    }
  },
  numberOfAccount: {
    isDisplay: true,
    isRelease: true,
    name: 'numberOfAccount',
    description: 'Số người sử dụng tối đa',
    availablePlan: {
      [ALL_PRICING_PLAN_V2.market_default.id]: '1',
      [ALL_PRICING_PLAN_V2.marketv2_small.id]: '1',
      [ALL_PRICING_PLAN_V2.marketv2_standard.id]: '1',
      [ALL_PRICING_PLAN_V2.marketv2_advanced.id]: '1',
      [ALL_PRICING_PLAN_V2.marketv2_premium.id]: '5',
      [ALL_PRICING_PLAN_V2.marketv2_enterprise.id]: UNLIMITED_SUPPORT,
      [ALL_PRICING_PLAN_V2.marketv2_business.id]: UNLIMITED_SUPPORT,
    }
  },
  numberOfAnalyticPerDay: {
    isDisplay: true,
    isRelease: true,
    name: 'numberOfAnalyticPerDay',
    description: 'Số phân tích mỗi ngày',
    availablePlan: {
      [ALL_PRICING_PLAN_V2.market_default.id]: '5',
      [ALL_PRICING_PLAN_V2.marketv2_small.id]: '100',
      [ALL_PRICING_PLAN_V2.marketv2_standard.id]: UNLIMITED,
      [ALL_PRICING_PLAN_V2.marketv2_advanced.id]: UNLIMITED,
      [ALL_PRICING_PLAN_V2.marketv2_premium.id]: UNLIMITED,
      [ALL_PRICING_PLAN_V2.marketv2_enterprise.id]: UNLIMITED,
      [ALL_PRICING_PLAN_V2.marketv2_business.id]: UNLIMITED,
    }
  },
  numberOfAnalyticPerWeek: {
    isDisplay: true,
    isRelease: true,
    name: 'numberOfAnalyticPerWeek',
    description: 'Số phân tích mỗi tuần',
    availablePlan: {
      [ALL_PRICING_PLAN_V2.market_default.id]: '15',
      [ALL_PRICING_PLAN_V2.marketv2_small.id]: '300',
      [ALL_PRICING_PLAN_V2.marketv2_standard.id]: '500',
      [ALL_PRICING_PLAN_V2.marketv2_advanced.id]: UNLIMITED,
      [ALL_PRICING_PLAN_V2.marketv2_premium.id]: UNLIMITED,
      [ALL_PRICING_PLAN_V2.marketv2_enterprise.id]: UNLIMITED,
      [ALL_PRICING_PLAN_V2.marketv2_business.id]: UNLIMITED,
    }
  },
  numberOfDataLineForDownloadPerWeek: {
    isDisplay: true,
    isRelease: true,
    name: 'numberOfDataLineForDownloadPerWeek',
    description: 'Số dòng data được download mỗi tuần',
    availablePlan: {
      [ALL_PRICING_PLAN_V2.market_default.id]: false,
      [ALL_PRICING_PLAN_V2.marketv2_small.id]: '200k',
      [ALL_PRICING_PLAN_V2.marketv2_standard.id]: '500k',
      [ALL_PRICING_PLAN_V2.marketv2_advanced.id]: '2M',
      [ALL_PRICING_PLAN_V2.marketv2_premium.id]: UNLIMITED,
      [ALL_PRICING_PLAN_V2.marketv2_enterprise.id]: UNLIMITED,
      [ALL_PRICING_PLAN_V2.marketv2_business.id]: UNLIMITED_SUPPORT,
    }
  },
  timeRangeOfAnalytic: {
    isDisplay: true,
    isRelease: true,
    name: 'timeRangeOfAnalytic',
    description: 'Khoảng thời gian số liệu',
    availablePlan: {
      [ALL_PRICING_PLAN_V2.market_default.id]: ['2 mốc thời gian', '(7, 30 ngày gần nhất)'],
      [ALL_PRICING_PLAN_V2.marketv2_small.id]: ['5 khoảng thời gian', '(7, 30, 90, 180, 365 ngày gần nhất)'],
      [ALL_PRICING_PLAN_V2.marketv2_standard.id]: ['5 khoảng thời gian', '(7, 30, 90, 180, 365 ngày gần nhất)'],
      [ALL_PRICING_PLAN_V2.marketv2_advanced.id]: ['Không giới hạn chọn khoảng thời gian', '(Chọn khoảng bất kỳ trong 1 năm)'],
      [ALL_PRICING_PLAN_V2.marketv2_premium.id]: ['Không giới hạn chọn khoảng thời gian', '(Chọn khoảng bất kỳ trong 1 năm)'],
      [ALL_PRICING_PLAN_V2.marketv2_enterprise.id]: ['Không giới hạn chọn khoảng thời gian', '(Gói riêng: Chọn khoảng bất kỳ trong 2 năm)'],
      [ALL_PRICING_PLAN_V2.marketv2_business.id]: ['Không giới hạn khoảng thời gian'],
    }
  },
  compareReport: {
    isDisplay: true,
    isRelease: true,
    name: 'compareReport',
    description: 'So sánh số liệu cùng kỳ',
    availablePlan: {
      [ALL_PRICING_PLAN_V2.market_default.id]: false,
      [ALL_PRICING_PLAN_V2.marketv2_small.id]: true,
      [ALL_PRICING_PLAN_V2.marketv2_standard.id]: true,
      [ALL_PRICING_PLAN_V2.marketv2_advanced.id]: true,
      [ALL_PRICING_PLAN_V2.marketv2_premium.id]: true,
      [ALL_PRICING_PLAN_V2.marketv2_enterprise.id]: true,
      [ALL_PRICING_PLAN_V2.marketv2_business.id]: true,
    }
  },
  viewChart: {
    isDisplay: true,
    isRelease: true,
    name: 'viewChart',
    description: 'Biểu đồ tăng trưởng doanh số / lượng bán',
    availablePlan: {
      [ALL_PRICING_PLAN_V2.market_default.id]: false,
      [ALL_PRICING_PLAN_V2.marketv2_small.id]: false,
      [ALL_PRICING_PLAN_V2.marketv2_standard.id]: true,
      [ALL_PRICING_PLAN_V2.marketv2_advanced.id]: true,
      [ALL_PRICING_PLAN_V2.marketv2_premium.id]: true,
      [ALL_PRICING_PLAN_V2.marketv2_enterprise.id]: true,
      [ALL_PRICING_PLAN_V2.marketv2_business.id]: true,
    }
  },
  numberOfProductLineForDownload: {
    isDisplay: true,
    isRelease: true,
    name: 'numberOfProductLineForDownload',
    description: 'Số dòng tối đa mỗi file Product List tải về',
    availablePlan: {
      [ALL_PRICING_PLAN_V2.market_default.id]: false,
      [ALL_PRICING_PLAN_V2.marketv2_small.id]: ['500', '(100, 200, 500)', '(Giới hạn tối đa 20% phân tích)'],
      [ALL_PRICING_PLAN_V2.marketv2_standard.id]: ['1000', '(100, 200, 500, 1000)', '(Giới hạn tối đa 20% phân tích)'],
      [ALL_PRICING_PLAN_V2.marketv2_advanced.id]: ['5000', '(500, 1000, 2000, 5000)', '(Giới hạn tối đa 20% phân tích)'],
      [ALL_PRICING_PLAN_V2.marketv2_premium.id]: ['10000', '(500, 1000, 2000, 5000, 10000)', '(Giới hạn tối đa 20% phân tích)'],
      [ALL_PRICING_PLAN_V2.marketv2_enterprise.id]: ['10000', '(500, 1000, 2000, 5000, 10000)'],
      [ALL_PRICING_PLAN_V2.marketv2_business.id]: ['10000', '(500, 1000, 2000, 5000, 10000)'],
    }
  },
  numberOfShopLineForDownload: {
    isDisplay: true,
    isRelease: true,
    name: 'numberOfShopLineForDownload',
    description: 'Số dòng tối đa mỗi file Shop List tải về',
    availablePlan: {
      [ALL_PRICING_PLAN_V2.market_default.id]: false,
      [ALL_PRICING_PLAN_V2.marketv2_small.id]: ['100', '(20, 50, 100)', '(Giới hạn tối đa 20% phân tích)'],
      [ALL_PRICING_PLAN_V2.marketv2_standard.id]: ['100', '(20, 50, 100)', '(Giới hạn tối đa 20% phân tích)'],
      [ALL_PRICING_PLAN_V2.marketv2_advanced.id]: ['200', '(20, 50, 100, 200)', '(Giới hạn tối đa 20% phân tích)'],
      [ALL_PRICING_PLAN_V2.marketv2_premium.id]: ['500', '(50, 100, 200, 500)', '(Giới hạn tối đa 20% phân tích)'],
      [ALL_PRICING_PLAN_V2.marketv2_enterprise.id]: ['500', '(50, 100, 200, 500)'],
      [ALL_PRICING_PLAN_V2.marketv2_business.id]: ['500', '(50, 100, 200, 500)'],
    }
  },
  numberOfBrandLineForDownload: {
    isDisplay: true,
    isRelease: true,
    name: 'numberOfBrandLineForDownload',
    description: 'Số dòng tối đã mỗi list top thương hiệu',
    availablePlan: {
      [ALL_PRICING_PLAN_V2.market_default.id]: false,
      [ALL_PRICING_PLAN_V2.marketv2_small.id]: ['50', '(10, 20, 50)', '(Giới hạn tối đa 20% phân tích)'],
      [ALL_PRICING_PLAN_V2.marketv2_standard.id]: ['50', '(10, 20, 50)', '(Giới hạn tối đa 20% phân tích)'],
      [ALL_PRICING_PLAN_V2.marketv2_advanced.id]: ['100', '(10, 20, 50, 100)', '(Giới hạn tối đa 20% phân tích)'],
      [ALL_PRICING_PLAN_V2.marketv2_premium.id]: ['200', '(20, 50, 100, 200)', '(Giới hạn tối đa 20% phân tích)'],
      [ALL_PRICING_PLAN_V2.marketv2_enterprise.id]: ['200', '(20, 50, 100, 200)'],
      [ALL_PRICING_PLAN_V2.marketv2_business.id]: ['200', '(20, 50, 100, 200)'],
    }
  },
  numberOfTrackedShop: {
    isDisplay: true,
    isRelease: true,
    name: 'numberOfTrackedShop',
    description: 'Số shop tối đa có thể theo dõi (mỗi user)',
    availablePlan: {
      [ALL_PRICING_PLAN_V2.market_default.id]: false,
      [ALL_PRICING_PLAN_V2.marketv2_small.id]: '15',
      [ALL_PRICING_PLAN_V2.marketv2_standard.id]: '15',
      [ALL_PRICING_PLAN_V2.marketv2_advanced.id]: '20',
      [ALL_PRICING_PLAN_V2.marketv2_premium.id]: '30',
      [ALL_PRICING_PLAN_V2.marketv2_enterprise.id]: '30',
      [ALL_PRICING_PLAN_V2.marketv2_business.id]: '30',
    }
  },
  templateReport: {
    isDisplay: true,
    isRelease: true,
    name: 'templateReport',
    description: 'Báo cáo mẫu cho Doanh nghiệp (Q2/2022)',
    availablePlan: {
      [ALL_PRICING_PLAN_V2.market_default.id]: false,
      [ALL_PRICING_PLAN_V2.marketv2_small.id]: false,
      [ALL_PRICING_PLAN_V2.marketv2_standard.id]: false,
      [ALL_PRICING_PLAN_V2.marketv2_advanced.id]: [true, 'Dự kiến Quý 2/2022'],
      [ALL_PRICING_PLAN_V2.marketv2_premium.id]: [true, 'Dự kiến Quý 2/2022'],
      [ALL_PRICING_PLAN_V2.marketv2_enterprise.id]: [true, 'Dự kiến Quý 2/2022'],
      [ALL_PRICING_PLAN_V2.marketv2_business.id]: [true, 'Dự kiến Quý 2/2022'],
    }
  },
  deepAnalytic: {
    isDisplay: true,
    isRelease: true,
    name: 'deepAnalytic',
    description: 'Phân tích sâu (Deep Overview) cho Doanh nghiệp',
    availablePlan: {
      [ALL_PRICING_PLAN_V2.market_default.id]: false,
      [ALL_PRICING_PLAN_V2.marketv2_small.id]: false,
      [ALL_PRICING_PLAN_V2.marketv2_standard.id]: false,
      [ALL_PRICING_PLAN_V2.marketv2_advanced.id]: [true, 'Dự kiến Quý 2/2022'],
      [ALL_PRICING_PLAN_V2.marketv2_premium.id]: [true, 'Dự kiến Quý 2/2022'],
      [ALL_PRICING_PLAN_V2.marketv2_enterprise.id]: [true, 'Dự kiến Quý 2/2022'],
      [ALL_PRICING_PLAN_V2.marketv2_business.id]: [true, 'Dự kiến Quý 2/2022'],
    }
  },
  trackPriceOfProductPerHour: {
    isDisplay: true,
    isRelease: true,
    name: 'trackPriceOfProductPerHour',
    description: 'Theo dõi giá sản phẩm (hàng giờ)',
    availablePlan: {
      [ALL_PRICING_PLAN_V2.market_default.id]: false,
      [ALL_PRICING_PLAN_V2.marketv2_small.id]: false,
      [ALL_PRICING_PLAN_V2.marketv2_standard.id]: false,
      [ALL_PRICING_PLAN_V2.marketv2_advanced.id]: false,
      [ALL_PRICING_PLAN_V2.marketv2_premium.id]: [true, '(50 sp/account)', 'Dự kiến Quý 1/2022'],
      [ALL_PRICING_PLAN_V2.marketv2_enterprise.id]: [true, '(50 sp/account)', 'Dự kiến Quý 1/2022'],
      [ALL_PRICING_PLAN_V2.marketv2_business.id]: [true, '(50 sp/account)', 'Dự kiến Quý 1/2022'],
    }
  },
  saveReport: {
    isDisplay: true,
    isRelease: true,
    name: 'saveReport',
    description: 'Lưu báo cáo (Tự tạo báo cáo)',
    availablePlan: {
      [ALL_PRICING_PLAN_V2.market_default.id]: false,
      [ALL_PRICING_PLAN_V2.marketv2_small.id]: false,
      [ALL_PRICING_PLAN_V2.marketv2_standard.id]: false,
      [ALL_PRICING_PLAN_V2.marketv2_advanced.id]: false,
      [ALL_PRICING_PLAN_V2.marketv2_premium.id]: true,
      [ALL_PRICING_PLAN_V2.marketv2_enterprise.id]: true,
      [ALL_PRICING_PLAN_V2.marketv2_business.id]: true,
    }
  },
  downloadDataByAPI: {
    isDisplay: true,
    isRelease: true,
    name: 'downloadDataByAPI',
    description: 'Tải dữ liệu bằng APIs',
    availablePlan: {
      [ALL_PRICING_PLAN_V2.market_default.id]: false,
      [ALL_PRICING_PLAN_V2.marketv2_small.id]: false,
      [ALL_PRICING_PLAN_V2.marketv2_standard.id]: false,
      [ALL_PRICING_PLAN_V2.marketv2_advanced.id]: false,
      [ALL_PRICING_PLAN_V2.marketv2_premium.id]: false,
      [ALL_PRICING_PLAN_V2.marketv2_enterprise.id]: [true, '(Big Data APIs)'],
      [ALL_PRICING_PLAN_V2.marketv2_business.id]: [true, '(Big Data APIs)'],
    }
  },
  compareReportByYear: {
    isDisplay: true,
    isRelease: true,
    name: 'compareReportByYear',
    description: 'Số liệu cùng kỳ năm ngoái',
    availablePlan: {
      [ALL_PRICING_PLAN_V2.market_default.id]: false,
      [ALL_PRICING_PLAN_V2.marketv2_small.id]: false,
      [ALL_PRICING_PLAN_V2.marketv2_standard.id]: false,
      [ALL_PRICING_PLAN_V2.marketv2_advanced.id]: [true, 'Dự kiến Quý 2/2022'],
      [ALL_PRICING_PLAN_V2.marketv2_premium.id]: [true, 'Dự kiến Quý 2/2022'],
      [ALL_PRICING_PLAN_V2.marketv2_enterprise.id]: [true, 'Dự kiến Quý 2/2022'],
      [ALL_PRICING_PLAN_V2.marketv2_business.id]: [true, 'Dự kiến Quý 2/2022'],
    }
  },
}
const LIST_VOUCHER = [
  {
    id: '1d',
    type: '1d',
    title: 'Vip 01 Ngày',
    name: 'Thời gian dùng',
    content: '1 ngày',
    sub_content: '1 tuần',
    price: '100000',
    text_price: '100K',
    promoted_text: '',
    btn_title: 'Nâng cấp',
    isActive: false,
    isPromote: true
  },
  {
    id: '7d',
    type: '7d',
    title: 'Vip 07 Ngày',
    name: 'Thời gian dùng',
    content: '7 ngày',
    sub_content: '1 tuần',
    price: '100000',
    text_price: '100K',
    promoted_text: '',
    btn_title: 'Nâng cấp',
    isActive: false,
    isPromote: true
  },
  {
    id: '14d',
    type: '14d',
    title: 'Vip 14 Ngày',
    name: 'Thời gian dùng',
    content: '14 ngày',
    sub_content: '2 tuần',
    price: '199000',
    text_price: '199K',
    promoted_text: '',
    btn_title: 'Nâng cấp',
    isActive: true,
    isPromote: false
  },
  {
    id: '30d',
    type: '30d',
    title: 'Vip 30 Ngày',
    name: 'Thời gian dùng',
    content: '30 ngày',
    sub_content: '1 tháng',
    price: '299000',
    text_price: '299K',
    // promoted_text: 'Tiết kiệm 130k so với gói 7 ngày',
    btn_title: 'Nâng cấp',
    isActive: true,
    isPromote: false
  },
  {
    id: '90d',
    type: '90d',
    title: 'Vip 90 Ngày',
    name: 'Thời gian dùng',
    content: '90 ngày',
    sub_content: '3 tháng',
    price: '599000',
    text_price: '599K',
    // promoted_text: 'Tiết kiệm 300k so với gói 30 ngày',
    btn_title: 'Nâng cấp',
    isActive: true,
    isPromote: false
  },
  {
    id: '180d',
    type: '180d',
    title: 'Vip 180 Ngày',
    name: 'Thời gian dùng',
    content: '180 ngày',
    sub_content: '6 tháng',
    price: '899000',
    text_price: '899K',
    // promoted_text: 'Tiết kiệm 300k so với gói 90 ngày',
    btn_title: 'Nâng cấp',
    isActive: true,
    isPromote: false
  },
  {
    id: 'market_business_agency__1w',
    title: 'Gói Doanh nghiệp 1 tuần',
    btn_title: 'Nâng cấp',
    isActive: false,
    isPromote: false
  },
]

const LIST_BANK_ACCOUNT = [
  {
    id: 1,
    type: 'personal',
    bankName: 'Ngân hàng TMCP Kỹ thương Việt Nam',
    accountName: 'HÀ THANH TÙNG',
    accountNumber: '19022011582022',
    accountAddress: '',
    urlLogo: 'https://www.techcombank.com.vn/Content/images/logo.png'
  },
  // {
  //   id: 2,
  //   type: 'company',
  //   bankName: 'Ngân hàng Ngân hàng TMCP Việt Nam Thịnh Vượng (VPBank)',
  //   accountName: 'DATA SCIENCE JSC (Công ty Cổ phần Khoa học Dữ liệu)',
  //   accountNumber: '180945587',
  //   accountAddress: '',
  //   urlLogo: 'https://neo.vpbank.com.vn/resources/sap/ui/core/mimes/logo/VPBankNeo_Slogan.png'
  // }
]

const TRANSACTION_STATE = {
  'COMPLETE': 'COMPLETE',
  'ERROR': 'ERROR',
  'WAITING': 'WAITING'
}

export {
  PRICING_PLANS,
  PRICING_PLAN_DURATION,
  ALL_FEATURES,
  UPDATE_PRICING_STATE,
  CONTACT_US_STATE,
  NOT_AVAILABLE_STATE,
  UNLIMITED_SUPPORT,
  PRICING_PLAN_DURATION_V2,
  ALL_PRICING_PLAN_V2,
  ALL_FEATURES_V2,
  UNLIMITED,
  LIST_VOUCHER,
  TRANSACTION_STATE,
  LIST_BANK_ACCOUNT
}
