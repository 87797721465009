import { URL_AUTH_SERVICE_API } from '@/config/ConfigEndpoint'

export const urlGoogleAuthCallback = () => {
  return `${ URL_AUTH_SERVICE_API }/auth/google/callback`
}

export const urlGoogleAuthCallbackV2 = () => {
  return `${ URL_AUTH_SERVICE_API }/v2/auth/google/callback`
}

export const urlLogout = () => {
  return `${ URL_AUTH_SERVICE_API }/auth/logout`
}

export const urlLogoutAllDevice = () => {
  return `${ URL_AUTH_SERVICE_API }/auth/logout-all`
}
