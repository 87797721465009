import {ALL_CHART_TAB, ALL_GENERAL_STATISTIC, ALL_METRIC} from "@/constant/general/GeneralConstant";

const aggData = {}
for (const chartTabKey in ALL_CHART_TAB) {
  let chartTabStoreName = ALL_CHART_TAB[chartTabKey].storeName
  aggData[chartTabStoreName] = {}
  for (const metricKey in ALL_METRIC) {
    let metricStoreName = ALL_METRIC[metricKey].storeName
    aggData[chartTabStoreName][metricStoreName] = `${chartTabStoreName}_${metricStoreName}`
  }
}

for (const generalStatisticKey in ALL_GENERAL_STATISTIC) {
  let generalStatisticStoreName =  ALL_GENERAL_STATISTIC[generalStatisticKey].storeName
  aggData[generalStatisticStoreName] = generalStatisticStoreName
  if (ALL_GENERAL_STATISTIC[generalStatisticKey].storeNameDetailStatistic) {
    aggData[ALL_GENERAL_STATISTIC[generalStatisticKey].storeNameDetailStatistic] = ALL_GENERAL_STATISTIC[generalStatisticKey].storeNameDetailStatistic
  }
}

const MutationSearchStatisticResult = {
  MODULE_PATH: "SearchStatisticResultModule",
  status: 'status',
  setOrderTotal: 'setOrderTotal',
  setRevenueTotal: 'setRevenueTotal',
  setProductTotal: 'setProductTotal',
  setShopTotal: 'setShopTotal',
  shopMall: 'shopMall',
  otherShop: 'otherShop',
  revenueByShopMall: 'revenueByShopMall',
  revenueByOtherShop: 'revenueByOtherShop',
  orderByShopMall: 'orderByShopMall',
  orderByOtherShop: 'orderByOtherShop',
  setCommissionTotal: 'setCommissionTotal',
  setAggRevenueByCategories: 'setAggRevenueByCategories',
  setAggRevenueByPriceRange: 'setAggRevenueByPriceRange',
  setAggRevenueByBrands: 'setAggRevenueByBrands',
  setAggRevenueByLocations: 'setAggRevenueByLocations',

  setAggOrderCountByCategories: 'setAggOrderCountByCategories',
  setAggOrderCountByPriceRange: 'setAggOrderCountByPriceRange',
  setAggOrderCountByBrands: 'setAggOrderCountByBrands',
  setAggOrderCountByLocations: 'setAggOrderCountByLocations',

  setAggCommissionByCategories: 'setAggCommissionByCategories',
  setAggCommissionByPriceRange: 'setAggCommissionByPriceRange',
  setAggCommissionByBrands: 'setAggCommissionByBrands',
  setAggCommissionByLocations: 'setAggCommissionByLocations',
  ...aggData,
  RESET: 'RESET',
}

export {
  MutationSearchStatisticResult
}
