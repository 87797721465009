import {MessageExternalHandlerConstant} from "@/constant/extension/ExtensionConstant";

const getCookie = async (extensionId, url, key, defaultValue = null) => {
  if (chrome?.runtime?.sendMessage) {
    return new Promise((resolve, reject) => {
      chrome.runtime.sendMessage(
        extensionId,
        {
          name: MessageExternalHandlerConstant.GET_COOKIE,
          data: {
            url: url,
            key: key,
            defaultValue: defaultValue,
          }
        },
        function (response) {
          resolve(response)
        })
    })
  }
  return null
}

const setCookie = async (extensionId, url, key, value, days = 30) => {
  if (chrome?.runtime?.sendMessage) {
    return new Promise((resolve, reject) => {
      chrome.runtime.sendMessage(
        extensionId,
        {
          name: MessageExternalHandlerConstant.SET_COOKIE,
          data: {
            url: url,
            key: key,
            value: value,
            days: days
          }
        },
        function (response) {
          resolve(response)
        })
    })
  }
  return null
}

const getLocalStorageExtension = async (extensionId, key, defaultValue = null) => {
  try {
    if (chrome?.runtime?.sendMessage) {
      return new Promise((resolve, reject) => {
        chrome.runtime.sendMessage(
          extensionId,
          {
            name: MessageExternalHandlerConstant.GET_LOCALSTORAGE_EXTENSION,
            data: {
              key: key,
              defaultValue: defaultValue
            }
          },
          function (response) {
            resolve(response)
          })
      })
    }
  }
  catch (e) {
    // console.log(e)
  }
  return defaultValue
}

const setLocalStorageExtension = async (extensionId, key, value = null) => {
  if (chrome?.runtime?.sendMessage) {
    return new Promise((resolve, reject) => {
      chrome.runtime.sendMessage(
        extensionId,
        {
          name: MessageExternalHandlerConstant.SET_LOCALSTORAGE_EXTENSION,
          data: {
            key: key,
            value: value
          }
        },
        function (response) {
          resolve(response)
        })
    })
  }
  return null
}

const hasExtension = async (extensionId) => {
  if (extensionId === 'dev') {
    return true
  }
  try {
    if (chrome && chrome.runtime) {
      return await new Promise((resolve) => {
        chrome.runtime.sendMessage(extensionId, {name: 'GET_STATUS'}, () => {
          if (chrome.runtime.lastError) {
            resolve(false);
          } else {
            resolve(true);
          }
        })
      })
    } else {
      return false
    }
  } catch (e) {
    return false
  }
}

export {
  setCookie,
  getCookie,
  getLocalStorageExtension,
  setLocalStorageExtension,
  hasExtension
}
