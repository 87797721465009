import {http, httpDefault} from "@/plugin/http"
import {
  urlGoogleAuthCallbackV2,
  urlLogout,
  urlLogoutAllDevice
} from '@/api/auth/AuthEndpoint'

export const apiGoogleAuthCallback = async (payload) => {
  const urlApi = urlGoogleAuthCallbackV2()
  try {
    const response = await http.post(urlApi, payload)
    if (response?.data != null) {
      return response.data
    }
  } catch (e) {
    return e.response.status
  }
  return null
}

export const apiLogout = async (payload) => {
  const urlApi = urlLogout()
  try {
    const response = await httpDefault.post(urlApi, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      }
    })
    if (response?.data != null) {
      return response.data
    }
  } catch (e) {
    console.log(e)
  }
  return null
}

export const apiLogoutAll = async (payload) => {
  const urlApi = urlLogoutAllDevice()
  try {
    const response = await httpDefault.post(urlApi, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      }
    })
    if (response?.data != null) {
      return response.data
    }
  } catch (e) {
    console.log(e)
  }
  return null
}
