
const ActionNameConstants = {
  SEARCH: 'SEARCH',
  MARKET: 'MARKET',
  MARKET_GLOBAL: 'MARKET_GLOBAL',
  HOME_PAGE: 'HOME_PAGE',
  USER: 'USER',
  PRICE_ALERT: 'PRICE_ALERT',
  SUBSCRIBE: 'SUBSCRIBE',
}

const CNY_RATIO = 1/100
const CNY_RATE = 3541
const MAX_LENGTH_OF_REPORT_NAME = 150

const QUOTA_PRODUCT_ALERT = 50;

const LIST_REFERRAL_CODE = {
  'ha01': 'Hoàng Anh',
}

const PARTNER_TYPE = {
  affiliate: 'affiliate',
  delivery: 'delivery'
}

const PARTNER = {
  // 'accesstrade': {
  //   domain: 'accesstrade.vn',
  //   linkLogo: 'https://accesstrade.vn/wp-content/themes/accesstradev2/assets/images/logo-normal.png',
  //   type: PARTNER_TYPE.affiliate
  // },
  // 'masoffer': {
  //   domain: 'masoffer.com',
  //   linkLogo: 'https://masoffer.com/wp-content/uploads/2016/03/mo2-300x51.png',
  //   type: PARTNER_TYPE.affiliate
  // },
  // 'adpia': {
  //   domain: 'adpia.vn',
  //   linkLogo: 'https://lh3.googleusercontent.com/pw/ACtC-3feZZ7nWcBJ3kgnL4j4uKHXJEDWqaM4dLy5s5aDaorWqAp-ZAakuzYkn1eu-gIQvy9-BC8LXVD_k9c20uAVlDcqmo3-gkbhbTArRBNlYeG7QngBlWwBEE7Eb_03fCjeHwrwRiRnE-Ni0qdJYJajNWk=w108-h55-no',
  //   type: PARTNER_TYPE.affiliate
  // },
  // 'viettelpost': {
  //   domain: 'viettelpost.com.vn',
  //   linkLogo: 'https://viettelpost.com.vn/wp-content/uploads/2019/06/logo-01.png',
  //   type: PARTNER_TYPE.delivery
  // },
}

const ALL_COUNTRY_SUPPORTED = {
  china: 'Trung Quốc',
  vietnam: 'Việt Nam',
}

const PLATFORM_ID_OBJECT = {
  shopee: 1,
  lazada: 2,
  tiki: 3,
  sendo: 4,
  all_vietnam: 'c',
  _1688: 5,
  taobao: 6,
  tmall: 7
}

const PLATFORM_APPLIED_CNY_RATIO_ARRAY = [
  PLATFORM_ID_OBJECT._1688,
  PLATFORM_ID_OBJECT.taobao,
  PLATFORM_ID_OBJECT.tmall,
]

const ALL_PLATFORM_BASE_OBJECT = {
  /**
   * uniqueName: property to refer other object
   */
  shopee: {
    platform_id: PLATFORM_ID_OBJECT.shopee,
    name: 'Shopee',
    uniqueName: 'shopee', //property to refer other object,
    sortDefault: 'order_revenue',
    color: '#F4915A',
    urlLogo: 'https://lh3.googleusercontent.com/mvI9jsrKjP7Q7ZqYOXuJTRZF9Q1e4PZJjjmNAyzh6OKtxtSUNPK920MMFsXploU60VPMqHCdJLEMzaEF_ktvIXuII2IEamnw0Cr5Cb97_803ePqQQYOLtHQLrVUe9e2DPZ3MoxTp',
    country: ALL_COUNTRY_SUPPORTED.vietnam,
  },
  /**
   * uniqueName: property to refer other object
   */
  lazada: {
    platform_id: PLATFORM_ID_OBJECT.lazada,
    name: 'Lazada',
    uniqueName: 'lazada',
    sortDefault: 'order_revenue',
    color: '#E25A5A',
    urlLogo: 'https://laz-img-cdn.alicdn.com/tfs/TB1ODo.f2b2gK0jSZK9XXaEgFXa-64-64.png',
    country: ALL_COUNTRY_SUPPORTED.vietnam,
  },
  /**
   * uniqueName: property to refer other object
   */
  tiki: {
    platform_id: PLATFORM_ID_OBJECT.tiki,
    name: 'Tiki',
    uniqueName: 'tiki',
    sortDefault: 'order_revenue',
    color: '#5997F5',
    urlLogo: 'https://lh3.googleusercontent.com/-Rwy9DczZyAbvIivqO1fAmSWRw0cThG3fRXMjOOVsOtTiDlAdMUff8N_zmvCxI8CGzH-dWZdQNXFIGhmaR4Mc8J0GrkZwKL-MvsjsOdLw_9Aa9xJjVAKED4MCb1OtggIcnWFUPwRQQ',
    country: ALL_COUNTRY_SUPPORTED.vietnam,
  },
  /**
   * uniqueName: property to refer other object
   */
  sendo: {
    platform_id: PLATFORM_ID_OBJECT.sendo,
    name: 'Sendo',
    uniqueName: 'sendo',
    sortDefault: 'order_revenue',
    color: '#FBD0D8',
    urlLogo: 'https://lh3.googleusercontent.com/f7BgeL7wuw-UN_K66Ufiw63jZuJxWhQsz3XC0fZcvxUyJetPF3yvAT0-floqa7hPKwAn7taW0AS0myVVYH3yp6dUR_soEmCQSugI0CQWyFctBWVQ8r3ljxKasQgE1zETnxNWVlcnog',
    country: ALL_COUNTRY_SUPPORTED.vietnam,
  },
  /**
   * uniqueName: property to refer other object
   */
  all_vietnam: {
    platform_id: PLATFORM_ID_OBJECT.all_vietnam,
    platformIdsIncluded: [
      PLATFORM_ID_OBJECT.shopee,
      PLATFORM_ID_OBJECT.lazada,
      PLATFORM_ID_OBJECT.tiki,
      PLATFORM_ID_OBJECT.sendo,
    ],
    name: 'Tất cả',
    uniqueName: 'all_vietnam', //property to refer other object,
    sortDefault: 'order_revenue',
    color: '',
    urlLogo: '',
    country: ALL_COUNTRY_SUPPORTED.vietnam,
  },
  /**
   * uniqueName: property to refer other object
   */
  _1688: {
    country: ALL_COUNTRY_SUPPORTED.china,
    platform_id: PLATFORM_ID_OBJECT._1688,
    name: '1688',
    uniqueName: '_1688',
    sortDefault: 'order_revenue',
    urlLogo: 'https://img.alicdn.com/tps/TB1K3LKPpXXXXX2XXXXXXXXXXXX-111-38.png',
  },
  /**
   * uniqueName: property to refer other object
   */
  taobao: {
    country: ALL_COUNTRY_SUPPORTED.china,
    platform_id: PLATFORM_ID_OBJECT.taobao,
    name: 'Taobao',
    uniqueName: 'taobao',
    sortDefault: 'order_revenue',
    urlLogo: 'https://img.alicdn.com/tps/TB1K3LKPpXXXXX2XXXXXXXXXXXX-111-38.png',
  },
  /**
   * uniqueName: property to refer other object
   */
  tmall: {
    country: ALL_COUNTRY_SUPPORTED.china,
    platform_id: PLATFORM_ID_OBJECT.tmall,
    name: 'Tmall',
    uniqueName: 'tmall',
    sortDefault: 'order_revenue',
    urlLogo: 'https://img.alicdn.com/tps/TB1K3LKPpXXXXX2XXXXXXXXXXXX-111-38.png',
  }
}


const ALL_CHART_TAB = {
  revenue: {
    name: 'revenue',
    generalStatisticName: 'revenue',
    storeName: 'revenue',
    apiKey: 'REVENUE',
    label: 'Doanh số',
    iconType: 'dollar'
  },
  orderCount: {
    name: 'orderCount',
    generalStatisticName: 'orderCount',
    storeName: 'orderCount',
    apiKey: 'ORDER_COUNT',
    label: 'Số sản phẩm đã bán',
    iconType: 'unordered-list'
  },
  commission: {
    name: 'commission',
    generalStatisticName: 'commission',
    storeName: 'commission',
    apiKey: 'COMMISSION',
    label: 'Hoa hồng Marketing',
    iconType: 'dollar'
  },
  reviewCount: {
    name: 'reviewCount',
    generalStatisticName: 'reviewCount',
    storeName: 'reviewCount',
    apiKey: 'REVIEW_COUNT',
    label: 'Số đánh giá',
    iconType: 'unordered-list'
  },
}

const ALL_GENERAL_STATISTIC = {
  [ALL_CHART_TAB.revenue.generalStatisticName]: {
    name: ALL_CHART_TAB.revenue.generalStatisticName,
    storeNameDetailStatistic: 'revenueTotalDetail',
    apiKeyDetailStatistic: 'order_revenue',
    storeName: 'revenueTotal',
    apiKey: 'REVENUE_TOTAL',
    label: 'Doanh số',
    unit: 'VNĐ'
  },
  [ALL_CHART_TAB.orderCount.generalStatisticName]: {
    name: ALL_CHART_TAB.orderCount.generalStatisticName,
    storeNameDetailStatistic: 'orderTotalDetail',
    apiKeyDetailStatistic: 'order_count',
    storeName: 'orderTotal',
    apiKey: 'ORDER_TOTAL',
    label: 'Sản phẩm đã bán',
    unit: 'Sp'
  },
  [ALL_CHART_TAB.commission.generalStatisticName]: {
    name: ALL_CHART_TAB.commission.generalStatisticName,
    storeNameDetailStatistic: null,
    storeName: 'commissionTotal',
    apiKey: 'COMMISION_TOTAL',
    label: 'Hoa hồng Marketing',
    unit: 'VNĐ'
  },
  [ALL_CHART_TAB.reviewCount.generalStatisticName]: {
    name: ALL_CHART_TAB.reviewCount.generalStatisticName,
    storeNameDetailStatistic: null,
    storeName: 'reviewTotal',
    apiKey: 'REVIEW_TOTAL',
    label: 'Lượt Đánh giá',
    unit: 'Lượt'
  },
  product: {
    name: 'product',
    storeNameDetailStatistic: null,
    storeName: 'productTotal',
    apiKey: 'PRODUCT_TOTAL',
    label: 'Sản phẩm có lượt bán',
    unit: 'Sp'
  },
  shop: {
    name: 'shop',
    storeNameDetailStatistic: null,
    storeName: 'shopTotal',
    apiKey: 'SHOP_TOTAL',
    label: 'Shop có lượt bán',
    unit: 'Shop'
  },
}

const ALL_METRIC = {
  'platforms': {
    name: 'platforms',
    storeName: 'platforms',
    apiKey: 'PLATFORMS',
    label: 'các Sàn'
  },
  'categories': {
    name: 'categories',
    storeName: 'categories',
    apiKey: 'CATEGORIES',
    label: 'Ngành hàng'
  },
  'priceRange': {
    name: 'priceRange',
    storeName: 'priceRange',
    apiKey: 'PRICE_RANGE',
    label: 'Mức Giá'
  },
  'brands': {
    name: 'brands',
    storeName: 'brands',
    apiKey: 'BRANDS',
    label: 'Thương hiệu'
  },
  'location': {
    name: 'location',
    storeName: 'location',
    apiKey: 'LOCATION',
    label: 'Vùng miền (người bán/shop)',
    sortLabel: 'Vùng miền'
  },
  'shopType': {
    name: 'shopType',
    storeName: 'shopType',
    apiKey: 'SHOP_TYPE',
    label: 'kiểu Shop',
  },
}

const MESSENGER_ID = 'metric.ecom'

const MARKET_PLATFORM_ID_HEADER = {
  beecost: {
    id: 1
  },
  vts: {
    id: 2
  },
  vtp: {
    id: 3
  },
}

const HEADER_CONFIG = {
  platformId: MARKET_PLATFORM_ID_HEADER.beecost.id,
  token: null
}

const BRAND_NAME = 'Ecomviet Metric'
const SOLOGAN = 'Nền tảng Số liệu E-Commerce'

const NOTIFICATION_TYPE = {
  empty_response_search: 'empty_response_search',
  upgrade_plan: 'upgrade_plan',
  downgrade_plan: 'downgrade_plan',
  upgrade_permission: 'upgrade_permission',
  upgrade_business_permission: 'upgrade_business_permission',
  upgrade_company_permission: 'upgrade_company_permission',
  upgrade_permission_search_shop: 'upgrade_permission_search_shop',
  upgrade_permission_statistic_detail: 'upgrade_permission_statistic_detail',
  platform_url_error: 'platform_url_error',
  shop_url_error: 'shop_url_error',
  link_url_error: 'link_url_error',
  shop_empty_data_error: 'shop_empty_data_error',
  product_empty_data_error: 'product_empty_data_error',
  out_of_free_search: 'out_of_free_search',
  out_of_saved_report: 'out_of_saved_report',
  create_saved_report: 'tao-bao-cao',
}

export {
  ActionNameConstants,
  LIST_REFERRAL_CODE,
  PARTNER,
  PARTNER_TYPE,
  PLATFORM_ID_OBJECT,
  ALL_PLATFORM_BASE_OBJECT,
  ALL_CHART_TAB,
  ALL_GENERAL_STATISTIC,
  ALL_METRIC,
  ALL_COUNTRY_SUPPORTED,
  MESSENGER_ID,
  MARKET_PLATFORM_ID_HEADER,
  HEADER_CONFIG,
  BRAND_NAME,
  NOTIFICATION_TYPE,
  SOLOGAN,
  CNY_RATIO,
  CNY_RATE,
  PLATFORM_APPLIED_CNY_RATIO_ARRAY,
  MAX_LENGTH_OF_REPORT_NAME,
  QUOTA_PRODUCT_ALERT,
}
