import {getPlatformById} from "@/service/platform/PlatformCommonService";
import {getValueByKey} from "@/helper/ArrayHelper";
import {
  ALL_CATEGORIES_BY_PLATFORM,
  ALL_CATEGORIES_LV2_BY_PLATFORM
} from "@/constant/product/ProductCategoriesConstant";
import {
  ALL_CHART_TAB,
  ALL_GENERAL_STATISTIC,
  ALL_METRIC, CNY_RATE, CNY_RATIO, PLATFORM_APPLIED_CNY_RATIO_ARRAY,
} from "@/constant/general/GeneralConstant";
import {formatCurrency} from "@/helper/FormatHelper";
import {getDateFromOption} from "@/service/search/SearchService";
import moment from "moment";
import {ALL_SHOP_TYPE} from "@/constant/search/SearchConstant";

const sortArrayByOrderArray = (array, orderArray) => {
  let categoriesDataTemp = []
  for (const orderElement of orderArray) {
    let categoriesDataElem = array.find(item => item.name === orderElement) || {}
    categoriesDataElem.name = orderElement
    categoriesDataElem.value = categoriesDataElem?.value ? categoriesDataElem.value : 0
    categoriesDataTemp.push({
      ...categoriesDataElem,
    })
    if (categoriesDataElem) {
      array.splice(array.indexOf(categoriesDataElem), 1)
    }
  }
  categoriesDataTemp = [
    ...categoriesDataTemp,
    ...array
  ]
  return categoriesDataTemp
}

const calculateCategoriesChartData = (aggregationsEntitiesData, platformId) => {
  let categoriesData = []
  let totalGroup = 0
  let platform = getPlatformById(platformId)
  let isCategoryDetail = !ALL_CATEGORIES_BY_PLATFORM[platform.uniqueName].find(item => item.value === aggregationsEntitiesData?.[0]?.['entities']?.[aggregationsEntitiesData?.[0]?.['entities'].length - 1]?.key)
  for (let element of aggregationsEntitiesData?.[0]?.['entities']) {
    if (element && !element['value']) {
      element.value = element.platforms?.reduce(
        (previousValue, currentValue) => previousValue + currentValue.value,
        0
      )
    }
    if (element?.['value'] === 0) {
      continue
    }
    let catParent = null
    let catLabel = null
    if (isCategoryDetail) {
      let cat = getValueByKey(ALL_CATEGORIES_LV2_BY_PLATFORM[platform.uniqueName], 'value', element?.['key'])
      if(!cat) {
        continue
      }
      catParent = cat.parent
      catLabel = cat.label
      let catLabelDuplicate = categoriesData.filter(item => item['name'] === cat['label'])?.[0]
      if (cat && catLabelDuplicate) {
        let catDuplicateParent = getValueByKey(ALL_CATEGORIES_BY_PLATFORM[platform.uniqueName], 'value', catLabelDuplicate.parent)
        catLabelDuplicate.name = `${catDuplicateParent.label} - ${catLabelDuplicate.name}`
        let catParent = getValueByKey(ALL_CATEGORIES_BY_PLATFORM[platform.uniqueName], 'value', cat.parent)
        catLabel = `${catParent.label} - ${cat.label}`
      }
    } else {
      catLabel = getValueByKey(ALL_CATEGORIES_BY_PLATFORM[platform.uniqueName], 'value', element?.['key'])?.['label']
    }
    if (catLabel != null) {
      totalGroup += element?.['value']
      categoriesData.push({
        name: catLabel,
        value: element?.['value'],
        valueByPlatform: element?.platforms,
        parent: catParent
      })
    }
  }
  return {
    categoriesData,
    totalGroup
  }
}

const calculatePriceRangeChartData = (aggregationsEntitiesData, priceRate = 1) => {
  let categoriesData = []
  let totalGroup = 0
  for (let element of aggregationsEntitiesData?.[0]?.['entities']) {
    if (element && !element['value']) {
      element.value = element.platforms?.reduce(
        (previousValue, currentValue) => previousValue + currentValue.value,
        0
      )
    }
    if (element?.['value'] === 0) {
      continue
    }
    totalGroup += element?.['value']

    let categoryName = ''
    if (element?.['from'] == null) {
      categoryName = '< '
    }
    if (element?.['to'] == null) {
      categoryName = '> '
    }
    if (element?.['from'] != null) {
      categoryName += formatCurrency(element?.['from']*priceRate - element?.['from']*priceRate%1000)
    }
    if (element?.['to'] != null) {
      if (element?.['from'] != null) {
        categoryName += ` - ${formatCurrency(element?.['to']*priceRate - element?.['to']*priceRate%1000)}`
      } else {
        categoryName += formatCurrency(element?.['to']*priceRate - element?.['to']*priceRate%1000)
      }
    }
    categoriesData.push({
      value: element?.['value'],
      valueByPlatform: element?.platforms,
      name: categoryName,
      min: typeof element?.['from'] === "number" ? element?.['from'] : 0
    })
  }

  return {
    categoriesData,
    totalGroup
  }
}

/**
 * Check (startDate, endDate) is full month
 * @param startDate{Date}
 * @param endDate{Date}
 * @return isFullMonth{Boolean}
 */
const checkFullMonth = (startDate, endDate) => {
  let startMoment = moment(startDate)
  let endMoment = moment(endDate)
  let isFullMonth = startMoment.date() == 1
                    && startMoment.month() == endMoment.month()
                    && endMoment.month() != moment(endDate).add(1, 'day').month()
  return isFullMonth
}

const calculateDetailGeneralStatisticChartData = (detailGeneralStatisticChartData) => {
  let categoriesData = []
  let totalGroup = 0
  for (let element of detailGeneralStatisticChartData) {
    if (!element || element['value'] === 0 || element['from'] == null || element['to'] == null) {
      continue
    }
    totalGroup += element?.['value']
    let startMoment = moment(getDateFromOption(element['from']))
    let endMoment = moment(getDateFromOption(element['to']))
    let isFullMonth = startMoment.date() == 1 && startMoment.month() == endMoment.month() && endMoment.month() != moment(getDateFromOption(element['to'])).add(1, 'day').month()
    let categoryName = isFullMonth
      ? `Tháng ${startMoment.month() + 1} năm ${startMoment.year()}`
      : `${startMoment.format('DD/MM/YYYY')}${element['to'] - element['from'] > 1 ? ` -> ${endMoment.format('DD/MM/YYYY')}` : ''}`
    categoriesData.push({
      value: element?.['value'],
      name: categoryName,
      min: typeof +element?.['from'] === "number" ? getDateFromOption(element['from']).valueOf() : 0
    })
  }

  return {
    categoriesData,
    totalGroup
  }
}

const calculateDefaultChartData = (aggregationsEntitiesData) => {
  let categoriesData = []
  let totalGroup = 0
  for (let element of aggregationsEntitiesData?.[0]?.['entities']) {
    if (element && !element['value']) {
      element.value = element.platforms?.reduce(
        (previousValue, currentValue) => previousValue + currentValue.value,
        0
      )
    }
    if (!element || element['value'] === 0) {
      continue
    }
    totalGroup += element['value']
    categoriesData.push({
      name: element['key'],
      value: element['value']
    })
  }
  return {
    categoriesData,
    totalGroup
  }
}
const calculateShopTypeChartData = (aggregationsEntitiesData) => {
  let categoriesData = []
  let totalGroup = 0
  for (let element of aggregationsEntitiesData?.[0]?.['entities']) {
    if (element && !element['value']) {
      element.value = element.platforms?.reduce(
        (previousValue, currentValue) => previousValue + currentValue.value,
        0
      )
    }
    if (!element || element['value'] === 0) {
      continue
    }
    totalGroup += element['value']
    let name = ''
    let color = ''
    for (const allShopTypeKey in ALL_SHOP_TYPE) {
      if (ALL_SHOP_TYPE[allShopTypeKey].id == element.key) {
        name = ALL_SHOP_TYPE[allShopTypeKey].label
        color = ALL_SHOP_TYPE[allShopTypeKey].color
      }
    }
    categoriesData.push({
      name,
      color,
      value: element['value']
    })
  }
  return {
    categoriesData,
    totalGroup
  }
}

const calculatePlatformChartData = (aggregationsEntitiesData) => {
  let categoriesData = []
  let totalGroup = 0
  for (let element of aggregationsEntitiesData?.[0]?.['entities']) {
    if (!element || element['value'] === 0) {
      continue
    }
    totalGroup += element['value']
    categoriesData.push({
      name: element['platform_id'],
      value: element['value']
    })
  }
  return {
    categoriesData,
    totalGroup
  }
}

const calculateBrandChartData = (aggregationsEntitiesData) => {
  let categoriesData = []
  let totalGroup = 0
  for (let element of aggregationsEntitiesData?.[0]?.['entities']) {
    if (element && !element['value']) {
      element.value = element.platforms?.reduce(
        (previousValue, currentValue) => previousValue + currentValue.value,
        0
      )
    }
    if (!element || element['value'] === 0 || ["nobrand", "no brand", "no-brand"].includes(element['key'].toLowerCase())) {
      continue
    }
    totalGroup += element['value']
    categoriesData.push({
      name: element['key'],
      value: element['value']
    })
  }
  return {
    categoriesData,
    totalGroup
  }
}

const calculateAggregationsChartData = (aggregationsEntitiesData, aggregationTotal, platformId, chartTabKey, metricKey, chartDataOrder, valueRate = 1) => {
  const NUMBER_CATEGORIES_DISPLAY = 12
  let result = {
    categoriesData: [],
    totalGroup: 0
  }
  switch (metricKey) {
    case ALL_METRIC.platforms.name:
      result = calculatePlatformChartData(aggregationsEntitiesData)
      result.categoriesData = result.categoriesData.map(item => {
        if (!item) {
          return item
        }
        let platform = getPlatformById(item.name)
        if (!platform) {
          return item
        }
        item.name = platform.name
        item.color = platform.color
        item.urlLogo = platform.urlLogo
        return item
      })
      if (chartDataOrder) {
        result.categoriesData = sortArrayByOrderArray(result.categoriesData, chartDataOrder)
      }
      break
    case ALL_METRIC.categories.name:
      result = calculateCategoriesChartData(aggregationsEntitiesData, platformId)
      if (aggregationTotal !== result.totalGroup) {
        result.categoriesData.push({
          name: 'Khác',
          value: aggregationTotal - result.totalGroup
        })
      }
      if (!chartDataOrder) {
        result.categoriesData = result.categoriesData.sort((firstEl, secondEl) => secondEl.value - firstEl.value)
          .slice(0, NUMBER_CATEGORIES_DISPLAY)
      } else {
        result.categoriesData = sortArrayByOrderArray(result.categoriesData, chartDataOrder).slice(0, NUMBER_CATEGORIES_DISPLAY)
      }
      break
    case ALL_METRIC.priceRange.name:
      result = calculatePriceRangeChartData(aggregationsEntitiesData, PLATFORM_APPLIED_CNY_RATIO_ARRAY.includes(platformId) ? CNY_RATIO * CNY_RATE : 1)
      if (aggregationTotal !== result.totalGroup) {
        result.categoriesData.push({
          name: 'Khác',
          value: aggregationTotal - result.totalGroup
        })
      }
      if (!chartDataOrder) {
        result.categoriesData = result.categoriesData.sort((item1, item2) => item1.min -item2.min)
      } else {
        result.categoriesData = sortArrayByOrderArray(result.categoriesData, chartDataOrder)
      }
      break
    case ALL_METRIC.brands.name:
      result = calculateBrandChartData(aggregationsEntitiesData)
      if (chartDataOrder) {
        result.categoriesData = sortArrayByOrderArray(result.categoriesData, chartDataOrder)
      }
      break
    case ALL_METRIC.shopType.name:
      result = calculateShopTypeChartData(aggregationsEntitiesData)
      if (chartDataOrder) {
        result.categoriesData = sortArrayByOrderArray(result.categoriesData, chartDataOrder)
      }
      break
    case ALL_METRIC.location.name:
    default:
      result = calculateDefaultChartData(aggregationsEntitiesData)
      if (chartDataOrder) {
        result.categoriesData = sortArrayByOrderArray(result.categoriesData, chartDataOrder)
      }
      break
  }
  let platform = getPlatformById(platformId)
  if (platform.platformIdsIncluded?.length > 1) {
    result.categoriesData.forEach(item => {
      item.valueByPlatform?.forEach(item => item?.value * valueRate - (item?.value * valueRate) % 1)
    })
  }
  return {
    'seriesData': result.categoriesData.map(item => item?.value * valueRate - (item?.value * valueRate)%1),
    'seriesDataMultiplePlatformByCategories': platform.platformIdsIncluded ? result.categoriesData.map(item => item?.valueByPlatform) : null,
    'platformIdsIncluded': platform.platformIdsIncluded,
    'seriesColor': result.categoriesData.map(item => item?.color),
    'seriesUrlLogo': result.categoriesData.map(item => item?.urlLogo),
    'categoriesName': result.categoriesData.map(item => item?.name),
    'title': `Thống kê ${ALL_METRIC[metricKey].label}`,
    'subTitle': ALL_CHART_TAB[chartTabKey].label,
    'categoryTitle': ALL_METRIC[metricKey].sortLabel || ALL_METRIC[metricKey].label,
    'seriesTitle': ALL_CHART_TAB[chartTabKey].label,
    'countTotal': aggregationTotal * valueRate - (aggregationTotal * valueRate % 1),
    'countCharting': result.totalGroup * valueRate - (result.totalGroup * valueRate % 1),
  }
}

const calculateAggregationsChartGeneralStatisticData = (generalStatisticData, generalStatisticKey) => {
  let generalStatisticName = calculateDetailGeneralStatisticChartData(generalStatisticData)
  generalStatisticName.categoriesData = generalStatisticName.categoriesData.sort((item1, item2) => item1.min -item2.min)
  return {
    'seriesData': generalStatisticName.categoriesData.map(item => item?.value),
    'categoriesName': generalStatisticName.categoriesData.map(item => item.name),
    'subTitle': ALL_GENERAL_STATISTIC[generalStatisticKey].label,
    'seriesTitle': ALL_GENERAL_STATISTIC[generalStatisticKey].label,
  }
}

export {
  calculateCategoriesChartData,
  calculateAggregationsChartData,
  calculateAggregationsChartGeneralStatisticData,
  checkFullMonth
}
