const MutationSearchShopsResult = {
  MODULE_PATH: 'SearchShopsResultModule',
  shops: 'shops',
  shopsInsight: 'shopsInsight',
  pushInsightToShopsInsight: 'pushInsightToShopsInsight',
  RESET: 'RESET',
}

export {
  MutationSearchShopsResult
}
