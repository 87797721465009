import FingerprintJS from '@fingerprintjs/fingerprintjs'
import {camelToSnakeCase} from "@/helper/ObjectHelper";
import {Service} from "@/config/ConfigAuth";
import { md5 } from "@/helper/Hash"

const fpPromise = FingerprintJS.load()

export const getDeviceInformation = async () => {
  const fp = await fpPromise
  return await fp.get()
}

export const composeDeviceInformation = (rawInformation) => {
  const result = {}
  for (let key in rawInformation.components) {
    if (key === 'plugins') {
      result[key] = rawInformation.components[key].value.map(plugin => {
        return plugin.name;
      })
    } else if (key === 'languages') {
      result[key] = rawInformation.components[key].value.map(language => {
        return language[0];
      })
    } else if (key === 'domBlockers') {
      if (typeof rawInformation.components[key].value !== 'number') {
        result['dom_blockers'] = 0
      } else {
        result['dom_blockers'] = rawInformation.components[key].value
      }
    }else {
      result[camelToSnakeCase(key)] = rawInformation.components[key].value;
    }
  }
  const encoder = new FingerPrintEncoder(
    result['canvas'],
    result['platform'],
    result['device_memory'],
    result['vendor'],
    result['vendor_flavors'],
    result['audio'],
    result['hardware_concurrency'],
    result['timezone'],
    result['font_preferences'],
    result['fonts'],
    navigator.userAgent
  )
  rawInformation.oldVisitorId = rawInformation.visitorId;
  rawInformation.visitorId = encoder.getHash()
  result['visitor_id'] = rawInformation.visitorId;
  result['service'] = Service.NAME;
  result['raw_data'] = rawInformation;
  return result;
}

export class FingerPrintEncoder {
  dataSource = {}
  dataHash = {}
  constructor(canvas,
              platform,
              deviceMemory,
              vendor,
              vendorFlavors,
              audioContext,
              hardwareConcurrency,
              timezone,
              fontPreferences,
              fonts,
              userAgent) {
    this.dataSource = {
      canvas,
      platform,
      deviceMemory,
      vendor,
      vendorFlavors,
      audioContext,
      hardwareConcurrency,
      timezone,
      fontPreferences,
      fonts,
      userAgent
    }
  }
  _getDeviceCodeFromUserAgent() {
    const userAgent = this.dataSource.userAgent
    const start = userAgent.indexOf('(')
    const end = userAgent.indexOf(')')
    const deviceInfo = userAgent.slice(start + 1, end)
    const [devicePlatform, _, deviceCode] = deviceInfo.split('; ')
    if (!deviceCode) {
      const switcher = [
        ['Windows', 'Win'],
        ['Mac', 'Mac'],
        ['X11', 'Linux'],
        ['Linux', 'Linux'],
        ['iPhone', 'iPhone'],
        ['iPad', 'iPad']
      ]
      for (let matcher of switcher) {
        if (devicePlatform.includes(matcher[0])) {
          return matcher[1]
        }
      }
    }
    return deviceCode
  }
  getCanvasHash() {
    const canvasString = JSON.stringify(this.dataSource.canvas)
    this.dataHash.canvas = md5(canvasString)
  }
  getPlatformHash() {
    this.dataHash.platform = md5(this.dataSource.platform)
  }
  getDeviceMemoryHash() {
    const deviceMemoryString = JSON.stringify(this.dataSource.deviceMemory)
    this.dataHash.deviceMemory = md5(deviceMemoryString)
  }
  getVendorHash() {
    this.dataHash.vendor = md5(this.dataSource.vendor)
  }
  getVendorFlavorsHash() {
    const vendorFlavorsString = JSON.stringify(this.dataSource.vendorFlavors)
    this.dataHash.vendorFlavors = md5(vendorFlavorsString)
  }
  getAudioContextHash() {
    this.dataHash.audioContext = md5(this.dataSource.audioContext)
  }
  getHardwareConcurrencyHash() {
    this.dataHash.hardwareConcurrency = md5(this.dataSource.hardwareConcurrency)
  }
  getTimezoneHash() {
    this.dataHash.timezone = md5(this.dataSource.timezone)
  }
  getFontPreferencesHash() {
    const fontPreferencesString = JSON.stringify(this.dataSource.fontPreferences)
    this.dataHash.fontPreferences = md5(fontPreferencesString)
  }
  getFontsHash() {
    const fontsString = JSON.stringify(this.dataSource.fonts)
    this.dataHash.fonts = md5(fontsString)
  }
  getDeviceCodeHash() {
    const deviceCode = this._getDeviceCodeFromUserAgent()
    this.dataHash.deviceCode = md5(deviceCode)
  }
  getHash() {
    this.getCanvasHash()
    this.getPlatformHash()
    this.getVendorHash()
    this.getVendorFlavorsHash()
    this.getAudioContextHash()
    this.getDeviceMemoryHash()
    this.getHardwareConcurrencyHash()
    this.getTimezoneHash()
    this.getFontPreferencesHash()
    this.getFontsHash()
    this.getDeviceCodeHash()

    const dataHashString = JSON.stringify(this.dataHash)
    console.log(dataHashString)
    const ret = md5(dataHashString)
    return ret
  }
}
