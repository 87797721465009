import { MutationSidebar } from '@/store/modules/SidebarModule/mutation'

const initialState = () => {
  return {
    isCollapsed: false,
    menuSelected: '',
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters: {
    [MutationSidebar.isCollapsed]: state => state.isCollapsed,
    [MutationSidebar.menuSelected]: state => state.menuSelected
  },
  mutations: {
    [MutationSidebar.isCollapsed](state, value) {
      state.isCollapsed = value
    },
    [MutationSidebar.menuSelected](state, value) {
      state.menuSelected = value
    },
    [MutationSidebar.RESET](state) {
      const s = initialState()
      Object.keys(s)
        .forEach(key => {
          state[key] = s[key]
        })
    }
  },
  actions: {
    [MutationSidebar.RESET](context) {
      context.commit(MutationSidebar.RESET)
    }
  }
}
