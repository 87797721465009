//development
const BEECOST_EXTENSION_ID_DEV = 'dev'

// production
const BEECOST_EXTENSION_ID_PRODUCTION = 'hfgkoaengklibhfagaababcngpehggmm'

const BEECOST_EXTENSION_ID = process.env.NODE_ENV === 'development'
  ? BEECOST_EXTENSION_ID_DEV
  : BEECOST_EXTENSION_ID_PRODUCTION

const MessageExternalHandlerConstant = {
  AXIOS_REQUEST: 'AXIOS_REQUEST',
  SET_COOKIE: 'SET_COOKIE',
  GET_COOKIE: 'GET_COOKIE',
  GET_LOCALSTORAGE_EXTENSION: 'GET_LOCALSTORAGE_EXTENSION',
  SET_LOCALSTORAGE_EXTENSION: 'SET_LOCALSTORAGE_EXTENSION',
}

const LocalStorageExtensionConstant = {
  PUB: 'pub'
}

export {
  BEECOST_EXTENSION_ID,
  MessageExternalHandlerConstant,
  LocalStorageExtensionConstant
}
