import {toNumber} from "@/helper/StringHelper";

let formatCurrency = (price) => {
  if (price == null || price === '' || price === '0' || price === 0) {
    return ''
  }
  let priceNormalize = String(Number.parseInt(price))
  return parseFloat(priceNormalize)
    .toLocaleString({ style: 'currency' }) + '₫'
}

let formatSortTextCurrency = (price, isShortest = true) => {
  if (!!+price === false) {
    return price
  }
  price = +price
  if (price % 1000000 === 0) {
    return `${price / 1000000}${isShortest ? 'Tr' : ' Triệu'}`
  }
  if (price % 1000 === 0) {
    return `${price / 1000}${isShortest ? 'K' : ' Nghìn'}`
  }
}

let formatNumberHuman = (number) => {
  if (number == null || number === '' || number === '0' || number === 0) {
    return ''
  }
  let priceNormalize = String(Number.parseInt(number))
  return parseFloat(priceNormalize)
    .toLocaleString({ style: 'currency' })
}

let formatFloatNumberHuman = (number) => {
  if (number == null || number === '' || number === '0' || number === 0) {
    return ''
  }
  let priceNormalize = String(Number.parseFloat(number))
  return parseFloat(priceNormalize)
    .toLocaleString({ style: 'currency' })
}

let humanizeNumber = (number) => {
  if (isNaN(number)) {
    return 'N/A'
  }

  let formatted = ''
  if (number > 999999) {
    formatted = (number / 1000000).toFixed(1)
    formatted = formatted.replace('.', 'm')
      .replace('m0', 'm')
  } else if (number > 999) {
    formatted = (number / 1000).toFixed(1)
    formatted = formatted.replace('.', 'k')
      .replace('k0', 'k')
  } else {
    formatted = number
  }

  return formatted
}

let getRealPriceFormatShopee = (price) => {
  if (price == null || price === '' || price === '0' || price === 0) {
    return ''
  }
  let priceNormalize = String(price)
  return parseFloat(priceNormalize.substring(0, priceNormalize.length - 5))
}

let roundPrice = (price) => {
  if (price == null || price === '' || price === '0' || price === 0) {
    return ''
  }

  return Math.round(parseFloat(price))
}

let timestampToDate = (timestamp, format_string = 'dd/MM/yyyy') => {
  if (timestamp == null) {
    return timestamp
  }
  let d = new Date(timestamp)
  return [d.getDate(), d.getMonth() + 1, d.getFullYear()]
    .map(n => n < 10 ? `0${n}` : `${n}`)
    .join('/')
}

let urlDecode = (url) => {
  return decodeURI(url)
}

let urlEncode = (url) => {
  return encodeURI(url)
}

let humanizeFromNow = (timestamp) => {
  if (timestamp != null) {
    // formatDistance(new Date(timestamp), new Date(), {addSuffix: true, locale: vi});
    // return moment(timestamp).fromNow()
    let date = new Date(timestamp)
    return fromNow(date)
  }
  return null
}

let humanizeJoinedTime = (joinedTime) => {
  if (joinedTime != null) {
    // return moment(joinedTime).fromNow().replace('trước', '');
    // return formatDistance(new Date(joinedTime), new Date(), {addSuffix: true, locale: vi}).replace('trước', '');
    let date = new Date(joinedTime)
    return fromNow(date)
  }
  return null
}

let humanizeTimeResponse = (shop_response_time) => {
  if (shop_response_time == null) {
    return null
  }
  //by second
  if (shop_response_time <= 60) {
    return 'trong vài giây'
  } else if (shop_response_time <= 3600) {
    return 'trong vài phút'
  } else if (shop_response_time <= 3600 * 24) {
    return 'trong vài giờ'
  } else {
    return 'trên một ngày'
  }
}

function fromNow(date) {
  var seconds = Math.floor((new Date() - date) / 1000)
  var years = Math.floor(seconds / 31536000)
  var months = Math.floor(seconds / 2592000)
  var days = Math.floor(seconds / 86400)

  if (days > 548) {
    return years + ' năm trước'
  }
  if (days >= 320 && days <= 547) {
    return '1 năm trước'
  }
  if (days >= 45 && days <= 319) {
    return months + ' tháng trước'
  }
  if (days >= 26 && days <= 45) {
    return '1 tháng trước'
  }

  var hours = Math.floor(seconds / 3600)

  if (hours >= 36 && days <= 25) {
    return days + ' ngày trước'
  }
  if (hours >= 22 && hours <= 35) {
    return '1 ngày trước'
  }

  var minutes = Math.floor(seconds / 60)

  if (minutes >= 90 && hours <= 21) {
    return hours + ' giờ trước'
  }
  if (minutes >= 45 && minutes <= 89) {
    return '1 giờ trước'
  }
  if (seconds >= 90 && minutes <= 44) {
    return minutes + ' phút trước'
  }
  if (seconds >= 45 && seconds <= 89) {
    return '1 phút trước'
  }
  if (seconds >= 0 && seconds <= 45) {
    return 'vài giây trước'
  }
}

/**
 * compare 2 number to get percent of first one with second one
 * @param number {number | string}
 * @param valueComparison {number | string}
 * @param isOnlyDifferent {boolean} get only the different percent
 * @param fixed {number} number of digital after comma
 * @return {string}
 */
const compareNumberByPercent = (number, valueComparison = 100, isOnlyDifferent= false, fixed= 2) => {
  number = toNumber(number)
  valueComparison = toNumber(valueComparison)
  if (!number || !valueComparison) {
    return '0'
  }
  let result = number/valueComparison * 100
  result = isOnlyDifferent ? result - 100 : result
  return result.toFixed(fixed)
}

export {

  urlDecode,
  humanizeFromNow,
  humanizeTimeResponse,
  urlEncode,
  getRealPriceFormatShopee,
  timestampToDate,
  roundPrice,
  formatCurrency,
  formatSortTextCurrency,
  humanizeNumber,
  formatNumberHuman,
  formatFloatNumberHuman,
  humanizeJoinedTime,
  compareNumberByPercent
}

