<template>
  <div id="app" style="background-color: #f0f2f5; min-height: 100vh">
    <router-view/>
    <FacebookMessenger v-show="isFacebookMessengerVisible"/>
  </div>
</template>

<script>
  import {checkBeeCostExtInstall} from "@/service/extension/BeeCostExtensionService";
  import {
    getPromotedVoucher,
    getUserProfile,
    getVoucherInfo,
    updateProfileUserCurrent
  } from "@/service/user/UserProfileService";
  import {MutationUser} from "@/store/modules/UserModule/mutation";
  import {MutationGeneral} from "@/store/modules/GeneralModule/mutation";
  import {getPaidUserInformation, getUserPermissionFromRoles} from "@/service/user/UserPermissionService";
  import {getMarketReferralLink} from "@/service/referral/ReferralService";
  import {BRAND_NAME, SOLOGAN} from "@/constant/general/GeneralConstant";
  import {PERMISSION} from "@/constant/general/PermissionConstant";
  import {updatePricingPlans} from "@/service/payment/PaymentService";
  import {isMobile} from "@/helper/DeviceHelper";
  import {MutationGlobal} from "@/store/modules/GlobalModule/mutation";
  import {MutationSidebar} from "@/store/modules/SidebarModule/mutation";
  import {setCookieExpanded} from "@/helper/CookieHelper";
  import {getParamsFromUrl} from "@/helper/UrlHelper";
  import FacebookMessenger from "@/components/facebook/FacebookMessenger";
  import {gaTrackEvent} from "@/service/tracking/GaTrackingService";
import { URL_WEB } from "@/config/ConfigEndpoint";

  const listFacebookMessengerViewName = [
    'pricing',
    'business_pricing',
    'payment'
  ]

  export default {
    components: {
      FacebookMessenger
    },
    computed: {
      isFacebookMessengerVisible() {
        return listFacebookMessengerViewName.includes(this.$route.name)
      }
    },
    head() {
      return {
        title: `${BRAND_NAME} - ${SOLOGAN}`,
        titleTemplate: '%s',
        link: [
          {
            hid: 'canonical',
            rel: 'canonical',
            href: `${URL_WEB}${this.$route.path}`,
          },
        ],
        meta: [
          {
            hid: "description",
            name: "description",
            content:
              "Metric Ecom Việt là phần mềm phân tích số liệu doanh số, lượng bán, trending, thị phần, đối thủ... trên các sàn TMĐT Shopee, Lazada, Tiki, Sendo. Giúp doanh nghiệp và người bán hàng ra quyết định kinh doanh thông minh, nhanh và chính xác.",
          },
        ],
      };
    },
    beforeCreate() {
      let paramsObject = getParamsFromUrl(window.location.href)
      for (const paramsKey in paramsObject) {
        if (!paramsKey) {
          continue
        }
        setCookieExpanded(paramsKey, paramsObject[paramsKey], '.beecost.vn', true)
      }
      const whiteListReferrer = [
        'beecost.com',
        'market.beecost.vn',
        'metric.vn',
        'marketbee.vn',
        'viettelsale.com',
        'dev.vtsale.vn',
        'viettelpost.vn',
        'dev-news.viettelpost.vn',
        'accounts.google.com',
        'accounts.google.com.vn',
      ]
      try {
        if (!!document.referrer && !whiteListReferrer.includes(new URL(document.referrer).host)) {
          setCookieExpanded('referrer_link', document.referrer, '.beecost.vn', true)
        }
      } catch (e) {
      }
    },
    created() {
      if (isMobile()) {
        this.$store.dispatch(`${MutationGlobal.PATH_MODULE}/${MutationGlobal.isMobile}`)
        this.$store.commit(`SidebarModule/${MutationSidebar.isCollapsed}`, true)
      } else {
        this.$store.dispatch(`${MutationGlobal.PATH_MODULE}/${MutationGlobal.isDesktop}`)
      }
    },
    async mounted() {
      // console.log('default mouted')
      // tải thông tin userProfile từ localstorage vào vuex
      let userProfile = await getUserProfile()
      let permissionPlanName = null
      if (userProfile != null) {
        this.$store.commit(`UserModule/${MutationUser.setUserProfile}`, userProfile)
        this.$store.commit(`UserModule/${MutationUser.setFullName}`, userProfile['full_name'])
        this.$store.commit(`UserModule/${MutationUser.setIsLogin}`, true)
      }
      const response = await updateProfileUserCurrent()
      if (response === undefined) {
        await this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupSystemError}`, true)
        return
      }
      userProfile = response
      checkBeeCostExtInstall()
      if (userProfile != null) {
        permissionPlanName = getUserPermissionFromRoles(userProfile.roles)?.name
        this.$store.commit(`UserModule/${MutationUser.setUserProfile}`, userProfile)
        this.$store.commit(`UserModule/${MutationUser.setFullName}`, userProfile['full_name'])
        this.$store.commit(`UserModule/${MutationUser.setIsLogin}`, true)
        this.$store.commit(`UserModule/${MutationUser.permissionPlanName}`, permissionPlanName)
      }
      if (userProfile != null) {
        let voucherInformation = await getPaidUserInformation()
        if (!voucherInformation) {
          return
        }
        const expirationDate = new Date(voucherInformation.end_query_time)
        const isExpired = new Date() - expirationDate > 0
        let permissionAvailableName = isExpired ? PERMISSION.market_default.name : permissionPlanName
        this.$store.commit(`UserModule/${MutationUser.permissionAvailableName}`, permissionAvailableName)
        let freeSearchCount = voucherInformation.remain_free_search_count
        let freeSearchCountPerWeek = voucherInformation.remain_free_search_count_per_week
        freeSearchCountPerWeek = freeSearchCountPerWeek && typeof freeSearchCountPerWeek === 'number'
          ? freeSearchCountPerWeek
          : 0
        freeSearchCount = freeSearchCount && typeof freeSearchCount === 'number'
          ? freeSearchCount < freeSearchCountPerWeek ? freeSearchCount : freeSearchCountPerWeek
          : 0
        // this.$store.dispatch(`UserModule/${MutationUser.setInactiveVouchers}`, voucherInformation.inactive_vouchers?.[0])
        this.$store.dispatch(`UserModule/${MutationUser.setIsExpired}`, isExpired)
        this.$store.dispatch(`UserModule/${MutationUser.setExpirationDate}`, expirationDate)
        this.$store.dispatch(`${MutationUser.MODULE_PATH}/${MutationUser.freeSearchCount}`, freeSearchCount)
        this.$store.dispatch(`${MutationUser.MODULE_PATH}/${MutationUser.freeSearchCountPerWeek}`, freeSearchCountPerWeek)
        let remainProductDownloadCountPerWeek = voucherInformation.remain_product_download_count_per_week
        let remainShopDownloadCountPerWeek = voucherInformation.remain_shop_download_count_per_week
        remainProductDownloadCountPerWeek = remainProductDownloadCountPerWeek && typeof remainProductDownloadCountPerWeek === 'number'
          ? remainProductDownloadCountPerWeek
          : 0
        remainShopDownloadCountPerWeek = remainShopDownloadCountPerWeek && typeof remainShopDownloadCountPerWeek === 'number'
          ? remainShopDownloadCountPerWeek
          : 0
        this.$store.dispatch(`${MutationUser.MODULE_PATH}/${MutationUser.remainProductDownloadCountPerWeek}`, remainProductDownloadCountPerWeek)
        this.$store.dispatch(`${MutationUser.MODULE_PATH}/${MutationUser.remainShopDownloadCountPerWeek}`, remainShopDownloadCountPerWeek)
        let urlChatbotVoucher = (await getPromotedVoucher()).messenger_url
        if (urlChatbotVoucher && isExpired) {
          this.$store.dispatch(`UserModule/${MutationUser.setPromoteVoucher}`, urlChatbotVoucher)
        }
        let marketReferralLink = (await getMarketReferralLink())
        if (marketReferralLink) {
          this.$store.dispatch(`UserModule/${MutationUser.setMarketReferralLink}`, marketReferralLink)
        }
      } else {
        this.$store.commit(`UserModule/${MutationUser.permissionAvailableName}`, getUserPermissionFromRoles()?.name)
      }
      await updatePricingPlans()
      let voucherInfo = await getVoucherInfo()
      if (voucherInfo) {
        let voucherCode = this.$store.state.UserModule.voucherCode || voucherInfo.voucherCode
        let voucherId = this.$store.state.UserModule.selectedVoucherId || voucherInfo.selectedVoucherId
        this.$store.dispatch(`UserModule/${MutationUser.setVoucherCode}`, voucherCode)
        this.$store.dispatch(`UserModule/${MutationUser.setSelectedVoucherId}`, voucherId)
      }
      gaTrackEvent('user_access', userProfile?.email, this.$route.fullPath)
    }
  }
</script>

<style>
</style>
