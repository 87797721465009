import {MutationSearchBrandsResult} from "@/store/modules/SearchBrandsResultModule/mutation";

const initialState = () => {
  return {
    brands: [],
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters: {
    [MutationSearchBrandsResult.brands]: state => state.brands,
  },
  mutations: {
    [MutationSearchBrandsResult.brands](state, value) {
      state.brands = value
    },
    [MutationSearchBrandsResult.RESET](state) {
      const s = initialState()
      Object.keys(s)
        .forEach(key => {
          state[key] = s[key]
        })
    }
  },
  actions: {
    [MutationSearchBrandsResult.brands](context, data) {
      context.commit(MutationSearchBrandsResult.brands, data)
    },
    [MutationSearchBrandsResult.RESET](context) {
      context.commit(MutationSearchBrandsResult.RESET)
    }
  }
}
