import { MutationSearchFilter } from '@/store/modules/SearchFilterModule/mutation'
import {ALL_CATEGORIES_BY_PLATFORM} from '@/constant/product/ProductCategoriesConstant'
import { MutationSearchProductResult } from '@/store/modules/SearchProductResultModule/mutation'
import {
  apiSearchInsightShop,
  apiSearchKeyword,
  apiSearchProduct,
  apiSearchShop
} from '@/api/product/ApiProductSearch'
import { groupBy } from '@/helper/ArrayHelper'
import { MutationSearchStatisticResult } from '@/store/modules/SearchStatisticResultModule/mutation'
import {
  ALL_CHART_TAB,
  ALL_COUNTRY_SUPPORTED,
  ALL_GENERAL_STATISTIC,
  ALL_METRIC,
  ALL_PLATFORM_BASE_OBJECT,
  CNY_RATE,
  CNY_RATIO,
  NOTIFICATION_TYPE,
  PLATFORM_APPLIED_CNY_RATIO_ARRAY
} from '@/constant/general/GeneralConstant'
import { getPlatformById } from '@/service/platform/PlatformCommonService'
import {
  convertListCompareToStore, convertValueRangeStore,
  generateListCompareFromStore,
  generateValueRangeObject, getDateFromOption,
  getDurationRange, transformProductData
} from '@/service/search/SearchService'
import { gaTrackEvent } from '@/service/tracking/GaTrackingService'
import { MutationSearchStatisticComparisonResult } from '@/store/modules/SearchStatisticComparisonResultModule/mutation'
import { MutationGeneral } from '@/store/modules/GeneralModule/mutation'
import { toNumber } from '@/helper/StringHelper'
import moment from 'moment'
import {generateColumnsTable} from "@/service/market/MarketService";
import {FILTER_OPTION_DEFAULT} from "@/constant/general/PlatformConstant";
import {
  calculateAggregationsChartData, calculateAggregationsChartGeneralStatisticData, checkFullMonth,
} from "@/service/search/HandleResponseService";
import {MutationUser} from "@/store/modules/UserModule/mutation";
import {md5} from "@/helper/Hash";
import {getAccessToken} from "@/service/user/UserProfileService";
import {apiGroupSearch, apiInsightGeneralSearch} from "@/api/market/ApiMarket";
import {MutationSearchShopsResult} from "@/store/modules/SearchShopsResultModule/mutation";
import {MutationSearchKeywordsResult} from "@/store/modules/SearchKeywordsResultModule/mutation";
import {MutationSearchBrandsResult} from "@/store/modules/SearchBrandsResultModule/mutation";

const SHOP_MALL_NUMBER = 1
const SHOP_NORMAL_NUMBER = 0

const initialState = () => {
  let durationDay = 30
  let durationRange = getDurationRange(durationDay)
  let columnsTable = generateColumnsTable(durationDay)
  return {
    iCategoriesSelected: ALL_CATEGORIES_BY_PLATFORM[FILTER_OPTION_DEFAULT.uniqueName].map(cat => cat?.['value']),
    [MutationSearchFilter.iCategoriesLevel]: 1,
    [MutationSearchFilter.iIsRemoveBadProduct]: true,
    [MutationSearchFilter.iTrendingTime]: 0,
    iLocationWarehouses: ['all'],
    iKeywords: [],
    iQueries: [],
    iQueriesExclude: [],
    iOriginalQueries: [],
    isSmartQueries: false,
    iBrands: [],
    iIsShopMall: true,
    iIsShopNormal: true,
    iPlatforms: [FILTER_OPTION_DEFAULT.platform_id],
    iDurationDay: durationDay, // dùng cho khoảng ngày 7d, 30d, 90d, 180d
    iDurationRange: durationRange,
    iDurationRangeComparison: null,
    iLimit: 12,
    iPage: 1,
    iValueRange: {},
    iCompare: [],
    iSortBy: null,
    iFields: null,
    iStatistics: [],
    columnsTable: columnsTable,
    options: {},
    searchConfig: null,
    isClickedSearched: false,
    isLoading: false,
    isDemo: false,
    isOldFilter: false,
    [MutationSearchFilter.timestamp]: new Date().valueOf()
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters: {
    options: state => state.options,
    [MutationSearchFilter.isSavedReport]: state => state[MutationSearchFilter.options]?.isSavedReport,
    searchConfig: state => state.searchConfig,
    columnsTable: state => state.columnsTable,
    iDurationRange: state => state.iDurationRange,
    iDurationRangeFrom: state => state.iDurationRange[0],
    iDurationRangeTo: state => state.iDurationRange[1],
    iPlatforms: state => state.iPlatforms,
    isOldFilter: state => state.isOldFilter,
    iValueRange: state => state.iValueRange,
    iPage: state => state.iPage,
    iLimit: state => state.iLimit,
    [MutationSearchFilter.iCategoriesLevel]: state => state[MutationSearchFilter.iCategoriesLevel],
    [MutationSearchFilter.iIsRemoveBadProduct]: state => state[MutationSearchFilter.iIsRemoveBadProduct],
    [MutationSearchFilter.iTrendingTime]: state => state[MutationSearchFilter.iTrendingTime],
    [MutationSearchFilter.timestamp]: state => state[MutationSearchFilter.timestamp],
    [MutationSearchFilter.isSmartQueries]: state => state[MutationSearchFilter.isSmartQueries],
  },
  mutations: {
    [MutationSearchFilter.timestamp](state, value) {
      state[MutationSearchFilter.timestamp] = value
    },
    [MutationSearchFilter.options](state, value) {
      state.options = value
      state.isOldFilter = false
    },
    [MutationSearchFilter.searchConfig](state, value) {
      state.searchConfig = value
    },
    [MutationSearchFilter.columnsTable](state, value) {
      state.columnsTable = value
    },
    [MutationSearchFilter.iSortBy](state, value) {
      state.iSortBy = value
    },
    [MutationSearchFilter.iCategoriesSelected](state, value) {
      if (state.iCategoriesSelected != value) {
        state.isOldFilter = false
      }
      state.iCategoriesSelected = value
    },
    [MutationSearchFilter.iCategoriesLevel](state, value) {
      if (state[MutationSearchFilter.iCategoriesLevel] != value) {
        state.isOldFilter = false
      }
      state[MutationSearchFilter.iCategoriesLevel] = value
    },
    [MutationSearchFilter.iIsRemoveBadProduct](state, value) {
      if (state.iIsRemoveBadProduct != value) {
        state.isOldFilter = false
      }
      state.iIsRemoveBadProduct = value
    },
    [MutationSearchFilter.iTrendingTime](state, value) {
      if (state.iTrendingTime != value) {
        state.isOldFilter = false
      }
      state.iTrendingTime = value
    },
    [MutationSearchFilter.iLocationWarehouses](state, value) {
      if (state.iLocationWarehouses != value) {
        state.isOldFilter = false
      }
      state.iLocationWarehouses = value

    },
    [MutationSearchFilter.iKeywords](state, value) {
      if (state.iKeywords != value) {
        state.isOldFilter = false
      }
      state.iKeywords = value

    },
    [MutationSearchFilter.iBrands](state, value) {
      if (state.iBrands != value) {
        state.isOldFilter = false
      }
      state.iBrands = value

    },
    [MutationSearchFilter.iIsShopMall](state, value) {
      if (state.iIsShopMall != value) {
        state.isOldFilter = false
      }
      state.iIsShopMall = value

    },
    [MutationSearchFilter.iIsShopNormal](state, value) {
      if (state.iIsShopNormal != value) {
        state.isOldFilter = false
      }
      state.iIsShopNormal = value

    },
    [MutationSearchFilter.iPlatforms](state, value) {
      if (state.iPlatforms != value) {
        state.isOldFilter = false
      }
      state.iPlatforms = value

    },
    [MutationSearchFilter.iDurationDay](state, value) {
      if (state.iDurationDay != value) {
        state.isOldFilter = false
      }
      state.iDurationDay = value

    },
    [MutationSearchFilter.iDurationRange](state, value) {
      if (state.iDurationRange != value) {
        state.isOldFilter = false
      }
      state.iDurationRange = value

    },
    [MutationSearchFilter.iDurationRangeComparison](state, value) {
      state.iDurationRangeComparison = value
    },
    [MutationSearchFilter.iQueries](state, value) {
      if (state.iQueries != value) {
        state.isOldFilter = false
      }
      state.iQueries = value
    },
    [MutationSearchFilter.iQueriesExclude](state, value) {
      if (state.iQueriesExclude != value) {
        state.isOldFilter = false
      }
      state.iQueriesExclude = value
    },
    [MutationSearchFilter.iOriginalQueries](state, value) {
      if (state.iOriginalQueries != value) {
        state.isOldFilter = false
      }
      state.iOriginalQueries = value
    },
    [MutationSearchFilter.isSmartQueries](state, value) {
      if (state.isSmartQueries != value) {
        state.isOldFilter = false
      }
      state.isSmartQueries = value
    },
    [MutationSearchFilter.iLimit](state, value) {
      state.iLimit = value
    },
    [MutationSearchFilter.iPage](state, value) {
      state.iPage = value
    },
    [MutationSearchFilter.iCompare](state, value) {
      if (state.iCompare != value) {
        state.isOldFilter = false
      }
      state.iCompare = value
    },
    [MutationSearchFilter.iValueRange](state, value) {
      if (state.iValueRange != value) {
        state.isOldFilter = false
      }
      state.iValueRange = value
    },
    [MutationSearchFilter.isLoading](state, value) {
      state.isLoading = value
    },
    [MutationSearchFilter.isClickedSearched](state, value) {
      state.isClickedSearched = value
    },
    [MutationSearchFilter.isDemo](state, value) {
      state.isDemo = value
    },
    [MutationSearchFilter.isOldFilter](state, value) {
      state.isOldFilter = value
    },
    [MutationSearchFilter.RESET](state) {
      const s = initialState()
      Object.keys(s)
        .forEach(key => {
          state[key] = s[key]
        })
    }
  },
  actions: {
    [MutationSearchFilter.iPage](context, data) {
      context.commit(MutationSearchFilter.iPage, data)
    },
    [MutationSearchFilter.iLimit](context, data) {
      context.commit(MutationSearchFilter.iLimit, data)
    },
    [MutationSearchFilter.iCategoriesLevel](context, data) {
      context.commit(MutationSearchFilter.iCategoriesLevel, data)
    },
    [MutationSearchFilter.iIsRemoveBadProduct](context, data) {
      context.commit(MutationSearchFilter.iIsRemoveBadProduct, data)
    },
    [MutationSearchFilter.iTrendingTime](context, data) {
      context.commit(MutationSearchFilter.iTrendingTime, data)
    },
    [MutationSearchFilter.iValueRange](context, data) {
      if (!data) {
        return
      }
      context.commit(MutationSearchFilter.iValueRange, data)
    },

    /**
     * apply options filter in json type to Search Filter Store
     * @param context
     * @param data{object}
     * @return {boolean}
     */
    [MutationSearchFilter.APPLY_OPTIONS](context, data) {
      let status = true
      if (!data?.include_query) {
        return false
      }
      // "include_query"
      // "exclude_query"
      // "config"
      // "durationDay"
      // "start_date"
      // "end_date"
      // console.log(data.durationDay)
      let platforms = data.include_query?.platforms
      if (!platforms?.length) {
        return false
      }
      context.commit(MutationSearchFilter.iPlatforms, platforms)
      let durationRange = [moment(getDateFromOption(data.start_date)), moment(getDateFromOption(data.end_date))]
      context.commit(MutationSearchFilter.iDurationRange, durationRange)
      let durationDay = typeof data.durationDay === "number" ? data.durationDay : 0
      context.commit(MutationSearchFilter.iDurationDay, durationDay)
      let categoriesSelected = null
      categoriesSelected = data.include_query.categories?.length
        ? data.include_query.categories
        : ALL_CATEGORIES_BY_PLATFORM[getPlatformById(platforms[0]).uniqueName].map(cat => cat?.['value'])
      context.commit(MutationSearchFilter.iCategoriesSelected, categoriesSelected)
      let categoriesLevel = data.include_query.categories_level
      if (categoriesLevel) {
        context.commit(MutationSearchFilter.iCategoriesLevel, categoriesLevel)
      }
      let isRemoveBadProduct = data.include_query.is_remove_bad_product
      if (!isRemoveBadProduct) {
        context.commit(MutationSearchFilter.iIsRemoveBadProduct, !isRemoveBadProduct)
      }
      let trendingTime = data.include_query.trending_time
      if (trendingTime) {
        context.commit(MutationSearchFilter.iTrendingTime, trendingTime)
      }
      let keywords = data.include_query.keywords
      if (keywords) {
        context.commit(MutationSearchFilter.iKeywords, keywords)
      }
      if (data.exclude_query?.queries) {
        context.commit(MutationSearchFilter.iQueriesExclude, data.exclude_query.queries)
      }
      if (data.include_query?.queries) {
        context.commit(MutationSearchFilter.iQueries, data.include_query.queries)
      }
      let isSmartQueries = !!data.include_query.is_smart_queries
      context.commit(MutationSearchFilter.isSmartQueries, isSmartQueries)
      // let originalQueries = null
      // context.commit(MutationSearchFilter.iOriginalQueries, originalQueries)
      let valueRange = {}
      for (const includeQueryKey in data.include_query) {
        if (!includeQueryKey.includes('_range')) {
          continue
        }
        let valueRangeKey = includeQueryKey.replace('_range', '')
        let valueRangeObject = convertValueRangeStore(data.include_query[includeQueryKey])
        if (!valueRangeObject) {
          continue
        }
        valueRange[valueRangeKey] = convertValueRangeStore(data.include_query[includeQueryKey])
      }
      context.commit(MutationSearchFilter.iValueRange, valueRange)
      let compare = convertListCompareToStore(data.include_query.composite_compare_lst)
      context.commit(MutationSearchFilter.iCompare, compare)
      let locationWarehouses = data.include_query.locationWarehouses
      if (locationWarehouses) {
        context.commit(MutationSearchFilter.iLocationWarehouses, locationWarehouses)
      }
      let brands = data.include_query.brands
      if (brands) {
        context.commit(MutationSearchFilter.iBrands, brands)
      }
      let isShopMall = !!data.include_query.official_types?.includes(1)
      context.commit(MutationSearchFilter.iIsShopMall, isShopMall)
      let isShopNormal = !!data.include_query.official_types?.includes(0)
      context.commit(MutationSearchFilter.iIsShopNormal, isShopNormal)
      context.commit(MutationSearchFilter.options, data)
      return status
    },

    /**
     * build optionFilter
     * @param context
     * @param data{{isDraft: boolean}}
     */
    [MutationSearchFilter.BUILD_OPTIONS](context, data) {
      let startDate = null
      let endDate = null
      try {
        // error (TypeError: r.format(...).replaceAll is not a function) with some case
        // todo: fix that error
        startDate = context.getters.iDurationRangeFrom?.format('YYYY MM DD')?.replaceAll(' ', '')
        endDate = context.getters.iDurationRangeTo?.format('YYYY MM DD')?.replaceAll(' ', '')

      } catch (e) {
        console.log(e)
      }
      let options = {
        'start_date': startDate,
        'end_date': endDate,
        include_query: {
          'start_date': startDate,
          'end_date': endDate
        },
        exclude_query: {
          'start_date': startDate,
          'end_date': endDate
        },
        config: {
          end_date: context.getters.searchConfig?.endDate?.valueOf()
        },
        durationDay: context.state.iDurationDay
      }
      options.durationDay = options.durationDay === 365 ? 0 : options.durationDay

      let timestamp = context.getters[MutationSearchFilter.timestamp]
      if (timestamp) {
        options.timestamp = timestamp
        let token = getAccessToken()
        options.hash = md5(`000000000000__${timestamp}__${token}`)
      }
      if (context.state.iPlatforms?.length > 0) {
        options.include_query = {
          ...options.include_query,
          'platforms': context.state.iPlatforms
        }
      }
      if (context.state.iPlatforms?.length > 1) {
        options.durationDay = 0
      }
      let includeQueries = []
      let excludeQueries = []
      context.state.iQueries?.filter(query => !!query)?.forEach(query => {
        query = query.trim()
        if (query[0] === '-') {
          excludeQueries.push(query.substring(1).trim())
        } else {
          includeQueries.push(query)
        }
      })
      context.state.iQueriesExclude?.filter(query => !!query)?.forEach(query => {
        excludeQueries.push(query.trim())
      })
      if (includeQueries.length) {
        options.include_query = {
          ...options.include_query,
          'queries': includeQueries,
          'is_smart_queries': context.state.isSmartQueries
        }
      }
      if (excludeQueries.length) {
        options.exclude_query = {
          ...options.exclude_query,
          'queries': excludeQueries
        }
      }

      if (context.state.iOriginalQueries?.length > 0) {
        let includeQueries = []
        let excludeQueries = []
        context.state.iOriginalQueries.forEach(query => {
          query = query.trim()
          if (query[0] === '-') {
            excludeQueries.push(query.substring(1).trim())
          } else {
            includeQueries.push(query)
          }
        })
        if (includeQueries.length) {
          options.include_query = {
            ...options.include_query,
            'original_queries': includeQueries,
            // 'is_smart_queries': context.state.isSmartQueries
          }
        }
        if (excludeQueries.length) {
          options.exclude_query = {
            ...options.exclude_query,
            'original_queries': excludeQueries
          }
        }
      }

      // todo: update logic get platform from state.iPlatfroms, this logic is so bad
      let platform = context.state.iPlatforms.length > 1
        ? ALL_PLATFORM_BASE_OBJECT.all_vietnam
        : getPlatformById(context.state.iPlatforms[0])
      let categoryFieldName = ALL_PLATFORM_BASE_OBJECT.all_vietnam === platform ? 'bee_categories' : 'categories'
      if (context.state.iCategoriesSelected?.length > 0 && context.state.iCategoriesSelected[0] !== 'all') {
        const isCategoryDetail = !ALL_CATEGORIES_BY_PLATFORM[platform.uniqueName].find(item => item.value === context.state.iCategoriesSelected[0])
        if (isCategoryDetail || context.state.iCategoriesSelected.length !== ALL_CATEGORIES_BY_PLATFORM[platform.uniqueName].length) {
          options.include_query = {
            ...options.include_query,
            [categoryFieldName]: context.state.iCategoriesSelected
          }
        }
      }
      if (context.state[MutationSearchFilter.iCategoriesLevel]) {
        options.include_query = {
          ...options.include_query,
          'categories_level': context.state.iCategoriesLevel
        }
      }

      if (context.state[MutationSearchFilter.iTrendingTime]) {
        options.include_query = {
          ...options.include_query,
          'trending_time': context.state.iTrendingTime
        }
      }

      options.include_query = {
        ...options.include_query,
        'is_remove_bad_product': !!context.state.iIsRemoveBadProduct
      }

      if (context.state.iKeywords?.length > 0) {
        options.include_query = {
          ...options.include_query,
          'keywords': context.state.iKeywords
        }
      }
      if (context.state.iBrands?.length > 0) {
        let includeBrands = []
        let excludeBrands = []
        context.state.iBrands.forEach(query => {
          if (query[0] === '-') {
            excludeBrands.push(query.substring(1))
          } else {
            includeBrands.push(query)
          }
        })
        if (includeBrands.length) {
          options.include_query = {
            ...options.include_query,
            'brands': includeBrands
          }
        }
        if (excludeBrands.length) {
          options.exclude_query = {
            ...options.exclude_query,
            'brands': excludeBrands
          }
        }
      }
      let officialTypes = []
      if (context.state.iIsShopMall != null && context.state.iIsShopMall) {
        officialTypes.push(1)
      }
      if (context.state.iIsShopNormal != null && context.state.iIsShopNormal) {
        officialTypes.push(0)
      }

      if (officialTypes?.length > 0) {
        options.include_query = {
          ...options.include_query,
          'official_types': officialTypes
        }
      }

      if (context.state.iLocationWarehouses?.length > 0 && !context.state.iLocationWarehouses.includes('all')) {
        let includeLocations = []
        let excludeLocations = []
        context.state.iLocationWarehouses.forEach(query => {
          if (query[0] === '-') {
            excludeLocations.push(query.substring(1))
          } else {
            includeLocations.push(query)
          }
        })
        if (includeLocations.length) {
          options.include_query = {
            ...options.include_query,
            'locations': includeLocations
          }
        }
        if (excludeLocations.length) {
          options.exclude_query = {
            ...options.exclude_query,
            'locations': excludeLocations
          }
        }
      }

      if (context.state.iCompare?.length > 0) {
        let listCompare = generateListCompareFromStore(context.state.iCompare)
        if (listCompare) {
          options.include_query = {
            ...options.include_query,
            'composite_compare_lst': listCompare
          }
        }
      }

      for (const iValueRangeKey in context.getters[MutationSearchFilter.iValueRange]) {
        let item = context.getters[MutationSearchFilter.iValueRange][iValueRangeKey]
        let min = iValueRangeKey === 'platform_created_at' ? item.min?.valueOf() : item.min
        let max = iValueRangeKey === 'platform_created_at' ? item.max?.valueOf() : item.max
        if (PLATFORM_APPLIED_CNY_RATIO_ARRAY.includes(context.state.iPlatforms?.[0]) && iValueRangeKey === 'price') {
          max = max
            ? parseInt(max * 1/(CNY_RATE*CNY_RATIO))
            : max
          min = min
            ? parseInt(min * 1/(CNY_RATE*CNY_RATIO))
            : min
        }
        let minDefault = iValueRangeKey === `order_count_${options.durationDay}d`
                          || (!options.durationDay && iValueRangeKey === `order_count_custom`)
                          ? 1
                          : null
        // minDefault = minDefault
        //   ? minDefault
        //   : iValueRangeKey === `review_count_${options.durationDay}d`
        //     || (!options.durationDay && iValueRangeKey === `review_count_custom`)
        //       ? 1
        //       : null
        let maxDefault = null
        let queryValueRange = generateValueRangeObject(
          min,
          max,
          minDefault,
          maxDefault
        )
        if (Object.keys(queryValueRange).length > 0) {
          options.include_query = {
            ...options.include_query,
            [`${iValueRangeKey}_range`]: queryValueRange
          }
        }
      }
      if (!options.durationDay) {
        let queryValueRange = generateValueRangeObject(
          null,
          null,
          1,
          null
        )
        options.include_query = {
          ...options.include_query,
          [`order_count_custom_range`]: queryValueRange
        }
      }

      options.exclude_query = Object.keys(options.exclude_query).length > 2 ? options.exclude_query : null
      if (!!data?.isDraft === false) {
        context.state.options = options
      }
      // console.log('options', options)
      // not support
      //        'platform_created_range': null,
      //         'rating_count_range': null,
      //         'rating_avg_range': {
      //           'from': 3,
      //           'to': 5
      //         },
      //         'order_count_range': null,
      //         'revenue_range': null,
      return options
    },

    /**
     * build optionResponse for product: define fields should be fill in product of response
     * @param context
     * @param data: optionFilter
     * @return {{}}
     */
    [MutationSearchFilter.BUILD_PRODUCT_OPTIONS](context, data) {
      let durationDay = typeof data?.durationDay === 'number' ? data.durationDay : context.getters.options.durationDay
      const IS_CUSTOM_DURATION_RANGE = !durationDay
      let platform = context.state.iPlatforms.length > 1
        ? ALL_PLATFORM_BASE_OBJECT.all_vietnam
        : getPlatformById(context.state.iPlatforms[0])
      let options = {}
      if (context.state.iLimit != null) {
        options['limit'] = context.state.iLimit
      }
      if (context.state.iPage != null) {
        options['page'] = context.state.iPage - 1
      }

      let bee_fields = platform.name === ALL_PLATFORM_BASE_OBJECT.all_vietnam.name
        ? [
          'bee_brand',
          'bee_categories',
        ]
        : [
          'brand',
          'categories',
        ]

      let fields = context.state.iFields?.length
        ? [...context.state.iFields]
        : [
          'product_base_id',
          'product_name',
          'url_thumbnail',
          'price',
          'price_min',
          'price_max',
          'official_type',
          'rating_avg',
          'rating_count',
          'order_count',
          'price_updated_at',
          'revenue',
          'platform_created_at',
          "shop_platform_name",
          "shop_url",
          ...bee_fields
        ]
      let permissionName = context.rootGetters[`${MutationUser.MODULE_PATH}/${MutationUser.permissionAvailableName}`]
      let isBusinessAccount = context.rootGetters[`${MutationUser.MODULE_PATH}/${MutationUser.isBusinessAccount}`]
      if (isBusinessAccount) {
        fields.push('view_count')
      }
      let otherField = []
      // if (platform.name === ALL_PLATFORM_BASE_OBJECT.lazada.name) {
      //   otherField.push(
      //     ...[
      //       `review_count`,
      //     ]
      //   )
      // } else {
      //   otherField.push(
      //     ...[
      //       `order_count`,
      //       `order_revenue`
      //     ]
      //   )
      // }

      otherField.push(
        ...[
          `order_count`,
          `order_revenue`,
          `review_count`,
        ]
      )
      if (IS_CUSTOM_DURATION_RANGE) {
        otherField = otherField.map(item => `${item}_custom`)
      } else {
        otherField = otherField.map(item => `${item}_${durationDay}d`)
      }
      fields.push(
        ...otherField,
      )
      if (getPlatformById(platform.platform_id).country === ALL_COUNTRY_SUPPORTED.vietnam) {
        fields.push(
          ...otherField.map(item => `${item}_previous`)
        )
      }
      options['fields'] = fields
      let sortBy = platform?.sortDefault
      if (context.state.iSortBy?.length > 0 || !sortBy) {
        options['sort_by'] = context.state.iSortBy
      } else if (IS_CUSTOM_DURATION_RANGE) {
        options['sort_by'] = `${sortBy}_custom__desc`
      } else {
        options['sort_by'] = `${sortBy}_${durationDay}d__desc`
      }
      return options
    },

    /**
     * build optionResponse for revenue statistic: define fields of statistic should be fill in aggregations of response
     * @param context
     * @param data
     * @return {[]}
     */
    [MutationSearchFilter.BUILD_REVENUE_STATISTIC](context, data) {
      let durationDay = typeof data?.durationDay === 'number' ? data.durationDay : context.getters.options.durationDay
      const IS_CUSTOM_DURATION_RANGE = !durationDay
      let platform = context.state.iPlatforms.length > 1
        ? ALL_PLATFORM_BASE_OBJECT.all_vietnam
        : getPlatformById(context.state.iPlatforms[0])
      let beeFieldKey = platform.name === ALL_PLATFORM_BASE_OBJECT.all_vietnam.name
        ? 'bee_'
        : ''
      let statistics = []
      if (IS_CUSTOM_DURATION_RANGE) {
        statistics.push(...[
          `revenue__by__${beeFieldKey}categories_custom`,
          `revenue__by__${beeFieldKey}brands_custom`,
          `revenue__by__price_range_custom`,
          `revenue__by__${beeFieldKey}location_custom`,
        ])
      } else {
        statistics.push(...[
          `revenue__by__${beeFieldKey}categories_${durationDay}d`,
          `revenue__by__${beeFieldKey}brands_${durationDay}d`,
          `revenue__by__price_range_${durationDay}d`,
          `revenue__by__${beeFieldKey}location_${durationDay}d`,
        ])
      }
      return statistics
    },

    /**
     * build optionResponse for order count statistic: define fields of statistic should be fill in aggregations of response
     * @param context
     * @param data
     * @return {[]}
     */
    [MutationSearchFilter.BUILD_ORDER_COUNT_STATISTIC](context, data) {
      let durationDay = typeof data?.durationDay === 'number' ? data.durationDay : context.getters.options.durationDay
      const IS_CUSTOM_DURATION_RANGE = !durationDay
      let platform = context.state.iPlatforms.length > 1
        ? ALL_PLATFORM_BASE_OBJECT.all_vietnam
        : getPlatformById(context.state.iPlatforms[0])
      let beeFieldKey = platform.name === ALL_PLATFORM_BASE_OBJECT.all_vietnam.name
        ? 'bee_'
        : ''
      let statistics = []
      if (IS_CUSTOM_DURATION_RANGE) {
        statistics.push(...[
          `order_count__by__${beeFieldKey}categories_custom`,
          `order_count__by__${beeFieldKey}brands_custom`,
          `order_count__by__price_range_custom`,
          `order_count__by__${beeFieldKey}location_custom`,
        ])
      } else {
        statistics.push(...[
          `order_count__by__${beeFieldKey}categories_${durationDay}d`,
          `order_count__by__${beeFieldKey}brands_${durationDay}d`,
          `order_count__by__price_range_${durationDay}d`,
          `order_count__by__${beeFieldKey}location_${durationDay}d`,
        ])
      }
      return statistics
    },

    /**
     * build optionResponse for review count statistic: define fields of statistic should be fill in aggregations of response
     * @param context
     * @param data
     * @return {[]}
     */
    [MutationSearchFilter.BUILD_REVIEW_COUNT_STATISTIC](context, data) {
      let durationDay = typeof data?.durationDay === 'number' ? data.durationDay : context.getters.options.durationDay
      const IS_CUSTOM_DURATION_RANGE = !durationDay
      let platform = context.state.iPlatforms.length > 1
        ? ALL_PLATFORM_BASE_OBJECT.all_vietnam
        : getPlatformById(context.state.iPlatforms[0])
      let beeFieldKey = platform.name === ALL_PLATFORM_BASE_OBJECT.all_vietnam.name
        ? 'bee_'
        : ''
      let statistics = []
      if (IS_CUSTOM_DURATION_RANGE) {
        statistics.push(...[
          `review_count__by__${beeFieldKey}categories_custom`,
          `review_count__by__${beeFieldKey}brands_custom`,
          `review_count__by__price_range_custom`,
          `review_count__by__${beeFieldKey}location_custom`,
        ])
      } else {
        statistics.push(...[
          `review_count__by__${beeFieldKey}categories_${durationDay}d`,
          `review_count__by__${beeFieldKey}brands_${durationDay}d`,
          `review_count__by__price_range_${durationDay}d`,
          `review_count__by__${beeFieldKey}location_${durationDay}d`,
        ])
      }
      return statistics
    },

    /**
     * build optionResponse for general statistic: define fields of statistic should be fill in aggregations of response
     * @param context
     * @param data
     * @return {[string, string]}
     */
    [MutationSearchFilter.BUILD_GENERAL_STATISTIC](context, data) {
      let durationDay = typeof data?.durationDay === 'number' ? data.durationDay : context.getters.options.durationDay
      const IS_CUSTOM_DURATION_RANGE = !durationDay
      let statistics = [
        'shop_total',
        'product_total',
        'official_type'
      ]
      if (IS_CUSTOM_DURATION_RANGE) {
        statistics.push(...[
          `order_total_custom`,
          `review_total_custom`,
          // `commision_total_custom`,
          `revenue_total_custom`,
          `revenue__by__shop_type_custom`,
          `order_count__by__shop_type_custom`

        ])
      } else {
        statistics.push(...[
          `order_total_${durationDay}d`,
          `review_total_${durationDay}d`,
          // `commision_total_${durationDay}d`,
          `revenue_total_${durationDay}d`,
          `revenue__by__shop_type_${durationDay}d`,
          `order_count__by__shop_type_${durationDay}d`
        ])
      }
      return statistics
    },

    /**
     * Save response data to storeModule
     * @param context
     * @param responseData {products: [], aggregations: [], storeModule: {}}
     * @return {Promise<string>} Store Module save response
     */
    async [MutationSearchFilter.SET_RESPONSE_SEARCH_PRODUCT](context, responseData) {
      if (!responseData) {
        return null
      }
      const storeModule = responseData?.['storeModule'] ? responseData?.['storeModule'] : MutationSearchStatisticResult
      await context.dispatch(`${storeModule.MODULE_PATH}/${storeModule.status}`, true, {root: true})
      const productRaw = responseData?.['products']
      let revenueRate = PLATFORM_APPLIED_CNY_RATIO_ARRAY.includes(context.state.iPlatforms[0]) ? CNY_RATIO * CNY_RATE : 1
      if (responseData?.['aggregations']?.length > 0) {
        let aggregations = groupBy(responseData?.['aggregations'], 'aggregation')
        const IS_FIX_DURATION_RANGE = !responseData.isCustomRange
        const text_comparison = context.rootGetters["SearchStatisticComparisonResultModule/textComparison"]
        //todo: move OFFICIAL_TYPE to general statistic
        if (aggregations.OFFICIAL_TYPE?.[0] && storeModule.MODULE_PATH === 'SearchStatisticResultModule') {
          let valueShopMallWrap = aggregations.OFFICIAL_TYPE[0].entities?.find(item => item.key === '1')
          let valueShopMall = valueShopMallWrap?.value
            || valueShopMallWrap?.platforms?.reduce(
              (previousValue, currentValue) => previousValue + currentValue.value,
              0
            )
          || 0
          let valueOtherShopWrap = aggregations.OFFICIAL_TYPE[0].entities?.find(item => item.key === '0')
          let valueOtherShop = valueOtherShopWrap?.value
            || valueOtherShopWrap?.platforms?.reduce(
              (previousValue, currentValue) => previousValue + currentValue.value,
              0
            )
            || 0
          context.commit(
            `${MutationSearchStatisticResult.MODULE_PATH}/${MutationSearchStatisticResult.shopMall}`,
            valueShopMall,
            { root: true }
          )
          context.commit(
            `${MutationSearchStatisticResult.MODULE_PATH}/${MutationSearchStatisticResult.otherShop}`,
            valueOtherShop,
            { root: true }
          )
        }

        for (const generalStatisticKey in ALL_GENERAL_STATISTIC) {
          let generalStatistic =  ALL_GENERAL_STATISTIC[generalStatisticKey]
          let aggregationWrap = IS_FIX_DURATION_RANGE
            ? aggregations?.[`${generalStatistic.apiKey}_${context.state.options.durationDay}D`]
            : aggregations?.[`${generalStatistic.apiKey}_CUSTOM`]
          aggregationWrap = aggregationWrap ? aggregationWrap : aggregations?.[generalStatistic.apiKey]
          let aggregationTotal = aggregationWrap?.[0]?.['entity']?.value
          if (!aggregationTotal && aggregationWrap?.[0]?.['entity']?.platforms?.length) {
            aggregationTotal = aggregationWrap[0]['entity'].platforms.reduce(
              (previousValue, currentValue) => previousValue + currentValue.value,
              0
            )
            // start create object to calculate chart data
            let chartTab
            for (const chartTabKey in ALL_CHART_TAB) {
              if (generalStatistic.name === ALL_CHART_TAB[chartTabKey].generalStatisticName) {
                chartTab = ALL_CHART_TAB[chartTabKey]
                break
              }
            }
            if (chartTab) {
              let key = IS_FIX_DURATION_RANGE
                ? `${chartTab.apiKey}__BY__PLATFORMS_${context.state.options.durationDay}D`
                : `${chartTab.apiKey}__BY__PLATFORMS_CUSTOM`
              aggregations[key] =[
                {
                  "entities": aggregationWrap[0]['entity'].platforms,
                  "aggregation": key
                }
              ]
            }
            // end create
          }

          if (typeof aggregationTotal === "number") {
            context.commit(
              `${storeModule.MODULE_PATH}/${generalStatistic.storeName}`,
              aggregationTotal * (generalStatistic.storeName.includes('revenue') ? revenueRate : 1),
              { root: true })
          }
        }

        for (const chartTabKey in ALL_CHART_TAB) {
          let tab = ALL_CHART_TAB[chartTabKey]
          let aggregationTotal = context.rootGetters[`${storeModule.MODULE_PATH}/${ALL_GENERAL_STATISTIC[tab.generalStatisticName].storeName}`]
          for (const metricKey in ALL_METRIC) {
            let metric = ALL_METRIC[metricKey]
            let beeFieldKey = context.state.options?.include_query?.platforms?.length > 1
            && metricKey !== ALL_METRIC.platforms.name
            && metricKey !== ALL_METRIC.priceRange.name
            && metricKey !== ALL_METRIC.shopType.name
              ? 'BEE_'
              : ''
            let rawAggregations = IS_FIX_DURATION_RANGE
              ? aggregations?.[`${tab.apiKey}__BY__${beeFieldKey}${metric.apiKey}_${context.state.options.durationDay}D`]
              : aggregations?.[`${tab.apiKey}__BY__${beeFieldKey}${metric.apiKey}_CUSTOM`]
            if (!rawAggregations) {
              continue
            }
            if (rawAggregations[0]?.['entities']?.length) {
              let chartDataOrder = storeModule.MODULE_PATH !== MutationSearchStatisticResult.MODULE_PATH
                                    ? context.rootGetters[`${MutationSearchStatisticResult.MODULE_PATH}/${MutationSearchStatisticResult[tab.storeName][metric.storeName]}`]?.categoriesName
                                    : null
              let valueRate = chartTabKey.includes('revenue') ? revenueRate : 1
              let chartData = calculateAggregationsChartData(
                rawAggregations,
                aggregationTotal/valueRate,
                context.state.options?.include_query?.platforms?.length > 1
                  ? ALL_PLATFORM_BASE_OBJECT.all_vietnam.platform_id
                  : context.state.options?.include_query?.platforms?.[0],
                chartTabKey,
                metricKey,
                chartDataOrder,
                valueRate
              )
              chartData.subTitle += ` ${IS_FIX_DURATION_RANGE ? context.state.options.durationDay + ' ngày' : text_comparison}`,
              context.commit(
                `${storeModule.MODULE_PATH}/${MutationSearchStatisticResult[tab.storeName][metric.storeName]}`,
                chartData,
                { root: true }
              )
            } else {
              context.commit(
                `${storeModule.MODULE_PATH}/${MutationSearchStatisticResult[tab.storeName][metric.storeName]}`,
                null,
                { root: true }
              )
            }
          }
        }
      }
      const products = transformProductData(productRaw)
      if (products) {
        context.commit(`SearchProductResultModule/${MutationSearchProductResult.products}`, products, { root: true })
      }
      return storeModule.MODULE_PATH
    },

    /**
     * Save response products of keyword to storeModule
     * @param context
     * @param responseData {products: [], aggregations: [], storeModule: {}}
     * @return {Promise<boolean>} Store Module save response
     */
    async [MutationSearchFilter.SET_RESPONSE_SEARCH_KEYWORD_PRODUCT](context, responseData) {
      const products = transformProductData(responseData?.['products'])
      if (products) {
        context.commit(`${MutationSearchKeywordsResult.MODULE_PATH}/${MutationSearchKeywordsResult.products}`, products, { root: true })
      }
      return true
    },

    /**
     * Call api to search product, check new logic before use it: build optionFilter and build optionResponse and push both of them to data input
     * New logic, Step by step to search:
     * Step 1: Build optionFilter by BUILD_OPTIONS action
     * Step 2: Build optionResponse by BUILD_*_OPTIONS action (BUILD_PRODUCT_OPTIONS, BUILD_REVENUE_STATISTIC, BUILD_ORDER_COUNT_STATISTIC, BUILD_GENERAL_STATISTIC): define response field
     * Step 3: Merge optionFilter and optionResponse into data
     * Step 4: Search by SEARCH_PRODUCT action with searchType and option data
     * @param context
     * @param data {{isInit: boolean, type: string}}: search option
     * set isInit is true to apply state in data to current filter
     * type: type of Response action: [SET_RESPONSE_SEARCH_PRODUCT, SET_RESPONSE_SEARCH_PRODUCT_COMPARISON]
     * @return {Promise<boolean>}
     */
    async [MutationSearchFilter.SEARCH_PRODUCT](context, data) {
      let options = null
      context.state.isLoading = true
      context.state.isClickedSearched = true
      if (data && data.isInit) {
        // todo: test it before use
        options = data
        context.state.iQueries = options.queries
        context.state.iBrands = options.brands
        context.state.iDurationDay = toNumber(options.sort_by) || context.state.iDurationDay
        context.state.iPlatforms = options.iPlatforms || context.state.iPlatforms
        context.state.iCategoriesSelected = options.categories || context.state.iCategoriesSelected
        context.state.iIsShopMall = !!options.official_types?.includes(SHOP_MALL_NUMBER)
        context.state.iIsShopNormal = !!options.official_types?.includes(SHOP_NORMAL_NUMBER)
        context.state.iLocationWarehouses = options.locations || context.state.iLocationWarehouses
        context.state.iCompare = options.iCompare || context.state.iCompare
        context.state.iValueRange = options.iValueRange || context.state.iValueRange
        context.state.iDurationRange[0] = options[`start_date`] ? moment(getDateFromOption(options[`start_date`])) : null
        context.state.iDurationRange[1] = options[`end_date`] ? moment(getDateFromOption(options[`end_date`])) : null
      } else if (data) {
        options = {...data}
        delete options.isInit
        delete options.type
        if (options.durationDay === 365) {
          options.durationDay = 0
        }
        if (options.durationDay !== 0) {
          delete options.start_date
          delete options.end_date
        } else {
          delete options.durationDay
        }
      } else {
        await context.dispatch(MutationSearchFilter.BUILD_OPTIONS)
        options = context.state.options
      }
      let timeStartSearch = new Date()
      let response = await apiSearchProduct(options, context.state.isDemo, context.state.iPlatforms)

      let timeFinishSearch = new Date()
      let latency = timeFinishSearch - timeStartSearch
      latency = latency - latency % 100 + (latency % 100 > 50 ? 100 : 0)
      // console.log('response apiSearchProduct', response)
      let email = context.rootGetters["UserModule/email"]
      gaTrackEvent('api_search_latency', `${latency}`, `${email}_${JSON.stringify(options)}`)
      let timestamp = context.getters[MutationSearchFilter.timestamp] || new Date().valueOf()
      context.commit(MutationSearchFilter.timestamp, timestamp)
      if (!context.state.options?.isSavedReport) {
        await context.dispatch(MutationSearchFilter.BUILD_OPTIONS) // build new option with hash
      }
      if (
        response == null
        || response['data'] == null
        || (response['data'].products && response['data'].products.length === 0 && response['data'].aggregations)
      ) {
        await context.dispatch(`${MutationSearchStatisticResult.MODULE_PATH}/${MutationSearchStatisticResult.status}`, false, {root: true})
        await context.dispatch(`${MutationGeneral.MODULE_PATH}/${MutationGeneral.setShowPopupNotification}`, NOTIFICATION_TYPE.empty_response_search, { root: true })
        context.state.isLoading = false
        return false
      }
      let freeSearchCount = response.meta?.remain_free_search_count
      let freeSearchCountPerWeek = response.meta?.remain_free_search_count_per_week
      freeSearchCountPerWeek = freeSearchCountPerWeek && typeof freeSearchCountPerWeek === 'number'
        ? freeSearchCountPerWeek
        : 0
      freeSearchCount = freeSearchCount && typeof freeSearchCount === 'number'
        ? freeSearchCount < freeSearchCountPerWeek ? freeSearchCount : freeSearchCountPerWeek
        : 0
      await context.dispatch(`${MutationUser.MODULE_PATH}/${MutationUser.freeSearchCount}`, freeSearchCount, {root: true})
      await context.dispatch(`${MutationUser.MODULE_PATH}/${MutationUser.freeSearchCountPerWeek}`, freeSearchCountPerWeek, {root: true})
      let responseData = response['data']
      responseData.isCustomRange = data.durationDay === 0
      if (data?.type) {
        if (data.type === MutationSearchFilter.SET_RESPONSE_SEARCH_PRODUCT_COMPARISON) {
          responseData.storeModule = MutationSearchStatisticComparisonResult
        } else if (data.type === MutationSearchFilter.SET_RESPONSE_SEARCH_KEYWORD_PRODUCT) {
          await context.dispatch(MutationSearchFilter.SET_RESPONSE_SEARCH_KEYWORD_PRODUCT, responseData)
          context.state.isLoading = false
          return true
        } else {
          context.state.columnsTable = generateColumnsTable(data.durationDay)
          responseData.storeModule = MutationSearchStatisticResult
        }
      }
      await context.dispatch(MutationSearchFilter.SET_RESPONSE_SEARCH_PRODUCT, responseData)
      context.state.isLoading = false
      return true
    },

    /**
     * Call api to get list keyword
     * @param context
     * @param data
     * @return {Promise<void>}
     */
    async [MutationSearchFilter.SEARCH_KEYWORDS](context, data) {
      let options = data
      if (!data || !Object.keys(data).length) {
        await context.dispatch(MutationSearchFilter.BUILD_OPTIONS)
        options = context.state.options
      }
      let response = (await apiSearchKeyword(options))?.data?.keywords
      await context.dispatch(`${MutationSearchKeywordsResult.MODULE_PATH}/${MutationSearchKeywordsResult.keywords}`, response, {root: true})
    },

    /**
     * Call api to get list shop
     * @param context
     * @param data
     * @return {Promise<void>}
     */
    async [MutationSearchFilter.SEARCH_SHOPS](context, data) {
      let options = data
      if (!data || !Object.keys(data).length) {
        await context.dispatch(MutationSearchFilter.BUILD_OPTIONS)
        options = context.state.options
      }
      let response = (await apiSearchShop(options))?.data?.shops
      await context.dispatch(`${MutationSearchShopsResult.MODULE_PATH}/${MutationSearchShopsResult.shops}`, response, {root: true})
    },

    /**
     * Call api to get list brand
     * @param context
     * @param data
     * @return {Promise<void>}
     */
    async [MutationSearchFilter.SEARCH_BRANDS](context, data) {
      let options = data
      if (!data || !Object.keys(data).length) {
        await context.dispatch(MutationSearchFilter.BUILD_OPTIONS)
        options = context.state.options
      }
      let isMultiplePlatform = options?.include_query?.platforms?.length > 1
      let type = isMultiplePlatform ? 'bee_brand' : 'brand'
      let response = (await apiGroupSearch(options, type))?.data?.groups
      await context.dispatch(`${MutationSearchBrandsResult.MODULE_PATH}/${MutationSearchBrandsResult.brands}`, response, {root: true})
    },

    /**
     * Call api to get data shop detail
     * @param context
     * @param data
     * @return {Promise<void>}
     */
    async [MutationSearchFilter.SEARCH_INSIGHT_SHOPS](context, data) {
      let options = null
      let listShop = null
      if (!data || !Object.keys(data).length) {
        await context.dispatch(MutationSearchFilter.BUILD_OPTIONS)
        options = JSON.parse(JSON.stringify(data))
        listShop = context.getters[`${MutationSearchShopsResult.MODULE_PATH}/${MutationSearchShopsResult.shops}`]
          ?.slice(0, 5)
          .map(item => {
            return {shopId: item.shop_platform_id, shopName: item.shop_name}
          })
      } else {
        options = JSON.parse(JSON.stringify(data))
        listShop= data.listShop
        delete options.listShop
      }
      if (!listShop) {
        return
      }
      options.sub_start_date = options.start_date
      options.sub_end_date = options.end_date
      options.interval = options.interval || 7
      options["data_type"] = options["data_type"] || "order_revenue"
      await context.dispatch(`${MutationSearchShopsResult.MODULE_PATH}/${MutationSearchShopsResult.shopsInsight}`, [], {root: true})
      let isMultiplePlatform = options?.include_query?.platforms?.length > 1
      for (const shopItem of listShop) {
        options["include_query"] = options["include_query"] || {}
        options["include_query"]["shop_platform_ids"] = [shopItem.shopId]
        let responseFinal = (await apiSearchInsightShop(options))?.data?.data?.sort((item1, item2) => item1.from -item2.from)
        if (!responseFinal) {
          return
        }
        let shopInsight = {
          name: shopItem.shopName,
          data: responseFinal.map(
            item => isMultiplePlatform ? item.platforms?.reduce(
              (previousValue, currentValue) => previousValue + currentValue.value,
              0
            ) : item.value
          ),
          label: responseFinal.map(
            item => item.to - item.from === 1
              ? `${moment(getDateFromOption(item.from)).format('DD/MM/YYYY')}`
              : checkFullMonth(getDateFromOption(item.from), getDateFromOption(item.to))
                ? `Tháng ${getDateFromOption(item.from).getMonth() + 1} năm ${getDateFromOption(item.from).getFullYear()}`
                : `${moment(getDateFromOption(item.from)).format('DD/MM/YYYY')} -> ${moment(getDateFromOption(item.to)).format('DD/MM/YYYY')}`
          ),
        }
        await context.dispatch(`${MutationSearchShopsResult.MODULE_PATH}/${MutationSearchShopsResult.pushInsightToShopsInsight}`, shopInsight, {root: true})
      }
    },

    /**
     * Call api to get detail general statistic
     * @param context
     * @param data
     * @return {Promise<void>}
     */
    async [MutationSearchFilter.SEARCH_DETAIL_STATISTIC](context, data) {
      let options = data
      if (!data) {
        await context.dispatch(MutationSearchFilter.BUILD_OPTIONS)
        options = context.state.options
      }
      let listPromise = []
      let response = null
      let responsePlus = null
      let responseFinal = null
      options.sub_start_date = options.start_date
      options.sub_end_date = options.end_date
      let responsePromise = apiInsightGeneralSearch(options).then(res => {
        response = res?.data
      })
      listPromise.push(responsePromise)
      // if (options.interval === 30) {
      //   let startDate = moment(getDateFromOption(options.start_date))
      //   let startYear = +startDate.get("year")
      //   let startMonth = +startDate.get("month")
      //   let endDate = moment(getDateFromOption(options.end_date))
      //   let diffMonth = moment(endDate.format('YYYY-MM')).diff(moment(startDate.format('YYYY-MM')), "month")
      //   for (let i = 0; i < diffMonth + 1; i++) {
      //     let optionsPlus = {...options}
      //     let month = i + startMonth
      //     let year = startYear
      //     while (month > 11) {
      //       month -= 12
      //       year = year + 1
      //     }
      //     optionsPlus.sub_start_date = i === 0 ? startDate.format('YYYY MM DD').replaceAll(' ', '') : moment(`${year}-${month + 1}`).format('YYYY MM DD').replaceAll(' ', '')
      //     optionsPlus.sub_end_date = i >= diffMonth ? endDate.format('YYYY MM DD').replaceAll(' ', '') : moment(`${year}-${month + 1}`).endOf('month').format('YYYY MM DD').replaceAll(' ', '')
      //     optionsPlus.interval = 32
      //     let responsePromise = apiInsightGeneralSearch(optionsPlus).then(res => {
      //       if (!res?.data?.data) {
      //         return
      //       }
      //       if (!responsePlus) {
      //         responsePlus = res?.data
      //         return
      //       }
      //       responsePlus.data = responsePlus.data.concat(res.data.data)
      //     })
      //     listPromise.push(responsePromise)
      //   }
      // } else {
      //   let responsePromise = apiInsightGeneralSearch(options).then(res => {
      //     response = res?.data
      //   })
      //   listPromise.push(responsePromise)
      //   if (options.interval === 7) {
      //     let optionsPlus = {...options}
      //     let endDate = moment(getDateFromOption(optionsPlus.end_date)).startOf('week').subtract(1, "day")
      //     optionsPlus.sub_end_date = endDate.format('YYYY MM DD').replaceAll(' ', '')
      //     let responsePlusPromise = apiInsightGeneralSearch(optionsPlus).then(async res => {
      //       responsePlus = res?.data
      //       if (!response?.data?.length) {
      //         await responsePromise
      //       }
      //       if (responsePlus?.data?.length && response?.data?.length) {
      //         const reducer = (accumulator, currentValue) => accumulator + +currentValue.value
      //         const totalResponse = response.data.reduce(reducer, 0)
      //         const totalResponsePlus = responsePlus.data.reduce(reducer, 0)
      //         responsePlus.data.push({
      //           value: totalResponse - totalResponsePlus,
      //           to: options.sub_end_date,
      //           from: optionsPlus.sub_end_date
      //         })
      //       }
      //     })
      //     listPromise.push(responsePlusPromise)
      //   }
      // }
      await Promise.all(listPromise)
      responseFinal = responsePlus ? responsePlus : response
      let generalStatisticKey = responseFinal.data_type === 'order_revenue' ? 'revenue' : 'orderCount'
      for (const datum of responseFinal.data) {
        datum.value = datum.value
          ? datum.value
          : datum.platforms?.reduce((result, currentValue) => result + currentValue.value, 0)
        || 0
      }
      let chartData = calculateAggregationsChartGeneralStatisticData(responseFinal.data, generalStatisticKey)
      await context.dispatch(`${MutationSearchStatisticResult.MODULE_PATH}/${MutationSearchStatisticResult[ALL_GENERAL_STATISTIC[generalStatisticKey].storeNameDetailStatistic]}`, chartData, {root: true})
    },
    async [MutationSearchFilter.RESET_SEARCH](context) {
      context.commit(MutationSearchFilter.RESET)
      await context.dispatch(`${MutationSearchStatisticResult.MODULE_PATH}/${MutationSearchStatisticResult.RESET}`, true, {root: true})
      await context.dispatch(`${MutationSearchStatisticComparisonResult.MODULE_PATH}/${MutationSearchStatisticComparisonResult.RESET}`, true, {root: true})
      await context.dispatch(`${MutationSearchKeywordsResult.MODULE_PATH}/${MutationSearchKeywordsResult.RESET}`, true, {root: true})
      await context.dispatch(`${MutationSearchShopsResult.MODULE_PATH}/${MutationSearchShopsResult.RESET}`, true, {root: true})
      await context.dispatch(`${MutationSearchBrandsResult.MODULE_PATH}/${MutationSearchBrandsResult.RESET}`, true, {root: true})
    },
    [MutationSearchFilter.RESET](context) {
      context.commit(MutationSearchFilter.RESET)
    }
  }
}
