import {localStorageGet, localStorageSet} from '@/helper/LocalStorageHelper'
import {LocalStorageKeys} from '@/constant/localstorage/LocalStorageConstant'
import {
  apiGetCustomQuery, apiGetPromotedVoucher,
  apiGetUserProfileMarket,
  apiShareCustomQuery
} from '@/api/user/ApiUserProfile'
import {readCookie, setCookie} from "@/helper/CookieHelper"
import {apiSaveReferralTracking} from "@/api/referral/ApiReferralTracking"

const getAccessToken = () => {
  return localStorageGet(LocalStorageKeys.ACCESS_TOKEN)
}

const setAccessToken = async (accessToken) => {
  if (accessToken == null || accessToken.length === 0) {
    console.warn('accessToken: ', accessToken)
  }
  await localStorageSet(LocalStorageKeys.ACCESS_TOKEN, accessToken)
}

const getVoucherInfo = async () => {
  return localStorageGet(LocalStorageKeys.VOUCHER_INFO)
}

const setVoucherInfo = async (selectedVoucherId, voucherCode) => {
  const voucherInfoLocal = await getVoucherInfo()
  let voucherInfo = {
    selectedVoucherId: selectedVoucherId || voucherInfoLocal?.selectedVoucherId,
    voucherCode: voucherCode || voucherInfoLocal?.voucherCode
  }
  await localStorageSet(LocalStorageKeys.VOUCHER_INFO, voucherInfo)
}

const resetVoucherInfo = async () => {
  await localStorageSet(LocalStorageKeys.VOUCHER_INFO, null)
}

const getListVoucherWaiting = async () => {
  return localStorageGet(LocalStorageKeys.LIST_VOUCHER_WAITING)
}

const addVoucherWaiting = async (listVoucherCode) => {
  if (!listVoucherCode?.length) {
    return
  }
  let listVoucherWaiting = await getListVoucherWaiting()
  listVoucherWaiting = listVoucherWaiting ? listVoucherWaiting : []
  listVoucherCode.forEach(
    voucherCode => {
      if (listVoucherWaiting?.includes(voucherCode)) {
        return
      }
      listVoucherWaiting.push(voucherCode)
    }
  )
  await localStorageSet(LocalStorageKeys.LIST_VOUCHER_WAITING, listVoucherWaiting)
}

const removeVoucherWaiting = async (listVoucherCode) => {
  if (!listVoucherCode?.length) {
    return
  }
  let listVoucherWaiting = await getListVoucherWaiting()
  listVoucherWaiting = listVoucherWaiting ? listVoucherWaiting : []
  listVoucherCode.forEach(
    voucherCode => {
      let index = listVoucherWaiting.indexOf(voucherCode)
      if (index < 0) {
        return
      }
      listVoucherWaiting.splice(index, 1)
    }
  )
  await localStorageSet(LocalStorageKeys.LIST_VOUCHER_WAITING, listVoucherWaiting)
}

const resetListVoucherWaiting = async () => {
  await localStorageSet(LocalStorageKeys.LIST_VOUCHER_WAITING, null)
}

const logout = async () => {
  await localStorageSet(LocalStorageKeys.ACCESS_TOKEN, null)
  await localStorageSet(LocalStorageKeys.USER_PROFILE, null)
  await localStorageSet(LocalStorageKeys.FULL_NAME, null)
}

const isLogined = async () => {
  let accessToken = getAccessToken()
  if (accessToken == null || accessToken.length === 0) {
    return false
  }
  return true
}

const updateProfileUserCurrent = async () => {
  if (!await isLogined()) {
    return null
  }
  let response = await apiGetUserProfileMarket()
  if (response === undefined) {
    return response
  }
  if (response?.message === 'Invalid token.') {
    await localStorageSet(LocalStorageKeys.ACCESS_TOKEN, null)
    await localStorageSet(LocalStorageKeys.USER_PROFILE, null)
    await localStorageSet(LocalStorageKeys.FULL_NAME, null)
    return null
  }
  let userProfile = response
  if (userProfile != null && userProfile['id'] != null) {
    let full_name = `${userProfile['last_name']} ${userProfile['first_name']}`
    userProfile = {
      ...userProfile,
      full_name
    }

    let referralMarketTid = readCookie('market_tid', null)
    let saveReferralMarketResponse = await apiSaveReferralTracking(referralMarketTid)
    if (saveReferralMarketResponse !== null) {
      setCookie('market_tid', null)
      let referral_email = `${saveReferralMarketResponse.referral_email}`

      userProfile = {
        ...userProfile,
        referral_email
      }
    }


    await localStorageSet(LocalStorageKeys.USER_PROFILE, userProfile)
    await localStorageSet(LocalStorageKeys.FULL_NAME, full_name)
  } else {
    console.warn('not found profile', userProfile)
  }
  return userProfile
}

/**
 * Get promoted voucher
 * @return {Promise<null|{messenger_url: string}>}
 */
const getPromotedVoucher = async () => {
  let response = await apiGetPromotedVoucher()
  if (response != null) {
    return response
  }
  return null
}

/**
 * Get User Information from storage
 * @return {Promise<null|any|string>}
 */
const getUserProfile = async () => {
  let profile = localStorageGet(LocalStorageKeys.USER_PROFILE)
  if (profile != null) {
    return profile
  }
  return null
}


/**
 * Send hash to server and get the raw_query
 * @param hash {string}
 * @return {Promise<object>}
 */
const getCustomQuery = async (hash) => {
  return await apiGetCustomQuery(hash)

}

/**
 * Get hash string of options
 * @param options {object}
 * @return {Promise<string>}
 */
const shareCustomQuery = async (options) => {
  return await apiShareCustomQuery(options)
}

/**
 * Save expiration date to local storage
 * @param expirationDateString
 */
const setExpirationDate = (expirationDateString) => {
  localStorage.setItem(LocalStorageKeys.EXPIRATION_DATE, expirationDateString)
}

export {
  getAccessToken,
  setAccessToken,
  getVoucherInfo,
  setVoucherInfo,
  resetVoucherInfo,
  logout,
  updateProfileUserCurrent,
  getUserProfile,
  isLogined,
  getCustomQuery,
  shareCustomQuery,
  setExpirationDate,
  getListVoucherWaiting,
  addVoucherWaiting,
  removeVoucherWaiting,
  resetListVoucherWaiting,
  getPromotedVoucher
}
