import {isEmptyElement} from "ant-design-vue/lib/_util/props-util";

const toTitleCase = (phrase) => {
  if (phrase == null) {
    return phrase
  }
  return phrase
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0)
      .toUpperCase() + word.slice(1))
    .join(' ')
}
const toSeoName = (name, defaultValue = 'product', maxLength = 100) => {
  if (name == null) {
    return defaultValue
  }
  name = name.toLowerCase().normalize('NFD')
    // .replace(/[^A-Za-z0-9 -]/g, '')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[đĐ]/g, 'd')
    .replace(/([^0-9A-Za-z-\s])/g, '')
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-')
    .replace(/^-+|-+$/g, '')
    .replace('%', '')
    .replace('+', '')
    .replace(/\(+/g, '')
    .replace(/\)+/g, '')
  if (name.length === 0) {
    return encodeURIComponent(defaultValue)
  } else if (name.length > maxLength) {
    return encodeURIComponent(name.substring(0, maxLength))
  }
  return encodeURIComponent(name)
}

const copyToClipboard = (copyText) => {
  try {
    let temp = document.createElement('textarea');
    temp.value = copyText;
    document.body.appendChild(temp);
    temp.select();
    document.execCommand('copy');
    document.body.removeChild(temp);
    return true
  } catch (e) {
    return false
  }
}

/**
 * convert string (included locale string) to number with all number in this string
 * @param string{string | number}
 * @return {number}
 */
const toNumber = (string) => {
  if (typeof string === 'number') {
    return string
  }
  if (typeof string === "string") {
    let localeCharacter = 1.2.toLocaleString().replace(/[0-9]/g,'')
    let stringRegexMatch = new RegExp(`[0-9${localeCharacter}]+`, 'g')
    let result = Number.parseFloat(+string.match(stringRegexMatch)?.join(''))
    return result ? result : 0
  }
  return 0
}

/**
 * Check string is a http/https url
 * @param string
 * @return {boolean}
 */
function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

export {
  toTitleCase,
  toSeoName,
  copyToClipboard,
  toNumber,
  isValidHttpUrl
}
