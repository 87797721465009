import axios from 'axios'
import { getAccessToken } from '@/service/user/UserProfileService'
import {HEADER_CONFIG} from "@/constant/general/GeneralConstant";

// import { PROJECT_MODE } from '@/config/ConfigProject'
// import * as ExtensionStorageHelper from '@/helpers/ExtensionStorageHelper'

let http = axios.create({
  withCredentials: true,
  crossDomain: true
})

let httpDefault = axios.create({})

const middlewareAddHeader = async function (config) {
  const visitor = JSON.parse(localStorage.getItem("__visitor"))
  config.headers.PlatformId = HEADER_CONFIG.platformId
  config.headers.VisitorId = !visitor ? null : visitor["visitor_id"]
  if (HEADER_CONFIG.token) {
    config.headers.Token = HEADER_CONFIG.token
    // config.headers.Authorization = `Token ${HEADER_CONFIG.token}`
  } else {
    let accessToken = getAccessToken()
    if (accessToken != null && accessToken.length > 0) {
      // console.log('add accessToken header', accessToken)
      // config.headers.Authorization = `Token ${accessToken}`
      config.headers.Token = accessToken
    }
  }
  // let clientExtId = await ExtensionStorageHelper.get(LOCALSTORAGE_CLIENT_EXT_ID)
  // let clientWebId = await ExtensionStorageHelper.get(LOCALSTORAGE_CLIENT_WEB_ID)
  // if (accessToken != null && typeof accessToken === 'string' && accessToken.length > 0) {
  //   config.headers.Authorization = `Token ${accessToken}`
  // }
  // if (clientExtId != null) {
  //     config.headers['X-Client-Ext-Id'] = `${clientExtId}`
  // }
  // if (clientWebId != null) {
  //     config.headers['X-Client-Web-Id'] = `${clientWebId}`
  // }
  // config.crossdomain = true
  return config
}

http.interceptors.request.use(middlewareAddHeader)
httpDefault.interceptors.request.use(middlewareAddHeader)

http.interceptors.response.use(response => {
    if (response?.['data'] && response.data['message'] != null && ['Invalid token.', 'Authentication credentials were not provided.'].includes(response.data['message'])) {

      // if (error.response.status === 200) {
      //     let token = getLocalStorage(Authentication.REFRESH_TOKEN_KEY)
      //     if(token && token.length > 0){
      //         return refreshToken().then(() => {
      //             return http(originalRequest)
      //         }).catch(() => {
      //             logout()
      //             window.location.href = '/login'
      //         })
      //     } else {
      // logout()
      //   .then(() => {
          // window.location.href = '/login'
        // })
      //     }
      // }
    }
    return response
  },
  error => {
    let originalRequest = error.config
    if (error.response.status === 401) {
      localStorage.removeItem("access_token")
      localStorage.removeItem("refresh_token")
      localStorage.removeItem("user_profile")
      localStorage.removeItem("full_name")
      window.location.href = '/analytics'
    }
    // let originalRequest = error.config
    // if (error.response.status === 200) {
    //     let token = getLocalStorage(Authentication.REFRESH_TOKEN_KEY)
    //     if(token && token.length > 0){
    //         return refreshToken().then(() => {
    //             return http(originalRequest)
    //         }).catch(() => {
    //             logout()
    //             window.location.href = '/login'
    //         })
    //     } else {
    //         logout()
    //         window.location.href = '/login'
    //     }
    // }
    return Promise.reject(error)
  }
)

httpDefault.interceptors.response.use(response => {
    return response
  },
  error => {
    let originalRequest = error.config
    if (error.response.status === 401) {
      localStorage.removeItem("access_token")
      localStorage.removeItem("refresh_token")
      localStorage.removeItem("user_profile")
      localStorage.removeItem("full_name")
      window.location.href = '/analytics'
    }
    // let originalRequest = error.config
    // if (error.response.status === 200) {
    //     let token = getLocalStorage(Authentication.REFRESH_TOKEN_KEY)
    //     if(token && token.length > 0){
    //         return refreshToken().then(() => {
    //             return http(originalRequest)
    //         }).catch(() => {
    //             logout()
    //             window.location.href = '/login'
    //         })
    //     } else {
    //         logout()
    //         window.location.href = '/login'
    //     }
    // }
    return Promise.reject(error)
  }
)


export { http, httpDefault }
