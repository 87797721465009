import {urlGetProfileMarket, urlCustomQuery} from '@/api/user/UserEndpoint'
import { http } from '@/plugin/http'
import {
  urlApiCheckMe,
  urlApiGetPromotedVoucher,
  urlApiRegisterMe
} from "@/api/market/MarketEndpoint";

const apiGetUserProfileMarket = async () => {
  let urlApi = urlGetProfileMarket()
  try {
    let response = await http.get(urlApi)

    if (response?.data) {
      return response.data
    }
  } catch (e) {
    console.error(e)
    return undefined
  }

}

/**
 * Send a get http request with hash to get custom query
 * @param hash {string}
 * @return {Promise<object>}
 */
const apiGetCustomQuery = async (hash) => {
  let urlApi = urlCustomQuery(hash)
  try {
    let response = await http.get(urlApi)
    if (response?.data) {
      return response.data?.raw_query
    }
  } catch (e) {
    console.error(e)
  }
}

/**
 * Send Post http request with payload is options to server and get a hash string
 * @param options {object}
 * @return {Promise<string>}
 */
const apiShareCustomQuery = async (options) => {
  let urlApi = urlCustomQuery()
  try {
    let response = await http.post(urlApi, options)
    if (response?.data) {
      return response.data?.hash
    }
  } catch (e) {
    console.error(e)
  }
}

const apiCheckMe = async () => {
  const urlApi = urlApiCheckMe()
  try {
    const response = await http.get(urlApi)
    if (response?.data != null) {
      return response.data
    }
  } catch (e) {
    console.error(e)
  }
  return null
}

const apiRegisterMe = async () => {
  const urlApi = urlApiRegisterMe()
  try {
    const response = await http.get(urlApi)
    if (response?.data != null) {
      return response.data
    }
  } catch (e) {
    console.error(e)
  }
  return null
}

const apiGetPromotedVoucher = async () => {
  const urlApi = urlApiGetPromotedVoucher()
  try {
    const response = await http.get(urlApi)
    if (response?.data?.data != null) {
      return response.data?.data
    }
  } catch (e) {
    console.error(e)
  }
  return null
}

export {
  apiGetUserProfileMarket,
  apiGetCustomQuery,
  apiShareCustomQuery,
  apiCheckMe,
  apiRegisterMe,
  apiGetPromotedVoucher
}
