/**
 * check normal object is same or not
 * normal object is only contain Primitive type
 */
const isSameNormalObject = (condition1, condition2) => {
  if (!condition1 || !condition2) {
    return false
  }
  return JSON.stringify(condition1) === JSON.stringify(condition2)
}

export const camelToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);


export {
  isSameNormalObject
}
